import React from "react";
import {InputLogin} from "src/component/Useful/Input/InputLogin";
import "./style/RecoveryEmailUser.scss"
import Icon from "../../../Icon/Sprite";
import {ICONS} from "../../../../assets/sprite/iconsNames";
import {FormattedMessage, injectIntl} from "react-intl";
import {Col, Form, Row} from "antd";
import UserPicture from "../../../../assets/image/PictureLoginUser.png";
import {useNavigate} from "react-router";
import {ButtonUser} from "../../../Useful/Button/ButtonUser";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../redux/auth/actions";

const {recoverPassword} = actions

function RecoveryEmailUser() {

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [email, setEmail] = React.useState(null)
    const [stateResponse, setStateResponse] = React.useState(false)

    const recoverPasswordSuccess = useSelector(state => state.AuthReducer.recoverPasswordSuccess)

    React.useEffect(()=>{
        if(recoverPasswordSuccess){
            setStateResponse(true)
        }
    },[recoverPasswordSuccess])

    const handleClick = () =>{
        if(email){
            let data = {
                email: email,
                context: "user"
            }
            dispatch(recoverPassword(data))
        }
    }

    const goTo = (param) =>{
        if(param === "back"){
            navigate("/user/login")
        }
    }

    return(
        <div className={"containerUserRecoveryEmail"}>
            <div  className={"contentUserRecoveryEmail"}>
                <Row>
                    <Col md={{span: 9}} sm={{span: 20}} xs={{span: 24}}>
                        <div className={"image"}>
                            <img src={UserPicture} className={"side-image"}/>
                        </div>
                    </Col>
                    <Col md={{span: 12}} sm={{span: 20}} xs={{span: 24}} className={"flex-column-center"}>
                        {
                            stateResponse === false ? (
                                <div className={"contentRecoveryEmailUser"}>
                                    <header>
                                        <div className="header_mobile">
                                            <div className={"back"}>
                                                <span onClick={() => goTo("back")}><Icon name={ICONS.arrow_2} className="pointer" size={[20,20]}/></span>
                                                <div className="p_back margin-5">Indietro</div>
                                            </div>
                                            <h1><FormattedMessage id="RecoveryEmail_title"/></h1>
                                        </div>
                                        <div className="header_recovery">
                                            <p><FormattedMessage id="RecoveryEmail_HeaderText"/></p>
                                        </div>
                                    </header>

                                    <main>
                                        <p><FormattedMessage id="RecoveryEmail_Email"/></p>
                                        <Form.Item
                                            name={"email"}
                                            type={"email"}
                                            rules={[{type: 'email', message: false}]}
                                            className="inline-block width-100"
                                        >
                                            <InputLogin
                                                className="opacity-50"
                                                onChange={(e) => setEmail(e.target.value)}
                                                prefix={<Icon name={ICONS.mail} size={[20,20]}/>}
                                                placeholder="Inserire l'email"
                                            />
                                        </Form.Item>
                                    </main>
                                    <footer>
                                        <ButtonUser onClick={handleClick} block icon={<Icon name={ICONS.tik} size={[20,20]} className="opacity-50 margin-5"/>} className={"flex-center"}>
                                            <FormattedMessage id="RecoveryEmail_Button"/>
                                        </ButtonUser>
                                    </footer>
                                </div>
                            ) : (
                                <div className={"containerConfirmRecovery"}>
                                    <h1><FormattedMessage id="ConfirmRecovery_title"/></h1>
                                    <p><FormattedMessage id="ConfirmRecovery_text"/></p>
                                </div>
                            )
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default injectIntl(RecoveryEmailUser)
