import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import actions from "./actions";
import authActions from "src/redux/auth/actions";
import SuperFetch from "src/helpers/superFetch";
import {endpoints} from "src/config/site.config";

export function* sendEmail() {

    yield takeEvery(actions.SEND_EMAIL, function* (data) {
        let email={
            recipient: data.recipient,
            object: data.object,
            body: data.body
        }
        yield put({type: authActions.LOADING_TRUE});
        const { response, error } = yield call(SuperFetch.post, endpoints.email, email,true, false)
        yield put({type: authActions.LOADING_FALSE});
        if (response) {
            yield put({
                type: actions.SEND_EMAIL_SUCCESS
            });

        } else {
            yield put({
                type: actions.SEND_EMAIL_ERROR,
                payload: error
            });
        }
    })



}

export function* getEmail() {

    yield takeEvery(actions.GET_EMAIL, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const { response, error } = yield call(SuperFetch.get, endpoints.getEmail, null, true)
        yield put({type: authActions.LOADING_FALSE});
        if (response) {
            yield put({
                type: actions.GET_EMAIL_SUCCESS,
                payload: response
            });

        } else {
            yield put({
                type: actions.GET_EMAIL_ERROR,
                payload: error
            });
        }
    })
}


export default function* rootSaga() {
    yield all([
        fork(sendEmail),
        fork(getEmail)
    ]);
}
