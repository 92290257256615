import React, {useReducer} from "react";
import {Navigate, useLocation, useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import actions from "./redux/auth/actions";
import {PRIVATE_ADMIN_ROUTE, PRIVATE_COMPANY_ROUTE, PRIVATE_USER_ROUTE, PUBLIC_ROUTE} from "./route.constants";
import adminActions from "src/redux/admin/actions"
const {handleLandingModal} = adminActions

export default function SaveUrlDatas() {

    const navigateUrl = useLocation()
    const dispatch = useDispatch()

    const isLoggingOut = useSelector(state => state.AuthReducer.isLoggingOut)
    let isLoggingInCompanyRoute = false
    let isLoggingInUserRoute = false
    const {saveUrl} = actions

    if(!isLoggingOut){
        if(Object.values(PRIVATE_ADMIN_ROUTE).includes(navigateUrl.pathname)){
            dispatch(handleLandingModal(true))
            dispatch(saveUrl(navigateUrl))
        } else if (navigateUrl.pathname === PRIVATE_COMPANY_ROUTE.WORK_OFFERS){
            dispatch(saveUrl(navigateUrl))
            isLoggingInCompanyRoute = true
        } else if (navigateUrl.pathname === PRIVATE_USER_ROUTE.JOB_OFFER){
            dispatch(saveUrl(navigateUrl))
            isLoggingInUserRoute = true
        }
    }

    return (
        <>
            <Navigate to={isLoggingInCompanyRoute ? PUBLIC_ROUTE.COMPANY_LOG_IN : (isLoggingInUserRoute ? PUBLIC_ROUTE.USER_LOG_IN : "/")} replace/>
        </>
    )
}