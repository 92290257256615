const actions = {

    INIT_PREFERENCE: 'INIT_PREFERENCE',

    GET_ALL_PREFERENCE: 'GET_ALL_PREFERENCE',
    GET_ALL_PREFERENCE_SUCCESS: 'GET_ALL_PREFERENCE_SUCCESS',
    GET_ALL_PREFERENCE_ERROR: 'GET_ALL_PREFERENCE_ERROR:',

    SAVE_PREFERENCE: "SAVE_PREFERENCE",
    SAVE_PREFERENCE_SUCCESS: "SAVE_PREFERENCE_SUCCESS",
    SAVE_PREFERENCE_ERROR: "SAVE_PREFERENCE_ERROR",

    DELETE_PREFERENCE: "DELETE_PREFERENCE",
    DELETE_PREFERENCE_SUCCESS: "DELETE_PREFERENCE_SUCCESS",
    DELETE_PREFERENCE_ERROR: "DELETE_PREFERENCE_ERROR",

    CLOSE_VIEW_COMPONENT: "CLOSE_VIEW_COMPONENT",

    RESET_PREFERENCE: "RESET_PREFERENCE",


    getAll: () => ({
        type: actions.GET_ALL_PREFERENCE,
    }),

    savePreference: (data) => ({
        type: actions.SAVE_PREFERENCE,
        id: data
    }),

    deletePreference: (data, deleteType) => ({
        type: actions.DELETE_PREFERENCE,
        id: data,
        deleteType: deleteType
    }),

    resetStatePreference: ()=>({
        type: actions.RESET_PREFERENCE
    })
};

export default actions;
