import React from "react";
import ConfirmPicture from "../../../../assets/image/PictureRegistrationComplete.png";
import "./confirmRegistration.scss"
import {FormattedMessage, injectIntl} from "react-intl";
import {Col, Row} from "antd";

function ConfirmRegistrationUser() {


    return(
        <div className={'confirm-container'}>
            <div className={"confirm-content"}>
                <Row>
                    <Col xl={{span: 8, offset: 2}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                    <div className={"image"}>
                        <img src={ConfirmPicture} className={"side-image"}/>
                    </div>
                    </Col>
                    <Col xl={{span: 12, offset: 2}} md={{span: 12, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <div className="cen">
                            <div className={"textConfirm"}>
                                <h1><FormattedMessage id="perfect"/></h1>
                                <h2><FormattedMessage id="profileCreated"/></h2>
                                <p><FormattedMessage id="text_ProfileCreated"/></p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default injectIntl(ConfirmRegistrationUser)
