import React from "react";
import {Button as AntInput} from "antd";
import styled from "styled-components"

export const ButtonLine = styled(AntInput)`

  &.ant-btn{
    height: 48px;
    min-width: ${props => props.width ? props.width : "130px"};
    /* White */

    background: #FFFFFF;
    /* Dark/Light/80 */

    border: 1px solid #CFCFD5;
    box-shadow: none;
    border-radius: 3px;
    
    /* White */
    
    color: #208AC6;
  }
  
  &.ant-btn-default{
    height: 48px;
    min-width: 130px;
    
    background: #FFFFFF;
    /* Dark/Light/80 */

    border: 1px solid #CFCFD5;
    border-radius: 3px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    
    
    
    /* White */
    
    color: #208AC6;
  }
  
  &.ant-btn-default:hover{
    border-radius: 3px;
    border: 1px solid #8FC4E2;
    color: #208AC6;
  }
  &.ant-btn-default:focus{
    background: #FFFFFF;
    border-radius: 3px;
    color: #208AC6;
    border: 1px solid #8FC4E2;
  }
  &.ant-btn-default:active{
    background: #FFFFFF;
    border-radius: 3px;
    color: #208AC6;
    border: 1px solid #8FC4E2;
  }
  &.ant-btn-default:disabled{
    /* Dark/Light/90 */
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid #E7E7EA;
    border-radius: 3px;
  }
  &.ant-btn-block{
    width: 100%;
  }
`
