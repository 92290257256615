import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";


function Cv(props) {
    const cv = useSelector(state => state.Candidature.cv)

    return(
        <div>
            <object data={cv} type="application/pdf" width="100%" height="800px">
                <embed src={cv} type="application/pdf" />
            </object>
        </div>
    )
}

export default Cv
