import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom"
import {useSelector} from "react-redux";
import "../../variable.scss"
import Footerbar from "../../component/Layout/Footbar/footerbar";
import Topbar from "src/component/Layout/Topbar/landingTopbar";
import {useLocation, useNavigate} from "react-router";
import {CONTEXT, ROLES} from "src/utils/utility";
import {PRIVATE_ADMIN_ROUTE, PRIVATE_COMPANY_ROUTE, PUBLIC_ROUTE} from "../../route.constants";

export default function Home(props) {

    let {pathListOfUsersGradients, pathListOfCompanyGradients} = props

    const navigate = useNavigate()
    const loading = useSelector(state => state.AuthReducer.loading)
    const [currentContext, setCurrentContext] = useState(null)
    const [gradientClassName, setGradientClassName] = useState(null)
    const isLoggedIn = useSelector(state => state.AuthReducer.idToken)
    const role = useSelector(state => state.AuthReducer.role)
    const redirectUrl = useSelector(state => state.AuthReducer.url)

    const location = useLocation();

    useEffect(() => {
        let openStatusCompany = localStorage.getItem("openStatusCompany")
        let userRole = localStorage.getItem('role')
        if(isLoggedIn && redirectUrl && Object.values(PRIVATE_COMPANY_ROUTE).includes(redirectUrl.pathname) && (userRole === ROLES.EMPLOYEE || userRole === ROLES.ADMIN)){
            navigate(redirectUrl)
        }else if(isLoggedIn && redirectUrl && Object.values(PRIVATE_ADMIN_ROUTE).includes(redirectUrl.pathname) && userRole === ROLES.SYS_ADMIN){
            navigate(redirectUrl)
        }else if(isLoggedIn && redirectUrl && userRole === ROLES.USER){
            navigate(redirectUrl)
        }else if (!redirectUrl && isLoggedIn && role === ROLES.SYS_ADMIN) {
            navigate(PRIVATE_ADMIN_ROUTE.DASHBOARD)
        }else if (isLoggedIn && (role === ROLES.ADMIN || role === ROLES.EMPLOYEE) && openStatusCompany){
            navigate(PRIVATE_COMPANY_ROUTE.WORK_OFFERS)
        }else if(openStatusCompany){
            navigate(PUBLIC_ROUTE.COMPANY_LOG_IN)
        }
    }, [isLoggedIn])

    useEffect(() => {
        if (pathListOfUsersGradients.includes(location.pathname)) {
            setCurrentContext(CONTEXT.USER)
            setGradientClassName("bgUser")
        } else if (pathListOfCompanyGradients.includes(location.pathname)) {
            setCurrentContext(CONTEXT.COMPANY)
            setGradientClassName("bgCompany")
        } else if (location.pathname === PUBLIC_ROUTE.LANDING) {
            setCurrentContext(CONTEXT.LANDING)
            setGradientClassName(null)
        } else {
            setCurrentContext(null)
            setGradientClassName(null)
        }
    }, [location])

    return (
        <div className={"content-container " + gradientClassName}>
                <Topbar context={currentContext}/>

                <div className={'content landing'}>
                    <Outlet/>
                </div>

                <Footerbar/>
        </div>
    )

}
