import React, { useEffect, useState } from "react";
import "./superAdmin.scss"
import Icon from "src/component/Icon/Sprite";
import { ICONS } from "src/assets/sprite/iconsNames";
import Breadcrumb from "antd/es/breadcrumb";
import { PRIVATE_ADMIN_ROUTE } from "src/route.constants";
import { FormattedMessage } from "react-intl";
import { Divider, message, Table } from "antd";
import InputSearch from "src/component/Useful/Input/InputSearch";
import { Select } from "src/component/Useful/Select/Select";
import sprite from "src/assets/sprite/sprite.svg";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import actions from "src/redux/admin/actions";
import locationActions from "src/redux/location/actions";
import offerActions from "src/redux/offer/actions";
import { SORTING } from "src/utils/utility";
import dayjs from "dayjs";
import { Switch } from "src/component/Useful/Switch"
import { CustomDrawer } from "src/component/Layout/Drawer/CustomDrawer";
import drawerActions from "src/redux/drawer/actions";
import companyActions from "src/redux/company/actions";
import employeeActions from "src/redux/employee/actions";
import { Input } from "../../component/Useful/Input/Input";
import _ from "lodash";
import { PreviousMap } from "postcss";

const queryParams = new URLSearchParams(window.location.search)
const open = queryParams.get("open")

function AdminCompanies() {

    const id = queryParams.get("id")
    const companyId = queryParams.get("companyId")

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [messageApi, contextHolder] = message.useMessage();

    const { getAllAdminCompany, enableCompany, disableCompany, deleteCompany } = actions;
    const { openDrawer } = drawerActions;
    const { offertToModify } = companyActions;
    const { getAllCompanyLocationBySysAdmin } = locationActions;
    const { get } = offerActions;
    const { getAllBySysAdmin } = employeeActions;


    const companies = useSelector(state => state.Admin.companies)
    const total = useSelector(state => state.Admin.total)
    const reload = useSelector(state => state.Admin.reload)
    const record = useSelector(state => state.Offer.offer)
    const isDrawerOpen = useSelector(state => state.UserProfile.isDrawerOpen)
    const isOffersCreatedSuccess = useSelector(state => state.Offer.isOffersCreatedSuccess)


    const [objectFilters, setObjectFilters] = useState({
        page: 1,
        pageSize: 10,
        search: null,
        sort: "DESC", 
        state: "all"
    })

    useEffect(() => {
        if (reload) {
            dispatch(getAllAdminCompany(objectFilters))
        }
    }, [reload])

    React.useEffect(() => {
        dispatch(getAllAdminCompany(objectFilters))
    }, [objectFilters])


    const handleObjectFilters = (e) => {
        setObjectFilters(
            {
                page: e.page ? e.page : 1,
                pageSize: e.pageSize ? e.pageSize : 10,
                search: e.search && e.search ? e.search : null,
                sort: e.sort && e.sort ? e.sort : "DESC",
                state: e.state ? e.state : "all"
            }
        )
    }

    const searchOnChange = (value) => {
        handleObjectFilters({ 
            ...objectFilters,
            search: value })
    }


    const columns = [
        {
            title: 'Candidati',
            render: (text, record) => (
                <div className="flex-column">
                    <div className="companyName weight-600 size-14">
                        {record.companyName}
                    </div>
                    <div className="dateCreated">
                        {(dayjs(record.registrationDate).format("DD MMM YYYY"))}
                    </div>
                </div>
            )
        },
        {
            title: 'P.IVA',
            dataIndex: "vatNumber",
        },
        {
            title: "Telefono",
            dataIndex: "phoneNumber"
        },
        {
            title: 'Città',
            dataIndex: "city"
        },
        {
            title: 'Responsabile',
            dataIndex: "adminNameSurname"
        },
        {
            title: 'Offerte',
            dataIndex: "offertCount",
            render: (text) => (
                <div className="flex-center">
                    <Icon name={ICONS.doc} size={[20, 20]} />
                    <span className="margin-left-8">
                        {text}
                    </span>
                </div>
            )
        },
        {
            title: 'Candidati',
            dataIndex: "candidateCount",
            render: (text) => (
                <div className="flex-center">
                    <Icon name={ICONS.user} size={[20, 20]} />
                    <span className="margin-left-8">
                        {text}
                    </span>
                </div>
            )
        },
        {
            title: 'Attivo',
            dataIndex: "enabled",
            render: (text, record) => (
                <div>
                    <Switch
                        checkedChildren={<Icon name={ICONS.ok} size={[20, 20]} />}
                        unCheckedChildren={<Icon name={ICONS.close_switch} size={[20, 20]} />}
                        checked={text}
                        onChange={() => {
                            if (record.enabled) {
                                dispatch(disableCompany(record.id))

                            } else if (!record.enabled) {
                                dispatch(enableCompany(record.id))
                            }
                        }}
                    />
                </div>
            )
        },
        {
            render: (text, record) => (
                <div className="pointer" onClick={() => dispatch(deleteCompany(record.id))}>
                    <Icon name={ICONS.delete} size={[20, 20]} />
                </div>
            )
        }
    ];



    useEffect(() => {

        if (id) {
            dispatch(get(id))
        }

        if (companyId) {
            dispatch(getAllBySysAdmin(companyId))
            dispatch(getAllCompanyLocationBySysAdmin(companyId))
        }

        if (open) {
            dispatch(openDrawer())
            dispatch(offertToModify())
        }
    }, [id, companyId, open])


    useEffect(() => {
        if (isOffersCreatedSuccess) {
            messageApi.open({
                type: 'success',
                content: 'Offerta modifica notificata per email con successo!'
            })
        }
    }, [isOffersCreatedSuccess])

    return (
        <div className="admin">
            {contextHolder}
            <div className="max-width-1200 padding-50">
                <div>
                    <Breadcrumb className="customBreadcrumb" separator="›">
                        <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_ADMIN_ROUTE.DASHBOARD)}>
                            <FormattedMessage id="Breadcrumb_home" />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <FormattedMessage id="Breadcrumb_company" />
                        </Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="display-flex align-items-center">
                        <span className="size-28 weight-700 merriWeather">
                            Tutte le aziende
                        </span>
                    </div>

                    <Divider />

                    <div className="flex-center-between margin-bottom-24">
                        <div className="size-18 weight-600">
                            Lista delle aziende
                        </div>
                        <div className="margin-right-32">
                            <Input className={"inputField"} placeholder={"Ricerca aziende"} 
                            onChange={(e) => searchOnChange(e.target.value)} 
                            prefix={<Icon name={ICONS.search} size={[20, 20]} color={"#868696"} />} 
                            />
                        </div>
                    </div>

                    <div className="flex-center-between margin-bottom-24">
                        <div className="size-16 weight-700 merriWeather">
                            <span className="size-16 weight-700 merriWeather">Risultati({total})</span>
                        </div>

                        <div className="flex-align-center">
                            <div className="margin-right-24">
                                <span>
                                    Stato:
                                </span>
                                <Select
                                    popupClassName="dropdown border-radius-0"
                                    bordered={false}
                                    dropdownMatchSelectWidth={false}
                                    style={{ width: "fit-content" }}
                                    defaultValue={"all"}
                                    value={objectFilters.state}
                                    suffixIcon={<Icon name={ICONS.arrow_small} size={[20, 20]} color="black" />}
                                    options={[
                                        {
                                            value: "all",
                                            label: "Tutte"
                                        },
                                        {
                                            value: "true",
                                            label: "Abilitate"
                                        },
                                        {
                                            value: "false",
                                            label: "Disabilitate"
                                        }
                                    ]}
                                    onChange={(state) => handleObjectFilters({
                                        ...objectFilters,
                                        state: state,
                                        page: 1
                                    })}
                                />
                            </div>
                            <div>
                                <span>
                                    Ordina per:
                                </span>
                                <Select
                                    popupClassName="dropdown border-radius-0"
                                    bordered={false}
                                    dropdownMatchSelectWidth={false}
                                    style={{ width: "fit-content" }}
                                    value={objectFilters.sort}
                                    suffixIcon={<Icon name={ICONS.arrow_small} size={[20, 20]} color="black" />}
                                    options={[
                                        {
                                            options: [
                                                { label: 'Recenti', value: 'DESC' },
                                                { label: 'Meno Recenti', value: 'ASC' },
                                            ],
                                        }
                                    ]}
                                    onChange={(value) => handleObjectFilters({
                                        ...objectFilters,
                                        sort: value,
                                        page: 1
                                    })}
                                >

                                </Select>
                            </div>
                        </div>

                    </div>

                    {companies && companies.length !== 0 ?
                        <div className={"tableWrapper"}>
                            <Table
                                className="offerDetailTable border-radius-0"
                                rowKey={record => record.id}
                                columns={columns}
                                dataSource={companies}
                                scroll={{ x: 700 }}
                                pagination={{
                                    showSizeChanger:false,
                                    current: objectFilters.page,
                                    pageSize: objectFilters.pageSize,
                                    total: total,
                                    onChange: (page) => {handleObjectFilters({
                                            ...objectFilters,
                                            page: page
                                        })
                                    },
                                }}
                            />


                            <div className={"page-size-changer"}>
                                <span className="text">
                                    <FormattedMessage id="resultsPerPage" />
                                </span>
                                <Select
                                    className={"selectSizeGetAll"}
                                    defaultValue={"10"}
                                    height="35px"
                                    suffixIcon={<Icon name={ICONS.arrow_small} size={[20, 20]} color="#208ac6" />}
                                    options={
                                        [
                                            { label: '10', value: '10' },
                                            { label: '20', value: '20' },
                                            { label: '50', value: '50' },
                                            { label: '100', value: '100' },
                                        ]
                                    }
                                    onChange={(pageSize) => {handleObjectFilters({
                                            ...objectFilters,
                                            pageSize: pageSize,
                                            page: 1
                                        })
                                    }}

                                />
                            </div>
                        </div>
                        :
                        <div className="noCompanies">
                            <div className="noCompaniesIcon flex-center">
                                <Icon className={"infoIcon"} name={ICONS.business_100} size={[100, 100]} color={"#C41A43"} />
                            </div>
                            <div className="size-18 black weight-600">
                                <FormattedMessage id="Notification_noCompanies_1" />
                            </div>
                            <div className="black opacity-70">
                                <FormattedMessage id="Notification_noCompanies_2" />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <CustomDrawer
                record={record}
                modify={"modify"}
                isDrawerOpen={isDrawerOpen}
                type={"offer"}
            />
        </div>
    )
}

export default AdminCompanies



