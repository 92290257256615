import React, {useState} from "react";
import {List} from "antd";
import useMediaQuery from "../../Useful/Responsive/UseMedia";
import ListInputMyCandidature from "../../Useful/ListInput/MyCandidature/ListInputMyCandidature";
import ListInputMobileCandidature from "../../Useful/ListInput/MyCandidature/ListInputMobileMyCandidature";
import DrawerListMyCandidatureMobile from "./DrawerListMyCandidatureMobile";

function ListMyCandidatureMobile(props){

    const {myCandidatures, idRecord, setIdRecord, recordOffer, viewComponent, setViewComponent} = props

    const isTablet = useMediaQuery('(min-width: 720px)')
    const isMobile = useMediaQuery('(min-width: 640px)')

    return(
        <div className={"flex-row"}>
            <div className={"width-100"}>
                <List bordered dataSource={myCandidatures} className={"pointer"}>
                    {myCandidatures && myCandidatures.map((element) => {
                        return (
                            <List.Item key={element.id} onClick={() => {
                                setIdRecord(element.id)
                                setViewComponent(true)
                            }}
                                className={element.id === idRecord ? "color-light-blue" : "white"}>
                                {isMobile ?
                                <ListInputMyCandidature element={element}/>:
                                <ListInputMobileCandidature element={element}/>}
                            </List.Item>
                        )
                    })}
                </List>
            </div>
            <DrawerListMyCandidatureMobile
                width={isTablet ? "70%" : "100%" }
                recordOffer={recordOffer}
                openState={viewComponent}
                setOpenState={setViewComponent}
                idRecord={idRecord}
            />
        </div>
    )
}

export default ListMyCandidatureMobile