const actions = {

    GET_SETTINGS: "GET_SETTINGS",
    GET_SETTINGS_SUCCESS: "GET_SETTINGS_SUCCESS",
    GET_SETTINGS_ERROR: "GET_SETTINGS_ERROR",

    UPDATE_SETTINGS: "UPDATE_SETTINGS",
    UPDATE_SETTINGS_SUCCESS: "UPDATE_SETTINGS_SUCCESS",
    UPDATE_SETTINGS_ERROR: "UPDATE_SETTINGS_ERROR",


    getSettings: () => ({
        type: actions.GET_SETTINGS,
    }),

    updateSettings: (data) => ({
        type: actions.UPDATE_SETTINGS,
        payload: data
    }),
};

export default actions;
