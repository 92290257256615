import React, {useEffect, useState} from "react";
import "src/component/Layout/Drawer/OfferUser/QuestionUser/QuestionUser.scss";
import {Row, Col, Form, message} from "antd";
import {FormattedMessage} from "react-intl";
import {useWindowSize} from "src/utils/utility";
import {useDispatch, useSelector} from "react-redux";
import {getImageSource} from "src/redux/company/saga";
import QuestionList from "./QuestionList";
import actions from "src/redux/candidature/actions";
import QuestionTablet from "./Responsive/QuestionTablet";
import QuestionMobile from "./Responsive/QuestionMobile";
import QuestionDesktop from "./Responsive/QuestionDesktop";

const {save} = actions

export function QuestionsUser() {

    let dispatch = useDispatch()

    const size = useWindowSize();

    const record = useSelector(state => state.UserProfile.recordOffer)

    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const [listQuestion, setListQuestion] = useState([])

    const firstName = localStorage.getItem("firstName")
    const lastName = localStorage.getItem("lastName")
    const email = localStorage.getItem("email")


    React.useEffect(()=>{
        getImageSource(record.logoPath)
    },[])

    const error = () => {
        messageApi.open({
            key: "errorAnswer",
            type: 'error',
            content: 'Rispondere a tutte le domande'
        });
    };

    useEffect( () => {
        if(record.question && record.question.length > 0){
            setListQuestion(record.question.map( el => ({question: el.question, answer: null})))
        }
    }, [record])

    function checkSizeScreen() {
        if(size.width > 980){
            return <QuestionDesktop record={record} firstName={firstName} lastName={lastName} email={email}/>
        }else if(size.width < 980 && size.width > 600){
            return <QuestionTablet record={record} firstName={firstName} lastName={lastName} email={email}/>
        }else{
            return <QuestionMobile record={record} firstName={firstName} lastName={lastName} email={email}/>
        }
    }

    return (
        <div className="max-width-1200 bodyPadding">
            <div className="flex-center">
                <Row>
                    {checkSizeScreen()}
                </Row>
            </div>

            <Form
                className="flex-center"
                id={"secondStepCandidatureUserForm"}
                form={form}
                onFinish = {() => {
                    //check se le domande sono vuote
                    if(!record.question){
                        dispatch(save({offertId: record.id}))
                    } else {
                        dispatch(save({
                            offertId: record.id,
                            answeredQuestions: listQuestion
                        }))
                    }

                }}
            >
                {record && record.question && (
                <div className="border question-body padding-40">
                    <Row gutter={[0, 24]}>
                        <Col span={24}>
                            <div className="red size-18 weight-700">
                                <FormattedMessage id="UserDetails_questions"/>
                            </div>
                        </Col>
                        <Col span={24}>
                            <QuestionList record={record} listQuestion={listQuestion} setListQuestion = {setListQuestion} />
                        </Col>
                    </Row>
                </div>)}
            </Form>
        </div>
    )
}
