import React from "react";
import Icon from "../../Icon/Sprite";
import {ICONS} from "../../../assets/sprite/iconsNames";
import {Card} from "antd";

function EmptySite(){
    return(
        <div>
            <Card>
                <div style={{display:"flex",justifyContent:"center", marginBottom:20}}>
                    <div className={"company-circle"}>
                        <Icon name={ICONS.doc_add_100} size={[100,100]} color="#C41A43"/>
                    </div>
                </div>
                <div style={{display:"flex",justifyContent:"center"}}>
                    <b>Non ci sono offerte create ancora</b>
                </div>
                <div style={{display:"flex",justifyContent:"center"}}><p>Qui visualizzerai le tutte le tue offerte</p></div>
            </Card>
        </div>
    )
}

export default EmptySite
