import React, { useEffect, useState } from "react";
import ModalCv from '../../component/Layout/Candidate/CadidateDetail/ModalCv';
import ModalCandidateAnswers from '../../component/Layout/Candidate/CadidateDetail/ModalCandidateAnswers';
import { AlertModal } from "../../component/Layout/AlertModal/AlertModal";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ALERT_TYPE, firstLetters, switchStateLabel } from "../../utils/utility";
import { Breadcrumb, Col, Divider, Row, Table } from "antd";
import { useNavigate } from "react-router";
import { PRIVATE_COMPANY_ROUTE, PRIVATE_ADMIN_ROUTE } from "src/route.constants";
import moment from "moment";
import { ButtonLine } from "../../component/Useful/Button/ButtonLine";
import { getAge } from "../../helpers/utility";
import Icon from "../../component/Icon/Sprite";
import { ICONS } from "../../assets/sprite/iconsNames";
import actions from "../../redux/admin/actions";


const { getAllCandidatureByUserId, getUserById } = actions

function UserDetail() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [candidateDetail, setCandidateDetail] = useState(null);
    const [isModalAnswersOpen, setIsModalAnswersOpen] = useState(false);
    const [row, setRow] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState({ value: null, flag: false });
    const candidateDetails = useSelector(state => state.Admin.candidateDetails);
    const userDetail = useSelector(state => state.Admin.userDetail);



    useEffect(() => {
        let id = window.location.pathname.split("/")[3]
        dispatch(getAllCandidatureByUserId(id))
        dispatch(getUserById(id))
    }, [])

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    

    const columns = [
        {
            title: "Candidature",
            width: "34%",
            dataIndex: "offertTitle",
            render: (text, record) => <div>
                <div className="weigth-600 size-14 underlined blue pointer" onClick={() => {
                    setIsModalAnswersOpen(true)
                    setRow(record)
                }}>
                    {record.offertTitle}
                </div>
            </div>
        },
        {
            title: "Tipologia Contratto",
            width: "33%",
            dataIndex: "impegnoRichiesto",
            render: (text, record) => <div>
                <p>{record.impegnoRichiesto}</p>
                <span>{record.offertInsertMode}</span>

            </div>
        },
        {
            title: "Stato",
            width: "33%",
            dataIndex: "candidatureState",
            render: (text, record) => <div>
                {record.candidatureState && switchStateLabel(record.candidatureState)}
            </div>
        },
    ]





    return (
        <div className={"candidateDetail"} >
            <div className={"maxWidth"}>
                <Breadcrumb className="customBreadcrumb" separator="›">
                    <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY)}>
                        <FormattedMessage id="Breadcrumb_home" />
                    </Breadcrumb.Item>

                    <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_ADMIN_ROUTE.USERS)}>
                        <FormattedMessage id="Breadcrumb_user" />
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        {userDetail && userDetail.firstName && userDetail.lastName &&
                            userDetail.firstName + " " + userDetail.lastName}
                    </Breadcrumb.Item>
                </Breadcrumb>


                <Row gutter={24}>
                    {candidateDetails &&
                        <Col span={8}>
                            <div className="border-div margin-bottom-20">
                                <div className="ab flex-center">
                                    <div className="bor">
                                        {userDetail &&
                                            <div className={"profile-circle"}>{firstLetters(userDetail.firstName, userDetail.lastName)}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="padding-left-32 padding-right-32">
                                    <div className="flex-center flex-column padding">
                                        <div className={"flex-center weight-700 size-22"}>{userDetail && userDetail.firstName && userDetail.lastName ? userDetail.firstName + " " + userDetail.lastName : null}</div>
                                        {userDetail && userDetail.birthday && <div className={"flex-center weight-400 size-14"}>Nato
                                            il {moment(parseInt(userDetail.birthday)).format("DD MMM. YYYY")}
                                            <div
                                                className="opacity-50 margin-left-5">({getAge(parseInt(userDetail.birthday))} anni)
                                            </div>
                                        </div>}
                                        <div className={"flex-center"}>{userDetail && userDetail.city && userDetail.city}</div>
                                    </div>

                                    <div className="flex-center">
                                        {
                                            userDetail && userDetail.linkedin &&
                                            <ButtonLine className="flex-center margin-5" style={{ height: "32px" }} icon={<Icon name={ICONS.linkedin} size={[20, 20]} className="flex-center margin-5" />}>
                                                <a href={userDetail.linkedin} target="_blank">
                                                    Linkedin
                                                </a>
                                            </ButtonLine>
                                        }

                                        <ButtonLine className="flex-center margin-5" onClick={() => showModal()} style={{ height: "32px" }} icon={<Icon name={ICONS.doc} size={[20, 20]} className="flex-center margin-5" />}>CV</ButtonLine>
                                    </div>
                                    <p className="weight-400 size-14 text-align-center">{userDetail && userDetail.description && userDetail.description}</p>
                                    <Divider />

                                    <p className="weight-600 size-12 opacity-50">LINGUE</p>
                                    {userDetail && userDetail.languages && userDetail.languages.map((item) =>
                                        <div className="weight-600 size-14 flex-align-center">{item.spokenLanguages}:<p className="weight-400 size-14 margin-5">{item.spokenLanguagesLevel}</p></div>
                                    )}

                                    <p className="weight-600 size-12 opacity-50">CONTATTI</p>
                                    <div className="weight-600 size-14 flex-align-center">Email:<p className="weight-400 size-14 margin-5">{userDetail && userDetail.email && userDetail.email}</p></div>
                                    <div className="weight-600 size-14 flex-align-center">Telefono:<p className="weight-400 size-14 margin-5">{userDetail && userDetail.telephone && userDetail.telephone}</p></div>

                                    <p className="weight-600 size-12 opacity-50">STATO LAVORATIVO E FORMAZIONE</p>
                                    <div className="weight-600 size-14 flex-align-center">Stato lavorativo:<p className="weight-400 size-14 margin-5">{userDetail && userDetail.jobState && userDetail.jobState}</p></div>
                                    {userDetail && userDetail.qualificationList && userDetail.qualificationList.map((item) =>
                                        <div className="weight-600 size-14 flex-align-center">Livello di istruzione:<p className="weight-400 size-14 margin-5">{item.qualificationLevel}</p></div>
                                    )}

                                    <p className="weight-600 size-12 opacity-50">INDIRIZZO</p>
                                    <div className="weight-600 size-14 flex-align-center">Indirizzo:<p className="weight-400 size-14 margin-5">{userDetail && userDetail.address && userDetail.address}</p></div>
                                    <div className="weight-600 size-14 flex-align-center">Città:<p className="weight-400 size-14 margin-5">{userDetail && userDetail.city && userDetail.city}</p></div>
                                    <div className="weight-600 size-14 flex-align-center margin-bottom-20">CAP:<p className="weight-400 size-14 margin-5">{userDetail && userDetail.cap && userDetail.cap}</p></div>
                                </div>
                            </div>
                        </Col>
                    }
                    <Col span={16}>
                        <Table
                            style={{ marginTop: "65px" }}
                            columns={columns}
                            dataSource={candidateDetails}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </div>
            <ModalCv open={isModalOpen} onOk={handleOk} onCancel={handleCancel} cv={userDetail && userDetail.cv} />
            <ModalCandidateAnswers open={isModalAnswersOpen} candidature={row} onCancel={() => setIsModalAnswersOpen(false)} />

        </div>

    )
}

export default UserDetail
