import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {endpoints} from "../../config/site.config";
import SuperFetch from "../../helpers/superFetch";
import actions from "./actions";
import authActions from "../auth/actions";

export function* getAllOffer() {

    yield takeEvery(actions.GET_ALL_LANDING_OFFER, function* (data) {

        let queryString = `?page=${data?.payload?.page - 1}&size=${data?.payload?.size}&sort=dateCreated,${data?.payload?.sort}`

        yield put({type: authActions.LOADING_TRUE})
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.publicOffer + queryString, null, false)
            yield put({type: authActions.LOADING_FALSE})


        if (response) {
            yield put({
                type: actions.GET_ALL_LANDING_OFFER_SUCCESS,
                payload: response
            })
        } else {
            yield put({
                type: actions.GET_ALL_LANDING_OFFER_ERROR,
                payload: error
            })
        }
    })
}


export default function* rootSaga() {
    yield all([
        fork(getAllOffer),
    ]);
}
