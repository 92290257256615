import {ConfigProvider, Table} from "antd";
import React, {useEffect, useState} from "react";
import "src/component/Layout/OffersList/OffersListStyle.scss";
import {ICONS} from "src/assets/sprite/iconsNames";
import Icon from "../../Icon/Sprite";
import {useNavigate, useParams} from "react-router";
import {useDispatch} from "react-redux";
import drawerActions from "src/redux/drawer/actions"
import companyActions from "src/redux/company/actions";
import offertActions from "src/redux/offer/actions";
import {Switch} from "../../Useful/Switch";
import dayjs from "dayjs";
import {Select} from "../../Useful/Select/Select";
import {ALERT_TYPE, firstLetters, OFFER_STATE, substituteZeroWithLine} from "src/utils/utility";
import {FormattedMessage} from "react-intl";
import {AlertModal} from "../AlertModal/AlertModal";

function OffersTable(props) {
    const navigate = useNavigate()
    let dispatch = useDispatch()

    const {offers, totalOffers, state, setPageSize, pageSize, setPage, page, order} = props

    const {openDrawer} = drawerActions;
    const {offertToModify, setOffertRecord} = companyActions;
    const {deleteData, update, getAll, get, setOfferExpired} = offertActions;

    const [isAlertOpen, setIsAlertOpen] = useState({value: null, flag: false})
    const [isCloseCandidatureAlertOpen, setIsCloseCandidatureAlertOpen] = useState({value: null, flag: false})

    function scrollUp() {
        //window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        // document.getElementsByClassName("content-container").scrollTo(0, 0)
    }

    const changeState = (record) => {
        if (record.state === OFFER_STATE.toPublished.value) {
            record.state = OFFER_STATE.toPublished.value
        }
        if (record.location) {
            record.location = record.location.id
        }
        dispatch(update(record.id, record, state))
    };
    const columns = [
        {
            title: 'Offerte',
            width: "20%",
            dataIndex: 'offerts',
            render: (text, record) => <div>
                <div className="flex">
                    <div className="weight-600 size-15 underlined blue" onClick={() => setField(record)}>
                        {record.title && record.title}
                    </div>
                </div>
                <div className="weight-400 size-13 opacity-50">
                    {record.dateCreated && (dayjs(record.dateCreated).format("DD MMM YYYY"))} {record.location && "· " + record.location.city}
                </div>
            </div>
        },
        {
            title: 'Responsabile',
            width: "10%",
            dataIndex: 'employee',
            render: (text, record) =>
                <div className="flex-center">
                    <div className={record.responsible && record.responsible.isActive && "isEmployeeActive weight-700 padding-4 size-14"} style={{margin: "0 3px"}}>
                        {record.responsible && firstLetters(record.responsible.firstName, record.responsible.lastName)}
                    </div>
                </div>
        },
        {
            title: 'Tipologia contratto',
            width: "15%",
            dataIndex: 'typeContract',
            render: (text, record) =>
                <div>
                    {(record.impegnoRichiesto && record.insertMode) ?
                        <div>
                            <div className="weight-500 size-15">{record.impegnoRichiesto && record.impegnoRichiesto}</div>
                            <div className="weight-500 size-15 tableStyleLow">{record.insertMode && record.insertMode}</div>
                        </div> : "-"}
                </div>
        },
        {
            title: 'Candidati',
            width: "8%",
            dataIndex: 'candidate',
            render: (text, record) => <div>
                <div className="flex-start weight-500 size-15 cellContent">
                        <Icon className={"opacity-50"} name={ICONS.user_ok} size={[20, 20]}/>
                        <span className="margin-left-5">
                            {substituteZeroWithLine(record && record.candidateCount)}
                        </span>
                </div>
            </div>
        },
        {
            title: 'Visite',
            width: "5%",
            dataIndex: 'visits',
            render: (text, record) => <div>
                <div className="flex-start weight-500 size-15 cellContent">
                    <Icon className={"opacity-50"} name={ICONS.eye} size={[20, 20]}/>
                    <span className="margin-left-5">
                        {substituteZeroWithLine(record && record.visualization)}
                    </span>
                </div>
            </div>
        },
        {
            title: 'Stato',
            width: "15%",
            dataIndex: 'state',
            render: (text, record) => {
                let backgroundColor
                switch (record.state) {
                    case OFFER_STATE.draft.value:
                        backgroundColor = "#0E0E2C30"
                        break;
                    case OFFER_STATE.toPublished.value:
                        backgroundColor = "#FFA34E"
                        break;
                    case OFFER_STATE.published.value:
                        backgroundColor = "#69BE7B"
                        break;
                    case OFFER_STATE.expired.value:
                        backgroundColor = "#FC4853"
                        break;
                    default:
                        backgroundColor = "white"
                }
                return (
                    <div style={{backgroundColor: backgroundColor, width: "fit-content", padding: "5px 10px", color: "white"}}>
                        {record.state && record.state}
                    </div>
                )
            }
        },
        {
            fixed: 'right',
            width: "25%",
            render: (text, record) => <div className="flex-end">

                {record.state && record.state === "Bozza" ? (
                    <div className={"pointer flex-center action-icon"} onClick={(e) => {
                        e.preventDefault()
                        dispatch(setOffertRecord(record))
                        dispatch(offertToModify())
                        dispatch(openDrawer())
                        dispatch(get(record.id))
                    }}>
                        <Icon name={ICONS.edit} className="icon" color="#868696" size={[20, 20]}/>
                    </div>
                ) : null}

                {(record.state && record.state === OFFER_STATE.published.value) && (<div className={"flex-center action-icon pointer close-candidature-button"} onClick={() => setIsCloseCandidatureAlertOpen({value: record.id, flag: true})}>
                    <Icon name={ICONS.close} color="#868696" size={[20, 20]}/>
                    <p className={"close-candidature-text"}>chiudi candidature</p>
                </div>)}

                <div className={"flex-center action-icon"} onClick={() => setIsAlertOpen({value: record.id, flag: true})}>
                    <Icon name={ICONS.delete} className="icon pointer" color="#868696" size={[20, 20]}/>
                </div>
            </div>
        }
    ];


    function setField(record) {
        let path = "/company/offers/"
        if (record.id) path = path + record.id
        navigate(path)
    }

    function CheckHideIcon(field) {
        if (field === null) {
            return false
        } else {
            return field
        }
    }

    const customizedEmpty = () => (
        <div className="noOffers">
            <div className="noOffersIcon flex-center">
                <Icon className="infoIcon" name={ICONS.doc_add_100} size={[100, 100]} color={"#C41A43"}/>
            </div>
            <div className="size-18 black-text weight-600">
                <FormattedMessage id="Offers_noOffers_1"/>
            </div>
            <div className="black-text opacity-70">
                <FormattedMessage id="Offers_noOffers_2"/>
            </div>
        </div>
    )

    return (
        <div className={"offersList"}>
            <ConfigProvider renderEmpty={customizedEmpty}>
                <Table
                    columns={columns}
                    dataSource={offers}
                    pagination={{
                        showSizeChanger:false,
                        current: page,
                        pageSize: pageSize,
                        total: totalOffers,
                        onChange: (page) => {
                            setPage(page)
                            dispatch(getAll({
                                page: page,
                                size: pageSize,
                                state: state,
                                sort: order
                            }))
                            scrollUp()
                        },
                    }}
                />
            </ConfigProvider>
            <div className={"contentSelectSize"}>
                <div className={"text"}>
                    <span>Risultati per pagina</span>
                </div>
                <div>
                    <Select
                        className={"selectSizeGetAll"}
                        defaultValue={"10"}
                        height="35px"
                        suffixIcon={<Icon name={ICONS.arrow_small} size={[20, 20]} color="#208ac6"/>}
                        options={
                            [
                                {label: '10', value: '10'},
                                {label: '20', value: '20'},
                                {label: '50', value: '50'},
                                {label: '100', value: '100'},
                            ]
                        }
                        onChange={(pageSize) => {
                            setPage(1)
                            setPageSize(pageSize)
                            dispatch(getAll({
                                page: 1,
                                size: pageSize,
                                state: state,
                                sort: order
                            }))
                        }}
                    />
                </div>
            </div>
            <AlertModal
                type={ALERT_TYPE.ERROR}
                content={<div>Perderai l'offerta selezionata. Sei sicuro di procedere?</div>}
                okText={<FormattedMessage id="Alert_proceed"/>}
                onOk={() => {
                    dispatch(deleteData(isAlertOpen.value, state, order, pageSize))
                    setIsAlertOpen({value: null, flag: false})
                }}
                onClose={() => {
                    setIsAlertOpen({value: null, flag: false})
                }}
                isAlertOpen={isAlertOpen.flag}
            />
            <AlertModal
                type={ALERT_TYPE.ERROR}
                content={<div>Non potrai ricevere più candidature per questa offerta. Sei sicuro di procedere?</div>}
                okText={<FormattedMessage id="Alert_proceed"/>}
                onOk={() => {
                    dispatch(setOfferExpired(isCloseCandidatureAlertOpen.value, state, order, pageSize))
                    setIsCloseCandidatureAlertOpen({value: null, flag: false})
                }}
                onClose={() => {
                    setIsCloseCandidatureAlertOpen({value: null, flag: false})
                }}
                isAlertOpen={isCloseCandidatureAlertOpen.flag}
            />
        </div>
    )

}

export default OffersTable
