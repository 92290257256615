import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {endpoints} from "src/config/site.config";
import SuperFetch from "../../helpers/superFetch";
import actions from "./actions";
import authActions from "../auth/actions";


export function* getAllEmployee() {

    yield takeEvery(actions.GET_ALL_EMPLOYEE, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllEmployee, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ALL_EMPLOYEE_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_EMPLOYEE_ERROR,
                payload: error
            });
        }
    })

}

export function* getAllEmployeeBySysAdmin() {

    yield takeEvery(actions.GET_ALL_EMPLOYEE_BY_SYS_ADMIN, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllEmployeeBySysAdmin + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ALL_EMPLOYEE_BY_SYS_ADMIN_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_EMPLOYEE_BY_SYS_ADMIN_ERROR,
                payload: error
            });
        }
    })

}

export function* getEmployeeById() {

    yield takeEvery(actions.GET_EMPLOYEE_BY_ID, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getEmployeeById + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_EMPLOYEE_BY_ID_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_EMPLOYEE_BY_ID_ERROR,
                payload: error
            });
        }
    })

}

export function* saveEmployee() {

    yield takeEvery(actions.SAVE_EMPLOYEE, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.saveEmployee, data.payload, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.SAVE_EMPLOYEE_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SAVE_EMPLOYEE_ERROR,
                payload: error
            });
        }
    })

}

export function* updateEmployee() {

    yield takeEvery(actions.UPDATE_EMPLOYEE, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.updateEmployee + "/" + data.id, data.payload, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            localStorage.setItem('firstName', response.companyAdmin.firstName)
            localStorage.setItem('lastName', response.companyAdmin.lastName)
            localStorage.setItem('email', response.companyAdmin.email)

            yield put({
                type: actions.UPDATE_EMPLOYEE_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_EMPLOYEE_ERROR,
                payload: error
            });
        }
    })

}

export function* deleteEmployee() {

    yield takeEvery(actions.DELETE_EMPLOYEE, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.deleteEmployee + "/" + data.companyId + "/employee/delete/" + data.employeeId, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.DELETE_EMPLOYEE_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DELETE_EMPLOYEE_ERROR,
                payload: error
            });
        }
    })

}

export default function* rootSaga() {
    yield all([
        fork(getAllEmployee),
        fork(getAllEmployeeBySysAdmin),
        fork(getEmployeeById),
        fork(saveEmployee),
        fork(updateEmployee),
        fork(deleteEmployee)
    ]);
}
