import React, {useEffect, useState} from "react";
import {InputLogin} from "src/component/Useful/Input/InputLogin";
import {Col, Form, Modal, Row} from "antd";
import "./ModalEmployee.scss"
import {FormattedMessage} from "react-intl";
import {ICONS} from "src/assets/sprite/iconsNames";
import Icon from "src/component/Icon/Sprite";
import {ButtonUser} from "src/component/Useful/Button/ButtonUser";
import {ButtonLine} from "src/component/Useful/Button/ButtonLine";
import actions from "src/redux/employee/actions";
import companyActions from "src/redux/company/actions";
import {useDispatch, useSelector} from "react-redux";
import {ALERT_TYPE, COMPANY_MODAL_TYPE, newId, ROLES} from "src/utils/utility";
import {AlertModal} from "src/component/Layout/AlertModal/AlertModal";

function ModalEmployee(props) {

    const {save, update, deleteEmployee} = actions;
    const {company, setOpenModalEmployee, openModalEmployee, setEmployeesArray, type, record, employeesArray} = props

    const[isAlertOpen, setIsAlertOpen] = useState(false)

    let dispatch = useDispatch()
    const [form] = Form.useForm();

    useEffect(() => {
        if (record) {
            form.setFieldsValue(
                {
                    firstName: record.firstName,
                    lastName: record.lastName,
                    email: record.email,
                    password: record.password,
                    occupation: record.occupation,
                }
            )
        } else {
            form.resetFields()
        }
    }, [openModalEmployee])

    return (
        <>
            <Modal
                className={"modalNewEmployee"}
                open={openModalEmployee}
                closeIcon={<Icon name={ICONS.close} size={[20, 20]}/>}
                centered={true}
                onCancel={() => {
                    form.resetFields()
                    setOpenModalEmployee(false)
                }}
                destroyOnClose={true}
                footer={
                    <div className={(type === COMPANY_MODAL_TYPE.EDIT || type === COMPANY_MODAL_TYPE.EDIT_FORM) ? "flex-center-between" : "flex-end"}>
                        {(type === COMPANY_MODAL_TYPE.EDIT || type === COMPANY_MODAL_TYPE.EDIT_FORM) &&
                        <div className="pointer" onClick={() => setIsAlertOpen(true)}>
                            <Icon className="margin-top-5 icon" name={ICONS.delete} size={[20, 20]} color="#868696"/>
                        </div>
                        }
                        <div className="modalFooter">
                            <div
                                onClick={() => {
                                    form.resetFields()
                                    setOpenModalEmployee(false)
                                }}
                                className={"underlined opacity-50 flex-align-center margin-right-24 pointer"}
                            >
                                <FormattedMessage id="cancel"/>
                            </div>

                            {type === COMPANY_MODAL_TYPE.NEW_FORM &&
                            <ButtonLine
                                width={169}
                                onClick={() => {
                                    form.validateFields().then(values => {
                                        setEmployeesArray(employeesArray => [...employeesArray,
                                            {
                                                firstName: values.firstName,
                                                lastName: values.lastName,
                                                email: values.email,
                                                password: values.password,
                                                occupation: values.occupation,
                                                role: ROLES.EMPLOYEE,
                                                tempId: newId("employee")
                                            }
                                        ])
                                        form.resetFields()
                                    })
                                }}
                            >
                                <FormattedMessage id="ModalButton_SaveNew"/>
                            </ButtonLine>
                            }

                            <ButtonUser
                                className="flex-center"
                                htmlType="submit"
                                form={"createEmployee"}
                            >
                                <Icon name={ICONS.tik} size={[20, 20]} color="#FFFFFF"/>
                                <FormattedMessage id="ModalButton_SaveClose"/>
                            </ButtonUser>
                        </div>
                    </div>
                }
            >

                <Form
                    id={"createEmployee"}
                    form={form}
                    layout="vertical"
                    style={{width: "100%"}}
                    onFinish={(values) => {
                        let data
                        switch (type) {
                            case COMPANY_MODAL_TYPE.NEW:
                                data = {
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    email: values.email,
                                    password: values.password,
                                    occupation: values.occupation,
                                    role: ROLES.EMPLOYEE,
                                }
                                dispatch(save(data))
                                break;
                            case COMPANY_MODAL_TYPE.EDIT:
                                data = {
                                    id: record.id,
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    email: values.email,
                                    occupation: values.occupation,
                                    role: ROLES.EMPLOYEE,
                                }
                                dispatch(update(record.id, data))
                                break;
                            case COMPANY_MODAL_TYPE.NEW_FORM:
                                setEmployeesArray(employeesArray => [...employeesArray,
                                    {
                                        firstName: values.firstName,
                                        lastName: values.lastName,
                                        email: values.email,
                                        password: values.password,
                                        occupation: values.occupation,
                                        role: ROLES.EMPLOYEE,
                                        tempId: newId("employee"),
                                    }
                                ])
                                break;
                            case COMPANY_MODAL_TYPE.EDIT_FORM:
                                data = {
                                    id: record.id,
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    email: values.email,
                                    occupation: values.occupation,
                                    role: ROLES.EMPLOYEE,
                                }

                                let index = company.employees.findIndex(it => it.id === record.id);
                                if (index !== -1) {
                                    let updatedArray = [...company.employees];
                                    updatedArray[index] = data;
                                    setEmployeesArray(updatedArray);
                                }
                                break;
                        }

                        form.resetFields()
                        setOpenModalEmployee(false)
                    }}
                >

                    <div style={{fontSize: "30px", fontWeight: 700}}>
                        {(type === COMPANY_MODAL_TYPE.EDIT || type === COMPANY_MODAL_TYPE.EDIT_FORM) ?
                            <FormattedMessage id="CompanyDetails_editResponsible"/>
                            :
                            <FormattedMessage id="CompanyDetails_newResponsible"/>
                        }
                    </div>
                    <Row gutter={24}>
                        <Col span={12}>

                            <Form.Item
                                name="firstName"
                                label={"Nome"}
                                className="weight-600 size-14"
                                initialValue={null}
                                rules={[
                                    {
                                        required: true,
                                        message: false,
                                    },
                                ]}
                            >
                                <InputLogin/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="lastName"
                                label={"Cognome"}
                                className="weight-600 size-14"
                                initialValue={null}
                                rules={[
                                    {
                                        required: true,
                                        message: false,
                                    },
                                ]}
                            >
                                <InputLogin/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div>
                        <Form.Item
                            name="occupation"
                            label={"Area"}
                            className="weight-600 size-14"
                            initialValue={null}
                            rules={[
                                {
                                    required: true,
                                    message: false,
                                },
                            ]}
                        >
                            <InputLogin/>
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            name="email"
                            label={"Email"}
                            className="weight-600 size-14"
                            initialValue={null}
                            rules={[
                                {
                                    required: true,
                                    message: false,
                                    type: 'email',
                                },
                            ]}
                        >
                            <InputLogin/>
                        </Form.Item>
                    </div>
                    {(type === COMPANY_MODAL_TYPE.NEW || type === COMPANY_MODAL_TYPE.NEW_FORM) &&
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="password"
                                    label={"Password provvisoria"}
                                    className="weight-600 size-14"
                                    initialValue={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: false,
                                        },
                                    ]}
                                >
                                    <InputLogin autoComplete={"new-password"} type={"password"}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                    {/*{type === COMPANY_MODAL_TYPE.NEW ?*/}
                    {/*    <Row>*/}
                    {/*        <Col>*/}
                    {/*            <div className="weight-600 size-14" style={{marginBottom: "16px"}}>*/}
                    {/*                <FormattedMessage id="Modal_AssignLocation"/>*/}
                    {/*            </div>*/}
                    {/*            <Space direction={"vertical"} size={12}>*/}
                    {/*                {company.companyLocations && company.companyLocations.map((element) => {*/}
                    {/*                    return (*/}
                    {/*                        <div>*/}
                    {/*                            <Switch*/}
                    {/*                                checkedChildren={<Icon name={ICONS.ok} size={[20, 20]}/>}*/}
                    {/*                                unCheckedChildren={<Icon name={ICONS.close_switch} size={[20, 20]}/>}*/}
                    {/*                                onChange={(value) => {*/}
                    {/*                                    if (value) {*/}
                    {/*                                        setCheckedLocation(checkedLocation => [...checkedLocation, element.id])*/}
                    {/*                                    } else {*/}
                    {/*                                        let tempArray = checkedLocation.filter(it => it !== element.id)*/}
                    {/*                                        setCheckedLocation(tempArray)*/}
                    {/*                                    }*/}
                    {/*                                }}*/}
                    {/*                            />*/}
                    {/*                            <span className="weight-500 size-14" style={{margin: "0 12px"}}>*/}
                    {/*                            {element.city}*/}
                    {/*                        </span>*/}
                    {/*                            <span className="size-14 dark-light-50">*/}
                    {/*                            ({element.province}, Italia)*/}
                    {/*                        </span>*/}
                    {/*                        </div>*/}
                    {/*                    )*/}
                    {/*                })}*/}
                    {/*            </Space>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}
                </Form>


            </Modal>
            <AlertModal
                type={ALERT_TYPE.ERROR}
                content={<FormattedMessage id="Alert_deleteEmployee"/>}
                okText={<FormattedMessage id="Alert_proceed"/>}
                onOk={() => {
                    if (type === COMPANY_MODAL_TYPE.EDIT_FORM) {
                        let index
                        if (record.id) {
                            index = employeesArray.findIndex(employee => employee.id === record.id)
                        } else {
                            index = employeesArray.findIndex(employee => employee.tempId === record.tempId)
                        }
                        let tempArray = [...employeesArray]
                        tempArray.splice(index, 1);
                        setEmployeesArray(tempArray)
                    }
                    if (type === COMPANY_MODAL_TYPE.EDIT){
                        dispatch(deleteEmployee(record.id, company.id))
                    }
                    setOpenModalEmployee(false)
                    setIsAlertOpen(false)
                }}
                onClose={() => {
                    setIsAlertOpen(false)
                }}
                isAlertOpen={isAlertOpen}
            />
        </>
    )

}

export default ModalEmployee
