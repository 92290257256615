import React, {useEffect} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import actions from "src/redux/offer/actions";
import {PRIVATE_COMPANY_ROUTE, PUBLIC_ROUTE} from "src/route.constants";
import "./confirmOfferStyle.scss"

function ConfirmOffer() {

    const navigate = useNavigate()
    let dispatch = useDispatch()

    const {enabledOffert} = actions

    const queryParameters = new URLSearchParams(window.location.search)
    const isUpdateOfferBySysAdminSuccess = useSelector(state => state.Offer.isUpdateOfferBySysAdminSuccess)


    const otp = queryParameters.get("otp")

    useEffect(() => {
        if (otp) {
            dispatch(enabledOffert(otp))
        }
    }, [otp])

    useEffect(() => {
        if (isUpdateOfferBySysAdminSuccess) {
            if (localStorage.getItem('id_token')) {
                localStorage.removeItem('openStatus')
                localStorage.removeItem('openStatusCompany')


            } else {
                setTimeout(() => navigate(PUBLIC_ROUTE.LANDING), 2000)
            }
        }
    }, [isUpdateOfferBySysAdminSuccess])

    return (
        <div className={'activated-container-content-error'}>
            <h1><FormattedMessage id="approved_offer"/></h1>
        </div>
    )
}

export default ConfirmOffer
