import * as firebase from 'firebase/app';
import {getMessaging, getToken, onMessage, deleteToken, isSupported} from "firebase/messaging";
import {EventEmitter} from "events"

const firebaseConfig = {
    apiKey: "AIzaSyB7WKt0fbl4qI-uCJNfbAqJlRu5MTEDIhs",
    authDomain: "vocational-training-5ae67.firebaseapp.com",
    projectId: "vocational-training-5ae67",
    storageBucket: "vocational-training-5ae67.appspot.com",
    messagingSenderId: "743816473613",
    appId: "1:743816473613:web:30ab4e66b1ac6084cbf944"
};

const app = firebase.initializeApp(firebaseConfig)

export const messaging = (async () => {
    try {
        const isSupportedBrowser = await isSupported();
        if (isSupportedBrowser) {
            let messagingResolve
            messagingResolve = getMessaging(app)

            onMessage(messagingResolve, (payload) => {
                client.createEvent("newNotification", payload)
            });

            return messagingResolve;
        }
        console.log('Firebase not supported this browser');
        return null;
    } catch (err) {
        console.log(err);
        return null;
    }
})();

export const getFcmToken = async (dispatch) => {
    try {
        const messagingResolve = await messaging;
        const currentToken = await getToken(messagingResolve, {vapidKey: "BDv4kxqBRYls9uB0Q_Bgb8csQDJTjVtRGydSprRrhOZVNdWv2mbmlHPfNsaRXpZM_0tW2hc0HVEaoKugZ5mK8sI"})
        if (currentToken) {
            return currentToken
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
    }
}


export const deleteFcmToken = async () => {
        const messagingResolve = await messaging;
        return await deleteToken(messagingResolve)
}

class EventDispatcher extends EventEmitter {
    constructor() {
        super();
    }

    createEvent(eventType, payload) {
        this.emit(eventType, payload);
    }
}


export const client = new EventDispatcher();
