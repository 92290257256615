const actions = {

    INIT_COMPANY: 'INIT_COMPANY',

    GET_ALL_COMPANY: 'GET_ALL_COMPANY',
    GET_ALL_COMPANY_SUCCESS: 'GET_ALL_COMPANY_SUCCESS',
    GET_ALL_COMPANY_ERROR: 'GET_ALL_COMPANY_ERROR:',

    GET_COMPANY_BY_ID: "GET_COMPANY_BY_ID",
    GET_COMPANY_BY_ID_SUCCESS: "GET_COMPANY_BY_ID_SUCCESS",
    GET_COMPANY_BY_ID_ERROR: "GET_COMPANY_BY_ID_ERROR",

    SAVE_COMPANY: "SAVE_COMPANY",
    SAVE_COMPANY_SUCCESS: "SAVE_COMPANY_SUCCESS",
    SAVE_COMPANY_ERROR: "SAVE_COMPANY_ERROR",

    RESET_REGISTRATION_CONFIRM: "RESET_REGISTRATION_CONFIRM",

    GET_COMPANY_DASHBOARD: "GET_COMPANY_DASHBOARD",
    GET_COMPANY_DASHBOARD_SUCCESS: "GET_COMPANY_DASHBOARD_SUCCESS",
    GET_COMPANY_DASHBOARD_ERROR: "GET_COMPANY_DASHBOARD_ERROR",

    UPDATE_COMPANY: "UPDATE_COMPANY",
    UPDATE_COMPANY_SUCCESS: "UPDATE_COMPANY_SUCCESS",
    UPDATE_COMPANY_ERROR: "UPDATE_COMPANY_ERROR",

    GET_COMPANY_LOGO: "GET_COMPANY_LOGO",
    GET_COMPANY_LOGO_SUCCESS: "GET_COMPANY_LOGO_SUCCESS",
    GET_COMPANY_LOGO_ERROR: "GET_COMPANY_LOGO_ERROR",

    CLOSE_ACTIVATION_MESSAGE: "CLOSE_ACTIVATION_MESSAGE",
    CLOSE_ACTIVATION_MESSAGE_SUCCESS: "CLOSE_ACTIVATION_MESSAGE_SUCCESS",
    CLOSE_ACTIVATION_MESSAGE_ERROR: "CLOSE_ACTIVATION_MESSAGE_ERROR",

    CLOSE_STEP: "CLOSE_STEP",
    CLOSE_STEP_SUCCESS: "CLOSE_STEP_SUCCESS",
    CLOSE_STEP_ERROR: "CLOSE_STEP_ERROR",

    OFFERT_MODIFY: "OFFERT_MODIFY",
    OFFERT_CREATE: "OFFERT_CREATE",

    SET_OFFERT_RECORD: "SET_OFFERT_RECORD",

    GET_CV_SOURCE_DETAIL_USER: "GET_CV_SOURCE_DETAIL_USER",
    GET_CV_SOURCE_DETAIL_USER_SUCCESS: "GET_CV_SOURCE_DETAIL_USER_SUCCESS",
    GET_CV_SOURCE_DETAIL_USER_ERROR: "GET_CV_SOURCE_DETAIL_USER_ERROR",

    EDIT_MESSAGE_EMAIL: "EDIT_MESSAGE_EMAIL",
    EDIT_MESSAGE_EMAIL_SUCCESS: "EDIT_MESSAGE_EMAIL_SUCCESS",
    EDIT_MESSAGE_EMAIL_ERROR: "EDIT_MESSAGE_EMAIL_ERROR",

    GET_WORK_SECTOR: "GET_WORK_SECTOR",
    GET_WORK_SECTOR_SUCCESS: "GET_WORK_SECTOR_SUCCESS",
    GET_WORK_SECTOR_ERROR: "GET_WORK_SECTOR_ERROR",

    MODAL_EDIT_MESSAGE_EMAIL_OPEN: "MODAL_EDIT_MESSAGE_EMAIL_OPEN",
    MODAL_EDIT_MESSAGE_EMAIL_CLOSE: "MODAL_EDIT_MESSAGE_EMAIL_CLOSE",

    getAll: () => ({
        type: actions.GET_ALL_COMPANY,
    }),

    getCompanyById: (companyId) => ({
        type: actions.GET_COMPANY_BY_ID,
        id: companyId
    }),

    save: (data) => ({
        type: actions.SAVE_COMPANY,
        payload: data
    }),

    getCompanyDashboard: () => ({
        type: actions.GET_COMPANY_DASHBOARD
    }),

    update: (companyId, data) => ({
        type: actions.UPDATE_COMPANY,
        id: companyId,
        payload: data,
    }),

    resetRegistrationConfirm: () => ({
        type: actions.RESET_REGISTRATION_CONFIRM
    }),

    offertToModify:()=>({
        type: actions.OFFERT_MODIFY
    }),

    offertToCreate:()=>({
        type: actions.OFFERT_CREATE
    }),

    getImageSource: (data) => ({
        type: actions.GET_COMPANY_LOGO,
        payload: data
    }),

    closeActivationMessage: () => ({
        type: actions.CLOSE_ACTIVATION_MESSAGE,
    }),

    closeStep: () => ({
        type: actions.CLOSE_STEP,
    }),

    setOffertRecord: (data)=>({
        type: actions.SET_OFFERT_RECORD,
        payload: data
    }),

    getCvSourceDetailUser: (cv)=>({
        type: actions.GET_CV_SOURCE_DETAIL_USER,
        cv: cv
    }),

    editEmailMessage: (defaultMessage,companyId) =>({
        type: actions.EDIT_MESSAGE_EMAIL,
        defaultMessage: defaultMessage,
        companyId: companyId
    }),

    getWorkSector: () => ({
        type: actions.GET_WORK_SECTOR,
    }),

    openModal: ()=>({
        type: actions.MODAL_EDIT_MESSAGE_EMAIL_OPEN
    }),
    closeModal: ()=>({
        type: actions.MODAL_EDIT_MESSAGE_EMAIL_CLOSE
    })
};

export default actions;
