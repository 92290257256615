const actions = {

    GET_ALL_LANDING_OFFER: 'GET_ALL_LANDING_OFFER',
    GET_ALL_LANDING_OFFER_SUCCESS: 'GET_ALL_LANDING_OFFER_SUCCESS',
    GET_ALL_LANDING_OFFER_ERROR: 'GET_ALL_LANDING_OFFER_ERROR:',

    INIT_LANDING: "INIT_LANDING",


    getAllLandingOffer: (data) => ({
        type: actions.GET_ALL_LANDING_OFFER,
        payload: data
    }),

    initLanding: () => ({
        type: actions.INIT_LANDING,
    }),


};

export default actions;
