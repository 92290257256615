import React, { useEffect, useState } from "react";
import { Divider, Input } from "antd";
import sprite from "../../../assets/sprite/sprite.svg";
import { ICONS } from "../../../assets/sprite/iconsNames";
import { CandidateTable } from "./CandidateTable";
import candidatureActions from "../../../redux/candidature/actions";
import { useDispatch } from "react-redux";
import Icon from "../../Icon/Sprite";
import { Select } from "../../Useful/Select/Select";
import { ButtonLine } from "../../Useful/Button/ButtonLine";
import "src/component/Layout/Candidate/cardCreate.scss";
import CardNewCandidate from "./CardNewCandidate";
import { OFFER_FILTERS_CANDIDATE } from "../../../utils/utility";

//import "./cardCreate.scss"


function CardCreateCandidate({ ListCandidate, showModal, searchToCard, handleSearch, count, resetFilters, handleObjectFilters, objectFilters, visibleResetButton }) {


    let dispatch = useDispatch()

    const { multipleCvDownload } = candidatureActions

    const [state, setState] = React.useState(OFFER_FILTERS_CANDIDATE.ALL.value)
    const [search, setSeach] = React.useState(searchToCard)
    const [order, setOrder] = React.useState("ASC")
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])

    useEffect(() => {
        setSeach(searchToCard)
    }, [searchToCard])

    const handleResetFiltersCard = () => {
        handleSearch(null)
        resetFilters()
        setState(OFFER_FILTERS_CANDIDATE.ALL.value)
        setOrder("ASC")
        handleObjectFilters({
            name: null,
            province: null,
            city: null,
            qualificationLevel: null,
            page: 1,
            size: 10,
        })
    }

    const selectState = (e) => {
        setState(e)
        handleObjectFilters({
            state: e
        })
    }

    const selectOrder = (value) => {
        setOrder(value)
        handleObjectFilters({
            sort: value
        })
    }

    const searchOnChange = (value) => {
        setSeach(value)
        handleSearch(value)
        handleObjectFilters({ name: value })
    }

    const downloadZip = () => {
        if (selectedRows && selectedRows.length > 0) {
            let names = []
            selectedRows.forEach((element) => {
                names.push({
                    profileName: element.firstName + "_" + element.lastName,
                    fileName: element.cvPath.split(".pdf")[0]
                })
            })
            dispatch(multipleCvDownload(names))
        }
    };

    return (
        <div>
            <div className="flex-between margin-24">
                <span className="size-28 weight-700 merriWeather">
                    Candidati
                </span>
                <div className="flex-center">
                    {selectedRows && selectedRows.length > 0 ?
                        <div>
                            {/*<Icon name={ICONS.delete} size={[20, 20]} className="icon margin-right-24" color="grey"/>*/}
                            <div onClick={() => downloadZip()}>
                                <Icon name={ICONS.download} size={[20, 20]} className="icon margin-right-24" color="grey" />
                            </div>
                        </div> : null}
                    <div>
                        <ButtonLine className="flex-center margin-right-24" onClick={showModal}>
                            <svg width="20px" height="20px" color="#208AC6">
                                <use href={sprite + "#" + ICONS.filter} />
                            </svg>
                            <span className="flex-align-center margin-right-5 margin-left-5 blue">
                                Filtri
                            </span>
                            {count && count !== 0 ?
                                <div className="numberCount">
                                    {count}
                                </div>
                                : null
                            }
                        </ButtonLine>
                    </div>
                    {visibleResetButton ?
                        (<div className={"margin-right-24"} onClick={() => handleResetFiltersCard()}>
                            <a className="pointer grey"><u>Resetta</u></a>
                        </div>) : null}
                    <div>
                        <Input value={search} className={"inputField"} placeholder={"Ricerca candidati"} onChange={(e) => searchOnChange(e.target.value)} prefix={<Icon name={ICONS.search} size={[20, 20]} color={"#868696"} />} />
                    </div>
                </div>
            </div>
            <div className="margin-bottom-24 margin-top-24 flex-center-between">

                <div className="size-16 weight-700 merriWeather">
                    <span className="size-16 weight-700 merriWeather">Risultati</span>
                    ({ListCandidate && ListCandidate.length})
                </div>
                <div className="flex-align-center">
                    {/*<div className="margin-right-32">*/}
                    {/*    <span>*/}
                    {/*        Stato:*/}
                    {/*    </span>*/}
                    {/*    <Select*/}
                    {/*        className="dropdown"*/}
                    {/*        popupClassName="dropdown border-radius-0"*/}
                    {/*        bordered={false}*/}
                    {/*        dropdownMatchSelectWidth={false}*/}
                    {/*        defaultValue={OFFER_FILTERS_CANDIDATE.ALL.label}*/}
                    {/*        value={state}*/}
                    {/*        suffixIcon={<Icon name={ICONS.arrow_small} size={[20, 20]} color="black" />}*/}
                    {/*        onChange={(e) => selectState(e)}*/}
                    {/*        options={[*/}
                    {/*            {*/}
                    {/*                options: [*/}
                    {/*                    OFFER_FILTERS_CANDIDATE.ALL,*/}
                    {/*                ]*/}
                    {/*            },*/}
                    {/*            {*/}
                    {/*                label: <Divider className="divider" />,*/}
                    {/*                options:*/}
                    {/*                    [*/}
                    {/*                        OFFER_FILTERS_CANDIDATE.WAITING,*/}
                    {/*                        OFFER_FILTERS_CANDIDATE.INTERVIEW,*/}
                    {/*                        OFFER_FILTERS_CANDIDATE.PRESELECTED,*/}
                    {/*                        OFFER_FILTERS_CANDIDATE.DISLIKE,*/}
                    {/*                    ],*/}
                    {/*            }*/}
                    {/*        ]}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div>
                        <span>
                            Ordina per:
                        </span>
                        <Select
                            popupClassName="dropdown border-radius-0"
                            bordered={false}
                            defaultValue={order}
                            value={order}
                            dropdownMatchSelectWidth={false}
                            style={{ width: "fit-content" }}
                            suffixIcon={
                                <svg width="20px" height="20px" color="#0E0E2C">
                                    <use href={sprite + "#" + ICONS.arrow_small} />
                                </svg>}
                            onChange={(e) => {
                                selectOrder(e)
                            }}
                            options={[
                                {
                                    options: [
                                        { label: 'Recenti', value: 'ASC' },
                                        { label: 'Meno Recenti', value: 'DESC' },
                                    ],
                                }
                            ]}
                        >
                        </Select>
                    </div>
                </div>
            </div>
            {ListCandidate && ListCandidate.length !== 0 ?
                <CandidateTable
                    listCandidate={ListCandidate}
                    selectRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    handleObjectFilters={handleObjectFilters}
                    objectFilters={objectFilters}
                /> :
                <CardNewCandidate />}
        </div>
    )
}

export default CardCreateCandidate
