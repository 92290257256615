import React, {useEffect, useState} from "react";
import "src/Pages/account/AccountUser.scss";
import {ICONS} from "src/assets/sprite/iconsNames";
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router";
import {Breadcrumb, Col, Divider, message, Row} from "antd";
import {PRIVATE_COMPANY_ROUTE} from "src/route.constants";
import Icon from "src/component/Icon/Sprite";
import {useDispatch, useSelector} from "react-redux";
import actions from "src/redux/userProfile/actions";
import moment from "moment";
import drawerActions from "../../redux/drawer/actions";
import accountActions from "src/redux/account/actions"
import {CustomDrawer} from "src/component/Layout/Drawer/CustomDrawer";
import {DRAWER_TYPE} from "../../utils/utility";
import dayjs from "dayjs";
import ModalChangePassword from "src/component/Layout/Modal/ModalChangePassword/ModalChangePassword";
import authActions from "../../redux/auth/actions";
import {ButtonLine} from "../../component/Useful/Button/ButtonLine";
import useMediaQuery from "../../component/Useful/Responsive/UseMedia";

let relativeTime = require('dayjs/plugin/relativeTime')


function AccountUser() {

    let dispatch = useDispatch()
    const navigate = useNavigate()

    dayjs.extend(relativeTime)
    const [messageApi, contextHolder] = message.useMessage();

    const {getUser} = actions;
    const {openDrawer} = drawerActions;
    const {logout, resetCheckPassword, resetStateNewPassword} = authActions;
    const {openModalChangePassword} = accountActions;

    const firstName = localStorage.getItem("firstName")
    const lastName = localStorage.getItem("lastName")
    const email = localStorage.getItem("email")
    const id = localStorage.getItem('id')

    const [userProfile, setUserProfile] = useState(null)
    const [type, setType] = useState(null)

    const user = useSelector(state => state.UserProfile.userInfo)
    const isCurrentPasswordEqual = useSelector(state => state.AuthReducer.isCurrentPasswordEqual)
    const resetNewPasswordSuccess = useSelector(state => state.AuthReducer.resetNewPasswordSuccess)

    const isMobile = useMediaQuery('(min-width: 500px)')

    React.useEffect(() => {
        dispatch(getUser(id))
        setType(DRAWER_TYPE.firstStepCandidatureUser)
    }, [])

    React.useEffect(() => {
        if (user) {
            setUserProfile(user)
        }
    }, [user])

    const logOut = () => {
        dispatch(logout(navigate))
    }

    useEffect(() => {
        if (resetNewPasswordSuccess) {

            messageApi.open({
                type: 'success',
                content: 'Profilo Professionale aggiornato con successo!'
            })
            dispatch(resetStateNewPassword())
        }
    }, [resetNewPasswordSuccess])

    useEffect(() => {
        if (isCurrentPasswordEqual) {

            messageApi.open({
                type: 'success',
                content: 'La password inserita corrisponde!'
            })
        }

        dispatch(resetCheckPassword())

    }, [isCurrentPasswordEqual])

    return (
        <div>
            <CustomDrawer
                type={DRAWER_TYPE.firstStepCandidatureUser}
                setType={setType}
                userProfile={userProfile}
                modify={"modify"}
                state={""}
                userEditAccount={true}
            />
            <div className="account">
                {contextHolder}
                <div className="max-width-1200 ">

                    <Breadcrumb className="customBreadcrumb" separator="›">
                        <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY)}><FormattedMessage id="Breadcrumb_home"/></Breadcrumb.Item>
                        <Breadcrumb.Item><FormattedMessage id="Breadcrumb_account"/></Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="title flex-center-between margin-bottom-24">
                        <div>
                            <span onClick={() => navigate(-1)}>
                                <Icon className={"opacity-50 margin-right-22 pointer"} name={ICONS.arrow_2} size={[20, 20]} color={"#0E0E2C"}/>
                            </span>
                            <span className="size-28 weight-700 merriWeather">
                                <FormattedMessage id="account"/>
                            </span>
                        </div>
                        <div className="flex">
                            <div>
                                <div className="flex-center opacity-50 pointer" onClick={() => logOut()}>
                                    <Icon className={"margin-right-8"} name={ICONS.exit} size={[20, 20]} color={"#0E0E2C"}/>
                                    <span className="size-14 black"><FormattedMessage id="exit"/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Row gutter={24}>
                            {userProfile &&
                            <Col xl={8} md={24} sm={24} xs={24}>
                                <div className={"border-grey"}>
                                    <div style={{height: "100%", background: "rgb(210 232 244)", padding: "20px"}}>
                                        <div className={"flex-center"}>
                                            <div className="tinsel"/>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "center", paddingTop: 40, fontWeight: 700, fontSize: 22, color: "#0E0E2C"}}>
                                            {firstName + " " + lastName}
                                        </div>
                                        <div style={{display: "flex", justifyContent: "center", padding: 10}}>{userProfile.workingRole}</div>
                                        <div style={{textAlign: "center", padding: 10}}>
                                            {userProfile.description &&
                                            <p>
                                                “{userProfile.description}”
                                            </p>
                                            }
                                        </div>
                                        {/*<div style={{display: "flex", justifyContent: "center"}}>*/}
                                        {/*    <Icon className={"margin-right-8"} name={ICONS.linkedin} size={[20, 20]} color={"#0E0E2C"}/>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </Col>}
                            <Col xl={userProfile ? 16 : 24} md={24} sm={24} xs={24}>
                                <div className="subContent">
                                    <div className="subTitle size-18 weight-700">
                                        <FormattedMessage id="Account_account"/>
                                    </div>
                                    <div className="accountContainer">
                                        {isMobile ?
                                        <div className="flex-center-between">
                                            <div>
                                                <div className="line-24 size-18 weight-700">
                                                    {firstName + " " + lastName}
                                                </div>
                                                <div className="line-24">
                                                    {email}
                                                </div>
                                                <div className="line-24">
                                                    <FormattedMessage id="Account_Creation"/>: {userProfile && moment(userProfile.dateCreated).locale('it').format("DD-MMM-YYYY")}
                                                </div>
                                            </div>
                                            <div>
                                                {userProfile && userProfile.userProfileId ?
                                                    <ButtonLine style={{width: "163px"}} onClick={() => dispatch(openDrawer())}>
                                                        <FormattedMessage id="Account_editAccount"/>
                                                    </ButtonLine> :
                                                    <ButtonLine style={{width: "250px"}} onClick={() => dispatch(openDrawer())}>
                                                        Crea Account Professionale
                                                    </ButtonLine>
                                                }

                                            </div>
                                        </div> :
                                        <div>
                                            <div>
                                                <div className="flex-center-between">
                                                    <div className="line-24 size-18 weight-700">
                                                        {firstName + " " + lastName}
                                                    </div>
                                                    <div className="line-24">
                                                        <FormattedMessage id="Account_Creation"/>:
                                                    </div>
                                                </div>
                                                <div className="flex-center-between">
                                                    <div className="line-24">
                                                        {email}
                                                    </div>
                                                    <div className="line-24">
                                                        {userProfile && moment(userProfile.dateCreated).locale('it').format("DD-MMM-YYYY")}
                                                    </div>
                                                </div>
                                                {userProfile && userProfile.userProfileId ?
                                                    <ButtonLine style={{width: "100%"}} onClick={() => dispatch(openDrawer())}>
                                                        <FormattedMessage id="Account_editAccount"/>
                                                    </ButtonLine> :
                                                    <ButtonLine style={{width: "100%"}} onClick={() => dispatch(openDrawer())}>
                                                        Crea Account Professionale
                                                    </ButtonLine>
                                                }
                                            </div>
                                        </div>}
                                        <Divider className="divider" type={"horizontal"}/>
                                        {isMobile ?
                                        <div className="flex-center-between">
                                            <div>
                                                <div className="line-24 size-14 weight-700">
                                                    Password
                                                </div>
                                            </div>
                                            <div>
                                                <ButtonLine style={{width: "163px"}} onClick={() => dispatch(openModalChangePassword())}>
                                                    <FormattedMessage id="Account_modifyPassword"/>
                                                </ButtonLine>
                                            </div>
                                        </div> :
                                        <div>
                                            <div className="line-24 size-14 weight-700">
                                                Password
                                            </div>
                                            <ButtonLine style={{width: "100%"}} onClick={() => dispatch(openModalChangePassword())}>
                                                <FormattedMessage id="Account_modifyPassword"/>
                                            </ButtonLine>
                                        </div>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <ModalChangePassword/>
        </div>
    )
}


export default AccountUser

