import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {endpoints} from "src/config/site.config";
import SuperFetch from "../../helpers/superFetch";
import actions from "./actions";
import authActions from "../auth/actions";
import drawerAction from "../drawer/actions"


export function* registrationUser() {

    yield takeEvery(actions.CREATE_USER, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.registrationUserProfile, data.payload, false)

        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.CREATE_USER_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.CREATE_USER_ERROR,
                payload: error
            });
        }
    })

}

export function* getAllUserProfile() {

    yield takeEvery(actions.GET_ALL_USER_PROFILE, function* () {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllUserProfile, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ALL_USER_PROFILE_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_USER_PROFILE_ERROR,
                payload: error
            });
        }
    })

}


export function* getUserDashboard() {
    yield takeEvery(actions.GET_USER_DASHBOARD, function* () {
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getUserDashboard, null, true)
        if (response) {
            yield put({
                type: actions.GET_USER_DASHBOARD_SUCCESS,
                payload: response
            })
        } else {
            yield put({
                type: actions.GET_USER_DASHBOARD_ERROR,
                payload: error
            })
        }
    })
}

export function* getUserProfileById() {

    yield takeEvery(actions.GET_USER_PROFILE_BY_ID, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getUserProfileById + "/" + data.id, null, true)

        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_USER_PROFILE_BY_ID_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_USER_PROFILE_BY_ID_ERROR,
                payload: error
            });
        }
    })

}

export function* saveUserProfile() {

    yield takeEvery(actions.SAVE_USER_PROFILE, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.saveUserProfile, data.payload, true, true)

        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            localStorage.setItem("isCompleted", "true")
            yield put({
                type: actions.SAVE_USER_PROFILE_SUCCESS,
                payload: response
            });
            yield put({
                type: drawerAction.CLOSE_DRAWER
            })

        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SAVE_USER_PROFILE_ERROR,
                payload: error
            });
        }
    })

}

export function* saveUserProfileAccount(){
    yield takeEvery(actions.SAVE_USER_PROFILE_ACCOUNT, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.saveUserProfile, data.payload, true, true)
        if(response){
            yield put({type: authActions.LOADING_FALSE})
            localStorage.setItem("isCompleted", "true")

            yield put({
                type: actions.SAVE_USER_PROFILE_ACCOUNT_SUCCESS,
                payload: response
            })
            yield put({
                type: actions.GET_USER_PROFILE_BY_ID,
                id: response.baseUserId
            })
            yield put({
                type: drawerAction.CLOSE_DRAWER
            })
        }else{
            yield put({type: authActions.LOADING_FALSE})
            yield put({
                type: authActions.SAVE_USER_PROFILE_ACCOUNT_ERROR,
                payload: error
            })
        }

    })
}

export function* updateUserProfile() {

    yield takeEvery(actions.UPDATE_USER_PROFILE, function* (data ) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.put, endpoints.updateUserProfile + "/" + data.id, data.payload, true,true )

        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.UPDATE_USER_PROFILE_SUCCESS,
                payload: response
            });
            yield put({
                type: actions.GET_USER_PROFILE_BY_ID,
                id: response.baseUserId
            })
            yield put({
                type: drawerAction.CLOSE_DRAWER,
            })
            localStorage.setItem("isCompleted", "true")
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_USER_PROFILE_ERROR,
                payload: error
            });
        }
    })

}

export function* deleteUserProfile() {

    yield takeEvery(actions.DELETE_USER_PROFILE, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.delete, endpoints.deleteUserProfile + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.DELETE_USER_PROFILE_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DELETE_USER_PROFILE_ERROR,
                payload: error
            });
        }
    })

}

export function* getImageSourceUser() {
    yield takeEvery(actions.GET_USER_PROFILE_IMAGE, function* (data) {
        const {
            response,
            fileName,
            error
        } = yield call(SuperFetch.post, endpoints.getImageSource, data.payload, true, false, null, true)
        if (response) {
            let blob = yield response.blob()
            let fileUrl = URL.createObjectURL(blob)
            yield put({
                type: actions.GET_USER_PROFILE_IMAGE_SUCCESS,
                payload: fileUrl,
                fileBlob: blob,
                fileName: fileName,
                fileType: response.headers.get("Content-Type")
            });

        } else {
            yield put({
                type: actions.GET_USER_PROFILE_IMAGE_ERROR,
                payload: error
            })
        }
    })
}

export function* getCvSourceUser() {
    yield takeEvery(actions.GET_USER_PROFILE_CV, function* (data) {
        const {
            response,
            fileName,
            error
        } = yield call(SuperFetch.post, endpoints.getImageSource, data.payload, true, false, null, true)
        if (response) {
            let blob = yield response.blob()
            let fileUrl = URL.createObjectURL(blob)
            yield put({
                type: actions.GET_USER_PROFILE_CV_SUCCESS,
                payload: fileUrl,
                fileBlob: blob,
                fileName: fileName,
                fileType: response.headers.get("Content-Type")
            });

        } else {
            yield put({
                type: actions.GET_USER_PROFILE_CV_ERROR,
                payload: error
            })
        }
    })
}
export function* getCompanyByIdForUser() {
    yield takeEvery(actions.GET_COMPANY_BY_ID_FOR_USER, function* (data) {
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getCompanyByIdForUser + "/" + data.companyId, null, true)
        if (response) {
            yield put({
                type: actions.GET_COMPANY_BY_ID_FOR_USER_SUCCESS,
                payload: response
            });

        } else {
            yield put({
                type: actions.GET_COMPANY_BY_ID_FOR_USER_ERROR,
                payload: error
            })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(registrationUser),
        fork(getAllUserProfile),
        fork(getUserDashboard),
        fork(getUserProfileById),
        fork(saveUserProfile),
        fork(updateUserProfile),
        fork(deleteUserProfile),
        fork(getImageSourceUser),
        fork(getCvSourceUser),
        fork(saveUserProfileAccount),
        fork(getCompanyByIdForUser),
    ]);
}
