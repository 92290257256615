import React, {useEffect} from "react";
import "src/component/Layout/Login/LoginCompany/LoginCompany.scss"
import Icon from "src/component/Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {InputLogin} from "../../../Useful/Input/InputLogin";
import {Switch} from "../../../Useful/Switch";
import {ButtonCompany} from "../../../Useful/Button/ButtonCompany";
import {FormattedMessage, injectIntl} from "react-intl";
import {Form} from "antd";
import actions from "src/redux/auth/actions";
import {ROLES} from "src/utils/utility";
import {PRIVATE_COMPANY_ROUTE, PUBLIC_ROUTE} from "src/route.constants";
import mobileLogo from "src/assets/logo/logo-VT-red-mobile.svg";
import AdvantagesCompany from "../../../../assets/svg/AdvantagesCompany.svg";
import CompanyPicture from "../../../../assets/image/PictureLoginCompany.png";


const {login} = actions

function LoginCompany() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isLoggedIn = useSelector(state => state.AuthReducer.idToken)
    const role = useSelector(state => state.AuthReducer.role)
    const isButtonLoading = useSelector(state => state.AuthReducer.isButtonLoading)

    const [email, setEmail] = React.useState(null)
    const [password, setPassword] = React.useState(null)
    const [rememberMe, setRememberMe] = React.useState(false)
    const [toggleType, setToggleType] = React.useState(false)
    const [toggleIconPassword, setToggleIconPassword] = React.useState(false)

    useEffect(() => {
        if (isLoggedIn && role === ROLES.ADMIN || role === ROLES.EMPLOYEE) {
            navigate(PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY)
        }
    }, [isLoggedIn])

    const goTo = (page, type) => {
        if (page === "registration" && type === "company") {
            navigate(PUBLIC_ROUTE.COMPANY_REGISTRATION, {replace: true})
        } else {
            navigate(PUBLIC_ROUTE.COMPANY_RECOVERY_EMAIL, {replace: true})
        }
    }

    const goToBack = () => {
        navigate("/landing", {replace: true})
    }

    const handleClick = () => {

        if (email && password) {
            let data = {
                email: email,
                password: password,
                context: "company",
            }
            dispatch(login(data))
        }
    }

    const ShowPassword = () => {
        setToggleType(!toggleType)
        setToggleIconPassword(!toggleIconPassword)
    }

    return (
        <div className="login-container-company">
            <div className={"login-content"}>
                <div className={"flex"} style={{flex: 1, gap: "50px"}}>
                    <div className={"advantagesSection flex-center"} style={{flex: "1 1 50%"}}>
                        <div style={{width: "80%"}}>
                            <div className={"platformAdvantages"}>
                                <div className={"platformAdvantagesHeader size-18"}>
                                    <FormattedMessage id="CompanyAdvantages_Header" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                </div>
                                <div className={"platformAdvantagesDescription size-16"}>
                                    <FormattedMessage id="CompanyAdvantages_SubHeader" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                </div>
                            </div>
                            <div className={"flex-column gap-32"}>
                                <div className={"flex-start flex-align-center gap-16"}>
                                    <img src={AdvantagesCompany}/>

                                    <div className={"advantages size-16 merriWeather"}>
                                        <FormattedMessage id="CompanyAdvantages_1" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                    </div>
                                </div>

                                <div className={"flex-start flex-align-center gap-16"}>
                                    <img src={AdvantagesCompany}/>

                                    <div className={"advantages size-16 merriWeather"}>
                                        <FormattedMessage id="CompanyAdvantages_2" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                    </div>
                                </div>

                                <div className={"flex-start flex-align-center gap-16"}>
                                    <img src={AdvantagesCompany}/>

                                    <div className={"advantages size-16 merriWeather"}>
                                        <FormattedMessage id="CompanyAdvantages_3" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="image margin-top-30">
                                <img src={CompanyPicture} className={"side-image"}/>
                            </div>
                            <div className={"technicalSupport"}>
                                <div className={"size-16"}><FormattedMessage id="TechnicalSupport_Company_1"/></div>
                                <br/>
                                <div><FormattedMessage id="TechnicalSupport_Company_2"/></div>
                                <div><FormattedMessage id="TechnicalSupport_1"/></div>
                                <div><FormattedMessage id="TechnicalSupport_2"/></div>
                                <div><FormattedMessage id="TechnicalSupport_3"/></div>
                            </div>
                        </div>
                    </div>

                    <div style={{flex: "1 1 50%"}}>
                        <div className={"containerLoginCompanyForm"}>
                            <header>
                                <div className="header_mobile_company">
                                    <div className="back" onClick={goToBack}>
                                        <span>
                                            <Icon name={ICONS.arrow_2} className="pointer" size={[20, 20]}/>
                                        </span>
                                        <div className="p_back margin-5">
                                            Indietro
                                        </div>
                                    </div>
                                    <h1> Login </h1>
                                    <a href={"https://www.vt-job.it"}>
                                        <img className={"company-mobile-logo"} src={mobileLogo}/>
                                    </a>
                                </div>
                                <div className="header_login">
                                    <p className={"corp2_100"}>Buongiorno!</p>
                                    <p> Inserisci le tue credenziali per entrare</p>
                                </div>
                            </header>

                            <div className={"login_recovery"}>
                                <p><FormattedMessage id="accountDoesntExist"/> <a className={"pointer corp_100 weight-700 font-size-16"} onClick={() => goTo("registration", "company")}><FormattedMessage id="register"/> </a></p>
                            </div>
                            <main>
                                <Form
                                    layout={"vertical"}
                                    onFinish={handleClick}
                                >
                                    <div className={"inputField"}>
                                        <Form.Item
                                            name={"email"}
                                            type={"email"}
                                            label={"Email"}
                                            rules={[{type: 'email', message: false}]}
                                            className="weight-600 size4-14 inline-block width-100"
                                        >
                                            <InputLogin className={"input"} prefix={<Icon name={ICONS.mail} size={[20, 20]}/>} placeholder="Inserire l'email" onChange={(e) => setEmail(e.target.value)}/>
                                        </Form.Item>
                                        <Form.Item
                                            name={"password"}
                                            label={<FormattedMessage id="UserRegistration_main_password"/>}
                                            className="weight-600 size4-14 inline-block width-100"
                                        >
                                            <InputLogin type={toggleType ? "text" : "password"} className={"input"} prefix={<Icon name={ICONS.lock} size={[20, 20]}/>}
                                                        suffix={<div onClick={(e) => ShowPassword()}><Icon name={toggleIconPassword ? ICONS.eye : ICONS.eye_hide} className="flex-center pointer" color="#C41A43" size={[20, 20]}/></div>} placeholder="Inserire la password"
                                                        onChange={(e) => setPassword(e.target.value)}/>
                                        </Form.Item>
                                    </div>

                                    <div className={"section"}>
                                        <Switch checkedChildren={<Icon name={ICONS.ok} size={[20, 20]}/>}
                                                unCheckedChildren={<Icon name={ICONS.close_switch} size={[20, 20]}/>}
                                        />
                                        <p> Ricorda sessione </p>
                                    </div>

                                    <Form.Item>
                                        <ButtonCompany icon={<Icon name={ICONS.tik} className="margin-5 opacity-50" size={[20, 20]}/>} type="primary" block className={"save flex-center"} onClick={handleClick} loading={isButtonLoading}>
                                            Accedere
                                        </ButtonCompany>
                                    </Form.Item>
                                </Form>
                            </main>
                            <footer>
                                <a className={"pointer"} onClick={() => goTo("recovery", "company")}>Non ricordi le tue credenziali? </a>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default injectIntl(LoginCompany)
