import React, {useEffect, useState} from "react";
import Breadcrumb from "antd/es/breadcrumb";
import {PRIVATE_USER_ROUTE} from "src/route.constants";
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import "./myPreferenceStyle.scss"
import actions from "src/redux/preference/actions";
import Icon from "src/component/Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import {message} from "antd";
import preferenceActions from "src/redux/preference/actions";
import userActions from "../../../redux/userProfile/actions";
import ListMyPreference from "../../../component/Layout/MyPreference/ListMyPreference";
import useMediaQuery from "../../../component/Useful/Responsive/UseMedia";
import ListMyPreferenceMobile from "../../../component/Layout/MyPreference/ListMyPreferenceMobile";
import EmptyPreference from "../../../component/Layout/MyPreference/EmptyPreference";

function MyPreference() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [messageApi, contextHolder] = message.useMessage();

    const {getAll} = actions
    const {setRecordOffer} = userActions;
    const {resetStatePreference} = preferenceActions;

    const myPreferences = useSelector(state => state.Preference.myPreferences)
    const isPreferenceSetSuccessfully = useSelector(state => state.Preference.isPreferenceSetSuccessfully)
    const recordOffer = useSelector(state => state.UserProfile.recordOffer)

    const [idRecord, setIdRecord] = useState(null)
    const [viewComponent, setViewComponent] = useState(false)

    const isDesktop = useMediaQuery('(min-width: 1024px)')

    useEffect(() => {
        if (idRecord) {
            const record = myPreferences.find(element => element.id === idRecord)
            dispatch(setRecordOffer(record))
        }
    }, [idRecord])

    useEffect(() => {
        dispatch(getAll())
    }, [])

    useEffect(() => {
        if (isPreferenceSetSuccessfully === "remove") {
            dispatch(getAll())

            messageApi.open({
                type: 'success',
                content: 'Preferenza rimossa con successo!'
            })
            dispatch(resetStatePreference())
        }
    }, [isPreferenceSetSuccessfully])


    return (
        <div>
            <div className="myPreference">
                {contextHolder}
                <div className="max-width-1200 ">

                    <Breadcrumb className="customBreadcrumb" separator="›">
                        <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_USER_ROUTE.DASHBOARD_USER)}>
                            <FormattedMessage id="Breadcrumb_home"/>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <FormattedMessage id="Breadcrumb_preference"/>
                        </Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="flex-align-center margin-bottom-24">
                        <div onClick={() => navigate(PRIVATE_USER_ROUTE.DASHBOARD_USER)}>
                            <Icon name={ICONS.arrow_2} className="pointer margin-right-10" size={[20, 20]}/>
                        </div>
                        <span className="size-28 weight-700 merriWeather">
                            <FormattedMessage id="Breadcrumb_preference"/>
                        </span>
                    </div>

                    {myPreferences && myPreferences.length !== 0 ?
                        <div>
                            {isDesktop ?
                            <ListMyPreference myPreferences={myPreferences} recordOffer={recordOffer} setIdRecord={setIdRecord} idRecord={idRecord} viewComponent={viewComponent} setViewComponent={setViewComponent}/> :
                            <ListMyPreferenceMobile  myPreferences={myPreferences} recordOffer={recordOffer} setIdRecord={setIdRecord} idRecord={idRecord} viewComponent={viewComponent} setViewComponent={setViewComponent}/>}
                        </div> :

                        <EmptyPreference/>
                    }
                </div>
            </div>
        </div>
    )
}

export default MyPreference



