import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import {Breadcrumb, Input, message} from "antd";
import "src/Pages/workOffers/WorkOffersStyle.scss";
import {ICONS} from "src/assets/sprite/iconsNames";
import {CustomDrawer} from "src/component/Layout/Drawer/CustomDrawer";
import {ButtonUser} from "src/component/Useful/Button/ButtonUser";
import {FormattedMessage} from "react-intl";
import {PRIVATE_COMPANY_ROUTE} from "src/route.constants";
import OffersTable from "../../component/Layout/OffersList/OffersTable";
import EmptyOffers from "src/component/Layout/OffersList/EmptyOffers"
import Icon from "src/component/Icon/Sprite";
import {useDispatch, useSelector} from "react-redux";
import actions from "src/redux/company/actions";
import drawerActions from "src/redux/drawer/actions"
import offertActions from "src/redux/offer/actions";
import {OFFER_STATE} from "src/utils/utility";
import { Select } from "src/component/Useful/Select/Select";
import companyActions from "../../redux/company/actions";
import locationActions from "../../redux/location/actions";


function WorkOffers() {

    const navigate = useNavigate()
    let dispatch = useDispatch()
    const [messageApi, contextHolder] = message.useMessage();

    const {offertToCreate} = actions;
    const {getAll, get} = offertActions;
    const {openDrawer} = drawerActions;
    const {offertToModify} = companyActions;
    const {getAllCompanyLocation} = locationActions;

    const [state, setState] = React.useState("Tutti")
    const [search, setSearch] = React.useState(null)
    const [order, setOrder] = React.useState("DESC")
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = React.useState(10)

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)


    const loading = useSelector(state => state.AuthReducer.loading)
    const offers = useSelector(state => state.Offer.offers)
    const totalOffers = useSelector(state => state.Offer.totalOffers)
    const isOffersCreatedSuccess = useSelector(state => state.Offer.isOffersCreatedSuccess)
    //const record = useSelector(state => state.Company.record)
    const record = useSelector(state => state.Offer.offer)
    const modify = useSelector(state => state.Company.offertToModify)


    const queryParameters = new URLSearchParams(window.location.search)
    const openCompany = queryParameters.get("openCompany")
    const id = queryParameters.get("id")

    const selectState = (e) => {
        setPage(1)
        setState(e)
        if (e === "Tutti" && !search) {
            dispatch(getAll(
                {
                    page: 1,
                    size: pageSize,
                    sort: order
                }
            ))
        } else {
            dispatch(getAll(
                {
                    page: 1,
                    size: pageSize,
                    state: e,
                    title: search,
                    sort: order
                }
            ))
        }
    }
    const selectOrder = (value) => {
        setPage(1)
        setOrder(value)
        if (state !== "Tutti") {
            dispatch(getAll({
                title: search,
                page: 1,
                size: pageSize,
                state: state,
                sort: value
            }))
        } else {
            dispatch(getAll({
                title: search,
                page: 1,
                size: pageSize,
                sort: value
            }))
        }
    }

    const searchOnChange = (value) => {
        setPage(1)
        setSearch(value)
        if(state !== "Tutti" && !order){
            dispatch(getAll({
                title: value,
                page: 1,
                size: pageSize,
                state: state
            }))
        } else {
            dispatch(getAll({
                title: value,
                page: 1,
                size: pageSize,
                sort: order
            }))
        }
    };

    useEffect(() => {
        dispatch(getAll({
            page: 1,
            size: pageSize,
            state: state,
            sort: order,
        }))
        if(openCompany){
            dispatch(openDrawer())
        }
        dispatch(offertToModify())
    }, [])

    useEffect(() => {
        if (isOffersCreatedSuccess) {
            setPage(1)
            dispatch(getAll(
                {
                    page: 1,
                    size: pageSize,
                    sort: order
                }
            ))

            messageApi.open({
                type: 'success',
                content: 'Offerta aggiornata con successo!'
            })
        }
    }, [isOffersCreatedSuccess])

    useEffect(()=>{
        if(id){
            dispatch(get(id))
        }
    }, [])


    return (
        <div>
            <div className="workOffers">
                {contextHolder}
                <div className="max-width-1200 ">

                    <Breadcrumb className="customBreadcrumb" separator="›">
                        <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY)}><FormattedMessage id="Breadcrumb_home"/></Breadcrumb.Item>
                        <Breadcrumb.Item><FormattedMessage id="Breadcrumb_workOffer"/></Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="flex-center-between margin-bottom-24">
                        <div>
                            <span className="size-28 weight-700 merriWeather">
                                <FormattedMessage id="topBar_workOffers"/>
                            </span>
                        </div>
                        <div className="flex">

                            <div className="margin-right-22">
                                <Input className={"inputField"} placeholder={"Ricerca"} onChange={(e) => searchOnChange(e.target.value)} prefix={<Icon name={ICONS.search} size={[20, 20]} color={"#868696"}/>}/>
                            </div>

                            <div>
                                <ButtonUser className="flex-center-between" onClick={() => {
                                    dispatch(offertToCreate())
                                    dispatch(openDrawer())
                                }}>
                                    <Icon name={ICONS.doc_add} size={[20, 20]} color={"#FFFFF"}/>
                                    <span className="white-text">Nuova Offerta</span>
                                </ButtonUser>
                            </div>
                        </div>
                    </div>

                    <div className="margin-bottom-24 flex-center-between">
                        {offers && totalOffers > 0 ?
                            <div className="flex-center"><div className="size-14 weight-700 margin-right-5"><FormattedMessage id="results"/></div>({offers && totalOffers})</div> : <div/>}
                        <div className="flex-align-center">
                            <div className="margin-right-32">
                                <span>
                                    <FormattedMessage id="state"/>:
                                </span>
                                <Select
                                    popupClassName="dropdown border-radius-0"
                                    bordered={false}
                                    dropdownMatchSelectWidth={false}
                                    style={{width: "fit-content"}}
                                    defaultValue={"Tutti"}
                                    suffixIcon={
                                        <Icon name={ICONS.arrow_small} size={[20, 20]} color={"#0E0E2C"}/>
                                    }
                                    onChange={(e) => selectState(e)}
                                    options={[
                                        {label: OFFER_STATE.all.label, value: OFFER_STATE.all.value},
                                        {label: OFFER_STATE.draft.label, value: OFFER_STATE.draft.value},
                                        {label: OFFER_STATE.toPublished.label, value: OFFER_STATE.toPublished.value},
                                        {label: OFFER_STATE.published.label, value: OFFER_STATE.published.value}
                                    ]}
                                />
                            </div>
                            <div>
                                <span>
                                    <FormattedMessage id="orderBy"/>:
                                </span>
                                <Select
                                    popupClassName="dropdown border-radius-0"
                                    bordered={false}
                                    dropdownMatchSelectWidth={false}
                                    style={{width: "fit-content"}}
                                    defaultValue={order}
                                    suffixIcon={
                                        <Icon name={ICONS.arrow_small} size={[20, 20]} color={"#0E0E2C"}/>
                                    }
                                    options={[
                                        {label: 'Recenti', value: 'DESC'},
                                        {label: 'Meno Recenti', value: 'ASC'},
                                    ]}
                                    onChange={(e) => {
                                        selectOrder(e)
                                    }}
                                >
                                </Select>
                            </div>
                        </div>

                    </div>
                    <div className="margin-bottom-24">
                        {offers && totalOffers !== 0 ? <OffersTable offers={offers} totalOffers={totalOffers} state={state} setPageSize={setPageSize} pageSize={pageSize} setPage={setPage} page={page} order={order}/> : <EmptyOffers/>}
                    </div>
                </div>
            </div>
            <CustomDrawer
                record={record}
                modify={modify ? modify : "modify"}
                state={state}
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                type={"offer"}
            />
        </div>
    )
}

export default WorkOffers
