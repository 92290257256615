import React from "react";
import {Button, Card, Col, Divider, Row} from "antd";
import Icon from "../../Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import {ImgComponent, substituteZeroWithLine} from "src/utils/utility";
import Drawer from "antd/es/drawer";
import {useNavigate} from "react-router";
import GreyLogo from "../../../assets/logo/logo-VT-grey.svg";

function DrawerListMyCandidatureMobile(props){

    const {recordOffer, openState, setOpenState, width} = props

    const navigate = useNavigate()

    function setField(record) {
        let path = "/user/company/"
        if (record.id) path = path + record.id
        navigate(path)
    }

    return(
        <Drawer placement="right" onClose={()=>setOpenState(false)} open={openState} width={width}>
            <div style={{width: "100%", height:"100%"}}>
                <Card style={{marginLeft: "20px", position: "sticky", top: "70px", borderRadius: 0}}>
                    <div className="flex-row margin-bottom-30 flex-center-between">
                        <div className="flex-align-center">
                            <Icon color="grey" name={ICONS.user_ok}
                                  size={[20, 20]}/>
                            <span className="margin-left-5">
                                {substituteZeroWithLine(recordOffer && recordOffer.candidateCount)}
                            </span>

                        </div>
                        <div style={{
                            width: "120px",
                            height: "100px",
                            margin: "-80px 0 0 -20px",
                            border: "1px solid rgba(14, 14, 44, 0.15)"
                        }}>
                            {recordOffer && !recordOffer.anonymous ?
                                <div className="imgBorder">
                                    <ImgComponent logoPath={recordOffer && recordOffer.companyLogo}/>
                                </div>
                                :
                                <div className="noLogo">
                                    <img src={GreyLogo}/>
                                </div>
                            }
                        </div>
                        <div/>
                    </div>
                    <div className="flex-center flex-column margin-bottom-30">
                        <div className="weight-700 size-22">{recordOffer && recordOffer.offertTitle}</div>
                        {recordOffer && !recordOffer.anonymous ?
                            <div className="weight-40 size-14 underlined pointer" onClick={() => setField(recordOffer)}>
                                {recordOffer && recordOffer.uiCompanyLocationResponseData && recordOffer.uiCompanyLocationResponseData.name}
                            </div>
                            :
                            <div className="opacity-50 underlined size-12">
                                Azienda Anonima
                            </div>
                        }
                    </div>
                    <Divider/>
                    <div className="margin-top-30 margin-bottom-30">
                        <div>
                            {recordOffer && recordOffer.location &&
                            <div>
                                <span className="weight-700 margin-right-5">Dove:</span>
                                <span>{recordOffer && recordOffer.location}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.impegnoRichiesto &&
                            <div>
                                <span className="weight-700 margin-right-5">Tempo:</span>
                                <span>{recordOffer && recordOffer.impegnoRichiesto}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.insertMode &&
                            <div>
                                <span className="weight-700 margin-right-5">Contratto:</span>
                                <span>{recordOffer && recordOffer.insertMode}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.workPlace &&
                            <div>
                                <span className="weight-700 margin-right-5">Modalità:</span>
                                <span>{recordOffer && recordOffer.workPlace}</span>
                            </div>
                            }
                        </div>
                    </div>
                    <Row>
                        <Col xl={24}>
                            <div className="margin-bottom-30">
                                {/*<Button className={"padding-5 border-radius-3 margin-right-10"}>*/}
                                {/*    <Icon name={ICONS.facebook} size={[20, 20]} color={"#1877F2"}/>*/}
                                {/*</Button>*/}
                                {/*<Button className={"padding-5 border-radius-3 margin-right-10"}>*/}
                                {/*    <Icon name={ICONS.twitter} size={[20, 20]} color={"#1DA1F2"}/>*/}
                                {/*</Button>*/}
                                {recordOffer && recordOffer.company && recordOffer.company.linkedin &&
                                <Button className={"padding-5 border-radius-3 margin-right-10"}>
                                    <a href={recordOffer.company.linkedin} target="_blank">
                                        <Icon name={ICONS.linkedin} size={[20, 20]} color={"#1DA1F2"}/>
                                    </a>
                                </Button>
                                }
                                {recordOffer && recordOffer.company && recordOffer.company.webPage &&
                                <Button className={"padding-5 border-radius-3"}>
                                    <a href={recordOffer.company.webPage} target="_blank">
                                        <Icon name={ICONS.link} size={[20, 20]} className={"opacity-50"}/>
                                    </a>
                                </Button>
                                }
                            </div>
                        </Col>
                    </Row>
                    {recordOffer && recordOffer.description &&
                    <div>
                        <Divider>
                            <div className="size-12 opacity-50">Descrizione</div>
                        </Divider>
                        <p dangerouslySetInnerHTML={{__html: recordOffer && recordOffer.description}}/>
                    </div>}
                </Card>
            </div>
        </Drawer>
    )
}

export default DrawerListMyCandidatureMobile