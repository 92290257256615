import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import "./activateProfileSuccess.scss"


function ActiveProfileSuccess(){

    return(
        <div className={'activated-container-content-success'}>
            <h1><FormattedMessage id="confirm_account"/></h1>
            <h2><FormattedMessage id="go_to"/></h2>
            <p><FormattedMessage id="description_activation"/></p>
            <p><FormattedMessage id="description_redirect"/></p>
        </div>
    )
}

export default injectIntl(ActiveProfileSuccess)
