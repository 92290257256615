import React, {useEffect, useState} from "react";
import Breadcrumb from "antd/es/breadcrumb";
import {PRIVATE_COMPANY_ROUTE, PRIVATE_USER_ROUTE} from "../../../../route.constants";
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router";
import "./CandidateDetailStyle.scss"
import {Col, Row, Table} from "antd";
import moment from "moment";
import {getAge} from "../../../../helpers/utility";
import {ButtonLine} from "../../../Useful/Button/ButtonLine";
import Icon from "../../../Icon/Sprite";
import {ICONS} from "../../../../assets/sprite/iconsNames";
import Divider from "antd/es/divider";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../redux/candidature/actions"
import Modal from "antd/es/modal";
import ModalCv from "./ModalCv";
import {ALERT_TYPE, firstLetters, OFFER_FILTERS_CANDIDATE, switchStateLabel} from "../../../../utils/utility";
import {AlertModal} from "../../AlertModal/AlertModal";
import ModalCandidateAnswers from "./ModalCandidateAnswers";

const {getAllCandidatureByUserAndCompany, deleteCandidature} = actions

function CandidateDetail() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const candidateDetails = useSelector(state => state.Candidature.candidateDetails)
    const [candidateDetail, setCandidateDetail] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState({value: null, flag: false})
    const [isModalAnswersOpen, setIsModalAnswersOpen] = useState(false);
    const [row, setRow] = useState(false)

    useEffect(() => {
        let id = window.location.pathname.split("/")[3]
        dispatch(getAllCandidatureByUserAndCompany(id))
    }, [])

    
    const info = (record) => {
        Modal.info({
            content: (<div>
                {record.questions && record.questions.length > 0 ? record.questions.map((item) => {
                        return (<div>
                            domanda : {item.question}
                            risposta : {item.answer && item.answer}
                        </div>)
                    })
                    :
                    (
                        <div>
                            Non ci sono domande relative all'offerta
                        </div>
                    )
                }
            </div>)
        })
    }

    useEffect(() => {
        setCandidateDetail({
            userFirstName: candidateDetails && candidateDetails[0].userFirstName,
            userLastName: candidateDetails && candidateDetails[0].userLastName,
            userBirthday: candidateDetails && candidateDetails[0].userBirthday,
            userCity: candidateDetails && candidateDetails[0].userCity,
            userLinkedin: candidateDetails && candidateDetails[0].userLinkedin,
            userDescription: candidateDetails && candidateDetails[0].userDescription,
            userLanguages: candidateDetails && candidateDetails[0].userLanguages,
            userEmail: candidateDetails && candidateDetails[0].userEmail,
            userTelephone: candidateDetails && candidateDetails[0].userTelephone,
            userJobState: candidateDetails && candidateDetails[0].userJobState,
            userQualifications: candidateDetails && candidateDetails[0].userQualifications,
            userAddress: candidateDetails && candidateDetails[0].userAddress,
            userCap: candidateDetails && candidateDetails[0].userCap,
            userCvPath: candidateDetails && candidateDetails[0].userCvPath
        })

    }, [candidateDetails])


    const columns = [
        {
            title: "Candidature",
            width: "34%",
            dataIndex: "offertTitle",
            render: (text, record) => <div>
                <div className="weigth-600 size-14 underlined blue pointer" onClick={() => {
                    setIsModalAnswersOpen(true)
                    setRow(record)
                }}>
                    {record.offertTitle}
                </div>
            </div>
        },
        {
            title: "Tipologia Contratto",
            width: "33%",
            dataIndex: "impegnoRichiesto",
            render: (text, record) => <div>
                <p>{record.impegnoRichiesto}</p>
                <span>{record.offertInsertMode}</span>

            </div>
        },
        {
            title: "Stato",
            width: "33%",
            dataIndex: "candidatureState",
            render: (text, record) => <div>
                {record.candidatureState && switchStateLabel(record.candidatureState)}
            </div>
        },
        {
            fixed: 'right',
            width: "5%",
            render: (text, record) => <div className=" flex-center">
                <div style={{padding: 20}}
                     onClick={() => setIsAlertOpen({value: record.id, flag: true})}
                >
                    <Icon name={ICONS.delete} className="icon pointer" color="#868696" size={[20, 20]}/>
                </div>
            </div>
        }
    ]

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    //todo: chiamata in get alla mount per poter prendere il dettaglio del candidato
    return (
        <div className={"candidateDetail"}>
            <div className={"maxWidth"}>
                <Breadcrumb className="customBreadcrumb" separator="›">
                    <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY)}>
                        <FormattedMessage id="Breadcrumb_home"/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_COMPANY_ROUTE.CANDIDATE)}>
                        <FormattedMessage id="Breadcrumb_candidate"/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <FormattedMessage id="Breadcrumb_candidate_details"/>
                    </Breadcrumb.Item>
                </Breadcrumb>


                <Row gutter={24}>
                    {candidateDetails &&
                    <Col span={8}>
                        <div className="border-div margin-bottom-20">
                            <div className="ab flex-center">
                                <div className="bor">
                                    {candidateDetail &&
                                    <div className={"profile-circle"}>{firstLetters(candidateDetail.userFirstName, candidateDetail.userLastName)}
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className="padding-left-32 padding-right-32">
                                <div className="flex-center flex-column padding">
                                    <div className={"flex-center weight-700 size-22"}>{candidateDetail && candidateDetail.userFirstName && candidateDetail.userLastName ? candidateDetail.userFirstName + " " + candidateDetail.userLastName : null}</div>
                                    {candidateDetail && candidateDetail.userBirthday && <div className={"flex-center weight-400 size-14"}>Nato
                                        il {moment(parseInt(candidateDetail.userBirthday)).format("DD MMM. YYYY")}
                                        <div
                                            className="opacity-50 margin-left-5">({getAge(parseInt(candidateDetail.userBirthday))} anni)
                                        </div>
                                    </div>}
                                    <div className={"flex-center"}>{candidateDetail && candidateDetail.userCity && candidateDetail.userCity}</div>
                                </div>

                                <div className="flex-center">
                                    {
                                        candidateDetail && candidateDetail.userLinkedin &&
                                        <ButtonLine className="flex-center margin-5" style={{height: "32px"}} icon={<Icon name={ICONS.linkedin} size={[20, 20]} className="flex-center margin-5"/>}>
                                            <a href={candidateDetail.userLinkedin} target="_blank">
                                                Linkedin
                                            </a>
                                        </ButtonLine>
                                    }

                                    <ButtonLine className="flex-center margin-5" onClick={() => showModal()} style={{height: "32px"}} icon={<Icon name={ICONS.doc} size={[20, 20]} className="flex-center margin-5"/>}>CV</ButtonLine>
                                </div>
                                <p className="weight-400 size-14 text-align-center">{candidateDetail && candidateDetail.userDescription && candidateDetail.userDescription}</p>
                                <Divider/>

                                <p className="weight-600 size-12 opacity-50">LINGUE</p>
                                {candidateDetail && candidateDetail.userLanguages && candidateDetail.userLanguages.map((item) =>
                                    <div className="weight-600 size-14 flex-align-center">{item.spokenLanguages}:<p className="weight-400 size-14 margin-5">{item.spokenLanguagesLevel}</p></div>
                                )}

                                <p className="weight-600 size-12 opacity-50">CONTATTI</p>
                                <div className="weight-600 size-14 flex-align-center">Email:<p className="weight-400 size-14 margin-5">{candidateDetail && candidateDetail.userEmail && candidateDetail.userEmail}</p></div>
                                <div className="weight-600 size-14 flex-align-center">Telefono:<p className="weight-400 size-14 margin-5">{candidateDetail && candidateDetail.userTelephone && candidateDetail.userTelephone}</p></div>

                                <p className="weight-600 size-12 opacity-50">STATO LAVORATIVO E FORMAZIONE</p>
                                <div className="weight-600 size-14 flex-align-center">Stato lavorativo:<p className="weight-400 size-14 margin-5">{candidateDetail && candidateDetail.userJobState && candidateDetail.userJobState}</p></div>
                                {candidateDetail && candidateDetail.userQualifications && candidateDetail.userQualifications.map((item) =>
                                    <div className="weight-600 size-14 flex-align-center">Livello di istruzione:<p className="weight-400 size-14 margin-5">{item.qualificationLevel}</p></div>
                                )}

                                <p className="weight-600 size-12 opacity-50">INDIRIZZO</p>
                                <div className="weight-600 size-14 flex-align-center">Indirizzo:<p className="weight-400 size-14 margin-5">{candidateDetail && candidateDetail.userAddress && candidateDetail.userAddress}</p></div>
                                <div className="weight-600 size-14 flex-align-center">Città:<p className="weight-400 size-14 margin-5">{candidateDetail && candidateDetail.userCity && candidateDetail.userCity}</p></div>
                                <div className="weight-600 size-14 flex-align-center margin-bottom-20">CAP:<p className="weight-400 size-14 margin-5">{candidateDetail && candidateDetail.userCap && candidateDetail.userCap}</p></div>
                            </div>
                        </div>
                    </Col>}
                    <Col span={16}>
                        <Table
                            style={{marginTop: "65px"}}
                            columns={columns}
                            dataSource={candidateDetails}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </div>
            <ModalCv open={isModalOpen} onOk={handleOk} onCancel={handleCancel} cv={candidateDetail && candidateDetail.userCvPath}/>
            <ModalCandidateAnswers open={isModalAnswersOpen} candidature={row} onCancel={() => setIsModalAnswersOpen(false)}/>
            <AlertModal
                type={ALERT_TYPE.ERROR}
                content={<div>Perderai la tua candidatura. Sei sicuro di procedere?</div>}
                okText={<FormattedMessage id="Alert_proceed"/>}
                onOk={() => {
                    dispatch(deleteCandidature(isAlertOpen.value))
                    setIsAlertOpen({value: null, flag: false})
                    navigate(PRIVATE_COMPANY_ROUTE.CANDIDATE)
                }}
                onClose={() => {
                    setIsAlertOpen({value: null, flag: false})
                }}
                isAlertOpen={isAlertOpen.flag}
            />
        </div>

    )
}

export default CandidateDetail
