import React, {useState, useRef, useEffect} from "react";
import {NavLink} from "react-router-dom";
import "./topbar.scss"
import WhiteLogo from "src/assets/logo/logo-VT-white.svg"
import {Badge, Col, Divider, Popover, Row} from "antd";
import {PRIVATE_COMPANY_ROUTE} from "src/route.constants";
import {useLocation, useNavigate} from "react-router";
import {FormattedMessage, injectIntl} from "react-intl";
import {ICONS} from "src/assets/sprite/iconsNames";
import actions from "../../../redux/auth/actions";
import {useDispatch, useSelector} from "react-redux";
import Icon from "src/component/Icon/Sprite";
import notificationActions from "src/redux/notification/actions";
import {NOTIFICATION_TYPE, ROLES} from "../../../utils/utility";
import dayjs from "dayjs";

const {logout} = actions

function CompanyTopbar() {
    const relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)

    const dispatch = useDispatch();

    const location = useLocation()
    const navigate = useNavigate()

    const {getAllNotification, setNotificationRead} = notificationActions

    const [isAccountPopoverOpen, setIsAccountPopoverOpen] = useState(false)
    const [isNotifyPopoverOpen, setIsNotifyPopoverOpen] = useState(false)

    const isEnabled = useSelector(state => state.AuthReducer.isEnabled)
    const isCompleted = useSelector(state => state.AuthReducer.isCompleted)
    const role = useSelector(state => state.AuthReducer.role)

    const listNotification = useSelector(state => state.Notification.listNotification)
    const notificationCount = useSelector(state => state.Notification.notificationCount)

    const logOut = () => {
        dispatch(logout(navigate))
    }

    function setAllRead() {
        let ids = []
        let tempList = [...listNotification]
        tempList = tempList.filter(notification => !notification.read)
        tempList.forEach(notification => ids = ids.concat(notification.ids))
        if (ids.length !== 0) {
            dispatch(setNotificationRead(ids))
        }
    }

    useEffect(() => {
        dispatch(getAllNotification())
    }, [])


    return (
        <>
            <div className="companyTopbar">
                <div className="sidePadding">
                    <div className="topbarContent flex-center-between">
                        <div className="pointer" onClick={() => navigate(PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY)}>
                            <img src={WhiteLogo} className={"logo"}/>
                        </div>
                        <div className="menuContainer">
                            <ul>
                                {isEnabled &&
                                <>
                                    {role === ROLES.ADMIN &&
                                    <li>
                                        <NavLink to={PRIVATE_COMPANY_ROUTE.MY_COMPANY} className={({isActive}) => isActive ? 'active' : null}>
                                            <FormattedMessage id="topBar_myCompany"/>
                                        </NavLink>
                                    </li>
                                    }

                                    {isCompleted &&
                                    <>
                                        <li>
                                            <NavLink to={PRIVATE_COMPANY_ROUTE.WORK_OFFERS} className={({isActive}) => isActive ? 'active' : null}>
                                                <FormattedMessage id="topBar_workOffers"/>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={PRIVATE_COMPANY_ROUTE.CANDIDATE} className={({isActive}) => isActive ? 'active' : null}>
                                                <FormattedMessage id="topBar_candidates"/>
                                            </NavLink>
                                        </li>
                                    </>
                                    }
                                </>
                                }

                                {isEnabled &&
                                <>

                                    <Divider type={"vertical"} style={{backgroundColor: "#FFFFFF", opacity: "0.2", height: "32px"}}/>

                                    <li>
                                        <Popover
                                            overlayClassName="notifyPopover"
                                            placement={"bottomRight"}
                                            getPopupContainer={triggerNode => triggerNode.parentElement}
                                            trigger="click"
                                            open={isNotifyPopoverOpen}
                                            onOpenChange={(open) => setIsNotifyPopoverOpen(open)}
                                            content={
                                                <div style={{minHeight: "500px", maxHeight: "70vh"}}>
                                                    <div className={"header notifyPadding flex-center-between"}>
                                                        <div className="size-16 weight-700">
                                                            <FormattedMessage id="topBar_notification"/>
                                                        </div>
                                                        <div className="flex-align-center">
                                                            <div className="notifyPage size-14 weight-600 pointer" onClick={() => {
                                                                navigate(PRIVATE_COMPANY_ROUTE.NOTIFICATION)
                                                                setIsNotifyPopoverOpen(false)
                                                            }}>
                                                                <FormattedMessage id="topBar_notificationPage"/>
                                                            </div>
                                                            <div className="flex-align-center margin-left-18">
                                                                <Icon className="icon" name={ICONS.refresh} size={[20, 20]} color="#868696"/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div className="notifyPadding border-bottom flex-end">
                                                            <div className="flex-align-center opacity-50">
                                                                <div className="flex-align-center margin-left-18">
                                                                    <Icon className="opacity-50" name={ICONS.tik_all_read} size={[20, 20]} color={"#0E0E2C"}/>
                                                                </div>

                                                                <div className="size-14 weight-600 underlined pointer" onClick={() => setAllRead()}>
                                                                    <FormattedMessage id="topBar_MarkAsRead"/>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        {listNotification && listNotification.map((notify) => {
                                                            if (notify.type === NOTIFICATION_TYPE.NEW_CANDIDATURE) {
                                                                return (
                                                                    <Row className="notifyPadding border-bottom">
                                                                        <Col span={4}>
                                                                            <div className="notifyIcon flex-center">
                                                                                <Icon name={ICONS.user} size={[20, 20]} color={"#0E0E2C"}/>
                                                                            </div>
                                                                        </Col>
                                                                        <Col span={20}>
                                                                            <div className="flex-center-between">
                                                                                <div className="flex-align-center">
                                                                                    <div className={`size-14 ${!notify.read && "weight-700"}`}>
                                                                                        {notify.ids && notify.ids.length}
                                                                                        &nbsp;
                                                                                        <FormattedMessage id="notification_newCandidates"/>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex-center">
                                                                                    <div className="size-12 opacity-70 margin-left-8">
                                                                                        {notify && notify.dateCreated && dayjs(notify.dateCreated).fromNow(true)} fa
                                                                                    </div>
                                                                                    {notify.read ?
                                                                                        <div className="tik">
                                                                                            <Icon name={ICONS.tik} size={[20, 20]} color={"#208AC6"}/>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="blueCircle margin-left-8"/>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="blue underlined pointer" style={{width: "fit-content"}} onClick={() => {
                                                                                dispatch(setNotificationRead(notify.ids))
                                                                                navigate("/company/offers/" + (notify.offert && notify.offert.id))
                                                                                setIsNotifyPopoverOpen(false)
                                                                            }}>
                                                                                {notify.offert && notify.offert.title}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            } else {
                                                                return (
                                                                    <Row className="notifyPadding border-bottom">
                                                                        <Col span={4}>
                                                                            <div className="notifyIcon flex-center">
                                                                                <Icon name={ICONS.doc} size={[20, 20]} color={"#0E0E2C"}/>
                                                                            </div>
                                                                        </Col>
                                                                        <Col span={20}>
                                                                            <div className="flex-center-between">
                                                                                <div className="flex-align-center">
                                                                                    <div className={`size-14 ${!notify.read && "weight-700"}`}>
                                                                                        <div>
                                                                                            <span>
                                                                                                L'offerta&nbsp;
                                                                                            </span>
                                                                                            <span className="blue underlined pointer" style={{width: "fit-content"}} onClick={() => {
                                                                                                dispatch(setNotificationRead(notify.ids))
                                                                                                navigate("/company/offers/" + (notify.offert && notify.offert.id))
                                                                                            }}>
                                                                                                {notify.offert && notify.offert.title}
                                                                                            </span>
                                                                                            <span>
                                                                                                &nbsp;è stata pubblicata
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex-center">
                                                                                    <div className="size-12 opacity-70 margin-left-8">
                                                                                        {notify && notify.dateCreated && dayjs(notify.dateCreated).fromNow(true)} fa
                                                                                    </div>
                                                                                    {notify.read ?
                                                                                        <div className="tik">
                                                                                            <Icon name={ICONS.tik} size={[20, 20]} color={"#208AC6"}/>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="blueCircle margin-left-8"/>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                    <div className="end-border-bottom"/>
                                                </div>
                                            }
                                        >
                                            <div className={location.pathname === PRIVATE_COMPANY_ROUTE.NOTIFICATION ? 'active' : null}>

                                                <div
                                                    className="pointer flex-align-center hover-opacity-70"
                                                    id="notifyMenu"
                                                    onClick={() => {
                                                        setIsNotifyPopoverOpen(true)
                                                    }}
                                                >
                                                    <Badge count={notificationCount} size={"small"}>
                                                        <Icon className="icon-notify" name={ICONS.notify} size={[20, 20]} color={"#FFFFFF"}/>
                                                    </Badge>
                                                </div>

                                            </div>
                                        </Popover>
                                    </li>

                                    <Divider type={"vertical"} style={{backgroundColor: "#FFFFFF", opacity: "0.2", height: "32px"}}/>
                                </>
                                }

                                <li>
                                    <Popover
                                        className="popover-account"
                                        overlayClassName="myAccount"
                                        placement={"bottomRight"}
                                        getPopupContainer={triggerNode => triggerNode.parentElement}
                                        trigger="click"
                                        open={isAccountPopoverOpen}
                                        onOpenChange={(open) => setIsAccountPopoverOpen(open)}
                                        content={
                                            <div className="accountPadding">

                                                {isEnabled && isCompleted &&
                                                <>
                                                    <div className="flex-align-center spacing pointer padding-8" onClick={() => {
                                                        setIsAccountPopoverOpen(false)
                                                        navigate(PRIVATE_COMPANY_ROUTE.ACCOUNT)
                                                    }}>
                                                        <div className="flex-align-center padding-8">
                                                            <Icon className="opacity-70" name={ICONS.user} size={[20, 20]} color={"#0E0E2C"}/>
                                                        </div>
                                                        <div className="size-14 weight-500">
                                                            <FormattedMessage id="account"/>
                                                        </div>
                                                    </div>

                                                    <Divider/>
                                                </>
                                                }

                                                <div className="flex-align-center spacing pointer padding-8" onClick={() => logOut()}>
                                                    <div className="flex-align-center padding-8">
                                                        <Icon className="opacity-70" name={ICONS.exit} size={[20, 20]} color={"#0E0E2C"}/>
                                                    </div>
                                                    <div className="size-14 weight-500">
                                                        <FormattedMessage id="exit"/>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <div className={location.pathname === PRIVATE_COMPANY_ROUTE.ACCOUNT ? 'active' : null}>

                                            <div
                                                className="pointer flex-align-center hover-opacity-70"
                                                id="accountMenu"
                                                onClick={() => {
                                                    setIsAccountPopoverOpen(true)
                                                    setIsNotifyPopoverOpen(false)
                                                }}
                                            >
                                                <div>
                                                    <div className="size-14 weight-700 line-height-18">
                                                        {localStorage.getItem("companyName")}
                                                    </div>
                                                    <div className="size-12 opacity-70 line-height-16">
                                                        {localStorage.getItem("firstName") + " " + localStorage.getItem("lastName")}
                                                    </div>
                                                </div>
                                                <div className="flex-center">
                                                    <Icon name={ICONS.arrow_small} size={[20, 20]} color={"#FFFFFF"}/>
                                                </div>
                                            </div>

                                        </div>

                                    </Popover>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default injectIntl(CompanyTopbar)
