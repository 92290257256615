import React from "react";
import {Col, Row} from "antd";
import Card from "antd/es/card";
import Divider from "antd/es/divider";
import "src/component/Layout/Modal/ModalProfile/ModalProfile.scss"
import "src/Pages/candidate/profile.scss"
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {getAge} from "../../helpers/utility";
import {firstLetters} from "../../utils/utility";


function Profile() {

    let index = 0;

    const candidature = useSelector(state => state.Candidature.candidature)

    return(
            <Row gutter={24}>
                {candidature &&
                <Col span={8}>
                   <div className="border-div margin-bottom-20">
                        <div className="ab flex-center">
                            <div className="bor">
                                <div className={"profile-circle"}>{firstLetters(candidature.userFirstName, candidature.userLastName)}</div>
                            </div>
                        </div>
                        <div className="padding-left-32 padding-right-32">
                            <div className="flex-center flex-column padding">
                                <div className={"flex-center weight-700 size-22"}>{candidature.userFirstName + " " + candidature.userLastName}</div>
                                <div className={"flex-center weight-400 size-14"}>Nato il {moment(parseInt(candidature.userBirthday)).format("DD MMM. YYYY")} <div className="opacity-50 margin-left-5">({getAge(parseInt(candidature.userBirthday))} anni)</div></div>
                                <div className={"flex-center"}>{candidature.userCity}</div>
                            </div>

                            <p className="weight-400 size-14 text-align-center">{candidature.userDescription}</p>
                            <Divider/>

                            <p className="weight-600 size-12 opacity-50">LINGUE</p>
                            {candidature.userLanguages && candidature.userLanguages.map((item) =>
                                <div className="weight-600 size-14 flex-align-center">{item.spokenLanguages}:<p className="weight-400 size-14 margin-5">{item.spokenLanguagesLevel}</p></div>
                            )}

                            <p className="weight-600 size-12 opacity-50">CONTATTI</p>
                            <div className="weight-600 size-14 flex-align-center">Email:<p className="weight-400 size-14 margin-5">{candidature.userEmail}</p></div>
                            <div className="weight-600 size-14 flex-align-center">Telefono:<p className="weight-400 size-14 margin-5">{candidature.userTelephone}</p></div>

                            <p className="weight-600 size-12 opacity-50">STATO LAVORATIVO E FORMAZIONE</p>
                            <div className="weight-600 size-14 flex-align-center">Stato lavorativo:<p className="weight-400 size-14 margin-5">{candidature.userJobState}</p></div>
                            {candidature.userQualifications && candidature.userQualifications.map((item) =>
                                <div className="weight-600 size-14 flex-align-center">Livello di istruzione:<p className="weight-400 size-14 margin-5">{item.qualificationLevel}</p></div>
                            )}

                            <p className="weight-600 size-12 opacity-50">INDIRIZZO</p>
                            <div className="weight-600 size-14 flex-align-center">Indirizzo:<p className="weight-400 size-14 margin-5">{candidature.userAddress}</p></div>
                            <div className="weight-600 size-14 flex-align-center">Città:<p className="weight-400 size-14 margin-5">{candidature.userCity}</p></div>
                            <div className="weight-600 size-14 flex-align-center margin-bottom-20">CAP:<p className="weight-400 size-14 margin-5">{candidature.userCap}</p></div>
                        </div>
                    </div>
                </Col>}
                <Col span={16}>
                    <p className="weight-700 size-18">Offerta</p>
                    {candidature &&
                    <Card>
                        <div className="flex-column margin-5">
                        <p className="weight-700 size-18 merriWeather margin-0">{candidature.offertTitle}</p>
                        <p className="weight-400 size-14 margin-0">{candidature.companyName}</p>
                        <p className="weight-400 size-12 opacity-50 margin-0">Applicato: {moment(parseInt(candidature.userBirthday)).format("DD MMM. YYYY")}</p>
                        </div>
                        {candidature && candidature.candidatureQuestions && candidature.candidatureQuestions > 0 &&
                        <div>
                        <Divider  className="weight-600 size-12" orientation="left" orientationMargin="0"><p className="opacity-50" >DOMANDE</p></Divider>
                        {candidature.candidatureQuestions && candidature.candidatureQuestions.map((item) =>{
                            index++
                            return(
                            <div>
                                <div className={"align-point"}>
                                <div>
                                <div className={"circle"}>{index}</div>
                                </div>
                                <b>{item.questions}</b>
                                </div>
                                <div className={"margin-text-left margin-bottom-10"}>{item.answer}</div>
                            </div>
                            )})}
                        </div>}
                    </Card>}
                </Col>
            </Row>
    )
}

export default Profile
