import Breadcrumb from "antd/es/breadcrumb";
import {PRIVATE_ADMIN_ROUTE} from "../../route.constants";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Icon from "../../component/Icon/Sprite";
import {ICONS} from "../../assets/sprite/iconsNames";
import {Button, Divider} from "antd";
import "./SettingsStyle.scss"
import {InputLogin} from "../../component/Useful/Input/InputLogin";
import PreviewCompany from "src/assets/image/feedbackCompanyPreview.png"
import PreviewUsers from "src/assets/image/feedbackUsersPreview.png"
import {Switch} from "../../component/Useful/Switch";
import styled, {css} from "styled-components";
import {FeedbackButton} from "../../component/Useful/Button/FeedbackButton";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/settings/actions";
import Popconfirm from "antd/es/popconfirm";
import {getClickableLink, isValidUrl} from "../../helpers/utility";

const EditConfirmButton = styled(Button)`
  &.ant-btn {
    height: 50px;
    padding: 14px 14px;

    &.ant-btn-icon-only {
      width: 50px;
    }
  }

  &.ant-btn-primary {
    ${props => props.color === "green" && css`
      background-color: #69BE7B;
    `}
    
    &:not(:disabled):hover{
      ${props => props.color === "green" && css`
      background-color: #77C487;
    `}
    }
  }
  
`

export default function Settings() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {getSettings, updateSettings} = actions

    const settings = useSelector(state => state.Settings.settings)

    const [feedbackLinkCompanies, setFeedbackLinkCompanies] = useState()
    const [feedbackLinkCompaniesEnabled, setFeedbackLinkCompaniesEnabled] = useState(false)
    const [feedbackLinkUsers, setFeedbackLinkUsers] = useState()
    const [feedbackLinkUsersEnabled, setFeedbackLinkUsersEnabled] = useState(false)


    const [companiesPopoverOpen, setCompaniesPopoverOpen] = useState(false)
    const [usersPopoverOpen, setUsersPopoverOpen] = useState(false)

    const [feedbackLinkCompaniesEdit, setFeedbackLinkCompaniesEdit] = useState(false)
    const [feedbackLinkUsersEdit, setFeedbackLinkUsersEdit] = useState(false)

    const [usersStatus, setUsersStatus] = useState(null)
    const [companiesStatus, setCompaniesStatus] = useState(null)


    useEffect(() => {
        dispatch(getSettings())
    }, [])

    useEffect(() => {
        if (settings) {
            setFeedbackLinkCompanies(settings.feedbackLinkCompanies)
            setFeedbackLinkCompaniesEnabled(settings.feedbackLinkCompaniesEnabled)
            setFeedbackLinkUsers(settings.feedbackLinkUsers)
            setFeedbackLinkUsersEnabled(settings.feedbackLinkUsersEnabled)
        }
    }, [settings])

    const onEditUsersLink = () => {
        setFeedbackLinkUsersEdit(true)
    }

    const onConfirmUsersLink = () => {
        if (feedbackLinkUsers === "" || isValidUrl(feedbackLinkUsers)) {
            setUsersStatus(null)
            setFeedbackLinkUsersEdit(false)
            dispatch(updateSettings({feedbackLinkUsers: feedbackLinkUsers}))
        } else {
            setUsersStatus("error")
        }
    }

    const onChangeUsersEnabled = () => {
        setUsersPopoverOpen(true)
    }

    const onConfirmUsersSwitch = () => {
        setUsersPopoverOpen(false)
        dispatch(updateSettings({feedbackLinkUsersEnabled: !feedbackLinkUsersEnabled}))
    }

    const onCancelUsersSwitch = () => {
        setUsersPopoverOpen(false)
    }


    const onEditCompaniesLink = () => {
        setFeedbackLinkCompaniesEdit(true)
    }

    const onConfirmCompaniesLink = () => {
        if (feedbackLinkCompanies === "" || isValidUrl(feedbackLinkCompanies)) {
            setCompaniesStatus(null)
            setFeedbackLinkCompaniesEdit(false)
            dispatch(updateSettings({feedbackLinkCompanies: feedbackLinkCompanies}))
        } else {
            setCompaniesStatus("error")
        }
    }

    const onChangeCompaniesEnabled = () => {
        setCompaniesPopoverOpen(true)
    }

    const onConfirmCompaniesSwitch = () => {
        setCompaniesPopoverOpen(false)
        dispatch(updateSettings({feedbackLinkCompaniesEnabled: !feedbackLinkCompaniesEnabled}))
    }

    const onCancelCompaniesSwitch = () => {
        setCompaniesPopoverOpen(false)
    }


    return (
        <div className="admin">
            <div className="max-width-1200 padding-50">
                <div>
                    <Breadcrumb className="customBreadcrumb" separator="›">
                        <Breadcrumb.Item className="underlined pointer"
                                         onClick={() => navigate(PRIVATE_ADMIN_ROUTE.DASHBOARD)}>
                            <FormattedMessage id="Breadcrumb_home"/>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <FormattedMessage id="Breadcrumb_company"/>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="title flex-center-between margin-bottom-24">
                    <div>
                        <span onClick={() => navigate(PRIVATE_ADMIN_ROUTE.DASHBOARD)}>
                            <Icon className="opacity-50 margin-right-20 pointer" name={ICONS.arrow_2}
                                  size={[20, 20]} color={"#0E0E2C"}/>
                        </span>
                        <span className="size-28 weight-700 merriWeather">
                            <FormattedMessage id="Dashboard_settings"/>
                        </span>
                    </div>
                </div>

                <Divider/>

                <div className={"settings-box"}>
                    <div className="red size-18 weight-700">
                        <FormattedMessage id={"platformFeedbackTitle"}/>
                    </div>
                    <div className="size-16 grey-70 margin-top-12">
                        <FormattedMessage id={"platformFeedbackTip"}/>
                    </div>

                    <div className={"flex-center-between flex-gap-40"}>
                        <div className={"feedbackLink flex-2"}>
                            <label><FormattedMessage id={"surveyUsers"}/></label>
                            <div className={"link-container"}>
                                <InputLogin status={usersStatus} placeholder={"Scrivi qui il link"} value={feedbackLinkUsers} disabled={!feedbackLinkUsersEdit} onChange={(e) => setFeedbackLinkUsers(e?.target?.value)}/>
                                {feedbackLinkUsersEdit ?
                                    <EditConfirmButton className={"edit-button"} type="primary"
                                                       icon={<Icon name={ICONS.tik} size={[20, 20]}/>} color={"green"} onClick={onConfirmUsersLink}/>
                                    :
                                    <EditConfirmButton className={"edit-button"} type="primary"
                                                       icon={<Icon name={ICONS.edit} size={[20, 20]}/>} onClick={onEditUsersLink}/>
                                }
                            </div>
                            {!!usersStatus &&
                            <div className={"margin-top-5 margin-left-8 red"}><FormattedMessage id={"invalidLink"}/></div>
                            }
                        </div>
                        <div>
                            <div className={"weight-600"}>
                                <FormattedMessage id={"preview"}/>
                            </div>
                            <div style={{position: "relative"}}>
                                <img src={PreviewUsers} className={"flex-1"}/>
                                {feedbackLinkUsers &&
                                <FeedbackButton
                                    icon={<Icon name={ICONS.edit} size={[10, 10]}/>}
                                    className="margin-bottom-15 feedback-preview-button"
                                    href={getClickableLink(feedbackLinkUsers)}
                                    target={"_blank"}
                                    size={"small"}
                                    color={"#208ac6"}
                                >
                                    <FormattedMessage id={"feedbackPlatformCompany"}/>
                                </FeedbackButton>
                                }
                            </div>
                        </div>
                        <div className={"feedbackSwitch flex-1"}>
                            <Popconfirm
                                open={usersPopoverOpen}
                                title={<FormattedMessage id={!!settings?.feedbackLinkUsersEnabled ? "sureToHideButton" : "sureToShowButton"}/>}
                                onConfirm={onConfirmUsersSwitch}
                                onCancel={onCancelUsersSwitch}
                                okText="Si"
                                cancelText="No"
                            >
                                <Switch
                                    checkedChildren={<Icon name={ICONS.ok} size={[20, 20]}/>}
                                    unCheckedChildren={<Icon name={ICONS.close_switch} size={[20, 20]}/>}
                                    checked={feedbackLinkUsersEnabled}
                                    onChange={onChangeUsersEnabled}
                                />
                            </Popconfirm>
                            <label>
                                <FormattedMessage id={"showButtonInSite"}/>
                            </label>
                        </div>
                    </div>

                    <div className={"flex-center-between flex-gap-40"}>
                        <div className={"feedbackLink flex-2"}>
                            <label><FormattedMessage id={"surveyCompany"}/></label>
                            <div className={"link-container"}>
                                <InputLogin status={companiesStatus} placeholder={"Scrivi qui il link"} value={feedbackLinkCompanies} disabled={!feedbackLinkCompaniesEdit} onChange={(e) => setFeedbackLinkCompanies(e?.target?.value)}/>
                                {feedbackLinkCompaniesEdit ?
                                    <EditConfirmButton className={"edit-button"} type="primary"
                                                       icon={<Icon name={ICONS.tik} size={[20, 20]}/>} color={"green"} onClick={onConfirmCompaniesLink}/>
                                    :
                                    <EditConfirmButton className={"edit-button"} type="primary"
                                                       icon={<Icon name={ICONS.edit} size={[20, 20]}/>} onClick={onEditCompaniesLink}/>
                                }
                            </div>
                            {!!companiesStatus &&
                            <div className={"margin-top-5 margin-left-8 red"}><FormattedMessage id={"invalidLink"}/></div>
                            }
                        </div>
                        <div>
                            <div className={"weight-600"}>
                                <FormattedMessage id={"preview"}/>
                            </div>
                            <div style={{position: "relative"}}>
                                <img src={PreviewCompany} className={"flex-1"}/>
                                {!!feedbackLinkCompanies &&
                                <FeedbackButton
                                    icon={<Icon name={ICONS.edit} size={[10, 10]}/>}
                                    className="margin-bottom-15 feedback-preview-button"
                                    href={getClickableLink(feedbackLinkCompanies)}
                                    target={"_blank"}
                                    size={"small"}
                                    color={"#c41a43"}
                                >
                                    <FormattedMessage id={"feedbackPlatformCompany"}/>
                                </FeedbackButton>
                                }
                            </div>
                        </div>
                        <div className={"feedbackSwitch flex-1"}>
                            <Popconfirm
                                open={companiesPopoverOpen}
                                title={<FormattedMessage id={!!settings?.feedbackLinkCompaniesEnabled ? "sureToHideButton" : "sureToShowButton"}/>}
                                onConfirm={onConfirmCompaniesSwitch}
                                onCancel={onCancelCompaniesSwitch}
                                okText="Si"
                                cancelText="No"
                            >
                                <Switch
                                    checkedChildren={<Icon name={ICONS.ok} size={[20, 20]}/>}
                                    unCheckedChildren={<Icon name={ICONS.close_switch} size={[20, 20]}/>}
                                    checked={feedbackLinkCompaniesEnabled}
                                    onChange={onChangeCompaniesEnabled}
                                />
                            </Popconfirm>
                            <label>
                                <FormattedMessage id={"showButtonInSite"}/>
                            </label>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}