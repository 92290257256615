import React, {useEffect, useState} from "react";
import "src/component/Layout/Drawer/Company/CompanyContentStyle.scss";
import {Col, Divider, Form, message, Row, Space, Upload} from "antd";
import {ICONS} from "src/assets/sprite/iconsNames";
import {InputLogin} from "../../../Useful/Input/InputLogin";
import ModalEmployee from "../../Modal/ModalEmployee/ModalEmployee";
import ModalLocation from "../../Modal/ModalLocation/ModalLocation";
import Icon from "../../../Icon/Sprite";
import {Select} from "../../../Useful/Select/Select";
import {FormattedMessage} from "react-intl";
import {COMPANY_MODAL_TYPE, EXPERIENCE, firstLetters, ROLES} from "src/utils/utility";
import {useDispatch, useSelector} from "react-redux";
import actions from "src/redux/company/actions";
import {NUMBER_OF_EMPLOYEES} from "../../../../utils/utility";


export function CompanyContent(props) {

    let dispatch = useDispatch()
    const {update, getImageSource, getWorkSector} = actions;

    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const {company, companyEditAccount} = props

    const {TextArea} = InputLogin;

    const [openModalEmployee, setOpenModalEmployee] = useState(false)
    const [openModalLocation, setOpenModalLocation] = useState(false)
    const [employeesRecord, setEmployeesRecord] = useState(null)
    const [locationRecord, setLocationRecord] = useState(null)

    const [modalType, setModalType] = useState(null)

    const [isLocationNull, setIsLocationNull] = useState(false)
    const [locationArray, setLocationArray] = useState([])
    const [employeesArray, setEmployeesArray] = useState([])

    const [imgBase64, setImgBase64] = useState(null)
    const [fileList, setFileList] = useState([])

    const logo = useSelector(state => state.Company.logo)
    const fileBlob = useSelector(state => state.Company.fileBlob)
    const fileName = useSelector(state => state.Company.fileName)
    const fileType = useSelector(state => state.Company.fileType)
    const workSector = useSelector(state => state.Company.workSector)

    const uploadButton = (
        <div className="uploadSvg">
            <Icon name={ICONS.business} size={[20, 20]} color={"#868696"}/>
        </div>
    );

    const error = () => {
        messageApi.open({
            key: "errorLocation",
            type: 'error',
            content: 'Inserire almeno una sede'
        });
    };

    function checkFile(_, value) {
        if (fileList.length !== 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error())
    }

    useEffect(() => {
        if (company && company.logoPath) {
            dispatch(getImageSource({name: company.logoPath}))
        }

        if (company && (company.companyLocations.length !== 0)) {
            setLocationArray(company.companyLocations)
            form.setFieldsValue({...form.getFieldsValue, uiLocations: company.companyLocations})
        }

        if (company && company.employees.length !== 0) {
            setEmployeesArray(employeesArray => [...employeesArray, ...company.employees])
        }
    }, [company])


    useEffect(() => {
        if (logo) {
            setFileList([{uid: Math.random(), url: logo, originFileObj: new File([fileBlob], fileName, {type: fileType})}])
            setImgBase64(logo)
        }
    }, [logo])

    useEffect(() => {
        form.setFieldsValue({...form.getFieldsValue, uiLocations: locationArray})
    }, [locationArray])

    useEffect(()=>{
        dispatch(getWorkSector())
    }, [])

    return (
        <>
            <Form
                id={"companyForm"}
                form={form}
                layout="vertical"
                scrollToFirstError={{block: 'center'}}
                style={{width: "100%"}}
                onFinishFailed={(values) => {
                    if (locationArray.length === 0) {
                        setIsLocationNull(true)
                        error()
                    }
                }}
                onFinish={(values) => {
                    if (locationArray.length !== 0) {
                        setIsLocationNull(false)
                        values.newLogo = fileList[0].originFileObj
                        values.uiLocations = locationArray
                        values.uiEmployees = employeesArray

                        dispatch(update(window.localStorage.getItem('companyId'), values))
                    } else {
                        setIsLocationNull(true)
                        error()
                    }
                }}
            >
                <div className="companyContent">
                    <div className="  border margin-bottom-40 padding-40" id="companyData">
                        <Space
                            direction={"vertical"}
                            size={24}
                        >
                            <Row gutter={[0, 24]}>
                                <Col span={24}>
                                    <div className="red size-18 weight-700">
                                        <FormattedMessage id="CompanyDetails_companyData"/>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div>
                                        <Form.Item
                                            name="companyName"
                                            label={<FormattedMessage id="CompanyDetails_name"/>}
                                            initialValue={company && company.companyName}
                                            className="weight-600 size-14"
                                            rules={[
                                                {max: 100, message: false},
                                                {required: true, message: false},
                                            ]}
                                        >
                                            <InputLogin string={""} placeholder="Nome azienda"/>
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="workSector"
                                        label={<FormattedMessage id="CompanyDetails_sector"/>}
                                        initialValue={company && company.workSector}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: true,
                                                message: false,
                                            },
                                        ]}
                                    >
                                        <Select
                                            getPopupContainer={trigger => trigger.parentNode}
                                            suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]}/>}
                                            allowClear
                                            placeholder={"- Seleziona -"}
                                            options={workSector?.map((el)=> {return {value: el, label: el}})}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="vatNumber"
                                        label={<FormattedMessage id="CompanyDetails_IVA"/>}
                                        initialValue={company && company.vatNumber}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: true,
                                                pattern: /^[0-9]{11}$/,
                                                message: false,
                                            },
                                        ]}
                                    >
                                        <InputLogin string={""} placeholder="Partita IVA" maxLength={11}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="phoneNumber"
                                        label={<FormattedMessage id="CompanyDetails_phoneNumber"/>}
                                        initialValue={company && company.phoneNumber}
                                        className="weight-600 size-14"
                                        rules={[
                                            {min: 5, message: false},
                                            {pattern: "^[0-9]*$", message: false},
                                            {required: true, message: false},
                                        ]}
                                    >
                                        <InputLogin string={""} placeholder={<FormattedMessage id="CompanyDetails_phoneNumber"/>} maxLength={10}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="email"
                                        label={<FormattedMessage id="CompanyDetails_email"/>}
                                        initialValue={company && company.email}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: true,
                                                type: 'email',
                                                message: false,
                                            }
                                        ]}
                                    >
                                        <InputLogin string={""} placeholder="info@email.com"/>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="newLogo"
                                        label={<FormattedMessage id="CompanyDetails_image"/>}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: true,
                                                validator: checkFile
                                            }
                                        ]}
                                    >


                                        <div className="upload flex-row">
                                            {contextHolder}
                                            <Upload
                                                className="uploadImage"
                                                accept={"image/png, image/jpeg"}
                                                listType="picture-card"
                                                fileList={fileList}
                                                maxCount={1}
                                                showUploadList={{showPreviewIcon: false}}
                                                onRemove={() => {
                                                    setFileList([])
                                                    setImgBase64(null)
                                                }}
                                                onChange={(e) => {
                                                    setFileList(e.fileList)
                                                    if (e.file.status === "done") {
                                                        setImgBase64(e.file.originFileObj)
                                                    }
                                                }}
                                                customRequest={(test) => {
                                                    setTimeout(() => {
                                                        test.onSuccess(() => "ok",);
                                                    }, 0);
                                                }}
                                            >
                                                {fileList.length !== 0 ? null : uploadButton}
                                            </Upload>
                                            <div className="uploadInfo flex-center margin-left-16">
                                                <div className="weight-400 size-13 opacity-7">

                                                    <div>
                                                        <FormattedMessage id="CompanyDetails_imageFormat"/>
                                                    </div>
                                                    <div>
                                                        <FormattedMessage id="CompanyDetails_imageDimensions"/>
                                                    </div>
                                                    <div>
                                                        <FormattedMessage id="CompanyDetails_weight"/>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Form.Item>
                                </Col>

                                {!companyEditAccount &&
                                <Col span={24}>
                                    <Form.Item
                                        name="companySize"
                                        label={<FormattedMessage id="CompanyDetails_size"/>}
                                        initialValue={company && company.companySize}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: true,
                                                message: false,
                                            },
                                        ]}
                                    >
                                        <Select
                                            getPopupContainer={trigger => trigger.parentNode}
                                            suffixIcon={<Icon name={ICONS.arrow} size={[20, 20]} color="#208AC6"/>}
                                            placeholder="Dimensione aziendale"
                                            options={NUMBER_OF_EMPLOYEES}
                                        />
                                    </Form.Item>
                                </Col>}
                            </Row>
                        </Space>
                    </div>

                    {!companyEditAccount &&
                    <>
                        <div className="companyDescription border margin-bottom-40 padding-40" id="companyDescription">
                            <Space
                                direction={"vertical"}
                                size={24}
                            >
                                <Row gutter={[0, 24]}>
                                    <Col span={24}>
                                        <div className="red size-18 weight-700">
                                            <FormattedMessage id="CompanyDetails_companyDescription"/>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="description"
                                            label={<FormattedMessage id="CompanyDetails_description"/>}
                                            initialValue={company && company.description}
                                            className="weight-600 size-14"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: false,
                                                },
                                            ]}
                                        >
                                            <TextArea placeholder="Descrivi la tua azienda" style={{minWidth: "100%"}} autoSize={{minRows: 4}}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Space>
                        </div>

                        <div className="location border margin-bottom-40 padding-nobottom-40 padding-bottom-20" id="location">
                            <Form.Item
                                name="uiLocations"
                                initialValue={locationArray && locationArray}
                                rules={[
                                    {
                                        required: true,
                                        message: false,
                                    },
                                ]}
                            >
                                <Row gutter={[0, 24]}>
                                    <Col span={24}>
                                        <div className="flex-center-between red size-18 weight-700">
                                            <div>
                                                * <FormattedMessage id="CompanyDetails_location"/>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className="size-16">
                                            <FormattedMessage id="CompanyDetails_selectLocation"/>
                                        </div>

                                        {locationArray && locationArray.length === 0 ?
                                            <>
                                                <Col span={24}>
                                                    <div className={`grey-50 ${isLocationNull && "red"}`} style={{margin: "24px 0"}}>
                                                        <FormattedMessage id="CompanyDetails_addLocation"/>
                                                    </div>
                                                </Col>
                                            </>
                                            :
                                            <>
                                                <Divider style={{margin: "16px 0"}}/>
                                                {locationArray && locationArray.map((element) => {
                                                    return (
                                                        <>
                                                            <div className="flex-between" style={{width: "100%"}}>
                                                                <div>
                                                                    <span className="weight-600">
                                                                        {element.name && element.name}
                                                                    </span>
                                                                    <span className="opacity-50" style={{margin: "0 4px"}}>
                                                                        ·
                                                                    </span>
                                                                    <span className="weight-600">
                                                                        {element.province && element.province}
                                                                    </span>
                                                                    <span className="opacity-50" style={{margin: "0 4px"}}>
                                                                        ·
                                                                    </span>
                                                                    <span className="opacity-50">
                                                                        {element.city && element.city}
                                                                    </span>
                                                                    <span className="opacity-50" style={{margin: "0 4px"}}>
                                                                        ·
                                                                    </span>
                                                                    <span className="opacity-50">
                                                                        {element.cap && element.cap}
                                                                    </span>
                                                                </div>
                                                                <div className="flex-center">
                                                                    {element.mainLocation ?
                                                                        <span className="green" style={{marginRight: "32px"}}>
                                                                            Sede principale
                                                                        </span>
                                                                        :
                                                                        null
                                                                    }
                                                                    <div className="pointer" onClick={() => {
                                                                        setModalType(COMPANY_MODAL_TYPE.EDIT_FORM)
                                                                        setOpenModalLocation(true)
                                                                        setLocationRecord(element)
                                                                    }}>
                                                                        <Icon name={ICONS.edit} size={[20, 20]} className="icon" color={"#868696"}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Divider style={{margin: "16px 0"}}/>
                                                        </>
                                                    )
                                                })}
                                            </>
                                        }

                                        <Col span={24}>
                                            <div className="flex">
                                                <div className="flex-center pointer" onClick={() => {
                                                    setModalType(COMPANY_MODAL_TYPE.NEW_FORM)
                                                    setOpenModalLocation(true)
                                                    setLocationRecord(null)
                                                }}>
                                                    <Icon name={ICONS.add} size={[20, 20]} color={"#208AC6"}/>
                                                    <span className="weight-600 blue">
                                                        <FormattedMessage id="CompanyDetails_newLocation"/>
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </div>

                        <div className="responsible border margin-bottom-40 padding-40" id="responsible">
                            <Row gutter={[0, 24]}>
                                <Col span={24}>
                                    <div className="red size-18 weight-700">
                                        <div>
                                            <FormattedMessage id="CompanyDetails_responsible"/>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className="size-16">
                                        <FormattedMessage id="CompanyDetails_addResponsibleOffer"/>
                                    </div>
                                    <Divider style={{margin: "12px 0"}}/>

                                    {company &&
                                    <>
                                        <div className="flex-center flex-between" style={{justifyContent: "space-between"}}>
                                            <div className="flex-center">
                                                <div size={40} className={"margin-right-5 weight-600 size-14 isEmployeeNotActive"}>
                                                    {firstLetters(company.companyAdmin.firstName, company.companyAdmin.lastName)}
                                                </div>
                                                <p className="weight-600 size-14 margin-left-12"
                                                   style={{margin: "3px"}}>{company.companyAdmin.firstName} {company.companyAdmin.lastName}</p>
                                            </div>
                                            <div className="weight-400 size-14 italic">
                                                {company.companyAdmin.role === ROLES.ADMIN ? <FormattedMessage id="CompanyDetails_owner"/> : null}
                                            </div>
                                        </div>
                                        <Divider style={{margin: "12px 0"}}/>
                                    </>
                                    }

                                    {employeesArray && employeesArray.map((element) => {
                                        return (
                                            <>
                                                <div className="flex-center flex-between" style={{justifyContent: "space-between"}}>
                                                    <div className="flex-center">
                                                        <div size={40} className={"margin-right-5 weight-600 size-14 isEmployeeNotActive"}>{firstLetters(element.firstName, element.lastName)}</div>
                                                        <p className="weight-600 size-14 margin-left-12"
                                                           style={{margin: "3px"}}>{element.firstName} {element.lastName}</p>
                                                    </div>
                                                    <div className="pointer" onClick={() => {
                                                        setModalType(COMPANY_MODAL_TYPE.EDIT_FORM)
                                                        setEmployeesRecord(element)
                                                        setOpenModalEmployee(true)
                                                    }}>
                                                        <Icon name={ICONS.edit} className="icon" size={[20, 20]} color={"#868696"}/>
                                                    </div>
                                                </div>
                                                <Divider style={{margin: "12px 0"}}/>
                                            </>
                                        )
                                    })}

                                    <Col span={24} className="flex">
                                        <div
                                            className="flex pointer"
                                            onClick={() => {
                                                setModalType(COMPANY_MODAL_TYPE.NEW_FORM)
                                                setOpenModalEmployee(true)
                                                setEmployeesRecord(null)
                                            }}
                                        >
                                            <Icon name={ICONS.add} size={[20, 20]} color={"#208AC6"}/>
                                            <span className="weight-600 blue">
                                                <FormattedMessage id="CompanyDetails_addResponsible"/>
                                            </span>
                                        </div>
                                    </Col>
                                </Col>
                            </Row>
                        </div>

                        <div className="otherInformation border margin-bottom-40 padding-40" id="otherInformation">
                            <Space
                                direction={"vertical"}
                                size={24}
                            >
                                <Row gutter={[0, 24]}>
                                    <Col span={24}>
                                        <div className="red size-18 weight-700">
                                            <FormattedMessage id="CompanyDetails_otherInfo"/>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="webPage"
                                            label={<FormattedMessage id="CompanyDetails_webSite"/>}
                                            initialValue={company && company.webPage}
                                            className="weight-600 size-14"
                                            rules={[
                                                {
                                                    message: false,
                                                    pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[.\!\/\\w]*))?)/ig
                                                },
                                            ]}
                                        >
                                            <InputLogin placeholder={"https://www.azienda.com"}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>

                                        <Form.Item
                                            name="linkedin"
                                            label={<FormattedMessage id="CompanyDetails_linkedin"/>}
                                            initialValue={company && company.linkedin}
                                            className="weight-600 size-14"
                                            rules={[
                                                {
                                                    message: false,
                                                    pattern: /^(https:\/\/)([\w]+\.)linkedin\.com/,
                                                },
                                            ]}
                                        >
                                            <InputLogin placeholder="Inserisci profilo linkedin"/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Space>
                        </div>
                    </>
                    }

                </div>
            </Form>
            <ModalEmployee company={company} openModalEmployee={openModalEmployee} setOpenModalEmployee={setOpenModalEmployee}
                           setEmployeesArray={setEmployeesArray} type={modalType} record={employeesRecord} employeesArray={employeesArray}/>
            <ModalLocation company={company} openModalLocation={openModalLocation} setOpenModalLocation={(value) => setOpenModalLocation(value)}
                           setLocationArray={setLocationArray} type={modalType} record={locationRecord} locationArray={locationArray}/>
        </>
    )
}
