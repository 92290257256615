import React, {useEffect, useState} from "react";
import "src/component/Layout/Drawer/Offer/OfferContentStyle.scss";
import {Col, DatePicker, Form, Row, Space, TimePicker} from "antd";
import {Switch} from "src/component/Useful/Switch";
import {EFFORT, EXPERIENCE, INSERT_MODE, WORK_ACTIVITY} from "src/utils/utility.js";
import {Select} from "../../../Useful/Select/Select";
import {InputLogin} from "../../../Useful/Input/InputLogin";
import employeeActions from "../../../../redux/employee/actions";
import companyActions from "src/redux/company/actions"
import {useDispatch, useSelector} from "react-redux";
import Questions from "./Questions";
import moment from "moment";
import actions from "../../../../redux/offer/actions";
import dayjs from "dayjs";
import {ICONS} from "src/assets/sprite/iconsNames";
import Icon from "../../../Icon/Sprite";
import drawerActions from "../../../../redux/drawer/actions";
import locationActions from "src/redux/location/actions"
import {FormattedMessage} from "react-intl";
import {ROLES} from "src/utils/utility";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Disclaimer from "../../PopoverContent/disclaimer";

const {getAll, getAllBySysAdmin} = employeeActions
const {save, update, updateBySysAdmin} = actions
const {setChangeDrawer} = drawerActions;
const {getAllCompanyLocation, getAllCompanyLocationBySysAdmin, getCompanyLocationMainById} = locationActions;
const {getCompanyById, getImageSource} = companyActions;

export function OfferContent(props) {

    const {TextArea} = InputLogin;
    const dispatch = useDispatch()
    const dateFormat = "DD/MM/YYYY";


    const location = useSelector(state => state.Location.companyLocations)
    const locationBySysAdmin = useSelector(state => state.Location.companyLocationsBySysAdmin)
    const companyLocationMain = useSelector(state => state.Location.companyLocationMain)

    const employeeList = useSelector(state => state.Employee.employeeList)
    const employeeListBySysAdmin = useSelector(state => state.Employee.employeeListBySysAdmin)

    const company = useSelector(state => state.Company.company)

    const logo = useSelector(state => state.Company.logo)

    const role = useSelector(state => state.AuthReducer.role)

    const [form] = Form.useForm()

    const [deadLine, setDeadLine] = useState(false)
    const [freeQuestionsToggle, setFreeQuestionsToggle] = useState(false)
    const [employeeSelect, setEmployeeSelect] = useState([])
    const [employeeSelectBySysAdmin, setEmployeeSelectBySysAdmin] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [questions, setQuestions] = useState([])


    useEffect(() => {
        let employeeCopy = []
        if (employeeList) {
            employeeList.forEach(element => {
                if (element.isActive) {
                    employeeCopy.push(
                        {
                            id: element.id,
                            value: element.id,
                            label: element.firstName + " " + element.lastName,
                        }
                    )
                }
            })
            setEmployeeSelect(employeeCopy)
        }
    }, [employeeList])

    useEffect(() => {
        let employeeCopy = []
        if (employeeListBySysAdmin) {
            employeeListBySysAdmin.forEach(element => {
                if (element.isActive) {
                    employeeCopy.push(
                        {
                            id: element.id,
                            value: element.id,
                            label: element.firstName + " " + element.lastName,
                        }
                    )
                }
            })
            setEmployeeSelectBySysAdmin(employeeCopy)
        }
    }, [employeeListBySysAdmin])


    useEffect(() => {

        if (role === ROLES.SYS_ADMIN) {
            const queryParameters = new URLSearchParams(window.location.search)
            let companyId = queryParameters.get("companyId")

            dispatch(getCompanyById(companyId))
            dispatch(getAllBySysAdmin(companyId))
            dispatch(getAllCompanyLocationBySysAdmin(companyId))
            dispatch(getCompanyLocationMainById(companyId))
        } else {
            let companyId = localStorage.getItem("companyId")

            dispatch(getCompanyById(companyId))
            dispatch(getAllCompanyLocation())
            dispatch(getAll())
        }
    }, [])

    useEffect(() => {
        if (company && company.logoPath) {
            dispatch(getImageSource({name: company.logoPath}))
        }
        if (company && company.workSector) {
            form.setFieldsValue({
                workSector: company.workSector,
            })
        }
    }, [company])

    useEffect(() => {
        if (props.record && props.record.location) {
            form.setFieldsValue({
                location: {id: props.record.location.id, value: props.record.location.id, label: props.record.location.name}
            })
            setDisabled(true)
        }
    }, [props.record])

    useEffect(() => {
        if (!props.offertToModify && location && employeeList && !props.companyLocation) { 
            form.setFieldsValue({
                location: location && {id: location[0].id, value: location[0].id, label: location[0].name},
                responsible: employeeList && {id: employeeList[0].id, value: employeeList[0].id, label: employeeList[0].firstName + " " + employeeList[0].lastName}
            })
        } else if (props.companyLocation) { 
            form.setFieldsValue({ 
                location: {id: props.companyLocation.id, value: props.companyLocation.id, label: props.companyLocation.name},
                responsible: employeeList && {id: employeeList[0].id, value: employeeList[0].id, label: employeeList[0].firstName + " " + employeeList[0].lastName}

            })
            setDisabled(true)
        }
    }, [props.offertToModify, location, employeeList, props.companyLocation])

    useEffect(() => {
        if (props.offertToModify && props.record) {
            form.setFieldsValue({
                title: props.record.title,
                anonymous: props.record.anonymous,
                location: props.record.location && props.record.location.id,
                responsible: props.record.responsible && props.record.responsible.id,
                experience: props.record.experience,
                impegnoRichiesto: props.record.impegnoRichiesto,
                insertMode: props.record.insertMode,
                workPlace: props.record.workPlace,
                position: props.record.position,
                description: props.record.description,
                freeQuestions: props.record.freeQuestions,
                question: props.record.questions,
                showExpiry: props.record.showExpiry,
                dueDate: props.record.showExpiry === true ? dayjs(props.record.expiry) : null,
                dueTime: props.record.showExpiry === true ? dayjs(props.record.expiry) : null,
                sendNotificationMail: props.record.sendNotificationMail,
            })
            setFreeQuestionsToggle(props.record.freeQuestions)
            setDeadLine(props.record.showExpiry)
            if (props.record.questions) {
                setQuestions(props.record.questions.map((item) => {
                    return ({id: Math.random(), question: item.question})
                }))
            }
        }

    }, [props.record])

    return (
        <Form
            form={form}
            layout="vertical"
            scrollToFirstError={{block: 'center'}}
            style={{width: "100%"}}
            id="offerForm"
            onFinish={(value) => { 
                if (value.freeQuestions === true) {
                    value.question = questions.map((item) => {
                        return ({question: item.question})
                    })
                    if (value.question && value.question.length !== 0) {
                        value.question = value.question.map((item) => ({"question": item.question}))
                    } else {
                        value.freeQuestions = false
                    }
                } else {
                    value.question = null
                }

                if (value.responsible && value.responsible.id) {
                    value.responsible = value.responsible.id
                }

                if (value.location && value.location.id) {
                    value.location = value.location.id
                }
                if (deadLine) {
                    let fullDate = 0
                    value.showExpiry = deadLine
                    if (value.dueDate) {
                        fullDate = moment(new Date(value.dueDate)).format('YYYY-MM-DD')
                    }
                    if (value.dueTime) {
                        let time = moment(new Date(value.dueTime)).format('hh:mm:ss');
                        fullDate = fullDate + " " + time
                    } else {
                        fullDate = moment(fullDate).set({hours: 0, minutes: 0, seconds: 0})
                    }

                    value.expiry = new Date(fullDate).getTime()
                } else {
                    value.expiry = dayjs(Date.now()).add(value.expiry, 'month').valueOf()
                    value.showExpiry = deadLine
                }

                value.state = props.offertState

                if (props.offertToModify) {
                    if (role === ROLES.SYS_ADMIN) {
                        const queryParameters = new URLSearchParams(window.location.search)
                        let companyId = queryParameters.get("companyId")

                        dispatch(updateBySysAdmin(props.record.id, companyId, value, props.state))
                    } else {
                        let companyId = localStorage.getItem("companyId")
                        dispatch(update(props.record.id, companyId, value, props.state))
                    }
                } else { 
                    dispatch(save(value))
                }
                props.resetOffertState()
            }}
            onValuesChange={(value) => {
                if (value.freeQuestions !== undefined) {
                    setFreeQuestionsToggle(value.freeQuestions)
                }
                if (!value || !value.question) {
                    dispatch(setChangeDrawer())
                }
            }}
        >
            <div className="offerContent">
                {role && role === ROLES.SYS_ADMIN &&
                <div>
                    <p className="margin-bottom-8 weight-700 size-18 dark-light">Scheda azienda</p>
                    <p className="margin-bottom-25 weight-400 size-14 dark italic">Questa informazione è solo per l'admin</p>
                    <div className="box-admin flex-between">
                        <div>
                            <p className="padding-bottom-8 weight-700 size-18 dark-light margin-0">{company && company.companyName}</p>
                            <p className="weight-400 size-14 dark margin-0">Responsabile: {company && company.companyAdmin.firstName + " " + company.companyAdmin.lastName}</p>
                            <p className="weight-400 size-14 dark margin-0">Partita IVA: {company && company.vatNumber}</p>
                            <p className="weight-400 size-14 dark margin-0">Telefono di contatto: <span className="underlined"><b>{company && company.phoneNumber}</b></span></p>
                            <p className="weight-400 size-14 dark margin-0">Email: {company && company.email}</p>
                            <p className="weight-400 size-14 dark margin-0">Sede: {company && company.companyLocations[0].province + " " + company.companyLocations[0].city} - CAP: {company && company.companyLocations[0].cap} </p>
                        </div>
                        <div className="flex-end">
                            <img src={logo} className={"image-cover"}/>
                        </div>
                    </div>
                    <p className="margin-bottom-25 weight-700 size-18 dark-light">Annuncio da rivedere</p>
                </div>}
                <div className="personalData border margin-bottom-40 padding-40" id="personalData">
                    <Space
                        direction={"vertical"}
                        size={24}
                    >
                        <Row gutter={[0, 24]}>
                            <Col span={24}>
                                <div className="red size-18 weight-700">
                                    *Dati anagrafici dell'offerta
                                </div>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={"title"}
                                    rules={[
                                        {
                                            required: true,
                                            message: false,
                                        },
                                    ]}
                                    label={"Titolo offerta"}
                                >
                                    <InputLogin placeholder={"Es: Graphic Designer, Full-Stack, Front-End, Commerciale, Cameriere..."} onChange={(e)=> {
                                        form.setFieldsValue({title: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)})
                                    }}/>
                                </Form.Item>
                            </Col>
                            <Col span={24} className={"flex-row line-height-30"}>
                                <Form.Item
                                    name={"anonymous"}
                                    valuePropName={"checked"}
                                    style={{marginBottom: 0}}
                                >
                                    <Switch checkedChildren={<Icon name={ICONS.ok} size={[20, 20]}/>}
                                            unCheckedChildren={<Icon name={ICONS.close_switch} size={[20, 20]}/>}
                                    />
                                </Form.Item>
                                <span className="margin-left-12 weight-500">
                                    Offerta anonima
                                </span>
                            </Col>
                            <Col span={24}>
                                <p className="size-13 grey-50 weight-500">
                                    Spuntando questa opzione, l'offerta si pubblicherà con nome e foto nascosti.
                                </p>
                                <p className="size-13 grey-50 weight-500">
                                    La pubblicazione in forma anonima di un annuncio comporterà un costo. Contatta Vocational Training Srl al numero 0835331493 o scrivi un’email all’indirizzo info@vocationaltraining.it per maggiori informazioni.
                                </p>
                            </Col>
                        </Row>

                        <Row gutter={[24, 24]}>
                            <Col span={12}>
                                <Form.Item
                                    name={"location"}
                                    label={"Sede"}
                                >
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]}/>}
                                        placeholder={"- Seleziona -"}
                                        disabled={props.disabledLocation && props.disabledLocation}
                                        options={(role === ROLES.SYS_ADMIN) ?
                                            locationBySysAdmin && locationBySysAdmin.map(element => {
                                                return {id: element.id, value: element.id, label: element.name}
                                            }) :
                                            location && location.map(element => {
                                                return {id: element.id, value: element.id, label: element.name}
                                            })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={"responsible"}
                                    label={"Responsabile gestione offerta"}
                                >
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]}/>}
                                        options={(role === ROLES.SYS_ADMIN) ? employeeSelectBySysAdmin : employeeSelect}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                            <Col span={12}>
                                <Form.Item
                                    name={"workSector"}
                                    label={"Settore"}
                                >
                                    <InputLogin disabled={true}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={"experience"}
                                    label={"Esperienza"}
                                >
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]}/>}
                                        allowClear
                                        placeholder={"- Seleziona -"}
                                        options={EXPERIENCE}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                            <Col span={12}>
                                <Form.Item
                                    name={"impegnoRichiesto"}
                                    label={"Impegno richiesto"}
                                >
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]}/>}
                                        allowClear
                                        placeholder={"- Seleziona -"}
                                        options={EFFORT}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Disclaimer/>
                                <Form.Item
                                    name={"insertMode"}
                                    label={"Modalità d’inserimento"}
                                >
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]}/>}
                                        allowClear
                                        placeholder={"- Seleziona -"}
                                        options={INSERT_MODE}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                            <Col span={12}>
                                <Form.Item
                                    name={"workPlace"}
                                    label={"Attività lavorativa"}
                                >
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]}/>}
                                        allowClear
                                        placeholder={"- Seleziona -"}
                                        options={WORK_ACTIVITY}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div>
                                    <Form.Item
                                        name={"position"}
                                        label={"Posizione lavorativa / Ruolo"}
                                    >
                                        <InputLogin placeholder={"Es: Camerriere, addetto alle vendite, Magazziniere..."}/>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </Space>
                </div>

                <div className="offerDescription border margin-bottom-40 padding-40" id="offerDescription">
                    <Space
                        direction={"vertical"}
                        size={24}
                    >
                        <Row gutter={[0, 24]}>
                            <Col span={24}>
                                <div className="red size-18 weight-700">
                                    Descrizione dell'offerta
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="size-14 weight-400 dark-light">
                                    <div><FormattedMessage id="Offers_roles_title"/></div>
                                    <div><FormattedMessage id="Offers_roles_subtitle"/></div>
                                    <ul>
                                        <li><FormattedMessage id="Offers_role_point_one"/></li>
                                        <li><FormattedMessage id="Offers_role_point_two"/></li>
                                        <li><FormattedMessage id="Offers_role_point_three"/></li>
                                        <li><FormattedMessage id="Offers_role_point_four"/></li>
                                        <li><FormattedMessage id="Offers_role_point_five"/></li>
                                        <li><FormattedMessage id="Offers_role_point_six"/></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={[0, 24]}>
                            <Col span={24}>
                                <Form.Item
                                    name={"description"}
                                    rules={[
                                        {
                                            required: true,
                                            message: false,
                                        },
                                    ]}
                                    label={"Descrizione"}
                                >
                                    <ReactQuill className={"notranslate"} placeholder={"Inserisci qui la descrizione dell’offerta"} theme="snow"/>
                                    {/*// value={value} onChange={setValue}*/}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Space>
                </div>


                <div className="freeQuestions border margin-bottom-40 padding-40" id="freeQuestions">
                    <Space
                        direction={"vertical"}
                        size={24}
                    >
                        <Row gutter={[0, 24]}>
                            <Col span={24}>
                                <div className="red size-18 weight-700">
                                    Domande libere
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="size-16 grey-70">
                                    <FormattedMessage id="Offers_description_questions"/>
                                </div>
                            </Col>
                        </Row>
                        <Col span={24} style={{display: "flex", alignItems: "baseline"}}>
                            <Form.Item
                                name={"freeQuestions"}
                                valuePropName={"checked"}
                                style={
                                    {display: "flex", alignItems: "center"}
                                }
                            >
                                <Switch checkedChildren={<Icon name={ICONS.ok} size={[20, 20]}/>}
                                        unCheckedChildren={<Icon name={ICONS.close_switch} size={[20, 20]}/>}
                                />
                            </Form.Item>
                            <span className="margin-left-12 weight-500">
                                Attiva le domande libere
                            </span>
                        </Col>
                        <Form.Item
                            name={"question"}
                        >
                            <Questions freeQuestionsToggle={freeQuestionsToggle} questions={questions} setQuestions={setQuestions}/>
                        </Form.Item>
                    </Space>
                </div>


                <div className="expiration border margin-bottom-40 padding-40" id="expiration">
                    <Space
                        direction={"vertical"}
                        size={24}
                    >
                        <Row gutter={[0, 24]}>
                            <Col span={24}>
                                <div className="red size-18 weight-700">
                                    Scadenze
                                </div>
                            </Col>
                            <Col span={24} className={"flex-row line-height-30"}>
                                <Form.Item
                                    name={"showExpiry"}
                                    valuePropName={"checked"}
                                >
                                    <Switch checkedChildren={<Icon name={ICONS.ok} size={[20, 20]}/>}
                                            unCheckedChildren={<Icon name={ICONS.close_switch} size={[20, 20]}/>}
                                            checked={deadLine} onChange={(checked) => setDeadLine(checked)}/>
                                </Form.Item>
                                <span className="margin-left-12 weight-500">
                                    Aggiungere scadenza dell’offerta
                                </span>
                            </Col>
                        </Row>

                        <Row gutter={[24, 24]} className={"date"}>
                            {deadLine ?
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            name={"dueDate"}
                                            label={"Data di scadenza"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: false,
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                style={{
                                                    width: "100%"
                                                }}
                                                format={dateFormat}
                                                disabledDate={(currentDate) => {
                                                    return currentDate.isBefore(dayjs(Date.now()))
                                                }}
                                                suffixIcon={<Icon name={ICONS.calendar} className="margin-5 opacity-50" size={[20, 20]}/>}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={"dueTime"}
                                            label={"Ora"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: false,
                                                },
                                            ]}
                                        >
                                            <TimePicker
                                                style={{
                                                    width: "100%",
                                                }}
                                                suffixIcon={<Icon name={ICONS.clock} className="margin-5 opacity-50" size={[20, 20]}/>}/>
                                        </Form.Item>
                                    </Col>
                                </>
                                :
                                <Col span={24}>
                                    <Form.Item
                                        name={"expiry"}
                                        label={"Data di scadenza"}
                                        initialValue={1}
                                    >
                                        <Select
                                            style={{width: "100%"}}
                                            options={
                                                [
                                                    {label: "1 mese", value: 1},
                                                    {label: "2 mesi", value: 2},
                                                    {label: "3 mesi", value: 3},
                                                ]
                                            }/>
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                    </Space>
                </div>


                <div className="expiration border margin-bottom-40 padding-40" id="expiration">
                    <Space
                        direction={"vertical"}
                        size={24}
                    >
                        <Row gutter={[0, 24]}>
                            <Col span={24}>
                                <div className="red size-18 weight-700">
                                    Notifiche candidati
                                </div>
                            </Col>
                            <Col span={24} className={"flex-row line-height-30"}>
                                <Form.Item
                                    name={"sendNotificationMail"}
                                    valuePropName={"checked"}
                                >
                                    <Switch checkedChildren={<Icon name={ICONS.ok} size={[20, 20]}/>}
                                            unCheckedChildren={<Icon name={ICONS.close_switch} size={[20, 20]}/>}/>
                                </Form.Item>
                                <span className="margin-left-12 weight-500">
                                    Desidero ricevere notifiche via mail per questo annuncio.
                                </span>
                            </Col>
                        </Row>
                    </Space>
                </div>


            </div>
        </Form>
    )
}
