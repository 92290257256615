import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useLocation, useNavigate, useParams} from "react-router";
import "src/Pages/offerDetail/OfferDetailStyle.scss";
import {Breadcrumb, Button, Col, Divider, Popover, Row, Space, Table, message} from "antd";
import {PRIVATE_COMPANY_ROUTE} from "src/route.constants";
import Icon from "src/component/Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import {Ellipsis} from "src/component/Layout/Ellipsis/Ellipsis";
import RadioButtonGroup from "src/component/Layout/RadioButtonGroup/RadioButtonGroup";
import {Select} from "src/component/Useful/Select/Select";
import {OFFER_FILTERS, OFFER_STATE, substituteZeroWithLine} from "src/utils/utility";
import ModalProfile from "../../component/Layout/Modal/ModalProfile/ModalProfile";
import {useDispatch, useSelector} from "react-redux";
import offertActions from "../../redux/offer/actions";
import candidatureActions from "../../redux/candidature/actions"
import {removeTag} from "src/helpers/utility";
import ModalFilter from "../../component/Layout/Modal/ModalFilter/ModalFilter";
import moment from "moment";
import dayjs from "dayjs";
import CardNewCandidate from "../../component/Layout/Candidate/CardNewCandidate";
import {OFFER_FILTERS_CANDIDATE} from "src/utils/utility";
import CandidateCardStateTable from "../../component/Layout/Candidate/CandidateCardStateTable";
import {ButtonLine} from "../../component/Useful/Button/ButtonLine";
import drawerActions from "../../redux/drawer/actions";
import companyAction from "../../redux/company/actions";
import {CustomDrawer} from "../../component/Layout/Drawer/CustomDrawer";


function OfferDetail() {

    const [messageApi, contextHolder] = message.useMessage();

    let navigate = useNavigate();
    let dispatch = useDispatch()
    let {id} = useParams()

    const {get} = offertActions;
    const {openDrawer} = drawerActions;
    const {offertToModify, setOffertRecord} = companyAction;
    const {getCandidatureByOffertId, update, deleteData, getCvSource, multipleCvDownload, setViewDot, updateCandidatureStateAndViewed} = candidatureActions

    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(1)
    const [openProfile, setOpenProfile] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [profile, setProfile] = useState(null)
    const [state, setState] = useState("all")

    const [resetFilters, setResetFilters] = useState(false)

    const [selectedFilter, setSelectedFilter] = useState({
        id: id,
        page: page,
        pageSize: pageSize,
        state: OFFER_FILTERS.READ,
        name: null,
        province: null,
        city: null,
        qualification: null
    })


    const [count, setCount] = useState(null)

    const offerById = useSelector(state => state.Offer.offer)
    const totalOffertCandidature = useSelector(state => state.Candidature.totalOffertCandidature)
    const candidatureByOffert = useSelector(state => state.Candidature.candidatureByOffert)
    const countCandidatureByOffert = useSelector(state => state.Candidature.countCandidatureByOffert)
    const candidatureWaitingCount = useSelector(state => state.Candidature.candidatureWaitingCount)
    const candidatureSelectedCount = useSelector(state => state.Candidature.candidatureSelectedCount)
    const candidatureRejectedCount = useSelector(state => state.Candidature.candidatureRejectedCount)
    const candidaturePreselectionCount = useSelector(state => state.Candidature.candidaturePreselectionCount)
    const candidatureInterviewCount = useSelector(state => state.Candidature.candidatureInterviewCount)
    const candidatureNoneCount = useSelector(state => state.Candidature.candidatureNoneCount)
    const viewState = useSelector(state => state.Candidature.viewState)
    const modify = useSelector(state => state.Company.offertToModify)

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [openPop, setOpenPop] = useState(false);
    const updatedCandidature = useSelector(state => state.Candidature.updatedCandidature)


    useEffect(() => {
        if (updatedCandidature) {
            messageApi.open({
                type: 'success',
                content: 'Candidatura aggiornata con successo!'
            })
        }
    }, [updatedCandidature])


    const [listOfFiles, setListOfFiles] = useState([])
    const file = useSelector(state => state.Candidature.fileName)


    useEffect(() => {
        dispatch(get(id))
    }, [id])


    useEffect(() => { 
        dispatch(getCandidatureByOffertId(selectedFilter))
    }, [selectedFilter])

    useEffect(() => {
        if (viewState) {
            dispatch(getCandidatureByOffertId(selectedFilter))
        }
    }, [viewState])

    const handleObjectFilters = (e) => { 
        setSelectedFilter( 
            {
                id: id,
                page: page,
                pageSize: pageSize,
                state: selectedFilter.state,
                name: e.name && e.name ? e.name : null,
                province: e.province && e.province ? e.province : null,
                city: e.city && e.city ? e.city : null,
                qualification: e.qualification && e.qualification ? e.qualification : null,
                sort: e.sort && e.sort ? e.sort : null,
            }
        )
    }

    const handleCandidateTo = (record, state) => {
        if (state === "all") {
            record.candidatureState = null
        } else {
            record.candidatureState = state
        }
        dispatch(update(record.id, record, id, selectedFilter.state))
    }

    const handleDelete = (record) => {
        let offerId = id
        dispatch(deleteData(record.id, selectedFilter.state, offerId))
    }

    const columns = [
        {
            title: "Candidati",
            fixed: 'left',
            width: "300px",
            render: (field, record) => {
                return (
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div className="size-14 weight-600 underlined blue pointer"
                             onClick={() => {
                                 if (selectedFilter.state === OFFER_FILTERS_CANDIDATE.ALL.value){
                                     handleCandidateTo(record, OFFER_FILTERS_CANDIDATE.WAITING.value)

                                 }
                                 if (!record.viewed) {
                                     dispatch(setViewDot(record.id))
                                 }
                                 setField(record, record.id)
                             }
                             }>
                            {record &&
                            <div className="flex-row padding-bottom-8" style={{lineHeight: "5px"}}>
                                {record && !record.viewed &&
                                <div className="dot margin-right-5"/>
                                }
                                {record.userFirstName + " " + record.userLastName}
                            </div>}
                        </div>
                        <div>
                            {record.userEmail}
                        </div>
                        <div>
                            {record.userTelephone}
                        </div>
                        <div className="weight-400 size-12 opacity-50 grey">
                        </div>
                    </div>
                )
            }
        },
        {
            title: "Allegati",
            width: "100px",
            render: (field, record) => (
                <Button className="flex-center" onClick={() => {
                    if (record && record.userCvPath) {
                        dispatch(getCvSource(record.userCvPath))
                        if (selectedFilter.state === OFFER_FILTERS_CANDIDATE.ALL.value) {
                            let candidature = {...record}
                            candidature.candidatureState = OFFER_FILTERS_CANDIDATE.WAITING.value
                            dispatch(updateCandidatureStateAndViewed(candidature.id, candidature, id, selectedFilter.state))
                        }
                    }
                }}>
                    <Icon name={ICONS.download} size={[20, 20]} color={"#208AC6"}/>
                </Button>
            )
        },
        {
            title: "Stato Candidatura",
            render: (field, record) => (
                <div style={{width: 200}}>
                    <CandidateCardStateTable record={record} id={id} handleCandidateTo={handleCandidateTo}/>
                </div>
            )
        },
        {
            title: "",
            dataIndex: "options",
            width: "100px",
            fixed: 'right',
            render: (field, record) => (
                <Popover
                    overlayClassName="actions"
                    placement={"bottomRight"}
                    getPopupContainer={triggerNode => triggerNode.parentElement.parentElement.parentElement}
                    trigger="click"
                    content={
                        <div className="actionsContent">
                            <div className="element size-14 weight-500 pointer" onClick={() => handleDelete(record)}>
                                <FormattedMessage id="OffersDetail_deleteCandidate"/>
                            </div>
                        </div>
                    }
                >
                    <div className="flex-center">
                        <Button type="text" className="borderless">
                            <div style={{marginTop: "5px"}}>
                                <Icon name={ICONS.more} size={[20, 20]} color={"grey"}/>
                            </div>
                        </Button>
                    </div>
                </Popover>
            )
        }
    ];

    const handleOkProfile = () => {
        setOpenProfile(false);
    };
    const handleCancelProfile = () => {
        setOpenProfile(false);
    };

    const handleOpenFilter = () => {
        setIsModalOpen(true)
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleReset = () => {
        setIsModalOpen(false);
    };

    function setField(record, id) {
        setProfile(record)
        let view = true
        dispatch(getCvSource(record.userCvPath, view))
        setOpenProfile(true)
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
            setSelectedRows(selectedRows)
        },
    };

    const handleCount = (e) => {
        setCount(e)
    }

    const handleSelectedFilter = (e) => {
        setSelectedFilter(
            {
                id: e.id,
                page: e.page,
                pageSize: e.pageSize,
                state: e.state,
                name: e.name && e.name ? e.name : null,
                province: e.province && e.province ? e.province : null,
                city: e.city && e.city ? e.city : null,
                qualification: e.qualification && e.qualification ? e.qualification : null,
                sort: e.sort && e.sort ? e.sort : null,
            }
        )
    }

    const changeReset = () => {
        setResetFilters(false)
    }

    const handleResetFiltersCard = () => {
        setResetFilters(true)
        setSelectedFilter(
            {
                id: id,
                page: 0,
                pageSize: 10,
                state: OFFER_FILTERS.READ,
                name: null,
                province: null,
                city: null,
                qualification: null
            }
        )
    }

    const hide = () => {
        setOpenPop(false);
    };
    const handleOpenChange = (newOpen) => {
        setOpenPop(newOpen);
    };

    const downloadZip = () => {

        /*if (selectedRows && selectedRows.length > 0) {
            let names = []
            selectedRows.forEach((element) => {
                names.push(element.userCvPath.split(".pdf")[0])
            })
            dispatch(multipleCvDownload(names))
        }*/
    };

    return (
        <>
            <div className="offerProfile">
                <div className="max-width-1200 ">
                    {contextHolder}
                    <Breadcrumb className="customBreadcrumb" separator="›">
                        <Breadcrumb.Item className="underlined pointer"
                                         onClick={() => navigate(PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY)}><FormattedMessage
                            id="Breadcrumb_home"/></Breadcrumb.Item>
                        <Breadcrumb.Item className="underlined pointer"
                                         onClick={() => navigate(PRIVATE_COMPANY_ROUTE.WORK_OFFERS)}><FormattedMessage
                            id="Breadcrumb_workOffer"/></Breadcrumb.Item>
                        <Breadcrumb.Item><FormattedMessage id="Breadcrumb_offerDetail"/></Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="flex-center-between margin-bottom-24">
                        <div>
                            <span onClick={() => navigate(PRIVATE_COMPANY_ROUTE.WORK_OFFERS)}>
                                <Icon className={"pointer icon"} name={ICONS.arrow_2} size={[20, 20]} color={"grey"}/>
                            </span>
                            <span className="size-28 weight-700 merriWeather"
                                  style={{padding: "10px"}}>{offerById ? offerById.title : null}
                            </span>
                            {offerById && offerById.state === OFFER_STATE.draft.value &&
                            <span onClick={() => {
                                dispatch(setOffertRecord(offerById))
                                dispatch(offertToModify())
                                dispatch(openDrawer())
                            }} className={"pointer"}>
                                <Icon name={ICONS.edit} className="icon" size={[20, 20]} color={"grey"}/>
                            </span>
                            }
                        </div>
                        <div className="flex">
                            {/*<div className="flex-center margin-right-24">*/}
                            {/*    <ButtonLine className="flex-center">*/}
                            {/*        <Icon name={ICONS.eye} size={[20, 20]} color={"#208AC6"} className={"pointer"}/>*/}
                            {/*        <span className="margin-left-5">*/}
                            {/*            <FormattedMessage id="OffersDetail_seeOnlineOffer"/>*/}
                            {/*        </span>*/}
                            {/*    </ButtonLine>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <Row gutter={16}>

                        {/* CARD Laterale con la descrizione dell'offerta */}
                        <Col span={8}>
                            <div className="offerInfo">
                                <Space className="margin-bottom-16" direction={"vertical"} size={8}>
                                    <div className="size-12 weight-600 grey">
                                        <FormattedMessage id="OffersDetail_offerDesc"/>
                                    </div>

                                    {offerById && offerById.position ?
                                        <div className="size-14 margin-bottom-5 margin-top-10">
                                            <span className="weight-600 colon">
                                                <FormattedMessage id="OffersDetail_job"/>
                                            </span>
                                            <span className="weight-400">
                                                {offerById.position}
                                            </span>
                                        </div> : null}


                                    {offerById && offerById.location ?
                                        <div className="size-14 margin-bottom-5">
                                            <span className="weight-600 colon">
                                                <FormattedMessage id="OffersDetail_company"/>
                                            </span>
                                            <span className="weight-400">
                                                {offerById.location.name}
                                            </span>
                                        </div> : null}

                                    {offerById && offerById.description ?
                                        <div className="size-14 margin-bottom-5">
                                            <span className="weight-600 colon">
                                                <FormattedMessage id="OffersDetail_desc"/>
                                            </span>
                                            <span className="weight-400 line-height-20">
                                                <Ellipsis
                                                    text={removeTag(offerById.description)}
                                                    maxLength={250}
                                                    readMoreText={"leggi tutto"}
                                                    customClassName="size-14"/>
                                            </span>
                                        </div> : null}

                                </Space>
                                <Space direction={"vertical"} size={8}>
                                    <div className="size-12 weight-600 grey">
                                        <FormattedMessage id="OffersDetail_otherInfo"/>
                                    </div>

                                    {offerById && offerById.state ?
                                        <div className="size-14 margin-bottom-5 margin-top-10">
                                            <span className="weight-600 colon">
                                                <FormattedMessage id="OffersDetail_offerState"/>
                                            </span>
                                            <span className="weight-600 green">
                                                {offerById.state}
                                            </span>
                                        </div> : null}

                                    {offerById && offerById.impegnoRichiesto ?
                                        <div className="size-14 margin-bottom-5">
                                            <span className="weight-600 colon">
                                                <FormattedMessage id="OffersDetail_workingHours"/>
                                            </span>
                                            <span className="weight-400">
                                                {offerById.impegnoRichiesto}
                                            </span>
                                        </div> : null}

                                    {offerById && offerById.insertMode ?
                                        <div className="size-14 margin-bottom-5">
                                            <span className="weight-600 colon">
                                                <FormattedMessage id="OffersDetail_contract"/>
                                            </span>
                                            <span className="weight-400">
                                                {offerById.insertMode}
                                            </span>
                                        </div> : null}

                                    {offerById && offerById.workPlace ?
                                        <div className="size-14 margin-bottom-5">
                                            <span className="weight-600 colon">
                                                <FormattedMessage id="OffersDetail_type"/>
                                            </span>
                                            <span className="weight-400">
                                                {offerById.workPlace}
                                            </span>
                                        </div> : null}

                                    {offerById && offerById.dateCreated ?
                                        <div className="size-14 margin-bottom-5">
                                            <span className="weight-600 colon">
                                                <FormattedMessage id="OffersDetail_create"/>
                                            </span>
                                            <span className="weight-400">
                                                {moment(offerById.dateCreated).locale('it').format("DD MMM. YYYY")}
                                            </span>
                                        </div> : null}

                                    {offerById && offerById.expiry !== -1 ?
                                        <div className="size-14 margin-bottom-5">
                                            <span className="weight-600 colon margin-right-5">
                                                <FormattedMessage id="OffersDetail_deadline"/>
                                            </span>
                                            <span className="weight-600 orange">
                                                {offerById.expiry && (dayjs(offerById.expiry).format("DD MMM YYYY"))}
                                            </span>
                                        </div> : null}

                                    {offerById && offerById.responsible ?
                                        <div className="size-14">
                                            <span className="weight-600 colon">
                                                <FormattedMessage id="OffersDetail_offerCreatedBy"/>
                                            </span>
                                            <span className="weight-400">
                                                {offerById.responsible.firstName + " " + offerById.responsible.lastName}
                                            </span>
                                        </div> : null}

                                </Space>
                            </div>
                        </Col>
                        {/* --------------------------------------------- */}

                        {/* CARD per candidati e visite */}
                        <Col span={16}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <div className="card flex-center">
                                        <div className="flex-column">
                                            <span className="center size-25 weight-600 padding-8">
                                                {totalOffertCandidature && totalOffertCandidature !== 0 ? totalOffertCandidature : "-"}
                                            </span>
                                            <div className="flex padding-8">
                                                <Icon name={ICONS.user} size={[20, 20]}/>
                                                <span className="size-16 weight-700" style={{paddingLeft: 10}}>
                                                    <FormattedMessage id="OffersDetail_Candidate"/>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div className="card flex-center">
                                        <div className="flex-column">
                                            <span
                                                className="center size-25 weight-600 padding-8">{substituteZeroWithLine(offerById && offerById.visualization)}</span>
                                            <div className="flex padding-8">
                                                <Icon name={ICONS.eye} size={[20, 20]} className={"pointer"}/>
                                                <span className="size-16 weight-700" style={{paddingLeft: 10}}>
                                                    <FormattedMessage id="OffersDetail_visit"/>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                {/* ------------------------------ */}
                            </Row>

                            {/* Tabella di visualizzazione dei candidati relativi all'offerta */}


                            {candidatureByOffert ?
                                <div>

                                    <Row className="flex-center-between" style={{paddingTop: 50}}>
                                        <div className="size-18 weight-700">
                                            <FormattedMessage id="OffersDetail_allCandidates"/>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            {selectedRowKeys.length > 0 &&
                                            <Popover
                                                overlayClassName="actions"
                                                placement={"bottomRight"}
                                                getPopupContainer={triggerNode => triggerNode.parentElement.parentElement.parentElement}
                                                trigger="click"
                                                open={openPop}
                                                onOpenChange={handleOpenChange}
                                                className={"pointer"}
                                                content={
                                                    <div className="actionsContent">
                                                        <div className="element size-14 weight-500 pointer"
                                                             onClick={() => {
                                                                 selectedRows.forEach((it) => {
                                                                     it.candidatureState = OFFER_FILTERS_CANDIDATE.WAITING.value
                                                                     dispatch(update(it.id, it, id, selectedFilter.state))
                                                                 })
                                                                 hide()
                                                                 setSelectedRowKeys([])
                                                                 setSelectedRowKeys([])
                                                             }}>
                                                            <FormattedMessage id="OffersDetail_Modal_SeenCv"/>
                                                        </div>
                                                        <div className="element size-14 weight-500 pointer"
                                                             onClick={() => {
                                                                 selectedRows.forEach((it) => {
                                                                     it.candidatureState = OFFER_FILTERS_CANDIDATE.INTERVIEW.value
                                                                     dispatch(update(it.id, it, id, selectedFilter.state))
                                                                 })

                                                                 hide()
                                                                 setSelectedRowKeys([])
                                                                 setSelectedRowKeys([])
                                                             }}>
                                                            <FormattedMessage id="OffersDetail_Modal_ToBeContacted"/>
                                                        </div>
                                                        <div className="element size-14 weight-500 pointer"
                                                             onClick={() => {
                                                                 selectedRows.forEach((it) => {
                                                                     it.candidatureState = OFFER_FILTERS_CANDIDATE.PRESELECTED.value
                                                                     dispatch(update(it.id, it, id, selectedFilter.state))
                                                                 })
                                                                 hide()
                                                                 setSelectedRowKeys([])
                                                                 setSelectedRowKeys([])
                                                             }}
                                                        >
                                                            <FormattedMessage id="OffersDetail_Modal_ToBeReviewed"/>
                                                        </div>
                                                        <Divider/>
                                                        <div className="element size-14 weight-500 pointer"
                                                             onClick={() => {
                                                                 selectedRows.forEach((it) => {
                                                                     it.candidatureState = OFFER_FILTERS_CANDIDATE.DISLIKE.value
                                                                     dispatch(update(it.id, it, id, selectedFilter.state))
                                                                 })
                                                                 hide()
                                                                 setSelectedRowKeys([])
                                                                 setSelectedRowKeys([])
                                                             }}
                                                        >
                                                            <FormattedMessage id="OffersDetail_Modal_Unqualified"/>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <div type="text" className="flex-center" style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginRight: 20
                                                }}>
                                                    <Icon className="icon" name={ICONS.more} size={[20, 20]} color={"grey"}/>
                                                </div>
                                            </Popover>
                                            }
                                            <div type="text" className="flex-center"
                                                 style={{display: "flex", flexDirection: "row", marginRight: 20}}
                                                 onClick={() => downloadZip()}
                                            >
                                                <Icon name={ICONS.download} size={[20, 20]} className="icon" color={"grey"}/>
                                            </div>
                                            <div className={"margin-right-24 pointer flex-center"} onClick={() => handleResetFiltersCard()}>
                                                <a className="pointer grey"><u>Resetta</u></a>
                                            </div>
                                            <Button className="flex-center" onClick={handleOpenFilter}>
                                                <Icon name={ICONS.filter} size={[20, 20]} color={"#208AC6"}/>
                                                <span className="size-13 weight-700"
                                                      style={{color: "#208AC6", marginLeft: "5px"}}>
                                                    <FormattedMessage id="filters"/> {count && count !== 0 ? count : null}
                                                </span>
                                            </Button>
                                        </div>
                                    </Row>

                                    <Row className="flex-center-between" style={{margin: "24px 0", gap: "16"}}>
                                        <div className="offerFilterBox">
                                            <Button
                                                className={`flex-center-between ${selectedFilter.state === OFFER_FILTERS.READ && "selected"}`}
                                                onClick={() => {
                                                    if (selectedFilter.state !== OFFER_FILTERS.READ) {
                                                        setSelectedFilter({
                                                            id: id,
                                                            page: page,
                                                            pageSize: pageSize,
                                                            name: selectedFilter.name,
                                                            province: selectedFilter.province,
                                                            city: selectedFilter.city,
                                                            qualification: selectedFilter.qualification,
                                                            sort: selectedFilter.sort,
                                                            state: OFFER_FILTERS.READ
                                                        })
                                                    }
                                                }}>
                                                <Icon name={ICONS.inbox} size={[20, 20]} color={"#208AC6"}/>
                                                <span className="weight-700 grey">
                                                    {candidatureNoneCount}
                                                </span>
                                            </Button>
                                        </div>

                                        <RadioButtonGroup
                                            candidatureWaitingCount={candidatureWaitingCount}
                                            candidatureSelectedCount={candidatureSelectedCount}
                                            candidatureRejectedCount={candidatureRejectedCount}
                                            candidaturePreselectionCount={candidaturePreselectionCount}
                                            candidatureInterviewCount={candidatureInterviewCount}
                                            selectedFilter={selectedFilter}
                                            handleSelectedFilter={handleSelectedFilter}
                                            id={id}
                                        />

                                    </Row>


                                    {candidatureByOffert && candidatureByOffert.length !== 0 ?
                                        <>
                                            <Table
                                                className="offerDetailTable border-radius-0"
                                                rowKey="id"
                                                pagination={{
                                                    showSizeChanger:false,
                                                    current: page,
                                                    pageSize: pageSize,
                                                    total: countCandidatureByOffert,
                                                    onChange: (page) => {
                                                        setPage(page)
                                                        setSelectedFilter({
                                                            ...selectedFilter,
                                                            page: page
                                                        })
                                                    },
                                                }}
                                                rowSelection={rowSelection}
                                                columns={columns}
                                                dataSource={candidatureByOffert ? candidatureByOffert : null}
                                                scroll={{x: 700}}
                                            />


                                            <div className={"page-size-changer"}>
                                                <span className="size-12 margin-right-16">
                                                    <FormattedMessage id="resultsPerPage"/>
                                                </span>
                                                <Select
                                                    className={"tableSelect"}
                                                    value={pageSize}
                                                    onChange={(value) => {
                                                        setPageSize(value)
                                                        setSelectedFilter({
                                                            ...selectedFilter,
                                                            pageSize: value,
                                                        })
                                                    }}
                                                    suffixIcon={<Icon name={ICONS.arrow_small} size={[20, 20]}
                                                                      color={"#208AC6"}/>}
                                                    options={
                                                        [
                                                            {label: 5, value: 5},
                                                            {label: 10, value: 10},
                                                            {label: 20, value: 20}
                                                        ]
                                                    }
                                                >
                                                </Select>
                                            </div>
                                        </>
                                        :
                                        <div className="noCandidates border-grey">
                                            <div className="noCandidatesIcon flex-align-center">
                                                <Icon className="infoIcon" name={ICONS.user_multi_100} size={[100, 100]} color={"#C41A43"}/>
                                            </div>
                                            <div className="size-18 black-text flex-center weight-600">
                                                <FormattedMessage id="Candidate_NoCandidates_1"/>
                                            </div>
                                            <div className="black-text flex-center">
                                                <FormattedMessage id="Candidate_NoCandidates_2"/>
                                            </div>
                                        </div>
                                    }

                                </div>
                                :

                                <div>
                                    <Divider/>
                                    <CardNewCandidate type={"offert"}/>
                                </div>}

                            {/* ------------------------------------------------------------- */}
                        </Col>
                    </Row>
                </div>
            </div>
            <ModalProfile state={openProfile} onOk={handleOkProfile} onCancel={handleCancelProfile} handleCandidateTo={handleCandidateTo}
                          setProfile={profile && profile.id} record={profile}/>
            <ModalFilter resetFilters={resetFilters} changeReset={changeReset} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} reset={handleReset} handleCount={handleCount} handleObjectFilters={handleObjectFilters} objectFilters={selectedFilter}/>
            <CustomDrawer
                record={offerById}
                modify={modify}
                state={state}
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                type={"offer"}
            />
        </>
    )
}

export default OfferDetail
