import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import "./activateProfileError.scss"
import {ButtonLine} from "../../../component/Useful/Button/ButtonLine";
import {useNavigate} from "react-router";


function ActiveProfileError(){

    const navigate = useNavigate();

    const goTo = () => {
        navigate("/company/registration", {replace: true})
    }

    return(
            <div className={'activated-container-content-error'}>
                <h1><FormattedMessage id="error_account"/></h1>
                <h2><FormattedMessage id="expired"/></h2>
                <p><FormattedMessage id="description_expired"/></p>
                <p><FormattedMessage id="description_to_do"/></p>
                <ButtonLine classname={'flex-center btn-line'} onClick={goTo}>Vai alla registrazione</ButtonLine>
            </div>
    )
}

export default injectIntl(ActiveProfileError)
