import actions from "./actions";
import authActions from "../auth/actions";

const initState = {
    companies: [],
    total: null,
    reload: false,
    open: false,
    users: null,
    companyCount: null,
    newCompanyCount: null,
    baseUserCount: null,
    newBaseUserCount: null,
    cv: null,
    fileBlob: null,
    fileName: null,
    fileType: null,
    isDownloadingCompanyCsv: false,
    isDownloadingUserCsv: false,
}

export default function adminReducer(state = initState, action) {
    switch (action.type) {
        case action.INIT:
            return {...initState}
        case authActions.LOGOUT:
            return {...initState}

        case actions.GET_ALL_USERS_SUCCESS:
        return{
            ...state,
            users: action.payload.elements,
            total: action.payload.total,
        }

        case actions.GET_ALL_CANDIDATURE_BY_USER_SUCCESS:
            return {
                ...state,
                candidateDetails: action.payload
            }

        case actions.GET_USER_SUCCESS:
            return {
                ...state,
                userDetail: action.payload
            }

        case actions.GET_ADMIN_DASHBOARD_SUCCESS:
            return{
                ...state,
                companyCount: action.payload.companyCount,
                newCompanyCount: action.payload.newCompanyCount,
                baseUserCount: action.payload.baseUserCount,
                newBaseUserCount: action.payload.newBaseUserCount,
                reload: false
            }
            
        case actions.GET_ALL_ADMIN_COMPANY_SUCCESS:
            return{
                ...state,
                companies: action.payload.elements,
                total: action.payload.total,
                reload: false,
            }
        case actions.ENABLE_COMPANY_SUCCESS:
            return{
                ...state,
                reload: true,
            }
        case actions.DISABLE_COMPANY_SUCCESS:
            return{
                ...state,
                reload: true,
            }
        case actions.DELETE_COMPANY_SUCCESS:
            return{
                ...state,
                reload: true,
            }
        case actions.OPEN_MODAL:
            return{
                ...state,
                open: true
            }
        case actions.CLOSE_MODAL:
            return{
                ...state,
                open: false
            }
        case actions.GET_ADMIN_CV_SUCCESS:
            return{
                ...state,
                cv: action.payload,
                fileBlob: action.fileBlob,
                fileName: action.fileName,
                fileType: action.fileType
            }
        case actions.HANDLE_LANDING_MODAL:
            return{
                ...state,
                isLandingModalOpen: action.isLandingModalOpen
            }
        case actions.CREATE_COMPANY_CSV:
            return {
                ...state,
                isDownloadingCompanyCsv: true
            }
        case actions.CREATE_COMPANY_CSV_SUCCESS:
            return {
                ...state,
                isDownloadingCompanyCsv: false
            }
        case actions.CREATE_COMPANY_CSV_ERROR:
            return {
                ...state,
                isDownloadingCompanyCsv: false
            }
        case actions.CREATE_USERS_CSV:
            return {
                ...state,
                isDownloadingUserCsv: true
            }
        case actions.CREATE_USERS_CSV_SUCCESS:
            return {
                ...state,
                isDownloadingUserCsv: false
            }
        case actions.CREATE_USERS_CSV_ERROR:
            return {
                ...state,
                isDownloadingUserCsv: false
            }
        default:
            return state;
    }

}
