import React from "react";
import {FormattedMessage} from "react-intl";
import "src/component/Layout/OffersList/OffersListStyle.scss";
import {Table} from "antd";
import {ICONS} from "src/assets/sprite/iconsNames";
import Icon from "../../Icon/Sprite";
import {useDispatch} from "react-redux";
import candidatureActions from "../../../redux/candidature/actions";
import {useNavigate, useParams} from "react-router";
import {Select} from "../../Useful/Select/Select";

export function CandidateTable(props) {

    let {listCandidate, selectedRowKeys, setSelectedRowKeys, selectedRows, setSelectedRows, handleObjectFilters, objectFilters} = props
    let dispatch = useDispatch()
    let {id} = useParams()
    const navigate = useNavigate()


    const {update} = candidatureActions;

    // const [open, setOpen] = useState(false)
    // const [idProfile, setIdProfile] = useState(null)
    // const [pageSize, setPageSize] = useState(10)
    // const [page, setPage] = useState(0)
    // const [selectedFilter, setSelectedFilter] = useState({
    //     id: id,
    //     page: page,
    //     pageSize: pageSize,
    //     state: OFFER_FILTERS.READ,
    //     name: null,
    //     province: null,
    //     city: null,
    // })

    const columns = [
        {
            title: 'Candidati',
            render: (text, record) => {
                return (
                    <div>
                        <div className="weigth-600 size-14 underlined blue pointer" onClick={() => setField(record, record.id)}>{record.firstName} {record.lastName}</div>

                        <div className="weigth-400 size-12 opacity-50">
                            {(record.age) ? <span>{record.age} anni · </span> : null} {((record.location)) ? <span>{record.location} {record.province}</span> : null}
                        </div>
                    </div>
                )
            }
        },
        {
            title: 'Titolo di studio',
            render: (text, record) => <div>
                <div>{record.qualifications[0].qualificationLevel}</div>
            </div>
        },
        {
            title: 'Stato',
            render: (text, record) => <div>
                <div>{record.jobState}</div>
            </div>
        }
    ];


    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
            setSelectedRows(selectedRows)
        },
    };


    function setField(record, id) {
        let path = "/company/candidate/"
        if (id) {
            path = path + id
        }
        navigate(path)
    }

    return (
        <>
            {listCandidate && listCandidate.length !== 0 ?
                <div className="tableWrapper">
                    <Table
                        rowSelection={rowSelection}
                        rowKey="id"
                        columns={columns}
                        dataSource={listCandidate}
                        pagination={{
                            showSizeChanger:false,
                            pageSize: objectFilters.size,
                        }}
                    />
                    <div className={"contentSelectSize"}>
                        <div className={"text"}>
                            <span>Risultati per pagina</span>
                        </div>
                        <div>
                            <Select
                                className={"selectSizeGetAll"}
                                defaultValue={"10"}
                                height="35px"
                                suffixIcon={<Icon name={ICONS.arrow_small} size={[20, 20]} color="#208ac6"/>}
                                options={
                                    [
                                        {label: '10', value: '10'},
                                        {label: '20', value: '20'},
                                        {label: '50', value: '50'},
                                        {label: '100', value: '100'},
                                    ]
                                }
                                onChange={(pageSize) => {
                                    handleObjectFilters({
                                        page: 1,
                                        size: pageSize
                                    })
                                }}
                            />
                        </div>
                    </div>
                    {/*<ModalProfile state={open} onOk={handleOk} onCancel={handleCancel} setProfile={idProfile && idProfile.id} record={idProfile} handleCandidateTo={handleCandidateTo}/>*/}
                </div>
                :
                <div className="noCandidates border-grey padding-40">
                    <div className="noCandidatesIcon flex-align-center">
                        <Icon className="infoIcon" name={ICONS.user_multi_100} size={[100, 100]} color={"#c41a43"}/>
                    </div>
                    <div className="size-18 black-text flex-center weight-600">
                        <FormattedMessage id="Candidate_NoCandidates_1"/>
                    </div>
                    <div className="black-text flex-center">
                        <FormattedMessage id="Candidate_NoCandidates_2"/>
                    </div>
                </div>
            }
        </>
    )
}
