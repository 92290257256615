import React, {useEffect, useState} from "react";
import "src/Pages/account/AccountCompanyStyle.scss";
import {ICONS} from "src/assets/sprite/iconsNames";
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router";
import {Breadcrumb, Divider, message, Space} from "antd";
import {PRIVATE_COMPANY_ROUTE} from "src/route.constants";
import Icon from "src/component/Icon/Sprite";
import {ButtonGhost} from "src/component/Useful/Button/ButtonGhost";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/company/actions";
import drawerActions from "../../redux/drawer/actions";
import authActions from "../../redux/auth/actions";
import accountActions from "src/redux/account/actions"
import ModalChangePassword from "../../component/Layout/Modal/ModalChangePassword/ModalChangePassword";
import moment from 'moment';
import 'moment/locale/it'
import {CustomDrawer} from "src/component/Layout/Drawer/CustomDrawer";
import {DRAWER_TYPE} from "src/utils/utility";
import ModalEditAccount from "src/component/Layout/Modal/ModalEditAccount/ModalEditAccount";
import ModalEditMessageEmail from "../../component/Layout/Modal/ModalEditMessageEmail/ModalEditMessageEmail";

function AccountCompany() {

    const [messageApi, contextHolder] = message.useMessage();

    const {getCompanyById, getImageSource,openModal} = actions;
    const {openDrawer} = drawerActions
    const {logout, resetCheckPassword, resetStateNewPassword} = authActions;
    const {openModalChangePassword} = accountActions;

    const company = useSelector(state => state.Company.company)
    const logo = useSelector(state => state.Company.logo)
    const isAccountEditSuccess = useSelector(state => state.Company.isAccountEditSuccess)
    const modalEditEmail = useSelector(state => state.Company.modalEditEmail)
    const isCurrentPasswordEqual = useSelector(state => state.AuthReducer.isCurrentPasswordEqual)
    const resetNewPasswordSuccess = useSelector(state => state.AuthReducer.resetNewPasswordSuccess)

    const [location, setLocation] = useState(null)
    const [dateCreated, setDateCreated] = useState(null)
    const [isOpenEditAccount, setIsOpenEditAccount] = useState(false)
    const [openModalChangeMessageEmail, setOpenModalChangeMessageEmail] = useState(false)
    const navigate = useNavigate()
    let dispatch = useDispatch()

    useEffect(() => {
        if (company) {
            let time = moment(company.registrationDate).locale('it').format("DD MMM. YYYY")
            setDateCreated(time)
            company.companyLocations.forEach((it) => {
                if (it.mainLocation) {
                    setLocation(it)
                }
            })

            if (company.logoPath) {
                dispatch(getImageSource({name: company.logoPath}))
            }
        }
    }, [company])

    useEffect(() => {
        dispatch(getCompanyById(window.localStorage.getItem('companyId')))
    }, [])


    const logOut = () => {
        dispatch(logout(navigate))
    }

    useEffect(()=>{
        if (isAccountEditSuccess) {
            setIsOpenEditAccount(false)
        }
    }, [isAccountEditSuccess])

    useEffect(()=>{
        if (resetNewPasswordSuccess) {

            messageApi.open({
                type: 'success',
                content: 'Password aggiornata con successo!'
            })
            dispatch(resetStateNewPassword())
        }
    },[resetNewPasswordSuccess])

    useEffect(()=>{
        if (isCurrentPasswordEqual) {

            messageApi.open({
                type: 'success',
                content: 'La password inserita corrisponde!'
            })
        }

        dispatch(resetCheckPassword())

    },[isCurrentPasswordEqual])

    return (
        <div>
            <div className="account">
                {contextHolder}
                <div className="max-width-1200">

                    <Breadcrumb className="customBreadcrumb" separator="›">
                        <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY)}>
                            <FormattedMessage id="Breadcrumb_home"/>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <FormattedMessage id="Breadcrumb_account"/>
                        </Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="title flex-center-between margin-bottom-24">
                        <div>
                            <span className="size-28 weight-700 merriWeather">
                                <FormattedMessage id="account"/>
                            </span>
                        </div>
                        <div className="flex">
                            <div>
                                <div className="flex-center opacity-50 logout" onClick={() => logOut()}>
                                    <Icon className={"margin-right-8"} name={ICONS.exit} size={[20, 20]} color={"#0E0E2C"}/>
                                    <span className="size-14 black">
                                        <FormattedMessage id="exit"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="subContent">
                            <div className="subTitle size-18 weight-700">
                                <FormattedMessage id="Account_companyData"/>
                            </div>
                            <div className="accountContainer flex-center-between">
                                <Space direction={"vertical"} size={8}>
                                    {company && company.isCompleted ?
                                        <div className="companyImg">
                                            <img src={logo}/>
                                        </div> :
                                        <div className="margin-top-20 bg-grey" style={{width: 140, height: 60}}>
                                            <div className="flex-center weight-700 size-14 opacity-50" style={{lineHeight: "60px"}}>No foto</div>
                                        </div>}
                                    <div>
                                        <div className="line-24 size-18 weight-700">
                                            {company && company.companyName}
                                        </div>
                                        <div className="line-24" style={{fontStyle: "italic"}}>
                                            {company && company.workSector ? company.workSector : " - "}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="line-24">
                                            {company && company.email}
                                        </div>
                                        <div className="line-24">
                                            <FormattedMessage id="Account_phone"/>: <span>{company && company.phoneNumber && company.phoneNumber}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="line-24">
                                            <FormattedMessage id="Account_pi"/>: <span>{company && company.vatNumber}</span>
                                        </div>
                                        <div className="line-24 opacity-50">
                                            <FormattedMessage id="Account_profileCreated"/>: <span>{dateCreated && dateCreated}</span>
                                        </div>
                                    </div>
                                </Space>
                                <div>
                                    <div>
                                        <ButtonGhost style={{width:"240px"}} onClick={() => {
                                            dispatch(openDrawer())
                                        }}>
                                            <FormattedMessage id="Account_editProfessional"/>
                                        </ButtonGhost>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="subContent">
                            <div className="subTitle size-18 weight-700">
                                <FormattedMessage id="Account_PersonalData"/>
                            </div>
                            <div className="accountContainer">
                                <div className="flex-center-between">
                                    <div>
                                        <div className="line-24 size-18 weight-700">
                                            {localStorage.getItem('firstName') + " " + localStorage.getItem('lastName')}
                                        </div>
                                        <div className="line-24">
                                            {localStorage.getItem('email')}
                                        </div>
                                    </div>
                                    <div>
                                        <ButtonGhost style={{width: "240px"}} onClick={() => setIsOpenEditAccount(true)}>
                                            <FormattedMessage id="Account_editAccount"/>
                                        </ButtonGhost>
                                    </div>
                                </div>
                                <Divider className="divider" type={"horizontal"}/>
                                <div className="flex-center-between">
                                    <div>
                                        <div className="line-24 size-14 weight-700">
                                            Passwords
                                        </div>
                                    </div>
                                    <div>
                                        <ButtonGhost style={{width: "240px"}} onClick={() => dispatch(openModalChangePassword())}>
                                            <FormattedMessage id="Account_modifyPassword"/>
                                        </ButtonGhost>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="subTitle size-18 weight-700">
                                Impostazioni
                            </div>
                            <div className="accountContainer">
                                <div className="flex-center-between">
                                    <div>
                                        <div className="line-24 size-14 weight-700">
                                            Messaggio preimpostato
                                        </div>

                                    </div>
                                    <div>
                                        <ButtonGhost style={{width: "240px"}} onClick={() => dispatch(openModal())}>
                                            Edita messaggio
                                        </ButtonGhost>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalChangePassword />
            <CustomDrawer
                type={DRAWER_TYPE.company}
                company={company}
                companyEditAccount={true}
            />
            <ModalEditAccount isOpen={isOpenEditAccount} setIsOpen={setIsOpenEditAccount}/>
            <ModalEditMessageEmail companyId={window.localStorage.getItem('companyId')} isOpen={modalEditEmail} setIsOpen={setOpenModalChangeMessageEmail} defaultMessage={company && company.defaultMessage}/>
        </div>
    )
}


export default AccountCompany
