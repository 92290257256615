import {Col, Divider, Row, Table} from "antd";
import React, {useState} from "react";
import Card from "antd/es/card";
import Icon from "src/component/Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import ModalEmployee from "src/component/Layout/Modal/ModalEmployee/ModalEmployee";
import ModalLocation from "src/component/Layout/Modal/ModalLocation/ModalLocation";
import {useNavigate} from "react-router";
import {COMPANY_MODAL_TYPE, DRAWER_TYPE, firstLetters} from "src/utils/utility";
import {FormattedMessage} from "react-intl";
import "src/component/Layout/MyCompanyElement/CardMyCompany.scss"
import { ButtonLine } from "../../../Useful/Button/ButtonLine";
import {useDispatch} from "react-redux";
import drawerActions from "../../../../redux/drawer/actions";
import actions from "../../../../redux/company/actions";

function CardCreatedCompany(props) {

    const {company, setChangeType} = props

    const navigate = useNavigate()
    let dispatch = useDispatch()

    const {openDrawer} = drawerActions;
    const {offertToCreate} = actions;

    const [openModalEmployee, setOpenModalEmployee] = useState(false)
    const [openModalLocation, setOpenModalLocation] = useState(false)
    const [modalType, setModalType] = useState(null)
    const [editLocation, setEditLocation] = useState(null)
    const [locationArray, setLocationArray] = useState([])

    const columns = [
        {
            key: "id",
            title: 'Sedi',
            dataIndex: 'city',
            render: (text, record) => {
                return (
                    <div className={"pointer"} onClick={(e) => {
                        e.stopPropagation()
                        setField(e, record)}
                    }>
                        <div className="weight-600 size-14 underlined blue">{record.name && record.name}</div>
                        {record.mainLocation &&
                        <div className="weight-700 size-14 green">Principale</div>
                        }
                    </div>
                )
            }
        },
        {
            title: 'Responsabili',
            dataIndex: 'employees',
            render: (employees, record) =>
                <div className="flex-start">
                    {employees && employees.map((element) => {
                        return (
                            <div className={element.isActive && "isEmployeeActive weight-700 padding-4 size-14"} style={{margin: "0 3px"}}>
                                {firstLetters(element.firstName, element.lastName)}
                            </div>
                        )
                    })}
                </div>
        },
        {
            title: 'Offerte',
            dataIndex: 'offerts',
            render: (text, record) => <div className="flex-start">
                <Icon className="opacity-50 margin-right-5" name={ICONS.doc} size={[20, 20]}/>
                <div className="weight-400 size-14">{CheckField(record.offertsNumber)}</div>
            </div>
        },
        {
            title: 'Candidati',
            dataIndex: 'candidate',
            render: (text, record) => <div className="flex-start">
                <Icon className="opacity-50 margin-right-5" name={ICONS.user_multi} size={[20, 20]}/>
                <div className="weight-400 size-14">{CheckField(record.candidatesNumber)}</div>
            </div>
        },
        {
            title: 'Visite',
            dataIndex: 'visits',
            render: (text, record) => <div className="flex-start">
                <Icon className="opacity-50 margin-right-5" name={ICONS.eye} size={[20, 20]}/>
                <div className="weight-400 size-14">{CheckField(record.viewsNumber)}</div>
            </div>
        },
        {
            title: 'Stato',
            dataIndex: 'enabled',
            render: (text, record) => {
                 return (<div className="flex-start">
                    <div className="weight-600 size-14 green">{CheckState(record.enabled)}</div>
                </div>)
            }

        },
        {
            dataIndex: 'edit',
            render: (text, record) =>
                    <div
                        className="flex-start"
                        onClick={(e) => {
                            e.stopPropagation()
                            setEditLocation(record)
                            setOpenModalLocation(true)
                            setModalType(COMPANY_MODAL_TYPE.EDIT)
                        }}
                    >
                        <Icon className="pointer icon" color="#9595a2" name={ICONS.edit} size={[20, 20]}/>
                    </div>
        }
    ];

    function CheckField(field) {
        if (field === 0 || field === null) {
            return "-"
        } else {
            return field
        }
    }

    function CheckState(field) {
        if (field) {
            return <div className="green">Attivo</div>
        } else return <div className="grey">Non Attivo</div>
    }

    function setField(e, record) {
        e.preventDefault()
        if (record.id) {
            let newPath = '/company/myCompany/site?id=' + record.id
            navigate(newPath)
        }
    }


    return (
        <div>
            <Divider style={{margin: "0 0 32px 0"}}/>
            <Row gutter={16}>
                <Col>
                    <Card span={8} className="border-radius-0 border-grey flex-center" style={{width: 250}}>
                        <div className="flex-column">
                            <span className="flex-center weight-600 size-25">
                                {company && company.companyLocations.length}
                            </span>
                            <div className="flex-row">
                                <Icon name={ICONS.business} className="opacity-50" size={[20, 20]}/>
                                <span className="weight-700 size-16 padding-left-10">
                                    <FormattedMessage id="location"/>
                                </span>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col>
                    <Card span={8} className="border-radius-0 border-grey flex-center" style={{width: 250}}>
                        <div className="flex-column">
                            <span className="flex-center weight-600 size-25">
                                {company && company.offertCount}
                            </span>
                            <div className="flex-row">
                                <Icon name={ICONS.doc} className="opacity-50" size={[20, 20]}/>
                                <span className="weight-700 size-16 padding-left-10">
                                    <FormattedMessage id="offers"/>
                                </span>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col>
                    <Card span={8} className="border-radius-0 border-grey flex-center" style={{width: 250}}>
                        <div className="flex-column">
                            <span className="flex-center weight-600 size-25">
                                {company && company.candidatesCount}
                            </span>
                            <div className="flex-row">
                                <Icon name={ICONS.user} className="opacity-50" size={[22, 22]}/>
                                <span className="weight-700 size-16 padding-left-5">
                                    <FormattedMessage id="candidates"/>
                                </span>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row className="flex-between padding-top-30 padding-buttom-30">
                <div className="weight-600 size-18">Sedi</div>
                <div className="flex-row">
                    <ButtonLine style={{height: "32px"}} className="flex-row flex-align-center margin-right-20" onClick={() => {
                        setModalType(COMPANY_MODAL_TYPE.NEW)
                        setOpenModalLocation(true)
                        setEditLocation(null)
                    }}>
                        <Icon name={ICONS.business_add} className="margin-right-5" size={[20, 20]} color={"#208AC6"}/>
                        <div className="weight-700 size-13 blue">
                            <FormattedMessage id="CompanyDetails_newLocation"/>
                        </div>
                    </ButtonLine>
                    <ButtonLine style={{height: "32px"}} className="flex-row flex-align-center" onClick={() => {
                        setModalType(COMPANY_MODAL_TYPE.NEW)
                        setOpenModalEmployee(true)
                    }}>
                        <Icon name={ICONS.user_add} className="margin-right-5" size={[20, 20]} color={"#208AC6"}/>
                        <div className="weight-700 size-13 blue" onClick={() => setModalType(COMPANY_MODAL_TYPE.NEW)}>
                            <FormattedMessage id="CompanyDetails_newResponsible"/>
                        </div>
                    </ButtonLine>
                    <ButtonLine style={{height: "32px"}} className="flex-row margin-left-20 flex-align-center" onClick={() => {
                        setChangeType(DRAWER_TYPE.offer)
                        dispatch(openDrawer())
                    }}>
                        <Icon name={ICONS.doc_add} className="margin-right-5" size={[20, 20]} color={"#208AC6"}/>
                        <div className="weight-700 size-13 blue" onClick={() => {
                            dispatch(offertToCreate())
                            setModalType(COMPANY_MODAL_TYPE.NEW)
                        }}>
                            <FormattedMessage id="CompanyDetails_newOffer"/>
                        </div>
                    </ButtonLine>
                </div>
            </Row>
            <Table
                className="border-radius-0 border-grey"
                rowKey={record => record.id}
                columns={columns}
                dataSource={company.companyLocations}
                pagination={false}
            />
            <ModalEmployee setOpenModalEmployee={setOpenModalEmployee} openModalEmployee={openModalEmployee} type={modalType} company={company}/>
            <ModalLocation setOpenModalLocation={setOpenModalLocation} openModalLocation={openModalLocation} type={modalType} company={company} record={editLocation} locationArray={locationArray}/>
        </div>
    )
}

export default CardCreatedCompany
