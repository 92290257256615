import React from "react";
import {Card} from "antd";
import Icon from "../../Icon/Sprite";
import {ICONS} from "../../../assets/sprite/iconsNames";
import {FormattedMessage} from "react-intl";

function CardNewCandidate({type}){
    return(
        <div>
            <Card className="border-radius-0">
                <div style={{display:"flex",justifyContent:"center", marginBottom:20}}>
                    <div className={"company-circle"}>
                        <Icon name={ICONS.user_multi_100} size={[100,100]} color="#C41A43"/>
                    </div>
                </div>
                <div style={{display:"flex",justifyContent:"center"}}>
                    {(type !== "offert") ?
                        <b>
                            <FormattedMessage id="Candidate_NoCandidates_1"/>
                        </b>
                        :
                        <b>
                            Non ci sono candidati iscritti
                        </b>
                    }
                </div>
                <div style={{display:"flex",justifyContent:"center"}}>
                    {(type !== "offert") ?
                        <p>
                            <FormattedMessage id="Candidate_NoCandidates_2"/>
                        </p>
                        :
                        <p>
                            Qui visualizzerai tutti i candidati
                        </p>
                    }
                </div>
            </Card>
        </div>
    )
}

export default CardNewCandidate
