import actions from './actions';
import authActions from "../auth/actions";

const initState = {
    isSendEmail: false,
    defaultEmailMessage: null
};

export default function sendEmail(state = initState, action) {
    switch (action.type) {
        case authActions.LOGOUT:
            return {...initState}
        case actions.SEND_EMAIL:
            return {
                ...state,
                isSendEmail: true,
            }
        case actions.SEND_EMAIL_SUCCESS:
        case actions.SEND_EMAIL_ERROR:
            return {
                ...state,
                isSendEmail: false,
            }
        case actions.GET_EMAIL_SUCCESS:
            return {
                ...state,
                defaultEmailMessage: action.payload,
            };
        default:
            return state;
    }
}
