import React from "react";
import {Button as AntInput} from "antd";
import styled from "styled-components"

export const ButtonUser = styled(AntInput)`

  &.ant-btn{
    height: ${props => props.minWidth ? props.minWidth : "48px"};
    min-width: ${props => props.minWidth ? props.minWidth : "160px"};
    background: linear-gradient(180deg, #208AC6 0%, #167FBC 100%);
    box-shadow: 0px 1px 3px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.15);
    border-radius: 3px;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    
    
    /* White */
    
    color: #FFFFFF;
  }
  
  &.ant-btn-default{
    height: ${props => props.minWidth ? props.minWidth : "48px"};
    min-width: ${props => props.minWidth ? props.minWidth : "160px"};
    border: none;
    background: linear-gradient(180deg, #208AC6 0%, #167FBC 100%);
    box-shadow: 0px 1px 3px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.15);
    border-radius: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    
    
    /* White */
    
    color: #FFFFFF;
  }
  
  &.ant-btn-default:hover{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(180deg, #208AC6 0%, #167FBC 100%);
    box-shadow: 0px 1px 3px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.15);
    border-radius: 3px;
    border: none;
    color: #FFFFFF;
  }
  &.ant-btn-default:active{
    background: linear-gradient(0deg, rgba(14, 14, 44, 0.1), rgba(14, 14, 44, 0.1)), linear-gradient(180deg, #208AC6 0%, #167FBC 100%);
    box-shadow: inset 0px 1px 1px rgba(14, 14, 44, 0.15);
    border-radius: 3px;
    border: none;
    color: #FFFC;

  }
  &.ant-btn-default:disabled{
    background: #FFFFFF;
    /* Dark/Light/90 */
    color: #E7E7EA;
    border: 1px solid #E7E7EA;
    border-radius: 3px;
  }
  &.ant-btn-block{
    width: 100%;
  }
  
`
