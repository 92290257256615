import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {endpoints} from "../../config/site.config";
import SuperFetch from "../../helpers/superFetch";
import actions from "./actions";
import authActions from "../auth/actions";

export function* getAllPreference() {

    yield takeEvery(actions.GET_ALL_PREFERENCE, function* () {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllUserPreference, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ALL_PREFERENCE_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_PREFERENCE_ERROR,
                payload: error
            });
        }
    })

}

export function* savePreference() {

    yield takeEvery(actions.SAVE_PREFERENCE, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.registerNewPreference, {offertId: data.id}, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.SAVE_PREFERENCE_SUCCESS,
                payload: response,
                id: data.id
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SAVE_PREFERENCE_ERROR,
                payload: error
            });
        }
    })

}

export function* deletePreference() {

    yield takeEvery(actions.DELETE_PREFERENCE, function* (data ) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.delete, endpoints.deletePreference + "/"+ data.id ,null , true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            if (data.deleteType === "delete"){
                yield put({
                    type: actions.GET_ALL_PREFERENCE,
                })
                yield put({
                    type: actions.CLOSE_VIEW_COMPONENT
                })
            }

            yield put({
                type: actions.DELETE_PREFERENCE_SUCCESS,
                payload: response,
                id: data.id
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DELETE_PREFERENCE_ERROR,
                payload: error
            });
        }
    })

}

export default function* rootSaga() {
    yield all([
        fork(getAllPreference),
        fork(savePreference),
        fork(deletePreference),
    ]);
}
