import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {List} from "antd";
import {OFFER_FILTERS_CANDIDATE, SORTING} from "src/utils/utility";
import {ButtonLine} from "src/component/Useful/Button/ButtonLine";
import {FormattedMessage} from "react-intl";
import userActions from "../../../redux/userProfile/actions"
import actions from "src/redux/offer/actions";
import candidatureActions from "src/redux/candidature/actions";
import DrawerListOffertUserMobile from "./DrawerListOffertUserMobile";
import useMediaQuery from "../../Useful/Responsive/UseMedia";
import ListInputOffertUser from "../../Useful/ListInput/OfferUser/ListInputOffertUser";
import ListInputMobileOffertUser from "../../Useful/ListInput/OfferUser/ListInputMobileOffertUser";
import * as dayjs from "dayjs";
import {useNavigate} from "react-router";

let relativeTime = require('dayjs/plugin/relativeTime')

function ListMyOffertMobile(props) {

    let dispatch = useDispatch()
    const navigate = useNavigate()

    dayjs.extend(relativeTime)

    const {addUserOfferToPrevious, updateOfferViews} = actions;
    const {setRecordOffer} = userActions;
    const {getAllMyCandidature} = candidatureActions;

    const {page, setPage, titleFilter, locationFilter, impegnoFilter, onlyRemoteFilter, setType, viewComponent, setViewComponent} = props;

    const offers = useSelector(state => state.Offer.offers)
    const totalOffers = useSelector(state => state.Offer.totalOffers)
    const loading = useSelector(state => state.Offer.loading)
    const recordOffer = useSelector(state => state.UserProfile.recordOffer)
    const myCandidatures = useSelector(state => state.Candidature.myCandidatures)

    const isTablet = useMediaQuery('(min-width: 720px)')
    const isMobile = useMediaQuery('(min-width: 640px)')

    useEffect(() => {
        dispatch(getAllMyCandidature({
            state: OFFER_FILTERS_CANDIDATE.ALL.value,
            sort: SORTING.ASC.value
        }))

    }, [])

    return (
        <div className={"flex-row"}>
            <div className={"width-100"}>
                {offers &&
                <List bordered dataSource={offers} className={"pointer"} onClick={()=> setViewComponent(true)}>
                    {offers.map((element, index) => {
                        return (
                            <List.Item key={element.id} onClick={() => {
                                if (element.id !== (recordOffer && recordOffer.id)) {
                                    dispatch(updateOfferViews(element.id))
                                }
                                dispatch(setRecordOffer(element))
                                setViewComponent(true)
                            }}
                               className={element.id === (recordOffer && recordOffer.id) ? "color-light-blue" : "white"}>
                                {isMobile ?
                                <ListInputOffertUser element={element}/> :
                                <ListInputMobileOffertUser element={element}/>}
                            </List.Item>
                        )
                    })}
                </List>
                }
                <div className="loadMore size-14">
                    {totalOffers &&
                    <div className="flex-center">Offerte visualizzate <div
                        className="margin-left-5 weight-700">{totalOffers > page * 10 ? page * 10 : totalOffers} di {totalOffers}</div>
                    </div>
                    }
                    <div>
                        {totalOffers > page * 10 &&
                        <ButtonLine
                            className="loadMoreButton"
                            loading={loading}
                            onClick={() => {
                            let currentPage = page
                            setPage(page + 1)
                            dispatch(addUserOfferToPrevious(
                                {
                                    page: currentPage + 1,
                                    size: 10,
                                    title: titleFilter,
                                    locationId: locationFilter && locationFilter.id,
                                    impegnoRichiesto: impegnoFilter,
                                    onlyRemote: onlyRemoteFilter,
                                    sort: SORTING.DESC.value
                                }
                            ))
                        }}>
                            <FormattedMessage id="seeAllOffers"/>
                        </ButtonLine>
                        }
                    </div>
                </div>
            </div>
            <DrawerListOffertUserMobile
                width={isTablet ? "70%" : "100%" }
                recordOffer={recordOffer}
                myCandidatures={myCandidatures}
                openState={viewComponent}
                setOpenState={setViewComponent}
                idRecord={recordOffer && recordOffer.id}
                setType={setType}
            />
        </div>
    )
}

export default ListMyOffertMobile
