import React from "react";
import {ImgComponent} from "../../../../utils/utility";
import moment from "moment";
import Icon from "../../../Icon/Sprite";
import {ICONS} from "../../../../assets/sprite/iconsNames";
import GreyLogo from "../../../../assets/logo/logo-VT-grey.svg";

function ListInputMyCandidature(props) {

    const {element} = props

    return (
        <div className="width-100 flex-between">
            <div className="flex-row margin-right-20">
                <div style={element.companyLogo ? {width: "88px", height: "72px", marginRight: 20, border: "1px solid rgba(14, 14, 44, 0.15)"} : {width: "88px", height: "72px", marginRight: 20, border: "none"}}>
                    {element.companyLogo ?
                        <ImgComponent logoPath={element.companyLogo}/>
                        :
                        <div className="noLogo">
                            <img src={GreyLogo}/>
                        </div>
                    }
                </div>
                <div className="flex-column">
                    <div className="blue underlined weight-700 size-16">{element.offertTitle}</div>
                    {element.anonymous ?
                        <div className="opacity-50 underlined size-12">
                            Azienda Anonima
                        </div>
                        :
                        <div className="opacity-50 underlined weight-400 size-12">
                            {element.uiCompanyLocationResponseData && element.uiCompanyLocationResponseData.name}
                        </div>}
                    <div className="flex-gap dividerDot">
                        {element.offertInsertMode &&
                        <div className="flex-center">
                            {element.offertInsertMode}
                        </div>
                        }
                        {element.workPlace &&
                        <div className="flex-center">
                            {element.workPlace}
                        </div>
                        }
                        {element.location &&
                        <div className="flex-center">
                            {element.location}
                        </div>
                        }
                        {element.impegnoRichiesto &&
                        <div className="flex-center">
                            {element.impegnoRichiesto}
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div className="flex-center">
                <div className="weight-400 size-12 opacity-50 padding-right-40">
                    {
                        element.candidatureState && element.candidatureState === "none" ?
                            <div>
                                <div className="backgroundStatePending flex-center">
                                    <span>in attesa</span>
                                </div>
                                <span className="flex-end">Candiato il: {moment(element.candidatureSubmittedTimestamp).locale('it').format("DD-MMM-YYYY")}</span>
                            </div>
                            : element.candidatureState === "In attesa" ?
                            <div>
                                <div className="backgroundStatePending flex-center">
                                    <span>In attesa · visualizzato</span>
                                </div>
                                <span className="flex-end">Candiato il: {moment(element.candidatureSubmittedTimestamp).locale('it').format("DD-MMM-YYYY")}</span>
                            </div>
                            : element.candidatureState === "Colloquio" ?
                                <div>
                                    <div className="backgroundStateInterview flex-center">
                                        <span>Colloquio</span>
                                    </div>
                                    <span className="flex-end">Candiato il: {moment(element.candidatureSubmittedTimestamp).locale('it').format("DD-MMM-YYYY")}</span>
                                </div>
                                : element.candidatureState === "Preselezione" ?
                                    <div>
                                        <div className="backgroundStatePreselected flex-center">
                                            <span>Preselezione</span>
                                        </div>
                                        <span className="flex-end">Candiato il: {moment(element.candidatureSubmittedTimestamp).locale('it').format("DD-MMM-YYYY")}</span>
                                    </div>
                                    : element.candidatureState === "Selezionato" ?
                                        <div>
                                            <div className="backgroundStateSelected flex-center">
                                                <span>Selezionato</span>
                                            </div>
                                            <span className="flex-end">Candiato il: {moment(element.candidatureSubmittedTimestamp).locale('it').format("DD-MMM-YYYY")}</span>
                                        </div>
                                        : element.candidatureState === "Rifiutato" ?
                                            <div>
                                                <div className="backgroundStateReject flex-center">
                                                    <span>Rifiutato</span>
                                                </div>
                                                <span className="flex-end">Candidato il: {moment(element.candidatureSubmittedTimestamp).locale('it').format("DD-MMM-YYYY")}</span>
                                            </div>
                                            : null
                    }
                </div>
                <div style={{width: 30, height: 72}} className="flex-center">
                    <div style={{transform: "rotate(-90deg)"}}>
                        <Icon name={ICONS.arrow} size={[20, 20]} className="opacity-50"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListInputMyCandidature