import React from "react";
import Icon from "../../Icon/Sprite";
import {ICONS} from "../../../assets/sprite/iconsNames";
import {Card} from "antd";

function EmptyOffers(){
    return(
        <div>
            <Card className="border-radius-0">
                <div className="flex-center" style={{marginBottom:20}}>
                    <div className={"user-circle"}>
                        <Icon name={ICONS.doc_add_100} size={[100,100]} color="#208AC6"/>
                    </div>
                </div>
                <div style={{display:"flex",justifyContent:"center"}}>
                    <b>Non ci sono offerte</b>
                </div>
            </Card>
        </div>
    )
}

export default EmptyOffers
