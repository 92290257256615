import React, {useEffect} from "react";
import {Modal} from "antd";
import {ButtonUser} from "../../../Useful/Button/ButtonUser";
import {ButtonGhost} from "../../../Useful/Button/ButtonGhost";
import {FormattedMessage} from "react-intl";
import {COMPANY_MODAL_TYPE, newId, OFFER_FILTERS_CANDIDATE, ROLES} from "../../../../utils/utility";
import {ButtonLine} from "../../../Useful/Button/ButtonLine";
import Icon from "../../../Icon/Sprite";
import {ICONS} from "../../../../assets/sprite/iconsNames";
import "./ModalCandidateAnswers.scss"
import dayjs from "dayjs";

export default function ModalCandidateAnswers(props) {

    const {candidature, open, onCancel} = props

    return (
        <Modal
            width={800}
            open={open}
            closeIcon={<Icon name={ICONS.close} size={[20, 20]}/>}
            className={"modalCandidateAnswers"}
            onCancel={() => onCancel()}
            footer={[
                <div className={"flex-end"}>
                    <div className="flex-end">
                        <ButtonUser
                            className="flex-center"
                            onClick={() => onCancel()}
                        >
                            <Icon name={ICONS.tik} size={[20, 20]} color="#FFFFFF"/>
                            <FormattedMessage id="ModalButton_Close"/>
                        </ButtonUser>
                    </div>
                </div>
            ]}>
            <div className="flex-column">
                <div className="flex-start flex-align-center">
                    <div className="flex-column">
                        <p className="size-28 merriWeather weight-700 margin-0">{candidature.offertTitle}</p>
                        <p className="size-14 weight-400 margin-0">{candidature.companyName + " · " + candidature.location}</p>
                        <p className="size-14 weight-400 margin-0 opacity-50">{dayjs(candidature.candidatureSubmittedTimestamp).format("DD MMM YYYY - HH:mm")}</p>
                        {candidature.candidatureState !== OFFER_FILTERS_CANDIDATE.ALL.value && <p className="size-14 weight-400 margin-0 opacity-50">{candidature.candidatureState}</p>}
                    </div>
                </div>
                <div className="flex-center">
                    <p className="size-12 weight-600 opacity-50">DOMANDE</p>
                    <div className="margin-left-8"
                         style={{height: 1, flexGrow: 1, backgroundColor: "#0E0E2C", opacity: 0.1}}/>
                </div>
                <div className="flex-column">
                    {candidature && candidature.candidatureQuestions.length !== 0 ? candidature.candidatureQuestions.map((question, index) => {
                            return (
                                <div className="flex-start">
                                    <div className="numberDot">
                                        {index + 1}
                                    </div>
                                    <div className="flex-column">
                                        <p className="weight-700 margin-0" style={{lineHeight: "24px"}}>{question.question}</p>
                                        <p className="size-14 margin-0">{question.answer}</p>
                                    </div>
                                </div>
                            )
                        }
                    ) :
                        <div className="flex-center">
                            <p>Nessuna domanda presente</p>
                        </div>
                    }
                </div>
            </div>
        </Modal>
    )
}