import React from "react";
import {Input as AntInput} from "antd";
import styled from "styled-components"

export const Input = styled(AntInput)`

  &.ant-input, &.ant-input-affix-wrapper{
    height: ${props => props.height ? props.height : "48px"};
    padding: 8px 16px;
    border-radius: 3px;
    border-color: #0E0E2C33;
    
    &:hover{
      border-color: ${props => props.disabled ? "none" : "#868696"};
    }
    &:focus{
      border: 2px solid #208AC6 !important;
      box-shadow: none;
   }
  }
 
`
