import React, {useEffect, useState} from "react";
import "./superAdmin.scss"
import Icon from "src/component/Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import Breadcrumb from "antd/es/breadcrumb";
import {PRIVATE_ADMIN_ROUTE} from "src/route.constants";
import {FormattedMessage} from "react-intl";
import {Divider, message, Table} from "antd";
import InputSearch from "src/component/Useful/Input/InputSearch";
import {Select} from "src/component/Useful/Select/Select";
import sprite from "src/assets/sprite/sprite.svg";
import {useNavigate, useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import actions from "src/redux/admin/actions";
import locationActions from "src/redux/location/actions";
import offerActions from "src/redux/offer/actions";
import {SORTING} from "src/utils/utility";
import dayjs from "dayjs";
import {Switch} from "src/component/Useful/Switch"
import {CustomDrawer} from "src/component/Layout/Drawer/CustomDrawer";
import drawerActions from "src/redux/drawer/actions";
import companyActions from "src/redux/company/actions";
import employeeActions from "src/redux/employee/actions";

const queryParams = new URLSearchParams(window.location.search)
const open = queryParams.get("open")

function AdminUsers() {

    const id = queryParams.get("id")
    const companyId = queryParams.get("companyId")

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [messageApi, contextHolder] = message.useMessage();

    const {getAllAdminCompany, enableCompany, disableCompany, deleteCompany} = actions;
    const {openDrawer} = drawerActions;
    const {offertToModify} = companyActions;
    const {getAllCompanyLocationBySysAdmin} = locationActions;
    const {get} = offerActions;
    const {getAllBySysAdmin} = employeeActions;


    const companies = useSelector(state => state.Admin.companies)
    const total = useSelector(state => state.Admin.total)
    const reload = useSelector(state => state.Admin.reload)
    const record = useSelector(state => state.Offer.offer)
    const isDrawerOpen = useSelector(state => state.UserProfile.isDrawerOpen)
    const isOffersCreatedSuccess = useSelector(state => state.Offer.isOffersCreatedSuccess)

    const [state, setState] = useState("all")
    const [sorting, setSorting] = useState(SORTING.ASC.value)
    const [search, setSearch] = useState(null)

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)


    const columns = [
        {
            title: 'Candidati',
            render: (text, record) => (
                <div className="flex-column">
                    <div className="companyName weight-600 size-14">
                        {record.companyName}
                    </div>
                    <div className="dateCreated">
                        {(dayjs(record.registrationDate).format("DD MMM YYYY"))}
                    </div>
                </div>
            )
        },
        {
            title: 'P.IVA',
            dataIndex: "vatNumber",
        },
        {
            title: 'Città',
            dataIndex: "city"
        },
        {
            title: 'Responsabile',
            dataIndex: "adminNameSurname"
        },
        {
            title: 'Offerte',
            dataIndex: "offertCount",
            render: (text) => (
                <div className="flex-center">
                    <Icon name={ICONS.doc} size={[20, 20]}/>
                    <span className="margin-left-8">
                        {text}
                    </span>
                </div>
            )
        },
        {
            title: 'Candidati',
            dataIndex: "candidateCount",
            render: (text) => (
                <div className="flex-center">
                    <Icon name={ICONS.user} size={[20, 20]}/>
                    <span className="margin-left-8">
                        {text}
                    </span>
                </div>
            )
        },
        {
            title: 'Attivo',
            dataIndex: "enabled",
            render: (text, record) => (
                <div>
                    <Switch
                        checkedChildren={<Icon name={ICONS.ok} size={[20,20]}/>}
                        unCheckedChildren={<Icon name={ICONS.close_switch} size={[20,20]}/>}
                        checked={text}
                        onChange={()=>{
                            if (record.enabled){
                                dispatch(disableCompany(record.id))

                            } else if (!record.enabled){
                                dispatch(enableCompany(record.id))
                            }
                        }}
                    />
                </div>
            )
        },
        {
            render: (text, record) => (
                <div className="pointer" onClick={()=> dispatch(deleteCompany(record.id))}>
                    <Icon name={ICONS.delete} size={[20, 20]}/>
                </div>
            )
        }
    ];

    useEffect(()=>{
        dispatch(getAllAdminCompany({
            enabled: state,
            sort: sorting,
            search: search,
            page: page,
            pageSize: pageSize,
        }))
    },[state, sorting, search, page, pageSize])

    useEffect(()=>{
        if (reload){
            dispatch(getAllAdminCompany({
                enabled: state,
                sort: sorting,
                search: search,
                page: page,
                pageSize: pageSize,
            }))
        }
    }, [reload])

    useEffect(()=>{

        if(id){
            dispatch(get(id))
        }

        if(companyId) {
            dispatch(getAllBySysAdmin(companyId))
            dispatch(getAllCompanyLocationBySysAdmin(companyId))
        }

        if(open){
            dispatch(openDrawer())
            dispatch(offertToModify())
        }
    },[id, companyId, open])


    useEffect(()=>{
        if(isOffersCreatedSuccess){
            messageApi.open({
                type: 'success',
                content: 'Offerta modifica notificata per email con successo!'
            })
        }
    },[isOffersCreatedSuccess])

    return (
        <div className="admin">
            {contextHolder}
            <div className="max-width-1200 padding-50">
                <div>
                    <Breadcrumb className="customBreadcrumb" separator="›">
                        <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_ADMIN_ROUTE.DASHBOARD)}>
                            <FormattedMessage id="Breadcrumb_home"/>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <FormattedMessage id="Breadcrumb_user"/>
                        </Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="display-flex align-items-center">
                        <span className="size-28 weight-700 merriWeather">
                            Utenti
                        </span>
                    </div>

                    <Divider/>

                    <div className="flex-center-between margin-bottom-24">
                        <div className="size-18 weight-600">
                            Lista degli utenti
                        </div>
                        <div className="margin-right-32">
                            <InputSearch
                                text={"Ricerca utenti"}
                                value={search}
                                onChange={(value) => {
                                    setSearch(value)
                                    setPage(1)
                                }}
                            />
                        </div>
                    </div>

                </div>
            </div>
            <CustomDrawer
                record={record}
                modify={"modify"}
                isDrawerOpen={isDrawerOpen}
                type={"offer"}
            />
        </div>
    )
}

export default AdminUsers