import actions from './actions'
import {DRAWER_TYPE} from "../../utils/utility";
import authActions from "../auth/actions";

const initState = {
    candidatures: null,
    myCandidatures: [],
    candidature: null,
    candidatureByOffert: null,
    updateList: false,
    updated: false,
    countCandidatureByOffert: 0,
    candidatureWaitingCount:0,
    candidatureSelectedCount: 0,
    candidatureRejectedCount: 0,
    candidaturePreselectionCount: 0,
    candidatureInterviewCount: 0,
    candidatureNoneCount: 0,
    updatedCandidature: false,
    isCandidatureCreatedSuccess: false,
    confirmCandidature: false,
    cv: null,
    fileBlob: null,
    fileName: null,
    fileType: null,
    changeCustomDrawer: null,
    viewState: false
}

export default function candidatureReducer(state = initState, action){
    switch (action.type) {
        case actions.INIT_CANDIDATURE:
            return{
                ...initState
            };
        case authActions.LOGOUT:
            return {...initState}
        case actions.GET_ALL_CANDIDATURE_SUCCESS:
            return{
                ...state,
                candidatures: action.payload,
                updatedList: false
            };
        case actions.GET_ALL_MY_CANDIDATURE_SUCCESS:
            return{
                ...state,
                myCandidatures: action.payload,
            };
        case actions.GET_CANDIDATURE_BY_ID_SUCCESS:
            return{
                ...state,
                candidature: action.payload,
            };
        case actions.GET_CANDIDATURE_BY_OFFERT_ID_SUCCESS:
            return{
                ...state,
                totalOffertCandidature: action.payload.candidatureWaitingCount + action.payload.candidatureSelectedCount + action.payload.candidatureRejectedCount + action.payload.candidaturePreselectionCount + action.payload.candidatureInterviewCount + action.payload.candidatureNoneCount,
                candidatureByOffert: action.payload.elements,
                countCandidatureByOffert: action.payload.total,
                candidatureWaitingCount: action.payload.candidatureWaitingCount,
                candidatureSelectedCount: action.payload.candidatureSelectedCount,
                candidatureRejectedCount: action.payload.candidatureRejectedCount,
                candidaturePreselectionCount: action.payload.candidaturePreselectionCount,
                candidatureInterviewCount: action.payload.candidatureInterviewCount,
                candidatureNoneCount: action.payload.candidatureNoneCount,
            }
        case actions.GET_CANDIDATURE_COUNT_BY_OFFERT_ID_SUCCESS:
            return{
                ...state,
                countCandidatureByOffert: action.payload.total,
                candidatureWaitingCount: action.payload.candidatureWaitingCount,
                candidatureSelectedCount: action.payload.candidatureSelectedCount,
                candidatureRejectedCount: action.payload.candidatureRejectedCount,
                candidaturePreselectionCount: action.payload.candidaturePreselectionCount,
                candidatureInterviewCount: action.payload.candidatureInterviewCount,
                candidatureNoneCount: action.payload.candidatureNoneCount,
                updatedCandidature: false
            }
        case actions.SAVE_CANDIDATURE:
            return {
                ...state,
                changeCustomDrawer: DRAWER_TYPE.firstStepCandidatureUser,
            }
        case actions.SAVE_CANDIDATURE_SUCCESS:
            return {
                ...state,
                updateList: true,
                updated: true,
                changeCustomDrawer: DRAWER_TYPE.confirmOfferUser,
                isCandidatureCreatedSuccess: true,
            }
        case actions.UPDATE_CANDIDATURE_SUCCESS:
            return {
                ...state,
                candidature: action.payload.value,
                updateList: true,
                updated: true,
                updatedCandidature: true
            }
        case actions.DELETE_CANDIDATURE:
        case actions.DELETE_CANDIDATURE_PAGINATED:
            return {
                ...state,
                updateList: true
            }
        case actions.GET_CANDIDATURE_CV_SUCCESS:
            return{
                ...state,
                cv: action.payload,
                fileBlob: action.fileBlob,
                fileName: action.fileName,
                fileType: action.fileType
            }
        case actions.CONFIRM_CANDIDATURE:
            return {
                ...state,
                confirmCandidature: true
            }
        case actions.RESET_CANDIDATURE:
            return {
                ...state,
                confirmCandidature: false,
                isCandidatureCreatedSuccess: false
            }
        case actions.GET_ALL_CANDIDATURE_BY_USER_AND_COMPANY_SUCCESS:
            return {
                ...state,
                candidateDetails : action.payload
            }
        case actions.CHANGE_VIEW_FOR_DOT:
            return{
                ...state,
                viewState: false
            }
        case actions.CHANGE_VIEW_FOR_DOT_SUCCESS:
        case actions.CHANGE_VIEW_FOR_DOT_ERROR:
            return{
                ...state,
                viewState: true
            }
        default:
            return state;
    }
}
