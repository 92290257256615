import React from "react";
import {InputLogin} from "src/component/Useful/Input/InputLogin";
import "./style/RecoveryEmailCompany.scss"
import Icon from "../../../Icon/Sprite";
import {ICONS} from "../../../../assets/sprite/iconsNames";
import {FormattedMessage, injectIntl} from "react-intl";
import {Col, Row, Form} from "antd";
import CompanyPicture from "../../../../assets/image/PictureLoginCompany.png";
import {useNavigate} from "react-router";
import {ButtonCompany} from "../../../Useful/Button/ButtonCompany";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../redux/auth/actions";

const {recoverPassword} = actions

function RecoveryEmailCompany() {

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [email, setEmail] = React.useState(null)
    const [stateResponse, setStateResponse] = React.useState(false)

    const recoverPasswordSuccess = useSelector(state => state.AuthReducer.recoverPasswordSuccess)

    React.useEffect(()=>{
        if(recoverPasswordSuccess){
            setStateResponse(true)
        }
    },[recoverPasswordSuccess])

    const handleClick = () =>{
        if(email){
            let data = {
                email: email,
                context: "company"
            }
            dispatch(recoverPassword(data))
        }
    }

    const goTo = (param) =>{
        if(param === "back"){
            navigate("/company/login")
        }
    }

    return(
        <div className={"containerCompanyRecoveryEmail"}>
            <div  className={"contentCompanyRecoveryEmail"}>
                <Row>
                    <Col md={{span: 9}} sm={{span: 20}} xs={{span: 24}}>
                        <div className={"image"}>
                            <img src={CompanyPicture} className={"side-image"}/>
                        </div>
                    </Col>
                    <Col md={{span: 12}} sm={{ span: 20}} xs={{span: 24}} className={"flex-column-center"}>
                        {
                            stateResponse === false ? (
                                <div className={"contentRecoveryEmailCompany"}>
                                    <header>
                                        <div className="header_mobile">
                                            <div className={"back"}>
                                                <span onClick={() => goTo("back")}><Icon name={ICONS.arrow_2} className="pointer" size={[20,20]}/></span>
                                                <div className="p_back margin-5">Indietro</div>
                                            </div>
                                            <h1><FormattedMessage id="RecoveryEmail_title"/></h1>
                                        </div>
                                        <div className="header_recovery">
                                            <p><FormattedMessage id="RecoveryEmail_HeaderText"/></p>
                                        </div>
                                    </header>
                                    <main>
                                        <p><FormattedMessage id="RecoveryEmail_Email"/></p>
                                        <Form>
                                            <Form.Item
                                                name={"email"}
                                                type={"email"}
                                                rules={[{type: 'email', message: false}]}
                                                className="inline-block width-100"
                                            >
                                                <InputLogin
                                                    className="opacity-50"
                                                    onChange={(e)=>setEmail(e.target.value)}
                                                    prefix={<Icon name={ICONS.mail} size={[20,20]} />}
                                                    placeholder="Inserire l'email"
                                                />
                                            </Form.Item>
                                        </Form>
                                    </main>
                                    <footer>
                                        <ButtonCompany onClick={handleClick} block icon={<Icon name={ICONS.tik} className="margin-5 opacity-50" size={[20,20]}/>} className={"flex-center"}>
                                            <FormattedMessage id="RecoveryEmail_Button"/>
                                        </ButtonCompany>
                                    </footer>
                                </div>
                            ) : (
                                <div className={"containerConfirmRecovery"}>
                                    <h1><FormattedMessage id="ConfirmRecovery_title"/></h1>
                                    <p><FormattedMessage id="ConfirmRecovery_text"/></p>
                                </div>
                            )
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default injectIntl(RecoveryEmailCompany)
