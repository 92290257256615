export const ENDPOINT = {
    ANTONIO: "http://172.20.1.21:8080",
    MICHELEC: "http://172.20.1.162:8080",
    LUCHINO: "http://172.20.1.104:8080",
    PROD_SERVER: "https://vt-job.it/api",
    SERVER: "http://172.20.1.109:8080",
    LOCALHOST: "http://localhost:8080",
    DI_MICHELE: "http://172.20.50.37:8080",
    QUARZO: "http://172.20.1.81:8080"

}
//let apiUrl = ENDPOINT.ANTONIO
//let apiUrl = ENDPOINT.SERVER
//let apiUrl = ENDPOINT.LOCALHOST
// let apiUrl = ENDPOINT.MICHELEC
// let apiUrl = ENDPOINT.LUCHINO
// let apiUrl = ENDPOINT.DI_MICHELE
// let apiUrl = ENDPOINT.QUARZO
let apiUrl = ENDPOINT.PROD_SERVER



const endpoints = {
    login: apiUrl + "/auth/login",
    recoveryPassword: apiUrl + "/auth/recovery",
    resetPassword: apiUrl + "/auth/resetPassword",
    refreshToken: apiUrl + "/auth/refreshAuth",
    activateProfile: apiUrl + "/auth/activateProfile",
    resetNewPassword: apiUrl + "/auth/updatePassword",
    checkOldPassword: apiUrl + "/auth/checkOldPassword",

    //landing
    publicOffer: apiUrl + "/web/getAllOfferts",

    //closeActivationMessage
    closeActivationMessage: apiUrl + "/company/closeActivationMessage",

    //closeStep
    closeStep: apiUrl + "/company/closeStep",

    //UserProfile
    getAllUserProfile: apiUrl + "/user",
    getUserProfileById: apiUrl + "/user",
    registrationUserProfile: apiUrl + "/auth/register",
    saveUserProfile: apiUrl + "/user",
    updateUserProfile: apiUrl + "/user",
    deleteUserProfile: apiUrl + "/user",
    sendFcmToken: apiUrl + "/auth/newFCMToken",

    //Company
    getAllCompany: apiUrl + "/company",
    getAllCompanyForCsv: apiUrl + "/company/forCsv",
    getCompanyById: apiUrl + "/company",
    registrationCompany: apiUrl + "/company",
    updateCompany: apiUrl + "/company",
    getCompanyDashboard: apiUrl + "/dashboard/getCompanyDashboard",
    editEmailMessage: apiUrl + "/company/defaultMessage",

    //Offer
    getAllOffer: apiUrl + "/offert",
    getAllOfferUser: apiUrl + "/offert/user",
    getOfferDetails: apiUrl + "/offert",
    getOfferDetailsForUser: apiUrl + "/offert/user",
    getOfferByFilter: apiUrl + "/offert",
    saveOffer: apiUrl + "/offert",
    updateOffer: apiUrl + "/offert",
    updateOfferBySysAdmin: apiUrl + "/offert/updateBySys",
    deleteOffer: apiUrl + "/offert",
    updateOfferCount: apiUrl + "/offert/visualization",
    setOfferExpired: apiUrl + "/offert/closeCandidature",

    //Candidature
    getAllUserCandidature: apiUrl + "/candidature/user",
    // getAllCandidature: apiUrl + "/candidature",
    getAllCandidature: apiUrl + "/candidature/company/candidates",
    getAllCandidatureByUserAndCompany: apiUrl + "/candidature/company/candidate",
    getCandidatureById: apiUrl + "/candidature",
    getCandidatureDetail: apiUrl + "/candidature/offert",
    getCandidatureByFilter: apiUrl + "/candidature",
    saveCandidature: apiUrl + "/candidature",
    modifyCandidature: apiUrl + "/candidature",
    deleteCandidature: apiUrl + "/candidature",
    getFileCvSource: apiUrl + "/file/multipleDownload",
    setViewDot: apiUrl + "/candidature/updateViewed",

    //Preference
    getAllUserPreference: apiUrl + "/preference",
    registerNewPreference: apiUrl + "/preference",
    deletePreference: apiUrl + "/preference",

    //Settings
    getSettings: apiUrl + "/settings",
    updateSettings: apiUrl + "/settings",

    //NotificationCompany
    getAllNotification: apiUrl + "/notification",
    setNotificationRead: apiUrl + "/notification/read",

    //Location
    getAllLocation: apiUrl + "/location",
    getAllProvince: apiUrl + "/location/province",
    getLocationById: apiUrl + "/location",
    saveLocation: apiUrl + "/location",
    updateLocation: apiUrl + "/location",

    //CompanyLocation
    getCompanyLocationById: apiUrl + "/companyLocation",
    saveCompanyLocation: apiUrl + "/companyLocation",
    updateCompanyLocation: apiUrl + "/companyLocation",
    getAllCompanyLocation: apiUrl + "/companyLocation",
    deleteLocation: apiUrl + "/companyLocation",


    //Employee
    getAllEmployee: apiUrl + "/company/getAllEmployee",
    getEmployeeById: apiUrl + "/employee",
    saveEmployee: apiUrl + "/company/employee/new",
    updateEmployee: apiUrl + "/company/employee/update",
    deleteEmployee: apiUrl + "/company",

    //Email
    email: apiUrl + "/mail",
    getEmail: apiUrl + "/company/defaultMessage",

    //getImageSource
    getImageSource: apiUrl + "/file/getFile",

    //UserDashboard
    getUserDashboard: apiUrl + "/dashboard/getUserDashboard",
    getCompanyByIdForUser: apiUrl + "/company/user",

    //WorkSector
    workSector: apiUrl + "/company/workSectors",

    //Admin
    getSysDashboard: apiUrl + "/dashboard/getSysDashboard",
    getAllAdminCompany: apiUrl + "/company",
    enableCompany: apiUrl + "/company/enable",
    disableCompany: apiUrl + "/company/disable",
    deleteCompany: apiUrl + "/company",
    getAllUsers: apiUrl + "/sysAdmin/users",
    getAllUsersForCsv: apiUrl + "/sysAdmin/usersForCsv",
    getAllEmployeeBySysAdmin: apiUrl + "/sysAdmin/getAllEmployee",
    getAllCompanyLocationBySysAdmin: apiUrl + "/sysAdmin/getAllCompanyLocation",
    getCompanyLocationMainById : apiUrl + "/sysAdmin/getMainLocation",
    enabledOffert: apiUrl + "/sysAdmin/enable",
    getAllCandidatureByUserId : apiUrl + "/sysAdmin/cadidatureUser",
    getUserById: apiUrl + "/sysAdmin/user"
}

export {endpoints}
