import {notification} from 'antd';

const createNotification = (type, message, description, key=null) => {
    notification[type]({
        message,
        description,
        key
    });
};

export default createNotification;

