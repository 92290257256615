import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import Icon from "../../../Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import TextArea from "antd/es/input/TextArea";

export default function Questions(props) {
    const {questions, setQuestions, freeQuestionsToggle} = props

    const addQuestion = () => {
        if (!questions) {
            setQuestions([{id: Math.random(), question: null}])
        } else {
            let tempQuestions = [...questions]
            tempQuestions.push({id: Math.random(), question: null})
            setQuestions(tempQuestions)
        }
    }

    const onChangeValue = (value, id) => {
        let tempQuestions = [...questions]
        let index = tempQuestions.findIndex((item) => item.id === id)
        tempQuestions[index].question = value
        setQuestions(tempQuestions)
    }

    const deleteQuestion = (id) => {
        setQuestions(questions.filter(item => item.id !== id))
    }

    return (
        <div className={"questionContent"}>

            {freeQuestionsToggle &&
            <>
                <Row gutter={[0, 24]}>
                    <Col span={24}>
                        {questions && questions.map((it, index) =>
                            <div key={it.id} className={"questionBox"}>
                                <header className={"questionHeader"}>
                                    <span>
                                        <span className={"red"}>*</span>
                                        Domanda {index + 1}</span>
                                    <div onClick={() => deleteQuestion(it.id)} className={"deleteDiv"}>
                                        <span className={"deleteSpan"}>
                                            Elimina
                                        </span>
                                    </div>
                                </header>
                                <TextArea id={it.id} value={it.question} onChange={(e) => onChangeValue(e.target.value, it.id)}/>
                            </div>
                        )}
                    </Col>
                </Row>
                <Row gutter={[0, 24]}>
                    <Col span={24} className="flex">
                        <div onClick={()=>addQuestion()} className={!freeQuestionsToggle ? "none addQuestion" : "addQuestion"}>
                            <Icon name={ICONS.add} size={[20, 20]} color={"#0E0E2C"}/>
                            <span className="weight-600">
                                Aggiungi un’altra domanda
                            </span>
                        </div>
                    </Col>
                </Row>
            </>

            }

        </div>
    )
}
