import React, { useState } from "react";
import { Button, Card, Divider, List } from "antd";
import { ImgComponent, substituteZeroWithLine } from "../../../utils/utility";
import moment from "moment";
import Icon from "../../Icon/Sprite";
import { ICONS } from "../../../assets/sprite/iconsNames";
import { useNavigate } from "react-router";
import GreyLogo from "../../../assets/logo/logo-VT-grey.svg";

function ListMyCandidature(props) {

    const navigate = useNavigate()

    const { myCandidatures, idRecord, setIdRecord, recordOffer, viewComponent, setViewComponent } = props

    const [stateStyle, setStateStyle] = useState("width-100")

    function openDetailCard() {
        setStateStyle("ease-list-in width-60")
        setTimeout(() => {
            setViewComponent(true)
        }, 950)
    }

    function closeDetailCard() {
        setViewComponent(false)
        setStateStyle("ease-list-out width-100")
    }

    function setField(record) {
        console.log(record)
        let path = "/user/company/"
        if (record.companyId) path = path + record.companyId
        navigate(path)
    }

    return (
        <div className={"flex-row"}>
            <div className={stateStyle}>
                <List bordered onClick={openDetailCard} dataSource={myCandidatures} className={"pointer"}>
                    {myCandidatures && myCandidatures.map((element) => {
                        return (
                            <List.Item
                                key={element.id}
                                className={element.id === idRecord ? "color-light-blue" : "white"}
                                onClick={() => {
                                    setIdRecord(element.id)
                                }}
                            >
                                <div className="width-100 flex-between">
                                    <div className="flex-row margin-right-20">
                                        <div style={!element.anonymous ? { width: "88px", height: "72px", marginRight: 20, border: "1px solid rgba(14, 14, 44, 0.15)" } : { width: "88px", height: "72px", marginRight: 20, border: "none" }}>
                                            {!element.anonymous ?
                                                <ImgComponent logoPath={element.companyLogo} />
                                                :
                                                <div className="noLogo">
                                                    <img src={GreyLogo} />
                                                </div>
                                            }
                                        </div>
                                        <div className="flex-column">
                                            <div className="blue underlined weight-700 size-16">{element.offertTitle}</div>
                                            {!element.anonymous ?
                                                <div className="opacity-50 underlined weight-400 size-12">
                                                    {element.uiCompanyLocationResponseData && element.uiCompanyLocationResponseData.name}
                                                </div>
                                                :
                                                <div className="opacity-50 underlined size-12">
                                                    Azienda Anonima
                                                </div>
                                            }
                                            <div className="flex-gap dividerDot">
                                                {element.offertInsertMode &&
                                                    <div className="flex-center">
                                                        {element.offertInsertMode}
                                                    </div>
                                                }
                                                {element.workPlace &&
                                                    <div className="flex-center">
                                                        {element.workPlace}
                                                    </div>
                                                }
                                                {element.location &&
                                                    <div className="flex-center">
                                                        {element.location}
                                                    </div>
                                                }
                                                {element.impegnoRichiesto &&
                                                    <div className="flex-center">
                                                        {element.impegnoRichiesto}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-center">
                                        <div className="weight-400 size-12 opacity-50 padding-right-40">

                                            <div>
                                                <span className="flex-end">Candiato il: {moment(element.candidatureSubmittedTimestamp).locale('it').format("DD-MMM-YYYY")}</span>
                                            </div>
                                            {/* {
                                                element.candidatureState && element.candidatureState === "none" ?
                                                    <div>
                                                        <div className="backgroundStatePending flex-center">
                                                            <span>in attesa</span>
                                                        </div>
                                                        <span className="flex-end">Candiato il: {moment(element.candidatureSubmittedTimestamp).locale('it').format("DD-MMM-YYYY")}</span>
                                                    </div>
                                                    : element.candidatureState === "In attesa" ?
                                                        <div>
                                                            <div className="backgroundStatePending flex-center">
                                                                <span>In attesa · visualizzato</span>
                                                            </div>
                                                            <span className="flex-end">Candiato il: {moment(element.candidatureSubmittedTimestamp).locale('it').format("DD-MMM-YYYY")}</span>
                                                        </div>
                                                        : element.candidatureState === "Colloquio" ?
                                                            <div>
                                                                <div className="backgroundStateInterview flex-center">
                                                                    <span>Colloquio</span>
                                                                </div>
                                                                <span className="flex-end">Candiato il: {moment(element.candidatureSubmittedTimestamp).locale('it').format("DD-MMM-YYYY")}</span>
                                                            </div>
                                                            : element.candidatureState === "Preselezione" ?
                                                                <div>
                                                                    <div className="backgroundStatePreselected flex-center">
                                                                        <span>Preselezione</span>
                                                                    </div>
                                                                    <span className="flex-end">Candiato il: {moment(element.candidatureSubmittedTimestamp).locale('it').format("DD-MMM-YYYY")}</span>
                                                                </div>
                                                                : element.candidatureState === "Selezionato" ?
                                                                    <div>
                                                                        <div className="backgroundStateSelected flex-center">
                                                                            <span>Selezionato</span>
                                                                        </div>
                                                                        <span className="flex-end">Candiato il: {moment(element.candidatureSubmittedTimestamp).locale('it').format("DD-MMM-YYYY")}</span>
                                                                    </div>
                                                                    : element.candidatureState === "Rifiutato" ?
                                                                        <div>
                                                                            <div className="backgroundStateReject flex-center">
                                                                                <span>Rifiutato</span>
                                                                            </div>
                                                                            <span className="flex-end">Candidato il: {moment(element.candidatureSubmittedTimestamp).locale('it').format("DD-MMM-YYYY")}</span>
                                                                        </div>
                                                                        : null
                                            } */}
                                        </div>
                                        <div style={{ width: 30, height: 72 }} className="flex-center">
                                            <div style={{ transform: "rotate(-90deg)" }}>
                                                <Icon name={ICONS.arrow} size={[20, 20]} className="opacity-50" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        )
                    })}
                </List>
            </div>

            {viewComponent &&
                <div style={{ width: "40%" }}>
                    <Card style={{ marginLeft: "20px", position: "sticky", top: "20px", borderRadius: 0 }}>
                        <div className="flex-row margin-bottom-30 flex-center-between">
                            <div className="flex-align-center">
                                <Icon color="grey" name={ICONS.user_ok}
                                    size={[20, 20]} />
                                <span className="margin-left-5">
                                    {substituteZeroWithLine(recordOffer && recordOffer.candidateCount)}
                                </span>

                            </div>
                            <div style={{ width: "120px", height: "100px", margin: "-80px 0 0 0", border: "1px solid rgba(14, 14, 44, 0.15)" }}>

                                {!recordOffer.anonymous ?
                                    <ImgComponent logoPath={recordOffer.companyLogo} />
                                    :
                                    <div className="noLogo">
                                        <img src={GreyLogo} />
                                    </div>
                                }
                            </div>
                            <div className="flex-row">
                                <div onClick={closeDetailCard} className={"pointer"}>
                                    <Icon name={ICONS.close} size={[20, 20]} className="icon" color={"#868696"} />
                                </div>
                            </div>
                        </div>
                        <div className="flex-center flex-column margin-bottom-30">
                            <div className="weight-700 size-22"> {recordOffer && recordOffer.offertTitle}</div>
                            {!recordOffer.anonymous ?
                                <div className="weight-40 size-14 underlined pointer" onClick={() => setField(recordOffer)}>
                                    {recordOffer && recordOffer.uiCompanyLocationResponseData && recordOffer.uiCompanyLocationResponseData.name}
                                </div>
                                :
                                <div className="opacity-50 underlined size-12">
                                    Azienda Anonima
                                </div>
                            }
                        </div>
                        <Divider />
                        <div className="margin-top-30 margin-bottom-30">
                            <div>
                                {recordOffer && recordOffer.location &&
                                    <div>
                                        <span className="weight-700 margin-right-5">Dove:</span>
                                        <span>{recordOffer.location}</span>
                                    </div>
                                }
                            </div>
                            <div>
                                {recordOffer && recordOffer.impegnoRichiesto &&
                                    <div>
                                        <span className="weight-700 margin-right-5">Tempo:</span>
                                        <span>{recordOffer.impegnoRichiesto}</span>
                                    </div>
                                }
                            </div>
                            <div>
                                {recordOffer && recordOffer.offertInsertMode &&
                                    <div>
                                        <span className="weight-700 margin-right-5">Contratto:</span>
                                        <span>{recordOffer.offertInsertMode}</span>
                                    </div>
                                }
                            </div>
                            <div>
                                {recordOffer && recordOffer.workPlace &&
                                    <div>
                                        <span className="weight-700 margin-right-5">Modalità:</span>
                                        <span>{recordOffer.workPlace}</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="flex-row flex-center-between">
                            <div className="margin-bottom-30">
                                {/*<Button className={"padding-5 border-radius-3 margin-right-10"}>*/}
                                {/*    <Icon name={ICONS.facebook} size={[20, 20]} color={"#1877F2"}/>*/}
                                {/*</Button>*/}
                                {/*<Button className={"padding-5 border-radius-3 margin-right-10"}>*/}
                                {/*    <Icon name={ICONS.twitter} size={[20, 20]} color={"#1DA1F2"}/>*/}
                                {/*</Button>*/}
                                {recordOffer && recordOffer.company && recordOffer.company.linkedin &&
                                    <Button className={"padding-5 border-radius-3 margin-right-10"}>
                                        <a href={recordOffer.company.linkedin} target="_blank">
                                            <Icon name={ICONS.linkedin} size={[20, 20]} color={"#1DA1F2"} />
                                        </a>
                                    </Button>
                                }
                                {recordOffer && recordOffer.company && recordOffer.company.webPage &&
                                    <Button className={"padding-5 border-radius-3"}>
                                        <a href={recordOffer.company.webPage} target="_blank">
                                            <Icon name={ICONS.link} size={[20, 20]} className={"opacity-50"} />
                                        </a>
                                    </Button>
                                }
                            </div>
                        </div>
                        {recordOffer.description ?
                            <div>
                                <Divider>
                                    <div className="size-12 opacity-50">Descrizione</div>
                                </Divider>
                                <p dangerouslySetInnerHTML={{ __html: recordOffer.description }} />
                            </div> : null}
                    </Card>
                </div>
            }
        </div>
    )
}

export default ListMyCandidature