import actions from "./actions"
import authActions from "../auth/actions";

const initState = {
    isOpenModalForChangePassword: false,
}

export default function accountReducer(state = initState, action) {
    switch (action.type) {
        case authActions.LOGOUT:
            return {...initState}
        case actions.OPEN_MODAL_CHANGE_PASSWORD:
            return{
                ...state,
                isOpenModalForChangePassword: true
            }
        case actions.CLOSE_MODAL_CHANGE_PASSWORD:
            return{
                ...state,
                isOpenModalForChangePassword: false
            }
        default:
            return state;
    }
}