import {notification} from "antd";
import moment from "moment";

export function isTokenExpired() {

    let expires_at = parseInt(localStorage.getItem('expires_at'));
    if (!expires_at) {
        expires_at = 0
    }
    let currentTimestamp = new Date().getTime();

    if (currentTimestamp >= expires_at) {
        return true;
    } else {
        return false;
    }
}

export function getToken() {
    const idToken = localStorage.getItem('id_token');
    return idToken;
}

export function createNotification(type, message) {
    notification[type]({
        message: type,
        description: message,
    });
};

export function clearToken() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('timestamp');
    localStorage.removeItem('rememberMe');

}

export function removeTag(myString) {
    return myString.replace(/<[^>]*>?/gm, '');
}

export function getRole() {
    return localStorage.getItem('role')
}


export function isValidUrl(string) {
    let res = string.match(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[.\!\/\\w]*))?)/ig);
    return !!res
}


export function getUserId() {
    return localStorage.getItem("id")
}

export function getAge(dateString) {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export function createCsv(array, headers, fileName = "default.csv") {
    let csvRows = []
    if(!headers){
        headers = Object.keys(array[0])
    }
    csvRows.push(headers.join(','))

    array.forEach((object, index) => {
        const values = sanitizeRowForCSV(Object.values(object))
        csvRows.push(values)
    })

    let data = csvRows.join('\n')


    const blob = new Blob([data], {type: "text/csv"})

    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')

    a.setAttribute('href', url)

    a.setAttribute('download', fileName)

    a.click()
}

function sanitizeRowForCSV(row) {
    return row.map(sanitizeForCSV).join(',');
}

function sanitizeForCSV(value) {
    if(typeof value === "string"){
        value = value.replace(/"/g, '""');

        if (value.includes(',') || value.includes('\n')) {
            value = `"${value}"`;
        }
    }

    return value;
}

export const getClickableLink = link => {
    return link?.startsWith("http://") || link?.startsWith("https://") ?
        link
        : `//${link}`;
};

export function getOfferDate(timestamp){
    if(moment(timestamp).add(7, "days") > moment()){
        return moment(timestamp).fromNow()
    }else{
        return moment(timestamp).format("DD MMM YYYY")
    }
}

