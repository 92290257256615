import React, {useEffect} from "react";
import "src/component/Layout/Login/LoginUser/LoginUser.scss"
import Icon from "src/component/Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {InputLogin} from "../../../Useful/Input/InputLogin";
import {Switch} from "../../../Useful/Switch";
import actions from "src/redux/auth/actions";
import {ButtonUser} from "../../../Useful/Button/ButtonUser";
import {Form} from "antd";
import UserPicture from "../../../../assets/image/PictureLoginUser.png";
import mobileLogo from "src/assets/logo/logo-VT-blue-mobile.svg";
import {ROLES} from "../../../../utils/utility";
import {FormattedMessage} from "react-intl";
import AdvantagesUser from "src/assets/svg/AdvantagesUser.svg"

const {login} = actions

function LoginUser() {

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const isLoggedIn = useSelector(state => state.AuthReducer.idToken)
    const role = useSelector(state => state.AuthReducer.role)
    const isButtonLoading = useSelector(state => state.AuthReducer.isButtonLoading)
    const [form] = Form.useForm();


    useEffect(() => {
        if (isLoggedIn && role === ROLES.USER)
            navigate("/user/dashboard")
    }, [isLoggedIn])

    const [email, setEmail] = React.useState(null)
    const [password, setPassword] = React.useState(null)
    const [rememberMe, setRememberMe] = React.useState(false)
    const [toggleType, setToggleType] = React.useState(false)
    const [toggleIconPassword, setToggleIconPassword] = React.useState(false)

    const goTo = (page, type) => {
        if (page === "registration" && type === "user") {
            navigate("/user/registration", {replace: true})
        } else {
            navigate("/user/recovery", {replace: true})
        }
    }

    const goToBack = () => {
        navigate("/", {replace: true})
    }

    const handleClick = () => {
        if (email && password) {
            let data = {
                email: email,
                password: password,
                context: "user"
            }
            dispatch(login(data))
        }
    }

    const ShowPassword = () => {
        setToggleType(!toggleType)
        setToggleIconPassword(!toggleIconPassword)
    }

    return (
        <div className={"login-container-user"}>
            <div className={"login-content"}>
                <div className={"flex"} style={{flex: 1, gap: "50px"}}>
                    <div className={"advantagesSection flex-center"} style={{flex: "1 1 50%"}}>
                        <div style={{width: "80%"}}>
                            <div className={"platformAdvantages"}>
                                <div className={"platformAdvantagesHeader size-18"}>
                                    <FormattedMessage id="UserAdvantages_Header" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                </div>
                                <div className={"platformAdvantagesDescription size-16"}>
                                    <FormattedMessage id="UserAdvantages_SubHeader" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                </div>
                            </div>
                            <div className={"flex-column gap-32"}>
                                <div className={"flex-start flex-align-center gap-16"}>
                                    <img src={AdvantagesUser}/>

                                    <div className={"advantages size-16 merriWeather"}>
                                        <FormattedMessage id="UserAdvantages_1" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                    </div>
                                </div>

                                <div className={"flex-start flex-align-center gap-16"}>
                                    <img src={AdvantagesUser}/>

                                    <div className={"advantages size-16 merriWeather"}>
                                        <FormattedMessage id="UserAdvantages_2" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                    </div>
                                </div>

                                <div className={"flex-start flex-align-center gap-16"}>
                                    <img src={AdvantagesUser}/>

                                    <div className={"advantages size-16 merriWeather"}>
                                        <FormattedMessage id="UserAdvantages_3" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="image margin-top-30">
                                <img src={UserPicture} className={"side-image"}/>
                            </div>
                            <div className={"technicalSupport"}>
                                <div><FormattedMessage id="TechnicalSupport_User"/></div>
                                <div><FormattedMessage id="TechnicalSupport_1"/></div>
                                <div><FormattedMessage id="TechnicalSupport_2"/></div>
                                <div><FormattedMessage id="TechnicalSupport_3"/></div>
                            </div>
                        </div>
                    </div>

                    <div style={{flex: "1 1 50%"}}>
                        <div className={"containerLoginUserForm"}>
                            <header>
                                <div className="header_mobile_user">
                                    <div className={"back"}>
                                        <span onClick={goToBack}><Icon name={ICONS.arrow_2} className="pointer" size={[20, 20]}/></span>
                                        <div className="p_back margin-5">Indietro</div>
                                    </div>
                                    <h1> Login </h1>
                                    <a href={"https://www.vt-job.it"}>
                                        <img className={"user-mobile-logo"} src={mobileLogo}/>
                                    </a>
                                </div>
                                <div className="header_login">
                                    <p className={"corp_100"}>Buongiorno!</p>
                                    <p> Inserisci le tue credenziali per entrare</p>
                                </div>
                            </header>
                            <div className={"login_recovery"}>
                                <p>Non hai un account? <a className={"pointer corp2_100 weight-700 font-size-16"} onClick={() => goTo("registration", "user")}> Registrati›</a></p>
                            </div>
                            <main>
                                <Form
                                    form={form}
                                    layout={"vertical"}
                                    onFinish={handleClick}
                                >
                                    <div className={"inputField"}>
                                        <Form.Item
                                            name={"email"}
                                            type={"email"}
                                            label={"Email"}
                                            rules={[{type: 'email', message: false}]}
                                            className="weight-600 size4-14 inline-block width-100 margin-right-24"
                                        >
                                            <InputLogin className={"input"} prefix={<Icon name={ICONS.mail} size={[20, 20]}/>} placeholder="inserire l'email" onChange={(e) => setEmail(e.target.value)}/>
                                        </Form.Item>
                                        <Form.Item
                                            name={"password"}
                                            label={<FormattedMessage id="UserRegistration_main_password"/>}
                                            className="weight-600 size4-14 inline-block width-100 margin-right-24"
                                        >
                                            <InputLogin type={toggleType ? "text" : "password"} className={"input"} prefix={<Icon name={ICONS.lock} size={[20, 20]}/>}
                                                        suffix={<div onClick={(e) => ShowPassword()}><Icon name={toggleIconPassword ? ICONS.eye : ICONS.eye_hide} className="flex-center pointer" color="#208AC6" size={[20, 20]}/></div>} placeholder="Inserire la password"
                                                        onChange={(e) => setPassword(e.target.value)}/>
                                        </Form.Item>

                                    </div>

                                    <div className={"section"}>
                                        <Switch checkedChildren={<Icon name={ICONS.ok} size={[20, 20]}/>}
                                                unCheckedChildren={<Icon name={ICONS.close_switch} size={[20, 20]}/>}
                                        />
                                        <p> Ricorda sessione </p>

                                    </div>
                                    <Form.Item>
                                        <ButtonUser icon={<Icon name={ICONS.tik} className="margin-5 opacity-50" size={[20, 20]}/>} htmlType="submit" type="primary" block className={"save flex-center"} loading={isButtonLoading}>
                                            Accedere
                                        </ButtonUser>
                                    </Form.Item>
                                </Form>
                            </main>
                            <footer>
                                <a className={"pointer"} onClick={() => goTo("recovery", "user")}>Non ricordi le tue credenziali? </a>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginUser
