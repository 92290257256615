import React from "react";
import {firstLetters, ImgComponent} from "src/utils/utility";
import {Col} from "antd"

function QuestionTablet(props){

    const {firstName, lastName, email, record} = props

    return(
        <div>
            <Col span={24} className="flex-center">
                <div className="merriWeather size-16 weight-700 padding-bottom-24">Stai per candidarti a questa offerta</div>
            </Col>
            <Col span={24}>
                <div className="width-100">
                    <div className="flex-row-center">
                        <div className="left-point-button">
                            <div className="left-point-button-inner flex-center">
                                <div className="flex-column flex-center">
                                    <div className="avatar weight-700 size-14 flex-center margin-bottom-5">{firstLetters(firstName,lastName)}</div>
                                    <div className="title padding-5">{firstName + " " + lastName}</div>
                                    <div className="subtitle padding-5">{email}</div>
                                </div>
                            </div>
                        </div>
                        <div className="right-point-button">
                            <div className="right-point-button-inner flex-center">
                                <div className="flex-column flex-center">
                                    <div style={{width: "72px", height: "55px", border: "1px solid rgba(14, 14, 44, 0.15)"}}>
                                        <ImgComponent logoPath={record.company.logoPath}/>
                                    </div>
                                    <div className="title padding-5">{record.title}</div>
                                    <div className="subtitle padding-5">{record.company.companyName}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </div>
    )
}

export default QuestionTablet