import authSaga from "./auth/saga";
import candidatureSaga from "./candidature/saga";
import companySaga from "./company/saga";
import employeeSaga from "./employee/saga";
import locationSaga from "./location/saga";
import userProfileSaga from "./userProfile/saga";
import offerSaga from "./offer/saga";
import preferenceSaga from "./preference/saga";
import notificationSaga from "./notification/saga";
import email from "src/redux/email/saga";
import adminSaga from "src/redux/admin/saga";
import settingsSaga from "src/redux/settings/saga";
import landingSaga from "src/redux/landing/saga";

import {all} from 'redux-saga/effects';

export function* rootSaga() {
    yield all([
        authSaga(),
        candidatureSaga(),
        companySaga(),
        employeeSaga(),
        locationSaga(),
        userProfileSaga(),
        offerSaga(),
        preferenceSaga(),
        email(),
        notificationSaga(),
        adminSaga(),
        settingsSaga(),
        landingSaga()
    ]);
}
