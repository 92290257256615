import React from "react";
import {Input as AntInput} from "antd";
import styled from "styled-components"

export const InputLogin = styled(AntInput)`
  &.ant-input, &.ant-input-affix-wrapper{
    height: ${props => props.height ? props.height : "48px"};
    width: ${props => props.width ? props.width : "100%"};
    max-width: ${props => props.maxWidth ? props.maxWidth : "100%"};
    padding: 8px 16px;
    border: none;
    -webkit-appearance: none;
    -webkit-box-shadow: 0 0 0 1px #cfcfd5;
    box-shadow: 0 0 0 1px #cfcfd5;
    border-radius: 3px; 
    
    &:hover{
      box-shadow: 0 0 0 1px #868696;
      border-radius: 3px;
    }

    &:focus{
      box-shadow: inset 0 0 0 2px #208ac6 !important;
    }
    
    &:error{
      box-shadow: 0 0 0 1px #FC4853 !important;
      border-color: #FC4853 !important;
    }

    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover{
      box-shadow: 0 0 0 1px #FC4853;
      border-color: #FC4853 !important;
    }
    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
      box-shadow: 0 0 0 1px #FC4853;
      border-color: #FC4853 !important;
    }

    
  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover{
    box-shadow: 0 0 0 1px #FC4853;
    border-color: #FC4853 !important;
  }
    
  }  
  &.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper{
    box-shadow: 0 0 0 1px #FC4853;
    border-color: #FC4853 !important;
  } 

  &.ant-input-affix-wrapper-focused{
    box-shadow: 0 0 0 2px #208AC6 !important;
  }

  &.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper-focused{
    box-shadow: 0 0 0 1px #FC4853 !important;
    border-color: #FC4853 !important;
  }
  
  `

