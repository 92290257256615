import React, {useEffect, useState} from "react";
import "src/component/Layout/Drawer/OfferUser/OffertUserContentStyle.scss";
import {Col, DatePicker, Form, message, Row, Space, Upload, Skeleton} from "antd";
import {ICONS} from "src/assets/sprite/iconsNames";
import {Input} from "../../../Useful/Input/Input";
import Icon from "../../../Icon/Sprite";
import {Select} from "../../../Useful/Select/Select";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import actionsUser from "src/redux/userProfile/actions"
import actionsLocation from "src/redux/location/actions"
import {ButtonGhost} from "../../../Useful/Button/ButtonGhost";
import {InputLogin} from "../../../Useful/Input/InputLogin";
import TextArea from "antd/es/input/TextArea";
import Languages from "./CustomDrawerUser/Languages";
import GraduateLevels from "./CustomDrawerUser/GraduateLevels";
import {Checkbox} from "../../../Useful/Checkbox/Checkbox";
import dayjs from "dayjs";
import { deleteUserProfile } from "../../../../redux/userProfile/saga";

export function OffertUserContent(props) {

    let dispatch = useDispatch()
    const dateFormat = "DD/MM/YYYY";

    const {update, save, getImageSourceUser, getCvSourceUser, saveAccount, getUser} = actionsUser;
    const {getAllProvince, getCityByProvince} = actionsLocation;


    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const {TextArea} = Input;

    const [jobState, setJobState] = useState(null)

    const [isProvinceSelected, setIsProvinceSelected] = useState(false)
    const [provinceSelected, setProvinceSelected] = useState(null)

    const [imageList, setImageList] = useState([])

    const [fileList, setFileList] = useState([])

    const [isGraduateLevelsNull, setIsGraduateLevelsNull] = useState(false)
    const [graduateLevels, setGraduateLevels] = useState([{id: Math.random(), qualification: null, qualificationLevel: null}])

    const [isLanguagesNull, setIsLanguagesNull] = useState(false)
    const [languages, setLanguages] = useState([{id: Math.random(), spokenLanguages: null, spokenLanguagesLevel: null}])

    const logo = useSelector(state => state.UserProfile.logo)
    const fileBlob = useSelector(state => state.UserProfile.fileBlob)
    const fileName = useSelector(state => state.UserProfile.fileName)
    const fileType = useSelector(state => state.UserProfile.fileType)
    const fileBlobCv = useSelector(state => state.UserProfile.fileBlobCv)
    const fileNameCv = useSelector(state => state.UserProfile.fileNameCv)
    const fileTypeCv = useSelector(state => state.UserProfile.fileTypeCv)
    const cv = useSelector(state => state.UserProfile.cv)
    const userInfo = useSelector(state => state.UserProfile.userInfo)

    const province = useSelector(state => state.Location.province)
    const city = useSelector(state => state.Location.city)

    const uploadButton = (
        <div className="uploadSvg">
            <Icon name={ICONS.user} size={[20, 20]} color={"#868696"}/>
        </div>
    );

    const error = (type) => {
        switch (type) {
            case "graduateLevel":
                messageApi.open({
                    type: 'error',
                    content: 'Inserire almeno un livello di istruzione'
                });
                break
            case "languages":
                messageApi.open({
                    type: 'error',
                    content: 'Inserire almeno una lingua conosciuta'
                });
                break
        }
    };

    function checkFile(_, value) {

        if (fileList.length !== 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error())
    }

    useEffect(() => {
        let citySelected;
        if (props.userProfile) { 
            if (props.userProfile.qualificationList && props.userProfile.qualificationList !== 0) {
                setGraduateLevels(props.userProfile.qualificationList && props.userProfile.qualificationList.map((element) => {
                    return ({id: Math.random(), qualification: element.qualification, qualificationLevel: element.qualificationLevel})
                }))
            }
            if (props.userProfile.languageList && props.userProfile.languageList !== 0) {
                setLanguages(props.userProfile.languageList && props.userProfile.languageList.map((element) => {
                    return ({id: Math.random(), spokenLanguages: element.spokenLanguages, spokenLanguagesLevel: element.spokenLanguagesLevel})
                }))
            }
            if (props.userProfile.image) {
                dispatch(getImageSourceUser({name: props.userProfile.image}))
            }
            if (props.userProfile.cv) {
                dispatch(getCvSourceUser({name: props.userProfile.cv}))
            }
            if (props.userProfile.city && props.userProfile.city.label) {
                citySelected = props.userProfile.city.label
            } else {
                citySelected = props.userProfile.city
            }
            form.setFieldsValue({
                cv: props.userProfile.cv && props.userProfile.cv,
                profileImage: props.userProfile.image && props.userProfile.image,
                jobState: props.userProfile.jobState && props.userProfile.jobState,
                workingRole: props.userProfile.workingRole && props.userProfile.workingRole,
                qualificationList: props.userProfile.qualificationList && props.userProfile.qualificationList,
                name: props.userProfile.firstName && props.userProfile.firstName,
                surname: props.userProfile.lastName && props.userProfile.lastName,
                email: props.userProfile.email && props.userProfile.email,
                telephone: props.userProfile.telephone && props.userProfile.telephone,
                birthday: props.userProfile.birthday !== 0 ? dayjs(props.userProfile.birthday) : undefined,
                residence: props.userProfile.residence && props.userProfile.residence,
                address: props.userProfile.address && props.userProfile.address,
                province: props.userProfile.province && props.userProfile.province,
                city: citySelected && citySelected,
                cap: props.userProfile.cap ? props.userProfile.cap : undefined,
                description: props.userProfile.description && props.userProfile.description,
                languageList: props.userProfile.languageList && props.userProfile.languageList,
                linkedin: props.userProfile.linkedin && props.userProfile.linkedin,
                portfolio: props.userProfile.portfolio && props.userProfile.portfolio
            })
        }
    }, [props.userProfile])

    useEffect(() => {
        if (props.userProfile && props.userProfile.image) { 
            setImageList([{uid: Math.random(), url: logo, originFileObj: new File([fileBlob], fileName, {type: fileType})}])
        }
    }, [fileBlob])

    useEffect(() => {
        if (cv) {
            // setFileList([{uid: Math.random(), url: cv, originFileObj: new File([fileBlobCv], fileNameCv, {type: fileTypeCv})}])
            setFileList([{uid: Math.random(), url: cv, name: fileNameCv, status: "done"}])
        }
    }, [cv])

    function checkJobState(value) {
        if (value === "Occupato") {
            setJobState(value)
        } else {
            setJobState(null)
        }
    }

    useEffect(() => {
        if(!props.userProfile) dispatch(getUser(localStorage.getItem("id")))
    }, [])

    function validateGraduation(_, value) {
        let tempArray = []
        value.map((element) => {
            if (!element.qualificationLevel || element.qualificationLevel !== "Licenza Media" && !element.qualification) {
                tempArray.push(element)
            }
        })
        if (tempArray.length === 0) {
            return Promise.resolve()
        } else {
            error("graduateLevel")
            return Promise.reject(new Error())
        }
    }

    function validateLanguage(_, value) {
        let tempArray = []
        value.map((element) => {
            if (!element.spokenLanguages || !element.spokenLanguagesLevel){
                tempArray.push(element)
            }
        })
        if (tempArray.length === 0) {
            return Promise.resolve()
        } else {
            error("languages")
            return Promise.reject(new Error())
        }
    }

    return (
        <>
            <Form
                id={"firstStepCandidatureUserForm"}
                form={form}
                layout="vertical"
                scrollToFirstError={{block: 'center'}}
                style={{width: "100%"}}
                // onFinishFailed={(values) => {}}
                onFinish={(values) => {
                    values.qualificationList = graduateLevels.map((item) => {
                        return ({qualification: item.qualification, qualificationLevel: item.qualificationLevel})
                    })
                    values.languageList = languages.map((item) => {
                        return ({spokenLanguages: item.spokenLanguages, spokenLanguagesLevel: item.spokenLanguagesLevel})
                    })
                    values.birthday = values.birthday.unix() * 1000
                    if (imageList.length !== 0) {
                        values.profileImage = imageList[0].originFileObj
                    } else {
                        delete values.profileImage
                    }
                    values.cv = fileList[0].originFileObj
                    if (props.userEditAccount && props.userProfile && props.userProfile.userProfileId) {
                        dispatch(update(props.userProfile.userProfileId, values))
                    } else if (props.userEditAccount && !props.userProfile) {
                        dispatch(saveAccount(values))
                    } else {
                        dispatch(save(values))
                    }
                }}
            >
                {contextHolder}
                <div className="OfferUserContent">
                    <div className="OfferUserData border margin-bottom-40 padding-40" id="cv">
                        <Space
                            direction={"vertical"}
                            size={24}
                        >
                            <Row gutter={[0, 24]}>
                                <Col span={24}>
                                    <div className="red size-18 weight-700">
                                        <FormattedMessage id="UserDetails_cv"/>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className={"flex-row"}>
                                        <Form.Item
                                            name="cv"
                                            label={<FormattedMessage id="UserDetails_loading_cv"/>}
                                            className={"weight-600 size-14"}
                                            rules={[
                                                {
                                                    validator: checkFile,
                                                    required: true,
                                                    message: false,
                                                },
                                            ]}
                                        >
                                            <Upload
                                                className="upload-user"
                                                type={"file"}
                                                maxCount={1}
                                                accept={"application/pdf"}
                                                fileList={fileList}
                                                showUploadList={{showPreviewIcon: false}}
                                                onRemove={() => {
                                                    setFileList([])
                                                }}
                                                onChange={(e) => {
                                                    setFileList(e.fileList)
                                                }}
                                                customRequest={(test) => {
                                                    setTimeout(() => {
                                                        test.onSuccess(() => "ok",);
                                                    }, 0);
                                                }}
                                            >
                                                <ButtonGhost className={"flex-center"} style={{width: "138px"}} icon={<Icon name={ICONS.file_add} size={[20, 20]}/>}>
                                                    <FormattedMessage id="UserDetails_loadButton"/>
                                                </ButtonGhost>
                                            </Upload>
                                        </Form.Item>
                                        <div className={"weight-400 size-13 opacity-7 margin-top-30 height-30-px"} style={{position: "absolute", left: 180}}>
                                            <Space
                                                direction={"vertical"}
                                                size={0}>
                                                <div>
                                                    <FormattedMessage id="UserDetails_format"/>
                                                </div>
                                                <div>
                                                    <FormattedMessage id="UserDetails_weight"/>
                                                </div>
                                            </Space>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="profileImage"
                                        label={<FormattedMessage id="UserDetails_avatar"/>}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: false,
                                                message: false,
                                            }
                                        ]}
                                    >
                                        <div className="upload">
                                            <Upload
                                                accept={"image/png, image/jpeg"}
                                                listType="picture-card"
                                                fileList={imageList}
                                                maxCount={1}
                                                showUploadList={{showPreviewIcon: false}}
                                                onRemove={() => {
                                                    setImageList([])
                                                    form.setFieldsValue({profileImage: []})
                                                }}
                                                onChange={(e) => {
                                                    setImageList(e.fileList)
                                                }}
                                                customRequest={(test) => {
                                                    setTimeout(() => {
                                                        test.onSuccess(() => "ok",);
                                                    }, 0);
                                                }}
                                            >
                                                {imageList.length !== 0 ? null : uploadButton}
                                            </Upload>
                                            <div className="uploadInfo flex-center margin-left-16">
                                                <div className="weight-400 size-13 opacity-7">
                                                    <Space
                                                        direction={"vertical"}
                                                        size={0}>
                                                        <div>
                                                            <FormattedMessage id="CompanyDetails_imageFormat"/>
                                                        </div>
                                                        <div>
                                                            <FormattedMessage id="CompanyDetails_imageDimensions"/>
                                                        </div>
                                                        <div>
                                                            <FormattedMessage id="CompanyDetails_weight"/>
                                                        </div>
                                                    </Space>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name={"jobState"}
                                        label={<FormattedMessage id="UserDetails_jobState"/>}
                                        className="weight-600 size-14"
                                    >
                                        <Select
                                            getPopupContainer={trigger => trigger.parentNode}
                                            suffixIcon={<Icon name={ICONS.arrow} size={[20, 20]} color={"#208AC6"}/>}
                                            onChange={checkJobState}
                                            placeholder={"- Seleziona -"}
                                            options={[
                                                {
                                                    value: 'Occupato',
                                                    label: 'Sono occupato',
                                                    className: "weight-600 size-14",
                                                },
                                                {
                                                    value: 'In cerca di lavoro',
                                                    label: 'In cerca di nuova occupazione',
                                                    className: "weight-600 size-14",
                                                },
                                                {
                                                    value: 'Disoccupato',
                                                    label: 'Disoccupato',
                                                    className: "weight-600 size-14",
                                                },
                                                {
                                                    value: 'Inoccupato',
                                                    label: 'Inoccupato',
                                                    className: "weight-600 size-14",
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                {jobState &&
                                <Col span={24}>
                                    <Form.Item
                                        name={"workingRole"}
                                        label={<FormattedMessage id="UserDetails_jobState_now"/>}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: !!jobState,
                                                message: false,
                                            }
                                        ]}
                                    >
                                        <InputLogin prefix={<Icon name={ICONS.user} size={[20, 20]} className={"opacity-50"}/>} placeholder={"Grafic designer, Developer, CMO, HR..."}/>
                                    </Form.Item>
                                </Col>}
                                <Col span={24}>
                                    <Form.Item
                                        name={"qualificationList"}
                                        trigger={"setGraduateLevels"}
                                        rules={[
                                            {
                                                validator: validateGraduation,
                                                required: true,
                                                message: false,
                                            }
                                        ]}
                                    >
                                        <GraduateLevels graduateLevels={graduateLevels} setGraduateLevels={setGraduateLevels}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Space>
                    </div>

                    <div className="companyDescription border margin-bottom-40 padding-40" id="personalData">
                        <Space
                            direction={"vertical"}
                            size={24}
                        >
                            <Row gutter={[24, 24]}>
                                <Col span={24}>
                                    <div className="red size-18 weight-700">
                                        <FormattedMessage id="UserDetails_personalData"/>
                                    </div>
                                </Col>
                                <Col xl={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name={"name"}
                                        label={"Nome"}
                                        initialValue={localStorage.getItem('firstName')}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: true,
                                                message: false,
                                            }
                                        ]}
                                    >
                                        <InputLogin string={""} placeholder="Nome"/>
                                    </Form.Item>
                                </Col>
                                <Col  xl={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name={"surname"}
                                        label={"Cognome"}
                                        initialValue={localStorage.getItem('lastName')}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: true,
                                                message: false,
                                            }
                                        ]}
                                    >
                                        <InputLogin string={""} placeholder="Cognome"/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name={"email"}
                                        label={"Email"}
                                        initialValue={localStorage.getItem('email')}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: true,
                                                message: false,
                                            }
                                        ]}
                                    >
                                        <InputLogin string={""} placeholder="Indirizzo Email"/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name={"telephone"}
                                        label={"Telefono"}
                                        className="weight-600 size-14"
                                        rules={[
                                            {max: 15, message: false},
                                            {min: 5, message: false},
                                            {pattern: "^([-]?[1-9][0-9]*|0)$", message: false},
                                            {required: true, message: false},
                                        ]}
                                    >
                                        <InputLogin string={""} prefix={<Icon name={ICONS.tel} size={[20, 20]} className="opacity-30"/>} placeholder="Inserisci il tuo cellulare per poter contattarti"/>
                                    </Form.Item>
                                </Col>
                                <Col xl={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name={"birthday"}
                                        label={"Data di nascita"}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: true,
                                                message: false,
                                            }
                                        ]}
                                    >
                                        <DatePicker
                                            style={{
                                                width: "100%",
                                                height: "48px"
                                            }}
                                            format={dateFormat}
                                            placeholder="Seleziona una data"
                                            suffixIcon={<Icon name={ICONS.calendar} className="margin-5" color="898996" size={[20, 20]}/>}
                                            disabledDate={(currentDate) => {
                                                return currentDate.isAfter(dayjs(Date.now()).subtract(16, 'year').valueOf())
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xl={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name={"residence"}
                                        label={"Residenza attuale"}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: true,
                                                message: false,
                                            }
                                        ]}
                                    >
                                        <InputLogin string={""} placeholder="Residenza"/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name={"address"}
                                        label={"Indirizzo"}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: true,
                                                message: false,
                                            }
                                        ]}
                                    >
                                        <InputLogin string={""} prefix={<Icon name={ICONS.pin} size={[20, 20]} className={"opacity-30"}/>} placeholder="Inserisci l'indirizzo completo"/>
                                    </Form.Item>
                                </Col>
                                <Col xl={9} md={9} sm={24} xs={24}>
                                    <Form.Item
                                        name="province"
                                        label={"Provincia"}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: true,
                                                message: false,
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="-Seleziona-"
                                            autoComplete="nope"
                                            allowClear
                                            showSearch
                                            style={{height: 48}}
                                            suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]}/>}
                                            filterOption={false}
                                            onClear={() => {
                                                setIsProvinceSelected(false)
                                                form.setFieldsValue({...form.getFieldsValue, city: undefined})
                                            }}
                                            options={province && province.map(element => ({
                                                id: element.id,
                                                value: element.province,
                                                label: element.province
                                            }))}
                                            onFocus={() => {
                                                dispatch(getAllProvince({
                                                    page: 0,
                                                    size: 200
                                                }))
                                            }}
                                            onSearch={(value) => {
                                                dispatch(getAllProvince({
                                                    page: 0,
                                                    size: 200,
                                                    province: value
                                                }))
                                            }}
                                            onSelect={(value) => {
                                                setIsProvinceSelected(true)
                                                setProvinceSelected(value)
                                                form.setFieldsValue({...form.getFieldsValue, city: undefined})
                                                dispatch(getCityByProvince({
                                                    page: 0,
                                                    size: 200,
                                                    province: value
                                                }))
                                            }}
                                        />

                                    </Form.Item>
                                </Col>
                                <Col xl={9} md={9} sm={24} xs={24}>
                                    <Form.Item
                                        name="city"
                                        label={"Città"}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: true,
                                                message: false,
                                            },
                                        ]}
                                    >
                                        <Select
                                            autoComplete="nope"
                                            allowClear
                                            showSearch
                                            suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]}/>}
                                            filterOption={false}
                                            style={{height: 48}}
                                            placeholder="-Seleziona-"
                                            disabled={!isProvinceSelected}
                                            options={
                                                city && city.map(element => ({
                                                    id: element.id,
                                                    value: element.city,
                                                    label: element.city
                                                }))}
                                            onSearch={(value) => {
                                                dispatch(getCityByProvince({
                                                    page: 0,
                                                    size: 350,
                                                    province: provinceSelected,
                                                    city: value,
                                                }))
                                            }}
                                            onFocus={() => {
                                                dispatch(getCityByProvince({
                                                    page: 0,
                                                    size: 350,
                                                    province: provinceSelected,
                                                }))
                                            }}
                                        />

                                    </Form.Item>
                                </Col>
                                <Col xl={6} md={6} sm={24} xs={24}>
                                    <Form.Item
                                        name={"cap"}
                                        label={"CAP"}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                required: true,
                                                pattern: /^[0-9]{5}$/,
                                                message: false,
                                            }
                                        ]}
                                    >
                                        <InputLogin string={""} placeholder="CAP" maxLength={5}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Space>
                    </div>

                    <div className="location border margin-bottom-40 padding-40" id="languages">
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <div className="flex-center-between red size-18 weight-700 margin-bottom-20">
                                    <div>
                                        *<FormattedMessage id="UserDetails_known_languages"/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Form.Item
                            name={"languageList"}
                            trigger={"setLanguages"}
                            label={<FormattedMessage id="UserDetails_known_languages"/>}
                            rules={[
                                {
                                    validator: validateLanguage,
                                    required: true,
                                    message: false,
                                }
                            ]}
                        >
                            <Languages languages={languages} setLanguages={setLanguages}/>
                        </Form.Item>
                    </div>
                    <div className="responsible border margin-bottom-40 padding-40" id="personalDescription">
                        <Row gutter={[0, 24]}>
                            <Col span={24}>
                                <div className="red size-18 weight-700">
                                    <FormattedMessage id="UserDetails_personal_description"/>
                                </div>
                                <div className="size-12 weight-400 margin-top-10 dark-light">
                                    <FormattedMessage id="UserDetails_personal_description_tips"/>
                                </div>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="description"
                                    label={<FormattedMessage id="UserDetails_description"/>}
                                    className="weight-600 size-14"
                                    rules={[
                                        {
                                            message: false,
                                        },
                                    ]}
                                >
                                    <TextArea style={{minWidth: "100%"}} rows={10}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    <div className="otherInformation border margin-bottom-40 padding-40" id="otherInformation">
                        <Space
                            direction={"vertical"}
                            size={24}
                        >
                            <Row gutter={[0, 24]}>
                                <Col span={24}>
                                    <div className="red size-18 weight-700">
                                        <FormattedMessage id="UserDetails_other"/>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="linkedin"
                                        label={<FormattedMessage id="UserDetails_profile_linkedin"/>}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                message: false,
                                                pattern: /^(https:\/\/)([\w]+\.)linkedin\.com/,
                                            },
                                        ]}
                                    >
                                        <InputLogin placeholder="https://www.linkedin/in/tuo-account"/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="portfolio"
                                        label={<FormattedMessage id="UserDetails_portfolio"/>}
                                        className="weight-600 size-14"
                                        rules={[
                                            {
                                                message: false,
                                                pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[.\!\/\\w]*))?)/ig
                                            },
                                        ]}
                                    >
                                        <InputLogin placeholder="Inserisci un link al tuo sito o portfolio"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Space>
                    </div>
                    {!props.userEditAccount &&
                    <div>
                        <Row>
                            <Col xl={1} md={2} sm={2} xs={2}>
                                <Form.Item
                                    name={"policy"}
                                    valuePropName={"checked"}
                                    rules={[
                                        {
                                            required: true,
                                            message: false
                                        }
                                    ]}
                                >
                                    <Checkbox classname="margin-left-10"/>
                                </Form.Item>
                            </Col>
                            <Col xl={23} md={22} sm={22} xs={22} className={"flex-row"}>
                                <div className="red margin-top-5">
                                    *
                                </div>
                                <div className="size-14 weight-400 margin-top-5">
                                    <FormattedMessage id="UserDetails_disclaimer"/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    }
                </div>
            </Form>
        </>
    )
}
