import styled from "styled-components";
import {Button as AntInput} from "antd";

export const FeedbackButton = styled(AntInput)`
    &.ant-btn{
      text-decoration: unset;
      display: flex;
      align-items: center;
      justify-content: start;
      column-gap: 5px;
      width: fit-content;
      border-radius: 3px;
      color: ${props => props.color};
      font-weight: 700;
      height: 48px;
      font-size: 18px;
      
      &.ant-btn-sm{
        font-size: 8px;
        height: 24px;
        line-height: 1;
        border-radius: 1.5px;
      }
    }
  
  &.ant-btn-default:not(:disabled):hover{
    border-color: ${props => props.color};
    color: ${props => props.color};
  }
  
  &.ant-btn-default:not(:disabled):active{
    border-color: ${props => props.color};
    color: ${props => props.color};
  }
`