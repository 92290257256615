import React from "react";
import {Card} from "antd";
import Icon from "../../Icon/Sprite";
import {ICONS} from "../../../assets/sprite/iconsNames";
import {FormattedMessage} from "react-intl";

function EmptyCandidature(){
    return(
        <div>
            <Card>
                <div className={"flex-center margin-bottom-20"}>
                    <div className={"user-circle flex-center"}>
                        <Icon name={ICONS.doc_add_100} size={[100, 100]} color="#208ac6"/>
                    </div>
                </div>
                <div className={"flex-center"}>
                    <b>
                        <FormattedMessage id="Candidate_NoCandidates_1"/>
                    </b>
                </div>
                <div className={"flex-center"}>
                    <p>
                        <FormattedMessage id="Candidate_NoCandidates_2"/>
                    </p>
                </div>
            </Card>
        </div>
    )
}

export default EmptyCandidature