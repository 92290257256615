const actions = {

    INIT_USER_PROFILE: 'INIT_USER_PROFILE',

    CREATE_USER: 'CREATE_USER',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_ERROR: 'CREATE_USER_ERROR',

    GET_ALL_USER_PROFILE: 'GET_ALL_USER_PROFILE',
    GET_ALL_USER_PROFILE_SUCCESS: 'GET_ALL_USER_PROFILE_SUCCESS',
    GET_ALL_USER_PROFILE_ERROR: 'GET_ALL_USER_PROFILE_ERROR:',

    GET_USER_DASHBOARD: "GET_USER_DASHBOARD",
    GET_USER_DASHBOARD_SUCCESS: "GET_USER_DASHBOARD_SUCCESS",
    GET_USER_DASHBOARD_ERROR: "GET_USER_DASHBOARD_ERROR",

    GET_USER_PROFILE_BY_ID: "GET_USER_PROFILE_BY_ID",
    GET_USER_PROFILE_BY_ID_SUCCESS: "GET_USER_PROFILE_BY_ID_SUCCESS",
    GET_USER_PROFILE_BY_ID_ERROR: "GET_USER_PROFILE_BY_ID_ERROR",

    RESET_REGISTRATION_CONFIRM: "RESET_REGISTRATION_CONFIRM",

    SAVE_USER_PROFILE: "SAVE_USER_PROFILE",
    SAVE_USER_PROFILE_SUCCESS: "SAVE_USER_PROFILE_SUCCESS",
    SAVE_USER_PROFILE_ERROR: "SAVE_USER_PROFILE_ERROR",

    SAVE_USER_PROFILE_ACCOUNT: "SAVE_USER_PROFILE_ACCOUNT",
    SAVE_USER_PROFILE_ACCOUNT_SUCCESS: "SAVE_USER_PROFILE_ACCOUNT_SUCCESS",
    SAVE_USER_PROFILE_ACCOUNT_ERROR: "SAVE_USER_PROFILE_ACCOUNT_ERROR",

    UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
    UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
    UPDATE_USER_PROFILE_ERROR: "UPDATE_USER_PROFILE_ERROR",

    DELETE_USER_PROFILE: "DELETE_USER_PROFILE",
    DELETE_USER_PROFILE_SUCCESS: "DELETE_USER_PROFILE_SUCCESS",
    DELETE_USER_PROFILE_ERROR: "DELETE_USER_PROFILE_ERROR",

    SET_RECORD_OFFER: "SET_RECORD_OFFER",
    SET_RECORD_OFFER_SUCCESS: "SET_RECORD_OFFER_SUCCESS",
    SET_RECORD_OFFER_ERROR: "SET_RECORD_OFFER_ERROR",

    GET_USER_PROFILE_IMAGE: "GET_USER_PROFILE_IMAGE",
    GET_USER_PROFILE_IMAGE_SUCCESS: "GET_USER_PROFILE_IMAGE_SUCCESS",
    GET_USER_PROFILE_IMAGE_ERROR: "GET_USER_PROFILE_IMAGE_ERROR",

    GET_USER_PROFILE_CV: "GET_USER_PROFILE_CV",
    GET_USER_PROFILE_CV_SUCCESS: "GET_USER_PROFILE_CV_SUCCESS",
    GET_USER_PROFILE_CV_ERROR: "GET_USER_PROFILE_CV_ERROR",

    GET_COMPANY_BY_ID_FOR_USER: "GET_COMPANY_BY_ID_FOR_USER",
    GET_COMPANY_BY_ID_FOR_USER_SUCCESS: "GET_COMPANY_BY_ID_FOR_USER_SUCCESS",
    GET_COMPANY_BY_ID_FOR_USER_ERROR: "GET_COMPANY_BY_ID_FOR_USER_ERROR",

    RESET_USER_PROFILE: "RESET_USER_PROFILE",



    createUser: (data) => ({
        type: actions.CREATE_USER,
        payload: data
    }),

    getAll: () => ({
        type: actions.GET_ALL_USER_PROFILE,
    }),

    getUserDashboard: () => ({
        type: actions.GET_USER_DASHBOARD,
    }),

    getUser: (userId) => ({
        type: actions.GET_USER_PROFILE_BY_ID,
        id: userId
    }),

    save: (data) => ({
        type: actions.SAVE_USER_PROFILE,
        payload: data
    }),
    saveAccount: (data) =>({
        type:actions.SAVE_USER_PROFILE_ACCOUNT,
        payload: data
    }),


    update: (userId,data) => ({
        type: actions.UPDATE_USER_PROFILE,
        id: userId,
        payload: data,
    }),

    deleteData: (id) => ({
        type: actions.DELETE_USER_PROFILE,
        id: id
    }),

    resetRegistrationConfirm:()=>({
        type: actions.RESET_REGISTRATION_CONFIRM
    }),

    setRecordOffer: (data) => ({
        type: actions.SET_RECORD_OFFER,
        record: data
    }),

    getImageSourceUser: (data) => ({
       type: actions.GET_USER_PROFILE_IMAGE,
       payload: data
    }),

    getCvSourceUser: (data) => ({
       type: actions.GET_USER_PROFILE_CV,
       payload: data
    }),

    getCompanyByIdForUser: (companyId) => ({
       type: actions.GET_COMPANY_BY_ID_FOR_USER,
       companyId: companyId
    }),

    resetStateUserProfile: ()=>({
        type: actions.RESET_USER_PROFILE
    })
};

export default actions;
