import React, {useState} from "react";
import Modal from "antd/es/modal";
import "src/component/Layout/Modal/ModalEditAccount/ModalEditAccountStyle.scss"
import {Col, Form, Row, Space} from "antd";
import {InputLogin} from "src/component/Useful/Input/InputLogin";
import {FormattedMessage} from "react-intl";
import {ButtonUser} from "src/component/Useful/Button/ButtonUser";
import actions from "src/redux/employee/actions"
import {useDispatch} from "react-redux";
import { ICONS } from "src/assets/sprite/iconsNames";
import Icon from "src/component/Icon/Sprite";

function ModalEditAccount(props) {

    const {isOpen, setIsOpen} = props

    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const {update, checkOldPassword} = actions

    const [view, setView] = useState(false)
    const [data, setData] = useState(null)

    function resetFields (){
        setIsOpen(false)
        setView(false)
        setData(null)
        form.resetFields()
    }

    return (
        <Modal
            closeIcon={<Icon name={ICONS.close} size={[20,20]}/>}
            className={"editAccount"}
            open={isOpen}
            onCancel={() => resetFields()}
            centered
            destroyOnClose
            footer={
                <div>
                    <Space size={20} direction={"horizontal"}>
                        <div className={"pointer underlined opacity-50 flex-align-center"} onClick={() => setIsOpen(false)}>
                            <FormattedMessage id="cancel"/>
                        </div>
                        <ButtonUser htmlType="submit" form={"editCompanyAccount"}>
                            Salva
                        </ButtonUser>
                    </Space>
                </div>
            }
        >
            <>
                {!view ?
                    <div>
                        <div className={"weight-700 size-29 merriWeather"} style={{marginBottom: 20}}>
                            Modifica Account
                        </div>
                        <Form
                            id={"editCompanyAccount"}
                            layout={"vertical"}
                            form={form}
                            onFinish={(values) => {
                                if (values.email === localStorage.getItem('email')){
                                    dispatch(update(localStorage.getItem('id'), values, setIsOpen))
                                } else {
                                    setData(values)
                                    setView(true)
                                }
                            }}
                        >

                            <Space
                                direction="vertical"
                                size={[0, 24]}
                            >
                                <Row gutter={24}>
                                    <Col span={12}>

                                        <Form.Item
                                            name="firstName"
                                            label={"Nome"}
                                            initialValue={localStorage.getItem('firstName')}
                                            className="weight-600 size-14"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: false,
                                                },
                                            ]}
                                        >
                                            <InputLogin/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="lastName"
                                            label={"Cognome"}
                                            initialValue={localStorage.getItem('lastName')}
                                            className="weight-600 size-14"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: false,
                                                },
                                            ]}
                                        >
                                            <InputLogin/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            name="email"
                                            label={"Email"}
                                            initialValue={localStorage.getItem('email')}
                                            className="weight-600 size-14"
                                            rules={[
                                                {
                                                    type: 'email',
                                                    required: true,
                                                    message: false,
                                                },
                                            ]}
                                        >
                                            <InputLogin/>
                                        </Form.Item>
                                        <div>
                                            Per cambiare l'email dovrai inserire la password attuale
                                        </div>
                                    </Col>
                                </Row>
                            </Space>
                        </Form>
                    </div>
                    :
                    <div>
                        <div className={"weight-700 size-29 merriWeather"} style={{marginBottom: 20}}>
                            Inserisci la password attuale
                        </div>
                        <div>
                            <InputLogin onChange={(e)=> dispatch(checkOldPassword(e.target.value))}/>
                        </div>
                    </div>
                }
            </>
        </Modal>
    )
}

export default ModalEditAccount
