import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Divider, Breadcrumb } from "antd";
import "src/Pages/workOffers/WorkOffersStyle.scss";
import { ICONS } from "src/assets/sprite/iconsNames";
import { FormattedMessage } from "react-intl";
import { PRIVATE_COMPANY_ROUTE } from "src/route.constants";
import Icon from "src/component/Icon/Sprite";
import { Switch } from "src/component/Useful/Switch";
import { SiteTable } from "src/component/Layout/Site/SiteTable";
import EmptySite from "../../component/Layout/Site/EmptySite";
import { ButtonUser } from "src/component/Useful/Button/ButtonUser";
import { CustomDrawer } from "src/component/Layout/Drawer/CustomDrawer";
import { DRAWER_TYPE, SORTING } from "src/utils/utility";
import actionsLocation from "../../redux/location/actions";
import actionsCompany from "../../redux/company/actions"
import actionsDrawer from "src/redux/drawer/actions"
import { useDispatch, useSelector } from "react-redux";
import { COMPANY_MODAL_TYPE, firstLetters } from "src/utils/utility";
import ModalLocation from "../../component/Layout/Modal/ModalLocation/ModalLocation";


function Site() {

    const navigate = useNavigate()
    const location = useLocation()
    let dispatch = useDispatch()

    const { getCompanyLocationById } = actionsLocation;
    const { getCompanyById, offertToCreate } = actionsCompany;
    const { openDrawer } = actionsDrawer;

    const [openModalLocation, setOpenModalLocation] = useState(false)
    const [modalType, setModalType] = useState(null)

    const companyLocation = useSelector(state => state.Location.companyLocation)
    const company = useSelector(state => state.Company.company)

    React.useEffect(() => {
        if (location && location.search) {
            let id = location.search.substring(4)
            dispatch(getCompanyLocationById(id))
        }
    }, [])


    return (
        <div>
            <div className="workOffers">
                <div className="max-width-1200 ">

                    <Breadcrumb className="customBreadcrumb" separator="›">
                        <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY)}><FormattedMessage id="Breadcrumb_home" /></Breadcrumb.Item>
                        <Breadcrumb.Item>{company ? company.companyName : null}</Breadcrumb.Item>
                        <Breadcrumb.Item>{companyLocation ? companyLocation.name : null}</Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="flex-center-between margin-bottom-24">
                        <div className="flex-center">
                            <span onClick={() => navigate(-1)}>
                                <Icon className="margin-right-20 icon" color="#9595a2" name={ICONS.arrow_2} size={[20, 20]} />
                            </span>
                            <span className="size-28 weight-700 merriWeather">
                                {companyLocation ? companyLocation.name : null}
                            </span>
                            <span>
                                <div onClick={() => {
                                    setOpenModalLocation(true)
                                    setModalType(COMPANY_MODAL_TYPE.EDIT)
                                }}>
                                    <Icon className="margin-left-20 icon" color="#9595a2" name={ICONS.edit} size={[20, 20]} />
                                </div>
                            </span>
                        </div>
                        <div className="flex-center">
                            <div className="flex-end">
                                {companyLocation && companyLocation.employees && (companyLocation.employees.length > 0) && companyLocation.employees.map((item) => (
                                    <div className="margin-right-10">
                                        <div className={`weight-700 padding-10 flex-center size-14 ${item.isActive ? "isEmployeeActive" : "isEmployeeNotActive"}`} style={{ minWidth: "20px", minHeight: "20px" }}>
                                            {firstLetters(item.firstName, item.lastName)}
                                        </div>
                                    </div>
                                ))}
                                {/*<Divider className="flex-center margin-12" style={{height: "40px"}} type="vertical"/>
                               <div className="flex-center margin-5">Sede attiva</div>
                                <Switch checkedChildren={<Icon name={ICONS.ok} size={[20,20]}/>}
                                        unCheckedChildren={<Icon name={ICONS.close_switch} size={[20,20]}/>}
                                />*/}
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className="margin-bottom-24 flex-center-between">
                        <div className="size-18 weight-600">
                            Offerte
                        </div>
                        <div>
                            <ButtonUser className="flex-center-between" onClick={() => {
                                dispatch(offertToCreate())
                                dispatch(openDrawer())
                            }
                            }>
                                <Icon name={ICONS.doc_add} size={[20, 20]} color={"#FFFFF"} />
                                <span className="white-text">Nuova Offerta</span>
                            </ButtonUser>
                        </div>
                    </div>
                    <div className="margin-bottom-24">
                        {companyLocation && companyLocation.offerts && companyLocation.offerts.length > 0 ?
                            <SiteTable siteList={companyLocation} />
                            :
                            <EmptySite />
                        }
                    </div>
                </div>
            </div>
            <CustomDrawer
                sede={companyLocation}
                type={DRAWER_TYPE.offer}
                disabledLocation={true}
            />
            <ModalLocation setOpenModalLocation={setOpenModalLocation} openModalLocation={openModalLocation} type={modalType} company={company} record={companyLocation} />
        </div>
    )
}

export default Site

