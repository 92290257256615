import React from "react";
import "src/component/Layout/RadioButtonGroup/RadioButtonGroup.scss"
import {FormattedMessage} from "react-intl";
import {OFFER_FILTERS, OFFER_FILTERS_CANDIDATE} from "src/utils/utility";


export default function RadioButtonGroup(props) {

    const {selectedFilter, handleSelectedFilter, candidatureWaitingCount, candidatureSelectedCount, candidaturePreselectionCount, candidatureInterviewCount, candidatureRejectedCount} = props

    function resetPageFilter(){
        return(
        selectedFilter.page = 1,
        selectedFilter.pageSize = 10
        )} 
    

    return (
        <div className={"radio-button-group pointer"}>
            <div
                className={`right-point-button ${selectedFilter.state === OFFER_FILTERS.WAITING ? "selectedRadio" : null}`}
                onClick={() => {
                    resetPageFilter()
                    if (selectedFilter.state !== OFFER_FILTERS.WAITING) { 
                        handleSelectedFilter({
                            id: selectedFilter.id,
                            page: selectedFilter.page,
                            pageSize: selectedFilter.pageSize,
                            title: selectedFilter.title && selectedFilter.title,
                            province: selectedFilter.province && selectedFilter.province,
                            city: selectedFilter.city && selectedFilter.city,
                            qualification: selectedFilter.qualification && selectedFilter.qualification,
                            state: OFFER_FILTERS.WAITING
                        })
                    }
                }}
            >
                <div className={`right-point-button-inner ${selectedFilter.state === OFFER_FILTERS.WAITING ? "selectedInnerRadio" : null}`}>
                    <div className="flex-center-between padding-16 height-100">
                        <span className="size-13 weight-600 corp-100">
                            {OFFER_FILTERS_CANDIDATE.WAITING.label}
                        </span>
                        <span className="size-12 weight-700 dark-50">
                            {candidatureWaitingCount}
                        </span>
                    </div>
                </div>
            </div>
            <div
                className={`right-chevron-button ${selectedFilter.state === OFFER_FILTERS.INTERVIEW ? "selectedRadio" : null}`}
                onClick={() => {
                    if (selectedFilter.state !== OFFER_FILTERS.INTERVIEW) { 
                        resetPageFilter()
                        handleSelectedFilter({
                            id: selectedFilter.id,
                            page: selectedFilter.page,
                            pageSize: selectedFilter.pageSize,
                            title: selectedFilter.title && selectedFilter.title,
                            province: selectedFilter.province && selectedFilter.province,
                            city: selectedFilter.city && selectedFilter.city,
                            qualification: selectedFilter.qualification && selectedFilter.qualification,
                            state: OFFER_FILTERS.INTERVIEW
                        })
                    }
                }}
            >
                <div className={`right-chevron-button-inner ${selectedFilter.state === OFFER_FILTERS.INTERVIEW ? "selectedInnerRadio" : null}`}>
                    <div className="flex-center-between padding-16 height-100">
                        <span className="size-13 weight-600 corp-100">
                            {OFFER_FILTERS_CANDIDATE.INTERVIEW.label}
                        </span>
                        <span className="size-12 weight-700 dark-50">
                            {candidatureInterviewCount}
                        </span>
                    </div>
                </div>
            </div>
            <div
                className={`right-chevron-button ${selectedFilter.state === OFFER_FILTERS.PRESELECTED ? "selectedRadio" : null}`}
                onClick={() => {
                    if (selectedFilter.state !== OFFER_FILTERS.PRESELECTED) { 
                        resetPageFilter()
                        handleSelectedFilter({
                            id: selectedFilter.id,
                            page: selectedFilter.page,
                            pageSize: selectedFilter.pageSize,
                            title: selectedFilter.title && selectedFilter.title,
                            province: selectedFilter.province && selectedFilter.province,
                            city: selectedFilter.city && selectedFilter.city,
                            qualification: selectedFilter.qualification && selectedFilter.qualification,
                            state: OFFER_FILTERS.PRESELECTED
                        })
                    }
                }}
            >
                <div className={`right-chevron-button-inner ${selectedFilter.state === OFFER_FILTERS.PRESELECTED ? "selectedInnerRadio" : null}`}>
                    <div className="flex-center-between padding-16 height-100">
                        <span className="size-13 weight-600 corp-100">
                            {OFFER_FILTERS_CANDIDATE.PRESELECTED.label}
                        </span>
                        <span className="size-12 weight-700 dark-50">
                            {candidaturePreselectionCount}
                        </span>
                    </div>
                </div>
            </div>
            <div
                className={`right-chevron-button ${selectedFilter.state === OFFER_FILTERS.DISLIKE ? "selectedRadio" : null}`}
                onClick={() => {
                    if (selectedFilter.state !== OFFER_FILTERS.DISLIKE) { 
                        resetPageFilter()
                        handleSelectedFilter({
                            id: selectedFilter.id,
                            page: selectedFilter.page,
                            pageSize: selectedFilter.pageSize,
                            title: selectedFilter.title && selectedFilter.title,
                            province: selectedFilter.province && selectedFilter.province,
                            city: selectedFilter.city && selectedFilter.city,
                            qualification: selectedFilter.qualification && selectedFilter.qualification,
                            state:OFFER_FILTERS.DISLIKE
                        })
                    }
                }}
            >
                <div className={`right-chevron-button-inner ${selectedFilter.state === OFFER_FILTERS.DISLIKE ? "selectedInnerRadio" : null}`}>
                    <div className="flex-center-between padding-16 height-100">
                        <span className="size-13 weight-600 corp-100">
                            {OFFER_FILTERS_CANDIDATE.DISLIKE.label}
                        </span>
                        <span className="size-12 weight-700 dark-50">
                            {candidatureRejectedCount}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
