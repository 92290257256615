import React, {useEffect} from "react";
import "src/Pages/notification/NotificationCompanyStyle.scss";
import {ICONS} from "src/assets/sprite/iconsNames";
import {Breadcrumb, Button, ConfigProvider, List} from "antd";
import {useNavigate} from "react-router";
import {FormattedMessage} from "react-intl";
import {PRIVATE_COMPANY_ROUTE} from "src/route.constants";
import Icon from "src/component/Icon/Sprite";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/notification/actions";
import {NOTIFICATION_TYPE} from "../../utils/utility";
import dayjs from "dayjs";

function NotificationCompany() {
    const relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {getAllNotification, setNotificationRead} = actions

    const listNotification = useSelector(state => state.Notification.listNotification)

    const customizedEmpty = () => (
        <div className="noNotification">
            <div className="noNotificationIcon flex-center">

                <Icon className={"infoIcon"} name={ICONS.notify} size={[20, 20]} color={"#C41A43"}/>
            </div>
            <div className="size-18 black weight-600">
                <FormattedMessage id="Notification_noNotification_1"/>
            </div>
            <div className="black opacity-70">
                <FormattedMessage id="Notification_noNotification_2"/>
            </div>
        </div>
    )

    function setAllRead(){
        let ids =[]
        let tempList = [...listNotification]
        tempList = tempList.filter(notification => !notification.read)
        tempList.forEach(notification => ids = ids.concat(notification.ids))
        if (ids.length !== 0) {
            dispatch(setNotificationRead(ids))
        }
    }

    useEffect(() => {
        dispatch(getAllNotification())
    }, [])

    return (
        <div>
            <div className="notification">
                <div className="max-width-1200 ">

                    <Breadcrumb className="customBreadcrumb" separator="›">
                        <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY)}><FormattedMessage id="Breadcrumb_home"/></Breadcrumb.Item>
                        <Breadcrumb.Item><FormattedMessage id="Breadcrumb_notification"/></Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="title flex-center-between margin-bottom-24">
                        <div>
                            <span onClick={() => navigate(-1)}>
                                <Icon className="opacity-50 margin-right-22 pointer" name={ICONS.arrow_2} size={[20, 20]} color={"#0E0E2C"}/>
                            </span>
                            <span className="size-28 weight-700 merriWeather">
                                <FormattedMessage id="topBar_notification"/>
                            </span>
                        </div>
                        <div className="flex">
                            <div>
                                <Button className="reloadButton flex-center" onClick={()=> dispatch(getAllNotification())}>
                                    <Icon name={ICONS.refresh} size={[20, 20]} color={"#208AC6"}/>
                                    <span className="size-14 blue text"><FormattedMessage id="reload"/></span>
                                </Button>
                            </div>
                        </div>
                    </div>


                    <ConfigProvider renderEmpty={customizedEmpty}>
                        <List
                            itemLayout="horizontal"
                            dataSource={listNotification && listNotification}
                            bordered={listNotification && listNotification.length === 0}
                            header={listNotification && listNotification.length !== 0 &&
                            <div className={"flex-end"}>

                                <div className="flex-align-center opacity-50">
                                    <div className="flex-align-center margin-left-18">
                                        <Icon className="opacity-50" name={ICONS.tik_all_read} size={[20, 20]} color={"#0E0E2C"}/>
                                    </div>
                                    <div className="size-14 weight-600 underlined pointer" onClick={()=> setAllRead()}>
                                        <FormattedMessage id="topBar_MarkAsRead"/>
                                    </div>
                                </div>
                            </div>
                            }
                            renderItem={(item) => (
                                <>
                                    {item.type === NOTIFICATION_TYPE.NEW_CANDIDATURE ?
                                        <List.Item>
                                            <div>
                                                <div className="notifyIcon flex-center">
                                                    <Icon name={ICONS.user} size={[20, 20]} color={"#0E0E2C"}/>
                                                </div>
                                            </div>
                                            <div className={`size-14 ${!item.read ? "weight-700" : null}`}>
                                                {item.ids && item.ids.length}
                                                &nbsp;
                                                <FormattedMessage id="notification_newCandidates"/>
                                            </div>
                                            <div>
                                                <div className="blue underlined pointer" style={{width: "fit-content"}} onClick={() => {
                                                    dispatch(setNotificationRead(item.ids))
                                                    navigate("/company/offers/" + (item.offert && item.offert.id))
                                                }}>
                                                    {item.offert && item.offert.title}
                                                </div>
                                                <div className="dividerNotificationDot">
                                                    {item.offert && item.offert.location && item.offert.location.city &&
                                                    <span>
                                                        {item.offert && item.offert.location && item.offert.location.city}
                                                    </span>
                                                    }
                                                    {item.offert && item.offert.insertMode &&
                                                    <span>
                                                        {item.offert && item.offert.insertMode}
                                                    </span>
                                                    }
                                                    {item.offert && item.offert.impegnoRichiesto &&
                                                    <span>
                                                        {item.offert && item.offert.impegnoRichiesto}
                                                    </span>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                {item.dateCreated && dayjs(item.dateCreated).fromNow(true)} fa
                                            </div>
                                            {item.read ?
                                                <div className="tik">
                                                    <Icon name={ICONS.tik} size={[20, 20]} color={"#208AC6"}/>
                                                </div>
                                                :
                                                <div>
                                                    <div className="blueCircle"/>
                                                </div>
                                            }
                                        </List.Item>
                                        :
                                        <List.Item>
                                            <div>
                                                <div className="notifyIcon flex-center">
                                                    <Icon name={ICONS.doc} size={[20, 20]} color={"#0E0E2C"}/>
                                                </div>
                                            </div>
                                            <div className={`size-14 ${!item.read ? "weight-700" : null}`}>
                                                <FormattedMessage id="notification_newOffer"/>
                                            </div>
                                            <div>
                                                <span>
                                                    L'offerta&nbsp;
                                                </span>
                                                <span className="blue underlined pointer" style={{width: "fit-content"}} onClick={() => {
                                                    dispatch(setNotificationRead(item.ids))
                                                    navigate("/company/offers/" + (item.offert && item.offert.id))
                                                }}>
                                                    {item.offert && item.offert.title}
                                                </span>
                                                <span>
                                                    &nbsp;è stata pubblicata
                                                </span>
                                            </div>
                                            <div>
                                                {item.offert && item.offert.dateCreated && dayjs(item.offert.dateCreated).fromNow(true)} fa
                                            </div>
                                            {item.read ?
                                                <div className="tik">
                                                    <Icon name={ICONS.tik} size={[20, 20]} color={"#208AC6"}/>
                                                </div>
                                                :
                                                <div>
                                                    <div className="blueCircle"/>
                                                </div>
                                            }
                                        </List.Item>
                                    }
                                </>
                            )}
                        />
                    </ConfigProvider>
                </div>
            </div>
        </div>
    )
}

export default NotificationCompany
