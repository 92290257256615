import React, {useEffect, useState} from "react";
import RedLogo from "src/assets/logo/logo-VT-red.svg";
import BlueLogo from "src/assets/logo/logo-VT-blue.svg";
import LandingLogo from "src/assets/logo/logo-VT-landing.svg";
import {CONTEXT, useWindowSize} from "src/utils/utility";
import ModalSuperAdmin from "../Modal/ModalSuperAdmin/ModalSuperAdmin";
import {useDispatch, useSelector} from "react-redux";
import actions from "src/redux/admin/actions"
import {useSearchParams} from "react-router-dom";

const {handleLandingModal} = actions

export default function LandingTopbar(props) {

    const {context} = props

    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();


    const [logo, setLogo] = useState(null)
    const [openModalSuperAdmin, setOpenModalSuperAdmin] = useState(false);
    const [navigateTo, setNavigateTo] = useState("https://www.vt-job.it");

    const isLandingModalOpen = useSelector(state => state.Admin.isLandingModalOpen)

    let open = false

    const size = useWindowSize();

    const showModalSuperAdmin = () => {
        setOpenModalSuperAdmin(true);
    };

    const modalSuperAdminOk = () => {
        setOpenModalSuperAdmin(false);
    };

    const modalSuperAdminCancel = () => {
        setOpenModalSuperAdmin(false);
    };

    useEffect(() => {
        if (isLandingModalOpen) {
            setOpenModalSuperAdmin(true)
        }
    }, [isLandingModalOpen])

    useEffect(() => {
        if (searchParams.get("showLoginAdmin") === "true") {
            showModalSuperAdmin()
        }
    }, [searchParams])

    useEffect(() => {

        return () => {
            dispatch(handleLandingModal(false))
        }
    }, [])


    useEffect(() => {
        switch (context) {
            case CONTEXT.USER:
                setLogo(BlueLogo)
                // setNavigateTo("https://www.vocationaltraining.it/")
                break;
            case CONTEXT.COMPANY:
                setLogo(RedLogo)
                // setNavigateTo("https://www.vocationaltraining.it/aziende/")
                break;
            case CONTEXT.LANDING:
                setLogo(LandingLogo)
                // setNavigateTo("https://www.vocationaltraining.it/")
                break;
            default:
                setLogo(LandingLogo)
                // setNavigateTo("https://www.vocationaltraining.it/")
                break;
        }
    }, [context])


    return (
        <>{logo === LandingLogo ?
            <div className="landingTopbar">
                <div className={"topbarContent"}>
                    <a href={navigateTo}>
                        <img src={logo} className={"logo pointer"}/>
                    </a>
                </div>
                <ModalSuperAdmin state={openModalSuperAdmin} onOk={modalSuperAdminOk} onCancel={modalSuperAdminCancel}/>
            </div>
            :
            (size.width > 426 &&
                <div className="landingTopbar">
                    <div className={"topbarContent"}>
                        <a href={navigateTo}>
                            <img src={logo} className={"logo pointer"}/>
                        </a>
                    </div>
                    <ModalSuperAdmin state={openModalSuperAdmin} onOk={modalSuperAdminOk} onCancel={modalSuperAdminCancel}/>
                </div>
            )
        }
        </>
    )
}
