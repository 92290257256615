import React, {useEffect, useState} from "react";
import Breadcrumb from "antd/es/breadcrumb";
import {PRIVATE_USER_ROUTE} from "src/route.constants";
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import actions from "src/redux/candidature/actions";
import {OFFER_FILTERS_CANDIDATE, SORTING} from "src/utils/utility";
import "./myCandidatureStyle.scss"
import userActions from "src/redux/userProfile/actions";
import ListMyCandidature from "src/component/Layout/MyCandidature/ListMyCandidature";
import EmptyCandidature from "src/component/Layout/MyCandidature/EmptyCandidature";
import ListMyCandidatureMobile from "../../../component/Layout/MyCandidature/ListMyCandidatureMobile";
import useMediaQuery from "../../../component/Useful/Responsive/UseMedia";


function MyCandidature() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {getAllMyCandidature} = actions
    const {setRecordOffer} = userActions;

    const myCandidatures = useSelector(state => state.Candidature.myCandidatures)
    const recordOffer = useSelector(state => state.UserProfile.recordOffer)

    const [idRecord, setIdRecord] = useState(null)
    const [viewComponent, setViewComponent] = useState(false)

    const isDesktop = useMediaQuery('(min-width: 1024px)')

    useEffect(() => {
        dispatch(getAllMyCandidature({
            state: OFFER_FILTERS_CANDIDATE.ALL.value,
            sort: SORTING.ASC.value
        }))
    }, [])

    useEffect(() => {
        if (idRecord) {
            const record = myCandidatures.find(element => element.id === idRecord)
            dispatch(setRecordOffer(record))
        }
    }, [idRecord])

    return (
        <div>
            <div className="myCandidature">
                <div className="max-width-1200">

                    <Breadcrumb className="customBreadcrumb" separator="›">
                        <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_USER_ROUTE.DASHBOARD_USER)}>
                            <FormattedMessage id="Breadcrumb_home"/>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <FormattedMessage id="Breadcrumb_myCandidature"/>
                        </Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="flex-align-center" style={{marginBottom: 20}}>
                        <span className="size-28 weight-700 merriWeather">
                            <FormattedMessage id="Breadcrumb_myCandidature"/>
                        </span>
                    </div>

                    {myCandidatures && myCandidatures.length !== 0 ?

                        <div>
                            <div className="margin-bottom-24 margin-top-24 flex-center-between merriWeather">
                                Risultati({myCandidatures.length})
                            </div>
                            {isDesktop ?
                                <ListMyCandidature myCandidatures={myCandidatures} idRecord={idRecord} setIdRecord={setIdRecord} recordOffer={recordOffer} viewComponent={viewComponent} setViewComponent={setViewComponent}/>
                                :
                                <ListMyCandidatureMobile myCandidatures={myCandidatures} idRecord={idRecord} setIdRecord={setIdRecord} recordOffer={recordOffer} viewComponent={viewComponent} setViewComponent={setViewComponent}/>
                            }
                        </div>
                        :
                        <EmptyCandidature/>
                    }
                </div>
            </div>
        </div>
    )
}

export default MyCandidature