import React, {useEffect} from "react";
import Modal from "antd/es/modal";
import actions from "../../../../redux/company/actions";
import {useDispatch, useSelector} from "react-redux";
import {ButtonUser} from "../../../Useful/Button/ButtonUser";
import "./ModalCvStyle.scss"
const {getCvSourceDetailUser} = actions

function ModalCV(props) {
    let {open, onOk, onCancel, cv} = props
    let dispatch = useDispatch()

    const cvUser = useSelector(state => state.Company.cv)
    useEffect(()=>{
        if(cv !== null){
            dispatch(getCvSourceDetailUser(cv))
        }
    },[cv])

    return(
        <Modal open={open} onOk={onOk} onCancel={onCancel} className={"modalCvCompany"}
               footer={[
                   <ButtonUser key="submit" type="primary" onClick={onOk}>
                       Chiudi
                   </ButtonUser>
               ]}
        >
            <div>
                <object data={cvUser} type="application/pdf" width="100%" height="800px">
                    <embed src={cvUser} type="application/pdf" />
                </object>
            </div>
        </Modal>
    )
}

export default ModalCV