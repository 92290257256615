import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {endpoints} from "../../config/site.config";
import SuperFetch from "../../helpers/superFetch";
import actions from "./actions";
import authActions from "../auth/actions";
import {OFFER_FILTERS_CANDIDATE} from "src/utils/utility";

export function* getAllCandidature(data) {

    yield takeEvery(actions.GET_ALL_CANDIDATURE, function* (data) {
        // let queryString = "?page=" + (data.payload.page - 1) + "&size=" + data.payload.size
        let queryString = "?sort=dateCreated," + data.payload.sort

        if (data.payload.name) {
            queryString = queryString + "&name=" + data.payload.name
        }
        if (data.payload.province) {
            queryString = queryString + "&province=" + data.payload.province
        }
        if (data.payload.city) {
            queryString = queryString + "&city=" + data.payload.city.label
        }
        if (data.payload.qualificationLevel) {
            queryString = queryString + "&qualificationLevel=" + data.payload.qualificationLevel
        }
        if (data.payload.state && data.payload.state !== "none") {
            queryString = queryString + "&state=" + data.payload.state
        }
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllCandidature + queryString, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_CANDIDATURE_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_CANDIDATURE_ERROR,
                payload: error
            });
        }
    })

}

export function* getAllMyCandidature(data) {

    yield takeEvery(actions.GET_ALL_MY_CANDIDATURE, function* (data) {
        let queryString = "?sort=dateCreated," + data.payload.sort
        if (data.payload.state && data.payload.state !== OFFER_FILTERS_CANDIDATE.ALL.value) {
            queryString = queryString + "&state=" + data.payload.state
        }
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllUserCandidature + queryString, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_MY_CANDIDATURE_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_MY_CANDIDATURE_ERROR,
                payload: error
            });
        }
    })

}

export function* getCandidatureById() {

    yield takeEvery(actions.GET_CANDIDATURE_BY_ID, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getCandidatureById + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_CANDIDATURE_BY_ID_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_CANDIDATURE_BY_ID_ERROR,
                payload: error
            });
        }
    })

}

export function* getCandidatureByOffertId() {
    yield takeEvery(actions.GET_CANDIDATURE_BY_OFFERT_ID, function* (data) {
        let queryString = data.payload.id + "?page=" + (data.payload.page -1) + "&size=" + data.payload.pageSize
        if (data.payload.name) {
            queryString = queryString + "&name=" + data.payload.name
        }
        if (data.payload.province) {
            queryString = queryString + "&province=" + data.payload.province
        }
        if (data.payload.city) {
            queryString = queryString + "&city=" + data.payload.city.key
        }
        if (data.payload.state && data.payload.state !== "Tutti") {
            queryString = queryString + "&state=" + data.payload.state
        }
        if (data.payload.qualification && data.payload.qualification) {
            queryString = queryString + "&qualification=" + data.payload.qualification
        }
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getCandidatureDetail + "/" + queryString, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_CANDIDATURE_BY_OFFERT_ID_SUCCESS,
                payload: response
            });

        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_CANDIDATURE_BY_OFFERT_ID_ERROR,
                payload: error
            });
        }
    })

}

export function* getCountForCandidatureByOffert() {
    yield takeEvery(actions.GET_CANDIDATURE_COUNT_BY_OFFERT_ID, function* (data) {
        let queryString = data.payload + "?page=0&size=10"
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getCandidatureDetail + "/" + queryString, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE})
            yield put({
                type: actions.GET_CANDIDATURE_COUNT_BY_OFFERT_ID_SUCCESS,
                payload: response
            })
        } else {
            yield put({type: authActions.LOADING_FALSE})
            yield put({
                type: actions.GET_CANDIDATURE_COUNT_BY_OFFERT_ID_ERROR,
                payload: response
            })
        }
    })
}


export function* saveCandidature() {

    yield takeEvery(actions.SAVE_CANDIDATURE, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.saveCandidature, data.payload, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.SAVE_CANDIDATURE_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SAVE_CANDIDATURE_ERROR,
                payload: error
            });
        }
    })

}

export function* updateCandidature() {

    yield takeEvery(actions.UPDATE_CANDIDATURE, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.modifyCandidature + "/" + data.id, data.payload, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.UPDATE_CANDIDATURE_SUCCESS,
                payload: response
            });
            if (data.offerId && data.offerId !== "none") {
                let dataUpdateGet = {
                    id: data.offerId,
                    page: 0,
                    pageSize: 10,
                    state: data.state
                }
                yield put({
                    type: actions.GET_CANDIDATURE_BY_OFFERT_ID,
                    payload: dataUpdateGet
                })
            }
            if (data.payload.companyId && data.payload.companyId !== "none") {
                yield put({
                    type: actions.GET_CANDIDATURE_BY_ID,
                    id: data.id
                })
            }
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_CANDIDATURE_ERROR,
                payload: error
            });
        }
    })

}

export function* updateCandidatureStateAndViewed() {
    yield takeEvery(actions.UPDATE_CANDIDATURE_STATE_AND_VIEWED, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.modifyCandidature + "/" + data.id, data.payload, true)
        if (response) {

            yield put({
                type: actions.UPDATE_CANDIDATURE_STATE_AND_VIEWED_SUCCESS,
                payload: response
            });
            if (data.offerId && data.offerId !== "none") {
                let dataUpdateGet = {
                    id: data.offerId,
                    page: 0,
                    pageSize: 10,
                    state: data.state
                }
                yield put({
                    type: actions.GET_CANDIDATURE_BY_OFFERT_ID,
                    payload: dataUpdateGet
                })
            }
            if (data.payload.companyId && data.payload.companyId !== "none") {
                yield put({
                    type: actions.GET_CANDIDATURE_BY_ID,
                    id: data.id
                })
            }
        } else {
            yield put({
                type: actions.UPDATE_CANDIDATURE_STATE_AND_VIEWED_SUCCESS,
                payload: error
            });
        }

        console.log(data)

        if (!data.payload.viewed) {
            yield put({
                type: actions.CHANGE_VIEW_FOR_DOT,
                id: data.id
            })
        }

        yield put({type: authActions.LOADING_FALSE});
    })
}

export function* deleteCandidature() {

    yield takeEvery(actions.DELETE_CANDIDATURE, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.delete, endpoints.deleteCandidature + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.DELETE_CANDIDATURE_SUCCESS,
                payload: response
            });
            let dataUpdateGet = {
                id: data.offerId,
                page: 0,
                size: 10,
                state: data.state
            }
            yield put({
                type: actions.GET_CANDIDATURE_BY_OFFERT_ID,
                payload: dataUpdateGet
            })
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DELETE_CANDIDATURE_ERROR,
                payload: error
            });
        }
    })

}

export function* deleteCandidaturePaginated() {

    yield takeEvery(actions.DELETE_CANDIDATURE_PAGINATED, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.delete, endpoints.deleteCandidature + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.DELETE_CANDIDATURE_PAGINATED_SUCCESS,
                payload: response
            });
            let dataUpdateGet = {
                id: data.offerId,
                page: 0,
                size: 10,
                state: data.state
            }
            yield put({
                type: actions.GET_CANDIDATURE_BY_OFFERT_ID,
                payload: dataUpdateGet
            })
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DELETE_CANDIDATURE_PAGINATED_ERROR,
                payload: error
            });
        }
    })

}

export function* getCvSource() {
    yield takeEvery(actions.GET_CANDIDATURE_CV, function* (data) {
        const {
            response,
            fileName,
            error
        } = yield call(SuperFetch.post, endpoints.getImageSource, data.payload, true, false, null, true)
        if (response) {
            let blob = yield response.blob()
            let fileUrl = URL.createObjectURL(blob)
            yield put({
                type: actions.GET_CANDIDATURE_CV_SUCCESS,
                payload: fileUrl,
                // payload: response.headers.get("Content-Disposition").split(" ")[1].split("="),
                fileBlob: blob,
                fileName: fileName,
                // fileName: response.headers.get("Content-Disposition").split(" ")[1].split("=")[1],
                fileType: response.headers.get("Content-Type")
            });
            if (!data.view) {
                let alink = document.createElement('a');
                alink.href = fileUrl;
                alink.download = fileName;
                alink.click();
            }
        } else {
            yield put({
                type: actions.GET_CANDIDATURE_CV_ERROR,
                payload: error
            })
        }
    })
}

export function* multipleCvDownload() {
    yield takeEvery(actions.DOWNLOAD_MULTIPLE_CV, function* (data) {
        let names = {zipFileList: data.names}
        const {
            response,
            fileName,
            error
        } = yield call(SuperFetch.post, endpoints.getFileCvSource, names, true, false, null, true)
        if (response) {
            let blob = yield response.blob()
            let fileUrl = URL.createObjectURL(blob)
            yield put({
                type: actions.DOWNLOAD_MULTIPLE_CV_SUCCESS
            })
            if (!data.view) {
                let alink = document.createElement('a')
                alink.href = fileUrl;
                alink.download = response.headers.get("Content-Disposition").split(" ")[1].split("=")[1];
                alink.click()
            }
        } else {
            yield put({
                type: actions.DOWNLOAD_MULTIPLE_CV_ERROR
            })
        }
    })
}

export function* getAllCandidatureByUserAndCompany() {

    yield takeEvery(actions.GET_ALL_CANDIDATURE_BY_USER_AND_COMPANY, function* (data) {
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllCandidatureByUserAndCompany + "/" + data.id, null, true)
        if (response) {
            yield put({
                type: actions.GET_ALL_CANDIDATURE_BY_USER_AND_COMPANY_SUCCESS,
                payload: response
            })
        } else {
            yield put({
                type: actions.GET_ALL_CANDIDATURE_BY_USER_AND_COMPANY_ERROR,
                payload: error
            })
        }
    })
}

export function* setViewDot() {
    yield takeEvery(actions.CHANGE_VIEW_FOR_DOT, function* (data) {
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.setViewDot + "/" + data.id, null, true)
        if (response) {
            yield put({
                type: actions.CHANGE_VIEW_FOR_DOT_SUCCESS,
                payload: response
            })
        } else {
            yield put({
                type: actions.CHANGE_VIEW_FOR_DOT_ERROR,
                payload: error
            })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getAllCandidature),
        fork(getAllMyCandidature),
        fork(getCandidatureById),
        fork(getCandidatureByOffertId),
        fork(saveCandidature),
        fork(updateCandidature),
        fork(deleteCandidaturePaginated),
        fork(deleteCandidature),
        fork(getCountForCandidatureByOffert),
        fork(getCvSource),
        fork(multipleCvDownload),
        fork(getAllCandidatureByUserAndCompany),
        fork(setViewDot),
        fork(updateCandidatureStateAndViewed)
    ]);
}
