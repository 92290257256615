import React from "react";
import {InputLogin} from "src/component/Useful/Input/InputLogin";
import {ButtonUser} from "src/component/Useful/Button/ButtonUser";
import Icon from "src/component/Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import "./style/RecoveryPasswordUser.scss"
import {FormattedMessage, injectIntl} from "react-intl";
import {Col, Row,Form} from "antd";
import UserPicture from "../../../../assets/image/PictureLoginUser.png";
import {useLocation, useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import actions from "../../../../redux/auth/actions";

const {resetPassword} = actions

function RecoveryPasswordUser() {

    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();

    const [newPassword, setNewPassword] = React.useState(null)
    const [state, setState] = React.useState(false)
    const [toggleType, setToggleType] = React.useState(false)
    const [toggleIconPassword, setToggleIconPassword] = React.useState(false)

    const handleClick = () =>{
        if(newPassword){
            let otp = location.search.substring(5)
            let data = {
                newPassword: newPassword,
                otp:otp
            }
            dispatch(resetPassword(data))
            setState(true)
        }
    }

    const goTo = () => {
        navigate("/user/login")
    }

    const ShowPassword = () =>{
        setToggleType(!toggleType)
        setToggleIconPassword(!toggleIconPassword)
    }

    return(
        <div className={"containerRecoveryUserPassword"}>
            <div className="contentRecoveryUserPassword">
                <Row>
                    <Col md={{span: 12}} sm={{span: 20}} xs={{span: 24}}>
                        <div className={"image"}>
                            <img src={UserPicture} className={"side-image"}/>
                        </div>
                    </Col>
                    <Col md={{span: 8}} sm={{offset:2, span: 20}} xs={{span: 24}} className={"flex-column-center"}>
                        {
                            state === false ?
                            (
                                <div className={"contentRecoveryPasswordUser"}>
                                    <header className={"padding-bottom-20"}>
                                        <h1><FormattedMessage id="RecoveryPassword_Header_title"/></h1>
                                        <p><FormattedMessage id="RecoveryPassword_Header_text"/></p>
                                    </header>
                                    <main>
                                        <p><FormattedMessage id="RecoveryPassword_Main_title"/></p>
                                        <Form>
                                            <Form.Item
                                                name={"password"}
                                                className="inline-block width-100"
                                            >
                                                <InputLogin type={toggleType ? "text" : "password"} className={"input"} prefix={<Icon name={ICONS.lock} size={[20,20]} className="opacity-50"/>} suffix={<div onClick={(e)=>ShowPassword()}><Icon name={toggleIconPassword ? ICONS.eye : ICONS.eye_hide} className="flex-center pointer" color="#208AC6" size={[20,20]}/></div>} placeholder="Inserire la password" onChange={(e) => setNewPassword(e.target.value)}/>
                                            </Form.Item>
                                        </Form>
                                    </main>
                                    <footer>
                                        <ButtonUser block onClick={handleClick} icon={<Icon name={ICONS.tik} className="margin-5 opacity-50" size={[20,20]}/>} className={"flex-center"}>
                                            <FormattedMessage id="RecoveryPassword_Button"/>
                                        </ButtonUser>
                                    </footer>
                                </div>
                            ):(
                                <div className={"containerConfirmNewPassword padding-left-70"}>
                                    <h1 className={"green"}><FormattedMessage id="ConfirmNewPassword_title"/></h1>
                                    <ButtonUser onClick={goTo}>
                                        <FormattedMessage id="ConfirmNewPassword_button"/>
                                    </ButtonUser>
                                </div>
                            )
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )

}

export default injectIntl(RecoveryPasswordUser)
