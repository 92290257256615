import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "src/component/Layout/EmailModal/EmailModal.scss"
import {Modal, Button, Space, Form} from "antd";
import Icon from "src/component/Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import {FormattedMessage} from "react-intl";
import {ButtonUser} from "src/component/Useful/Button/ButtonUser";
import {Select} from "src/component/Useful/Select/Select";
import {Input} from "src/component/Useful/Input/Input";
import actions from "src/redux/email/actions";
import ReactQuill from "react-quill";

export function EmailModal(props) {

    const dispatch = useDispatch();

    const {sendEmail, setSendEmail, record} = props
    const {TextArea} = Input;

    const {sendEmailAction, getEmailAction} = actions;

    const defaultEmailMessage = useSelector(state => state.Email.defaultEmailMessage)

    const [mailObject, setMailObject] = useState(defaultEmailMessage && defaultEmailMessage.subject)
    const [mailContent, setMailContent] = useState(defaultEmailMessage && defaultEmailMessage.message)


    useEffect(()=>{
        if(record && record.firstName){
            setMailObject("Ciao "+record.userFirstName)
        }
    },[props])

    useEffect(()=>{
        if (sendEmail) {
            dispatch(getEmailAction())
        }
    }, [sendEmail])

    useEffect(()=>{
        if (defaultEmailMessage){
            setMailObject(defaultEmailMessage.subject)
            setMailContent(defaultEmailMessage.message)
        }
    }, [defaultEmailMessage])

    return (
        <Modal
            className="emailModal"
            centered
            onCancel={() => {
                setSendEmail(false)
            }}
            destroyOnClose={true}
            open={sendEmail}
            width={670}
            footer={
                <div className="footerPadding">
                    <Space size={20} direction={"horizontal"}>
                        <Button className="blue weight-700 size-14" type="text">
                            <FormattedMessage id="cancel"/>
                        </Button>
                        <ButtonUser
                            className="flex-align-center"
                            onClick={() => {
                                dispatch(sendEmailAction(record.userEmail, mailObject,mailContent))
                            }}>
                            <Icon className="padding-right-8" name={ICONS.tik} size={[20, 20]} color={"#d2e8f4"}/>
                            <FormattedMessage id="OffersDetail_Modal_sendEmail"/>
                        </ButtonUser>
                    </Space>
                </div>
            }

        >
            <div>
                <div className="size-28 weight-700 padding-bottom-30">
                    <FormattedMessage id="OffersDetail_Modal_message"/>
                </div>
                <Space direction={"vertical"} size={24} style={{width: "100%"}}>
                    <div>
                        <div className="padding-bottom-8">
                            <span className="size-14 colon">
                                <FormattedMessage id="OffersDetail_Modal_messageFor"/>
                            </span>
                            <span>
                                {record && record.userFirstName} {record && record.userLastName}
                            </span>
                        </div>
                        <div>
                            <span className="size-14 colon">
                                <FormattedMessage id="OffersDetail_Modal_email"/>
                            </span>
                            <span>
                                {record && record.userEmail}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="size-14 weight-600">
                            <FormattedMessage id="OffersDetail_Modal_object"/>
                        </div>
                        <Input
                            style={{width: "100%"}}
                            value={mailObject}
                            onChange={(value) => setMailObject(value.target.value)}
                        >
                        </Input>
                    </div>
                    <div>
                        <div className="size-14 weight-600">
                            <FormattedMessage id="OffersDetail_Modal_emailMessage"/>
                        </div>
                        <ReactQuill value={mailContent} onChange={setMailContent}  theme="snow" />
                    </div>
                </Space>
            </div>
        </Modal>
    )
}
