import React from "react";
import {ImgComponent} from "../../../../utils/utility";
import Icon from "../../../Icon/Sprite";
import {ICONS} from "../../../../assets/sprite/iconsNames";
import GreyLogo from "../../../../assets/logo/logo-VT-grey.svg";

function ListInputMyPreference(props) {

    const {element} = props

    return (
        <div className="width-100 flex-between">
            <div className="flex-row margin-right-20">
                <div style={!element.anonymous ? {width: "88px", height: "72px", marginRight: 20, border: "1px solid rgba(14, 14, 44, 0.15)"} : {width: "88px", height: "72px", marginRight: 20, border: "none"}}>
                    {!element.anonymous ?
                        <ImgComponent logoPath={element.company && element.company.logoPath}/>
                        :
                        <div className="noLogo">
                            <img src={GreyLogo}/>
                        </div>
                    }
                </div>
                <div className="flex-column">
                    <div className="blue underlined weight-700 size-16">{element.title}</div>
                    <div className="opacity-50 underlined weight-400 size-12">
                        {element.company && element.company.companyName}
                    </div>
                    <div className="flex-gap dividerDot">
                        {element.insertMode &&
                        <div className="flex-center">
                            {element.insertMode}
                        </div>
                        }
                        {element.workPlace &&
                        <div className="flex-center">
                            {element.workPlace}
                        </div>
                        }
                        {element.location && element.location &&
                        <div className="flex-center">
                            {element.location.city}
                        </div>
                        }
                        {element.impegnoRichiesto &&
                        <div className="flex-center">
                            {element.impegnoRichiesto}
                        </div>
                        }
                        {
                            <div className="flex-center">
                                <Icon color="grey" className="margin-left-5" name={ICONS.user_ok} size={[20, 20]}/>
                                <span className="margin-left-5">
                                    {element.candidateCount}
                                </span>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div style={{width: 30, height: 72}} className="flex-center">
                <div style={{transform: "rotate(-90deg)"}}>
                    <Icon name={ICONS.arrow} size={[20, 20]} className="opacity-50"/>
                </div>
            </div>
        </div>
    )
}

export default ListInputMyPreference