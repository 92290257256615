import React from "react";
import {Checkbox as AntCheckbox} from "antd";
import styled from "styled-components"

export const Checkbox = styled(AntCheckbox)`
  
  .ant-checkbox .ant-checkbox-inner, .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner{
    background-color: white;
    border-radius: 4px;
    border: 1px solid #CFCFD5;
  }

  &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
    border-color: #868696;
  }

  .ant-checkbox > .ant-checkbox-inner:after{
    border: 2px solid #208AC6;
    border-color: #208AC6;
    border-top: 0;
    border-inline-start: 0;
    width: 5px;
    border-bottom-right-radius: 1px;
    //content: url(src/assets/svg/Check.svg);
    margin: 0 1px;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after{
    display: flex;
    justify-content: center;
    transform: rotate(45deg) scale(0.9) translate(-65%,-45%) !important;
  }

  .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner{
    border:2px solid #208AC6;
    border-color: #208AC6 !important;
  }

  &.ant-checkbox.ant-checkbox-checked &.ant-checkbox-inner:hover{
    border:2px solid #208AC6;
    border-color: #208AC6 !important;
  }
  
  &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner{
    background-color: white;
  }
  
`
