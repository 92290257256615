import actions from './actions';
import candidatureActions from '../candidature/actions'
import {DRAWER_TYPE} from "../../utils/utility";
import authActions from "../auth/actions";

const initState = {
    users: null,
    user: null,
    updateList: false,
    updated: false,
    confirm: false,
    lastOffers: [],
    offerByLocation: [],
    otherLocationCount: null,
    feedbackLinkUsers: null,
    recordOffer: null,
    logo: null,
    fileBlob: null,
    fileType: null,
    fileName: null,
    changeCustomDrawer: null,
    cv: null,
    fileBlobCv: null,
    fileTypeCv: null,
    fileNameCv: null,
    userInfo: null,
    updateUserProfile: false,
    isButtonLoading: false,
    companyByUser: null
};

export default function userProfileReducer(state = initState, action){
    switch (action.type) {
        case actions.INIT_USER_PROFILE:
            return{
                ...initState
            };
        case authActions.LOGOUT:
            return {...initState}
        case actions.CREATE_USER:
            return{
                ...state,
                isButtonLoading: true
            }
        case actions.CREATE_USER_SUCCESS:
            return {
                ...state,
                updateList: true,
                updated: true,
                confirm: true,
                isButtonLoading: false
            }
        case actions.CREATE_USER_ERROR:
            return{
                ...state,
                isButtonLoading: false
            }
        case actions.GET_ALL_USER_PROFILE_SUCCESS:
            return{
                ...state,
                users: action.payload,
                totalResults: action.payload.totalResults,
                updatedList: false
            };
        case actions.GET_USER_DASHBOARD_SUCCESS:
            return {
                ...state,
                lastOffers: action.payload.lastOfferts,
                offerByLocation: action.payload.offertByLocation,
                otherLocationCount: action.payload.otherLocationCount,
                feedbackLinkUsers: action.payload.feedbackLinkUsers,
            };
        case actions.GET_USER_PROFILE_BY_ID_SUCCESS:
            return{
                ...state,
                userInfo: action.payload,
            };
        case actions.SAVE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                updateList: true,
                updated: true,
                confirm: true,
                changeCustomDrawer: DRAWER_TYPE.secondStepCandidatureUser
            }
        case actions.SAVE_USER_PROFILE_ACCOUNT_SUCCESS:
            return{
                ...state,
                updateList: true,
                updated: true,
                confirm: true,
                // updateUserProfile: true,
                userInfo: action.payload.value,
            }
        case candidatureActions.SAVE_CANDIDATURE:
            return {
                ...state,
                changeCustomDrawer: null
            }
        case candidatureActions.SAVE_CANDIDATURE_SUCCESS:
            return {
                ...state,
                changeCustomDrawer: DRAWER_TYPE.confirmOfferUser
            }
        case actions.UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                user: action.payload.value,
                updateList: true,
                updated: true,
                updateUserProfile: true
            }
        case actions.DELETE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                updateList: true
            }
        case actions.RESET_REGISTRATION_CONFIRM:
            return{
                ...state,
                confirm: false
            }
        case actions.SET_RECORD_OFFER:
            return {
                ...state,
                recordOffer: action.record
            }
        case actions.GET_USER_PROFILE_IMAGE_SUCCESS:
            return {
                ...state,
                logo: action.payload,
                fileBlob: action.fileBlob,
                fileName: action.fileName,
                fileType: action.fileType
            }
        case actions.GET_USER_PROFILE_CV_SUCCESS:
            return {
                ...state,
                cv: action.payload,
                fileBlobCv: action.fileBlob,
                fileNameCv: action.fileName,
                fileTypeCV: action.fileType
            }
            case actions.GET_COMPANY_BY_ID_FOR_USER_SUCCESS:
            return {
                ...state,
                companyByUser: action.payload,
            }
        case actions.RESET_USER_PROFILE:
            return {
                ...state,
                updateUserProfile: false,
            }
        default:
            return state;
    }
}
