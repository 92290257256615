import React from "react";
import Icon from "../../Icon/Sprite";
import {ICONS} from "../../../assets/sprite/iconsNames";
import {Card} from "antd";

function EmptyPreference() {
    return(
        <div>
            <Card>
                <div className={"flex-center margin-bottom-20"}>
                    <div className={"user-circle flex-center"}>
                        <Icon name={ICONS.heart_100} size={[100, 100]} color="#208ac6"/>
                    </div>
                </div>
                <div className={"flex-center"}>
                    <div className="weight-600 size-17">
                        Niente nei preferiti
                    </div>
                </div>
                <div className={"flex-center"}>
                    <div className="weight-400 size-14">
                        Qui visualizzerai tutto ciò che hai messo nei preferiti
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default EmptyPreference