import styled from "styled-components";
import {Switch as AntSwitch} from "antd";
import "../../_index.scss";

export const Switch = styled(AntSwitch)`
  
  &.ant-switch{
    height: 24px;
    width: 40px;
    border-radius: 3px;
    background: #E7E7EA;
    
    &.ant-switch-checked{
    
      background: #3696CC;
       
      &:hover{
        background: #208AC6;
      } 
    }
    
    &.ant-switch-checked .ant-switch-handle{
      inset-inline-start: calc(100% - 13px); 
    }
    
    &:hover{
      background: #CFCFD5;
    }
  }
  
  .ant-switch-handle{

      &::before{
        border-radius: 1px;
        height: 20px;
        width: 11px;
  }
  
`
