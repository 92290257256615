import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {Breadcrumb, message} from "antd";
import "src/Pages/candidate/Candidate.scss";
import {PRIVATE_COMPANY_ROUTE} from "src/route.constants";
import {FormattedMessage} from "react-intl";
import CardCreateCandidate from "../../component/Layout/Candidate/CardCreateCandidate";
import ModalFilter from "../../component/Layout/Modal/ModalFilter/ModalFilter";
import candidatureActions from "../../redux/candidature/actions"
import {useDispatch, useSelector} from "react-redux";
import _ from 'lodash';
import {OFFER_FILTERS_CANDIDATE} from "../../utils/utility";


function Candidate() {

    const navigate = useNavigate()
    let dispatch = useDispatch()

    const {getAll} = candidatureActions

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [search, setSearch] = useState(null)
    const [count, setCount] = useState(null)
    const [resetFilters, setResetFilters] = useState(false)
    const [visibleResetButton, setVisibleResetButton] = useState(false)
    const [objectFilters, setObjectFilters] = useState({
        name: null,
        province: null,
        city: null,
        qualificationLevel: null,
        state: OFFER_FILTERS_CANDIDATE.ALL.value,
        sort: "ASC",
        page: 1,
        size: 10
    })

    const candidatures = useSelector(state => state.Candidature.candidatures)

    useEffect(() => {
        dispatch(getAll(objectFilters))
    }, [objectFilters])

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleReset = () => {
        setIsModalOpen(false);
    };
    const handleSearch = (e) => {
        setSearch(e)
    }
    const handleCount = (e) => {
        setCount(e)
    }

    const handleResetFilters = () => {
        setResetFilters(true)
    }
    const changeReset = () => {
        setResetFilters(false)
    }

    const handleObjectFilters = (e) => {
        setObjectFilters(
            {
                name: e.name ? e.name : null,
                province: e.province ? e.province : null,
                city: e.city ? e.city : null,
                qualificationLevel: e.qualificationLevel ? e.qualificationLevel : null,
                state: e.state ? e.state : OFFER_FILTERS_CANDIDATE.ALL.value,
                sort: e.sort ? e.sort : "ASC",
                page: e.page ? e.page : 1,
                size: e.size ? e.size : 10,
            }
        )
    }

    useEffect(() => {
        let filtersToCompare = {
            name: objectFilters.name,
            province: objectFilters.province,
            city: objectFilters.city,
            qualificationLevel: objectFilters.qualificationLevel
        }

        let initialFilters = {
            name: null,
            province: null,
            city: null,
            qualificationLevel: null
        }

        if (_.isEqual(filtersToCompare, initialFilters)) {
            setVisibleResetButton(false)
        } else{
            setVisibleResetButton(true)
        }
    }, [objectFilters])

    return (
        <div>
            <div className="workOffers">
                <div className="max-width-1200 ">

                    <Breadcrumb className="customBreadcrumb" separator="›">
                        <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY)}><FormattedMessage id="Breadcrumb_home"/></Breadcrumb.Item>
                        <Breadcrumb.Item><FormattedMessage id="Breadcrumb_candidate"/></Breadcrumb.Item>
                    </Breadcrumb>

                    <CardCreateCandidate handleObjectFilters={handleObjectFilters} objectFilters={objectFilters} count={count} resetFilters={handleResetFilters} ListCandidate={candidatures} showModal={showModal} searchToCard={search} handleSearch={handleSearch}
                                         visibleResetButton={visibleResetButton}/>
                </div>
            </div>

            <ModalFilter resetFilters={resetFilters} handleObjectFilters={handleObjectFilters} objectFilters={objectFilters} changeReset={changeReset} open={isModalOpen} onOk={handleOk} handleCount={handleCount} searchToModal={search} handleSearch={handleSearch} onCancel={handleCancel}
                         reset={handleReset}/>
        </div>
    )
}

export default Candidate
