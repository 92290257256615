import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Breadcrumb, Divider, message, Table } from "antd";
import { PRIVATE_COMPANY_ROUTE } from "src/route.constants";
import { FormattedMessage } from "react-intl";
import ModalFilter from "../../component/Layout/Modal/ModalFilter/ModalFilter";
import actions from "../../redux/admin/actions"
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import { ButtonLine } from "../../component/Useful/Button/ButtonLine";
import Icon from "../../component/Icon/Sprite";
import { ICONS } from "../../assets/sprite/iconsNames";
import { Select } from "../../component/Useful/Select/Select";
import { Input } from "../../component/Useful/Input/Input";
import candidatureActions from '../../redux/candidature/actions';
import { getAge } from "../../helpers/utility";
import { QUALIFICATION } from "../../utils/utility";
import './sysAdminUsersList.scss';


function SysAdminUsersList() {

    const navigate = useNavigate()
    let dispatch = useDispatch()

    const { getAllUsers, getAdminCv } = actions
    const { multipleCvDownload, getCvSource } = candidatureActions

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [count, setCount] = useState(null)
    const [visibleResetButton, setVisibleResetButton] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [resetFilters, setResetFilters] = useState(false)
    const [search, setSearch] = useState(null)


    const [objectFilters, setObjectFilters] = useState({
        isProfileCompleted: null,
        jobState: null,
        name: null,
        province: null,
        city: null,
        qualificationLevel: null,
        page: 1,
        pageSize: 10,
        sort: "DESC"
    })

    const total = useSelector(state => state.Admin.total)
    const users = useSelector(state => state.Admin.users)
    const totalUsers = useSelector(state => state.Admin.total)

    const downloadZip = () => {
        if (selectedRows && selectedRows.length > 0) {

            let names = []
            selectedRows.forEach((element) => {
                names.push({
                    profileName: element.firstName + "_" + element.lastName,
                    fileName: element.cv.split(".pdf")[0]
                })
            })
            dispatch(multipleCvDownload(names))
        }
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
            setSelectedRows(selectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: record.isCompleted === false,
            name: record.name,
        }),
    };

    const columns = [
        {
            title: 'Utenti',
            render: (text, record) => (
                <div className="flex-column">
                    <div className="weigth-600 size-14 underlined blue pointer" onClick={() => setField(record)}>{record.firstName} {record.lastName}</div>

                    {record.birthday
                        ? <div className="weigth-400 size-12 opacity-50">
                            <span>{getAge(parseInt(record.birthday))} anni</span>
                            <span> · {record.city} ({record.province})</span>
                        </div>
                        : <span>-</span>
                    }

                </div>
            )
        },
        {
            title: 'Email',
            dataIndex: "email",
        },
        {
            title: 'Stato lavorativo',
            render: (text, record) => {
                return (
                    record.jobState
                        ? <div className="flex-column">
                            <div>{record && record.jobState}</div>
                        </div>
                        : <spa>-</spa>
                )
            }
        },
        {
            title: 'Titolo di studio',
            render: (text, record) => {
                return (
                    record.qualificationList
                        ? <div className="flex-column">
                            <div>{record && record.qualificationList && record.qualificationList.length !== 0 && record.qualificationList[0].qualificationLevel} </div>
                        </div>
                        : <spa>-</spa>
                )
            }
        },
        {
            render: (text, record) => {
                return (
                    record.cv
                        ? <div className="flex-center pointer" onClick={() => dispatch(getCvSource(record.cv))}>
                            <Icon name={ICONS.download} size={[20, 20]} className="icon margin-right-24" color="grey" />
                        </div>
                        : <spa>-</spa>
                )
            }

        }

    ];

    function setField(record) {
        let path = "/admin/users/"
        if (record.userProfileId) {
            path = path + record.userProfileId
        }
        navigate(path)
    }

    useEffect(() => {
        dispatch(getAllUsers(objectFilters))
    }, [objectFilters])

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleCount = (e) => {
        setCount(e)
    }

    const changeReset = () => {
        setResetFilters(false)
    }

    const handleSearch = (e) => {
        setSearch(e)
    }

    const handleReset = () => {
        setIsModalOpen(false);
    };

    const handleResetFilters = () => {
        handleObjectFilters({
            isProfileCompleted: null,
            jobState: null,
            name: null,
            province: null,
            city: null,
            qualificationLevel: null,
        });
        handleReset();
        handleCount(0);
    }


    const handleObjectFilters = (e) => {
        setObjectFilters(
            {
                isProfileCompleted: e.isProfileCompleted !== null ? e.isProfileCompleted : null,
                jobState: e.jobState ? e.jobState : null,
                name: e.name ? e.name : null,
                province: e.province ? e.province : null,
                city: e.city ? e.city : null,
                qualificationLevel: e.qualificationLevel ? e.qualificationLevel : null,
                page: e.page ? e.page : 1,
                pageSize: e.pageSize ? e.pageSize : 10,
                sort: e.sort ? e.sort : "DESC"
            }
        )
    }

    const searchOnChange = (value) => {
        handleObjectFilters({
            ...objectFilters,
            name: value
        })
    }

    useEffect(() => {
        let filtersToCompare = {
            isProfileCompleted: objectFilters.isProfileCompleted,
            jobState: objectFilters.jobState,
            name: objectFilters.name,
            province: objectFilters.province,
            city: objectFilters.city,
            qualificationLevel: objectFilters.qualificationLevel,
        }
        let initialFilters = {
            isProfileCompleted: null,
            jobState: null,
            name: null,
            province: null,
            city: null,
            qualificationLevel: null,
        }
        if (_.isEqual(filtersToCompare, initialFilters)) {
            setVisibleResetButton(false)
        } else {
            setVisibleResetButton(true)
        }
    }, [objectFilters])

    return (
        <div>
            <div className="workOffers">
                <div className="max-width-1200 ">

                    <Breadcrumb className="customBreadcrumb" separator="›">
                        <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY)}><FormattedMessage id="Breadcrumb_home" /></Breadcrumb.Item>
                        <Breadcrumb.Item><FormattedMessage id="Breadcrumb_user" /></Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="display-flex align-items-center">
                        <span className="size-28 weight-700 merriWeather">
                            Utenti
                        </span>
                    </div>

                    <Divider />

                    <div className="flex-center-between margin-bottom-24">
                        <div className="size-18 weight-600">
                            Lista utenti
                        </div>
                        <div className="flex-center">
                            {selectedRows && selectedRows.length > 0 &&
                                <div className="pointer" onClick={() => downloadZip()}>
                                    <Icon name={ICONS.download} size={[20, 20]} className="icon margin-right-24" color="grey" />
                                </div>}
                            <div>

                                <ButtonLine className="flex-center margin-right-24"
                                    onClick={showModal}
                                    disabled={!objectFilters.isProfileCompleted && objectFilters.isProfileCompleted !== null ? true : false}>
                                    <Icon name={ICONS.filter} size={[20, 20]} color={!objectFilters.isProfileCompleted && objectFilters.isProfileCompleted !== null ? "0E0E2C" : "#208AC6"} />

                                    <span className={!objectFilters.isProfileCompleted && objectFilters.isProfileCompleted !== null ? "flex-align-center margin-right-5 margin-left-5 grey" : "flex-align-center margin-right-5 margin-left-5 blue"}>
                                        Filtri
                                    </span>
                                    {count && count !== 0 ?
                                        <div className="numberCount">
                                            {count}
                                        </div>
                                        : null
                                    }
                                </ButtonLine>
                            </div>
                            {visibleResetButton ?
                                (<div className={"margin-right-24"} onClick={() => handleResetFilters()}>
                                    <a className="pointer grey"><u>Resetta</u></a>
                                </div>) : null}
                            <div>
                                <Input value={objectFilters.name} className={"inputField"} placeholder={"Ricerca candidati"} onChange={(e) => searchOnChange(e.target.value)} prefix={<Icon name={ICONS.search} size={[20, 20]} color={"#868696"} />} />
                            </div>
                        </div>
                    </div>

                    <div className="margin-bottom-24 margin-top-24 flex-center-between">

                        <div className="size-16 weight-700 merriWeather">
                            <span className="size-16 weight-700 merriWeather">Risultati({total})</span>

                        </div>
                        <div className="flex-align-center">

                            <div className="margin-right-32">
                                <span>
                                    Profilo:
                                </span>
                                <Select
                                    popupClassName="dropdown border-radius-0"
                                    bordered={false}
                                    value={objectFilters.isProfileCompleted}
                                    dropdownMatchSelectWidth={false}
                                    style={{ width: "fit-content" }}
                                    suffixIcon={<Icon name={ICONS.arrow_small} size={[20, 20]} color="black" />}
                                    onChange={(value) => {
                                        if (objectFilters.isProfileCompleted !== false) {
                                            handleObjectFilters({
                                                ...objectFilters,
                                                jobState: null,
                                                name: null,
                                                province: null,
                                                city: null,
                                                qualificationLevel: null,
                                                isProfileCompleted: value,
                                            });
                                            handleReset();
                                            handleCount(0);
                                        } else {
                                            handleObjectFilters({
                                                ...objectFilters,
                                                isProfileCompleted: value,
                                                page: 1
                                            })
                                        }
                                    }
                                    }
                                    options={[
                                        {
                                            options: [
                                                { label: 'Tutti', value: null },
                                                { label: 'Profili completi', value: true },
                                                { label: 'Profili non completi', value: false },
                                            ],
                                        }
                                    ]}
                                >
                                </Select>
                            </div>

                            <div className="margin-right-32">
                                <span>
                                    Stato lavorativo:
                                </span>
                                <Select
                                    className="dropdown"
                                    popupClassName="dropdown border-radius-0"
                                    bordered={false}
                                    dropdownMatchSelectWidth={false}
                                    value={objectFilters.jobState}
                                    disabled={!objectFilters.isProfileCompleted && objectFilters.isProfileCompleted !== null ? true : false}
                                    suffixIcon={<Icon name={ICONS.arrow_small} size={[20, 20]} color="black" />}
                                    onChange={(value) => handleObjectFilters({
                                        ...objectFilters,
                                        jobState: value,
                                        page: 1
                                    })}
                                    options={[
                                        {
                                            options: [
                                                { label: 'Tutti', value: null },
                                                { label: 'Occupato', value: 'Occupato' },
                                                { label: 'In cerca di lavoro', value: 'In cerca di lavoro' },
                                                { label: 'Disoccupato', value: 'Disoccupato' },
                                                { label: 'Inoccupato', value: 'Inoccupato' },
                                            ],
                                        }
                                    ]}
                                />
                            </div>
                            <div>
                                <span>
                                    Ordina per:
                                </span>
                                <Select
                                    popupClassName="dropdown border-radius-0"
                                    bordered={false}
                                    value={objectFilters.sort}
                                    dropdownMatchSelectWidth={false}
                                    style={{ width: "fit-content" }}
                                    suffixIcon={<Icon name={ICONS.arrow_small} size={[20, 20]} color="black" />}
                                    onChange={(value) => handleObjectFilters({
                                        ...objectFilters,
                                        sort: value,
                                        page: 1
                                    })}
                                    options={[
                                        {
                                            options: [
                                                { label: 'Recenti', value: 'DESC' },
                                                { label: 'Meno Recenti', value: 'ASC' },
                                            ],
                                        }
                                    ]}
                                >
                                </Select>
                            </div>
                        </div>
                    </div>

                    <div className="margin-bottom-24 margin-top-24">
                        {users && users.length !== 0 ?
                            <div className={"tableWrapper"}>
                                <Table
                                    className="offerDetailTable border-radius-0"
                                    rowSelection={rowSelection}
                                    rowKey="baseUserId"
                                    columns={columns}
                                    dataSource={users}
                                    scroll={{ x: 700 }}
                                    pagination={{
                                        showSizeChanger:false,
                                        current: objectFilters.page,
                                        pageSize: objectFilters.pageSize,
                                        total: totalUsers,
                                        onChange: (value) => {
                                            handleObjectFilters({
                                                ...objectFilters,
                                                page: value
                                            })
                                        },
                                    }}
                                />

                                <div className={"page-size-changer"}>
                                    <span className="text">
                                        <FormattedMessage id="resultsPerPage" />
                                    </span>
                                    <Select
                                        className={"selectSizeGetAll"}
                                        defaultValue={"10"}
                                        height="35px"
                                        suffixIcon={<Icon name={ICONS.arrow_small} size={[20, 20]} color="#208ac6" />}
                                        options={
                                            [
                                                { label: '10', value: '10' },
                                                { label: '20', value: '20' },
                                                { label: '50', value: '50' },
                                                { label: '100', value: '100' },
                                            ]
                                        }
                                        onChange={(value) => {
                                            handleObjectFilters({
                                                ...objectFilters,
                                                pageSize: value,
                                                page: 1
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            :
                            <div className="noCompanies">
                                <div className="noCompaniesIcon flex-center">
                                    <Icon className={"infoIcon"} name={ICONS.user_multi_100} size={[100, 100]} color={"#C41A43"} />
                                </div>
                                <div className="size-18 black weight-600">
                                    <FormattedMessage id="Notification_noUsers_1" />
                                </div>
                                <div className="black opacity-70">
                                    <FormattedMessage id="Notification_noUsers_2" />
                                </div>
                            </div>
                        }

                    </div>

                </div>
            </div>
            <ModalFilter
                resetFilters={resetFilters}
                handleObjectFilters={handleObjectFilters}
                objectFilters={objectFilters}
                changeReset={changeReset}
                open={isModalOpen}
                onOk={handleOk}
                handleCount={handleCount}
                searchToModal={search}
                handleSearch={handleSearch}
                onCancel={handleCancel}
                reset={handleReset} />
        </div>
    )
}

export default SysAdminUsersList
