import React from "react";
import {Select as AntSelect} from "antd";
import styled from "styled-components"

export const Select = styled(AntSelect)`
    
    .ant-select-selector{
        border-radius: 3px;
        border:none;
    }

    &.ant-select > .ant-select-selector{
        background: #E9F3F9;
        height: ${props => props.height ? props.height : "48px"};
        padding: 0 16px;
        box-shadow: inset 0px -1px 0px #D2E8F4;
        border: none;
        }

    &.ant-select > .ant-select-selector > .ant-select-selection-placeholder{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #0E0E2C;
        line-height: ${props => props.height ? props.height : "48px"};
    }

    &.ant-select > .ant-select-selector >.ant-select-selection-item{
        display: flex;
        align-items: center;
    }

    &.ant-select > .ant-select-selector:hover{
        background-color: #F4F9FC;
        box-shadow: inset 0px -1px 0px #D2E8F4;
    }

    &.ant-select-focused .ant-select-selector{
        box-shadow: 0 0 0 1px #208AC6;
    }
    
    .ant-select-clear{
        width: 20px;
        background-color: #F4F9FC;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .ant-select-clear:hover{
        background-color: #E9F3F9;
    }
    
    &.ant-select-status-error .ant-select-selector{
        box-shadow: inset 0px -1px 0px #FC4853;
        
    }
    
    &.ant-select-status-error .ant-select-selector:hover{
        box-shadow: inset 0px -1px 0px #FC4853;
        
    }
    
    &.ant-select-open .ant-select-arrow{
        transform: rotate(180deg);
    }
    
    .ant-select-selection-search input{
            height: 100% !important;
        }

`


const SelectPrefixWrapper = styled.div`
        position: relative;

        .select-prefix-icon{
            position: absolute;
            z-index: 1;
            width: 3rem;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .ant-select .ant-select-selector {
            padding-left: 40px;
        }
          
        .ant-select-selection-search{
            display: flex;
            align-items: center;
            padding-left: 30px;
        }
        
    `


export default function SelectPrefix(props) {

    return (
        <SelectPrefixWrapper>
            {props.prefixicon &&
            <div className="select-prefix-icon">
                {props.prefixicon}
            </div>
            }

            <Select {...props}/>
        </SelectPrefixWrapper>
    )

}
