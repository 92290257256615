import React, {useEffect} from "react";
import Icon from "src/component/Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import {useLocation, useNavigate} from "react-router";
import "src/component/Layout/Registration/RegistrationUser/RegistrationUser.scss"
import {FormattedMessage, injectIntl} from "react-intl";
import {ButtonUser} from "../../../Useful/Button/ButtonUser";
import {ButtonLine} from "../../../Useful/Button/ButtonLine";
import {InputLogin} from "../../../Useful/Input/InputLogin";
import {Select} from "../../../Useful/Select/Select";
import {Checkbox} from "../../../Useful/Checkbox/Checkbox";
import actions from "src/redux/userProfile/actions"
import {useDispatch, useSelector} from "react-redux";
import {Form} from "antd";
import UserPicture from "../../../../assets/image/PictureLoginUser.png";
import {PUBLIC_ROUTE} from "src/route.constants";
import {HOW_DO_YOU_KNOW_US_USER} from "src/utils/utility";
import mobileLogo from "../../../../assets/logo/logo-VT-blue-mobile.svg";
import AdvantagesUser from "../../../../assets/svg/AdvantagesUser.svg";

const {createUser} = actions

function RegistrationUser() {


    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [form] = Form.useForm()

    const confirm = useSelector(state => state.UserProfile.confirm)
    const isButtonLoading = useSelector(state => state.UserProfile.isButtonLoading)

    const [toggleType, setToggleType] = React.useState(false)
    const [toggleIconPassword, setToggleIconPassword] = React.useState(false)

    const ShowPassword = () => {
        setToggleType(!toggleType)
        setToggleIconPassword(!toggleIconPassword)
    }

    const goTo = (path) => {
        const pathName = location.pathname
        let newPathName;

        switch (path) {
            case "company":
                newPathName = pathName.replace("user/registration", "company/login")
                navigate(newPathName, {replace: true})
                break;
            case "user":
                newPathName = pathName.replace("registration", "login")
                navigate(newPathName, {replace: true})
                break;
            default:
                newPathName = pathName.replace("/user/registration", "/confirm")
                navigate(newPathName, {replace: true})
                break;
        }

    }

    useEffect(() => {
        if (confirm === true) {
            navigate("/confirm")
        }
    }, [confirm])

    return (

        <div className={"registration-user-container"}>
            <div className="registration-content">
                <div className={"flex"} style={{flex: 1, gap: "50px"}}>
                    <div className={"advantagesSection flex-justify-center"} style={{flex: "1 1 50%"}}>
                        <div style={{width: "80%"}}>
                            <div className={"platformAdvantages"}>
                                <div className={"platformAdvantagesHeader size-18"}>
                                    <FormattedMessage id="UserAdvantages_Header" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                </div>
                                <div className={"platformAdvantagesDescription size-16"}>
                                    <FormattedMessage id="UserAdvantages_SubHeader" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                </div>
                            </div>
                            <div className={"flex-column gap-32"}>
                                <div className={"flex-start flex-align-center gap-16"}>
                                    <img src={AdvantagesUser}/>

                                    <div className={"advantages size-16 merriWeather"}>
                                        <FormattedMessage id="UserAdvantages_1" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                    </div>
                                </div>

                                <div className={"flex-start flex-align-center gap-16"}>
                                    <img src={AdvantagesUser}/>

                                    <div className={"advantages size-16 merriWeather"}>
                                        <FormattedMessage id="UserAdvantages_2" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                    </div>
                                </div>

                                <div className={"flex-start flex-align-center gap-16"}>
                                    <img src={AdvantagesUser}/>

                                    <div className={"advantages size-16 merriWeather"}>
                                        <FormattedMessage id="UserAdvantages_3" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="image margin-top-30">
                                <img src={UserPicture} className={"side-image"}/>
                            </div>
                            <div className={"technicalSupport"}>
                                <div><FormattedMessage id="TechnicalSupport_User"/></div>
                                <div><FormattedMessage id="TechnicalSupport_1"/></div>
                                <div><FormattedMessage id="TechnicalSupport_2"/></div>
                                <div><FormattedMessage id="TechnicalSupport_3"/></div>
                            </div>
                        </div>
                    </div>

                    <div style={{flex: "1 1 50%"}}>
                        <div className={"containerRegistrationUserForm"}>
                            <header>
                                <div className="header_mobile">
                                    <div className={"back"}>
                                        <span onClick={() => goTo("user")}><Icon className="pointer" name={ICONS.arrow_2} size={[20, 20]}/></span>
                                        <div className="p_back margin-5">Indietro</div>
                                    </div>
                                    <h1>Registrazione</h1>
                                    <a href={"https://www.vt-job.it"}>
                                        <img className={"user-mobile-logo"} src={mobileLogo}/>
                                    </a>
                                </div>
                                <div className="header_registration">
                                    <p className={"corp_100"}>Benvenuto!</p>
                                    <p><FormattedMessage id="UserRegistration_head2"/></p>
                                </div>
                            </header>
                            <div className={"registration_recovery"}>
                                <p>Hai già un account?<a className={"corp2_100 pointer weight-700 font-size-16"} onClick={() => navigate(PUBLIC_ROUTE.USER_LOG_IN)}><FormattedMessage id="singIn"/></a></p>
                            </div>

                            <main>
                                <h1 className={"corp_100"}><FormattedMessage id="UserRegistration_main_title2"/></h1>
                                <Form
                                    form={form}
                                    style={{flexDirection: "column"}}
                                    layout={"vertical"}
                                    id="registrationUserForm"
                                    className="nameSurname"
                                    onFinish={(value) => {
                                        let data = {
                                            "firstName": value.firstName,
                                            "lastName": value.lastName,
                                            "email": value.email,
                                            "password": value.password,
                                            "howDoYouKnowUs": value.howDoYouKnowUs
                                        }
                                        dispatch(createUser(data))
                                    }}
                                    onFinishFailed={(value) => {
                                    }}
                                >
                                    <Form.Item
                                        name={"firstName"}
                                        label={<FormattedMessage id="UserRegistration_main_name"/>}
                                        rules={[{required: true, message: false}]}
                                        className="weight-600 size-14"
                                    >
                                        <InputLogin className={"input"}
                                                    placeholder="Nome"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={"lastName"}
                                        label={<FormattedMessage id="UserRegistration_main_surname"/>}
                                        rules={[{required: true, message: false}]}
                                        className="weight-600 size-14"
                                    >
                                        <InputLogin className={"input"}
                                                    placeholder="Cognome"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={"email"}
                                        label={<FormattedMessage id="UserRegistration_main_email"/>}
                                        rules={[{type: 'email', message: false}, {required: true, message: false}]}
                                        className="weight-600 size-14 inline-block width-100 margin-right-24"
                                    >
                                        <InputLogin className={"input"}
                                                    placeholder="Inserisci un'email personale"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={"password"}
                                        label={<FormattedMessage id="UserRegistration_main_password"/>}
                                        rules={[{required: true, message: false}]}
                                        className="weight-600 size-14 inline-block width-100 margin-right-24"
                                    >
                                        <InputLogin type={toggleType ? "text" : "password"} className={"input"} suffix={<div onClick={(e) => ShowPassword()}><Icon name={toggleIconPassword ? ICONS.eye : ICONS.eye_hide} className="flex-center pointer" color="#208AC6" size={[20, 20]}/></div>}
                                                    placeholder="Inserire la password"/>
                                    </Form.Item>

                                    <Form.Item
                                        name={"howDoYouKnowUs"}
                                        label={<FormattedMessage id="howMeetVT"/>}
                                        rules={[{required: true, message: "required"}]}
                                        className="weight-600 size-14 inline-block width-100 margin-right-24"
                                    >
                                        <Select className={"select"} placeholder="- Seleziona -"
                                                getPopupContainer={trigger => trigger.parentNode}
                                                suffixIcon={<Icon name={ICONS.arrow} color={"#208AC6"} size={[20, 20]}/>}
                                                options={HOW_DO_YOU_KNOW_US_USER}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className={"generalConditions"}
                                        name={"policy"}
                                        valuePropName={"checked"}
                                        rules={[
                                            {required: true, message: false},
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    if (value) {
                                                        return Promise.resolve()
                                                    } else {
                                                        return Promise.reject(new Error())
                                                    }
                                                }
                                            })
                                        ]}
                                    >
                                        <div className="flex-align-center">
                                            <Checkbox className="checkbox"/>
                                            <p className={"required"}>
                                                <FormattedMessage id="generalConditions1"/>
                                                <a href="https://www.iubenda.com/privacy-policy/82649189" target="_blank">
                                                    <FormattedMessage id="generalConditions2"/>
                                                </a>
                                                <FormattedMessage id="generalConditions3"/>
                                            </p>
                                        </div>
                                    </Form.Item>
                                </Form>
                                {/*<p onClick={goTo}> button Registrazione </p>*/}
                                <ButtonUser form="registrationUserForm" type="primary" htmlType="submit" icon={<Icon name={ICONS.tik} className="margin-5 opacity-50" size={[20, 20]}/>} block className={"save flex-center"} loading={isButtonLoading}>
                                    Registrati
                                </ButtonUser>
                            </main>
                            <footer>
                                <div className={"loginAsCompany"}>
                                    <h1><FormattedMessage id="loginAsCompany_title"/></h1>
                                    <p><FormattedMessage id="loginAsCompany_text"/></p>
                                    {/*<p onClick={() => goTo("company")}>button accedere come azienda</p>*/}
                                    <ButtonLine block onClick={() => goTo("company")}><FormattedMessage id="loginAsCompany_button"/></ButtonLine>
                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default injectIntl(RegistrationUser)
