export const ICONS = {
    crm: "crm",
    download: "download",
    settings: "settings",
    eye_hide: "eye_hide",
    inbox: "inbox",
    pipeline: "pipeline",
    notes: "notes",
    close: "close",
    tel: "tel",
    twitter: "twitter",
    facebook: "facebook",
    linkedin: "linkedin",
    whatsapp: "whatsapp",
    link: "link",
    edit: "edit",
    heart: "heart",
    picture: "picture",
    pin: "pin",
    tik_all_read: "tik_all_read",
    refresh: "refresh",
    exit: "exit",
    arrow_link: "arrow_link",
    info: "info",
    dislike: "dislike",
    like: "like",
    archives: "archives",
    doc_add: "doc_add",
    doc: "doc",
    search: "search",
    business_add: "business_add",
    business: "business",
    more: "more",
    delete: "delete",
    file: "file",
    file_add: "file_add",
    clock: "clock",
    lock: "lock",
    mail: "mail",
    calendar: "calendar",
    add: "add",
    bold: "bold",
    number_list: "number_list",
    list: "list",
    underline: "underline",
    italic: "italic",
    eye: "eye",
    user_multi: "user_multi",
    user_add: "user_add",
    user_ok: "user_ok",
    user: "user",
    bag: "bag",
    tik: "tik",
    arrow_2: "arrow_2",
    filter: "filter",
    arrow: "arrow",
    arrow_small: "arrow_small",
    notify: "notify",
    doc_add_100: "doc_add_100",
    user_multi_100: "user_multi_100",
    business_100: "business_100",
    notify_100: "notify_100",
    heart_100: "heart_100",
    close_switch: "close_switch",
    ok: "ok",
    tik_checkbox: "tik_checkbox",
    union: "union",
    burger: "burger",
    help: "Help"
}
