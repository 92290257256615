import React from "react";
import {Form} from "antd";
import TextArea from "antd/es/input/TextArea";

function QuestionList(props) {

    const {record, listQuestion, setListQuestion} = props

    function addQuestion(e, index, question) {

        listQuestion[index].answer = e.target.value
        setListQuestion([...listQuestion])
        }

    return(
        <div>
            {record && record.question && record.question.map((element, index) => (
                <div>
                    <Form.Item
                        name={"description" + index}
                        label={element.question}
                        className="weight-600 flex-column size-14"
                        rules={[
                            {
                                required: true,
                                message: false,
                            },
                        ]}
                    >
                        <TextArea placeholder="Descriviti brevemente quello che sai fare" style={{minWidth: "100%"}} rows={5} onChange={(e)=>{addQuestion(e, index, element)}}/>
                    </Form.Item>
                </div>))}
        </div>
    )
}

export default QuestionList
