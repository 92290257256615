const actions = {

    INIT_CANDIDATURE: 'INIT_CANDIDATURE',

    GET_ALL_CANDIDATURE: 'GET_ALL_CANDIDATURE',
    GET_ALL_CANDIDATURE_SUCCESS: 'GET_ALL_CANDIDATURE_SUCCESS',
    GET_ALL_CANDIDATURE_ERROR: 'GET_ALL_CANDIDATURE_ERROR',

    GET_ALL_MY_CANDIDATURE: 'GET_ALL_MY_CANDIDATURE',
    GET_ALL_MY_CANDIDATURE_SUCCESS: 'GET_ALL_MY_CANDIDATURE_SUCCESS',
    GET_ALL_MY_CANDIDATURE_ERROR: 'GET_ALL_MY_CANDIDATURE_ERROR',

    GET_CANDIDATURE_BY_ID: "GET_CANDIDATURE_BY_ID",
    GET_CANDIDATURE_BY_ID_SUCCESS: "GET_CANDIDATURE_BY_ID_SUCCESS",
    GET_CANDIDATURE_BY_ID_ERROR: "GET_CANDIDATURE_BY_ID_ERROR",

    GET_CANDIDATURE_BY_OFFERT_ID: "GET_CANDIDATURE_BY_OFFERT_ID",
    GET_CANDIDATURE_BY_OFFERT_ID_SUCCESS: "GET_CANDIDATURE_BY_OFFERT_ID_SUCCESS",
    GET_CANDIDATURE_BY_OFFERT_ID_ERROR: "GET_CANDIDATURE_BY_OFFERT_ID_ERROR",

    GET_CANDIDATURE_COUNT_BY_OFFERT_ID: "GET_CANDIDATURE_COUNT_BY_OFFERT_ID",
    GET_CANDIDATURE_COUNT_BY_OFFERT_ID_SUCCESS: "GET_CANDIDATURE_COUNT_BY_OFFERT_ID_SUCCESS",
    GET_CANDIDATURE_COUNT_BY_OFFERT_ID_ERROR: "GET_CANDIDATURE_COUNT_BY_OFFERT_ID_ERROR",

    SAVE_CANDIDATURE: "SAVE_CANDIDATURE",
    SAVE_CANDIDATURE_SUCCESS: "SAVE_CANDIDATURE_SUCCESS",
    SAVE_CANDIDATURE_ERROR: "SAVE_CANDIDATURE_ERROR",

    UPDATE_CANDIDATURE: "UPDATE_CANDIDATURE",
    UPDATE_CANDIDATURE_SUCCESS: "UPDATE_CANDIDATURE_SUCCESS",
    UPDATE_CANDIDATURE_ERROR: "UPDATE_CANDIDATURE_ERROR",

    UPDATE_CANDIDATURE_STATE_AND_VIEWED: "UPDATE_CANDIDATURE_STATE_AND_VIEWED",
    UPDATE_CANDIDATURE_STATE_AND_VIEWED_SUCCESS: "xUPDATE_CANDIDATURE_STATE_AND_VIEWED_SUCCESS",
    UPDATE_CANDIDATURE_STATE_AND_VIEWED_ERROR: "UPDATE_CANDIDATURE_STATE_AND_VIEWED_ERROR",

    DELETE_CANDIDATURE: "DELETE_CANDIDATURE",
    DELETE_CANDIDATURE_SUCCESS: "DELETE_CANDIDATURE_SUCCESS",
    DELETE_CANDIDATURE_ERROR: "DELETE_CANDIDATURE_ERROR",

    DELETE_CANDIDATURE_PAGINATED: "DELETE_CANDIDATURE_PAGINATED",
    DELETE_CANDIDATURE_PAGINATED_SUCCESS: "DELETE_CANDIDATURE_PAGINATED_SUCCESS",
    DELETE_CANDIDATURE_PAGINATED_ERROR: "DELETE_CANDIDATURE_PAGINATED_ERROR",

    GET_CANDIDATURE_CV: "GET_CANDIDATURE_CV",
    GET_CANDIDATURE_CV_SUCCESS: "GET_CANDIDATURE_CV_SUCCESS",
    GET_CANDIDATURE_CV_ERROR: "GET_CANDIDATURE_CV_ERROR",

    CONFIRM_CANDIDATURE: "CONFIRM_CANDIDATURE",

    RESET_CANDIDATURE: "RESET_CANDIDATURE",

    DOWNLOAD_MULTIPLE_CV: "DOWNLOAD_MULTIPLE_CV",
    DOWNLOAD_MULTIPLE_CV_SUCCESS: "DOWNLOAD_MULTIPLE_CV_SUCCESS",
    DOWNLOAD_MULTIPLE_CV_ERROR: "DOWNLOAD_MULTIPLE_CV_ERROR",

    GET_ALL_CANDIDATURE_BY_USER_AND_COMPANY: "GET_ALL_CANDIDATURE_BY_USER_AND_COMPANY",
    GET_ALL_CANDIDATURE_BY_USER_AND_COMPANY_SUCCESS: "GET_ALL_CANDIDATURE_BY_USER_AND_COMPANY_SUCCESS",
    GET_ALL_CANDIDATURE_BY_USER_AND_COMPANY_ERROR: "GET_ALL_CANDIDATURE_BY_USER_AND_COMPANY_ERROR",

    CHANGE_VIEW_FOR_DOT: "CHANGE_VIEW_FOR_DOT",
    CHANGE_VIEW_FOR_DOT_SUCCESS: "CHANGE_VIEW_FOR_DOT_SUCCESS",
    CHANGE_VIEW_FOR_DOT_ERROR: "CHANGE_VIEW_FOR_DOT_ERROR",

    getAll: (data) => ({
        type: actions.GET_ALL_CANDIDATURE,
        payload: data
    }),

    getAllMyCandidature: (data) => ({
        type: actions.GET_ALL_MY_CANDIDATURE,
        payload: data
    }),

    getCandidatureById: (candidatureId) => ({
        type: actions.GET_CANDIDATURE_BY_ID,
        id: candidatureId
    }),

    getCandidatureByOffertId: (data) => ({
       type: actions.GET_CANDIDATURE_BY_OFFERT_ID,
       payload: data
    }),

    getCountForCandidatureByOffert: (data) => ({
        type: actions.GET_CANDIDATURE_COUNT_BY_OFFERT_ID,
        payload: data
    }),

    save: (data) => ({
        type: actions.SAVE_CANDIDATURE,
        payload: data
    }),

    update: (candidatureId,data, id, state) => ({
        type: actions.UPDATE_CANDIDATURE,
        id: candidatureId,
        payload: data,
        offerId: id,
        state: state,
    }),

    deleteData: (id,state,offerId) => ({
        type: actions.DELETE_CANDIDATURE_PAGINATED,
        id: id,
        state: state,
        offerId: offerId
    }),

    deleteCandidature: (id) => ({
       type: actions.DELETE_CANDIDATURE,
       id: id
    }),

    getCvSource: (data,view) => ({
        type: actions.GET_CANDIDATURE_CV,
        payload: data,
        view: view
    }),

    confirmCandidature: ()=>({
        type: actions.CONFIRM_CANDIDATURE
    }),

    resetStateCandidature: ()=>({
       type: actions.RESET_CANDIDATURE
    }),

    multipleCvDownload: (names)=>({
        type: actions.DOWNLOAD_MULTIPLE_CV,
        names: names
    }),
    getAllCandidatureByUserAndCompany: (id) =>({
        type: actions.GET_ALL_CANDIDATURE_BY_USER_AND_COMPANY,
        id: id
    }),
    setViewDot: (id, data) => ({
        type: actions.CHANGE_VIEW_FOR_DOT,
        id: id,
        payload: data
    }),

    updateCandidatureStateAndViewed: (candidatureId, data, id, state) => ({
        type: actions.UPDATE_CANDIDATURE_STATE_AND_VIEWED,
        id: candidatureId,
        payload: data,
        offerId: id,
        state: state,
    })
};

export default actions;
