import actions from "./actions";

const initState = {
    idToken: null,
    role: null,
    recoverPasswordSuccess: false,
    resetPasswordSuccess: false,
    resetNewPasswordSuccess: false,
    errorMessage: null,
    message: null,
    loading: false,
    firstName: null,
    lastName: null,
    activateStatus: null,
    isCompleted: false,
    isEnabled: false,
    isCurrentPasswordEqual: false,
    openModal: false,
    isButtonLoading: false,
    url: null,
    isLoggingOut : false
}

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case action.INIT:
            return {...initState}
        case actions.LOGOUT:
            return {...initState, isLoggingOut : true}
        case actions.SET_IS_ENABLED:
            return{
                ...state,
                isCompleted: action.isCompleted,
                isEnabled: action.isEnabled
            }
        case actions.LOGIN_REQUEST:
            return{
                ...state,
                isButtonLoading: true
            }
        case actions.LOGIN_SUCCESS:
            return{
                ...state,
                idToken: action.token,
                role: action.role,
                firstName: action.firstName,
                lastName: action.lastName,
                isButtonLoading: false,
                isLoggingOut : false
            }
        case actions.LOGIN_ERROR:
            return{
                ...state,
                errorMessage: action.payload,
                isButtonLoading: false,
            }
        case actions.RESET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: null
            }
        case actions.GET_ID_TOKEN:
            return {
                ...state,
                idToken: localStorage.getItem("id_token")
            }
        case actions.GET_ROLES:
            return {
                ...state,
                role: localStorage.getItem("role")
            }
        case actions.RECOVER_PASSWORD_SUCCESS:
            return {
                ...state,
                recoverPasswordSuccess: true,
                message: action.message
            }
        case actions.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordSuccess: true,
                message: action.message
            }
        case actions.CHECK_ACTIVE_PROFILE_SUCCESS:
            return {
                ...state,
                activateStatus: true
            }
        case actions.CHECK_ACTIVE_PROFILE_ERROR:
            return {
                ...state,
                activateStatus: false
            }
        case actions.LOADING_TRUE:
            return {
                ...state,
                loading: true
            }
        case actions.LOADING_FALSE:
            return {
                ...state,
                loading: false
            }
        case actions.SAVE_URL:
            return {
                ...state,
                url: action.url
            }
        case actions.RESET_NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                resetNewPasswordSuccess: true,
                message: action.payload
            }
        case actions.RESET_STATE_NEW_PASSWORD:
            return{
                ...state,
                resetNewPasswordSuccess: false,
            }
        case actions.CHECK_OLD_PASSWORD_SUCCESS:
            return{
                ...state,
                isCurrentPasswordEqual: true
            }
        case actions.CHECK_OLD_PASSWORD_ERROR:
            return{
                ...state,
                isCurrentPasswordEqual: false
            }
        case actions.RESET_OLD_PASSWORD:
            return{
                ...state,
                isCurrentPasswordEqual: false
            }
        default:
            return state;
    }

}
