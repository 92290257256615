import React, {useEffect, useState} from "react";
import {Button} from "antd";
import Icon from "../../Icon/Sprite";
import {ICONS} from "../../../assets/sprite/iconsNames";
import {Select} from "../../Useful/Select/Select";
import {OFFER_FILTERS_CANDIDATE} from "../../../utils/utility";
import candidatureActions from "../../../redux/candidature/actions";
import {useDispatch, useSelector} from "react-redux";
import {EmailModal} from "../EmailModal/EmailModal";

function CandidateCardStateTable(props){
    const [view, setView] = useState(true)
    const [sendEmail, setSendEmail] = useState(false)
    const sended = useSelector(state => state.Email.isSendEmail)

    useEffect(()=>{
        if( props.record.candidatureState !== "none" &&  props.record.candidatureState !== "Rifiutato"){
           setView(false)
        }
    },[])

    useEffect(()=>{
        if(sended){
            setSendEmail(false)
        }
    },[sended])

    return(
        <>
            {view ?
                <div className="flex-center-evenly">
                    {
                        props.record.candidatureState === OFFER_FILTERS_CANDIDATE.ALL.value ?
                            <>
                                <Button className="flex-center margin-5" key={props.record.id} onClick={() => {
                                    setView(false)
                                    props.handleCandidateTo(props.record, OFFER_FILTERS_CANDIDATE.INTERVIEW.value)}}>
                                    <Icon name={ICONS.like} size={[20, 20]} color={"#6E5AE8"}/>
                                </Button>
                                <Button className="flex-center margin-5" onClick={()=>{props.handleCandidateTo(props.record,OFFER_FILTERS_CANDIDATE.DISLIKE.value)}}>
                                    <Icon name={ICONS.dislike} size={[20, 20]} color={"grey"}/>
                                </Button>
                            </>

                        :

                            <>
                                <Button className="flex-center" onClick={()=>{props.handleCandidateTo(props.record,OFFER_FILTERS_CANDIDATE.PRESELECTED.value)}} style={{backgroundColor: "#0E0E2C"}}>
                                    <Icon name={ICONS.dislike} size={[20, 20]} color={"white"}/>
                                </Button>
                            </>

                    }

                </div> :
                <div className="flex-center-evenly" key={props.record.id}>
                    <Button className="flex-center margin-right-20" onClick={() => setSendEmail(true)}>
                        <Icon name={ICONS.mail} size={[20, 20]} color={"#6E5AE8"}/>
                    </Button>
                    <Select
                        className={"detailTable"}
                        style={{width: 150}}
                        height={"36px"}
                        dropdownMatchSelectWidth={false}
                        defaultValue={props.record.candidatureState !== "none" && props.record.candidatureState}
                        suffixIcon={
                            <Icon name={ICONS.arrow_small} size={[20, 20]} color={"#0E0E2C"}/>
                        }
                        onChange={(e)=>{
                                props.handleCandidateTo(props.record,e)

                                props.record.candidatureState = e.value
                                // dispatch(update(
                                //     props.record.id,
                                //     props.record,
                                //     props.id
                                //     )
                                // )
                        }
                        }
                        options={[
                            {
                                label: OFFER_FILTERS_CANDIDATE.WAITING.label,
                                value: OFFER_FILTERS_CANDIDATE.WAITING.value
                            },
                            {
                                label: OFFER_FILTERS_CANDIDATE.INTERVIEW.label,
                                value: OFFER_FILTERS_CANDIDATE.INTERVIEW.value
                            },
                            {
                                label: OFFER_FILTERS_CANDIDATE.PRESELECTED.label,
                                value: OFFER_FILTERS_CANDIDATE.PRESELECTED.value
                            },
                            {
                                label: OFFER_FILTERS_CANDIDATE.DISLIKE.label,
                                value: OFFER_FILTERS_CANDIDATE.DISLIKE.value
                            },
                        ]}
                        // onChange={(e) => getsSelectCandidature(e)}
                    />
                </div>
            }
            <EmailModal record={props.record} sendEmail={sendEmail} setSendEmail={setSendEmail}/>
        </>
    )
}
export default CandidateCardStateTable
