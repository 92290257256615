import React, {useEffect} from "react";
import "src/Pages/notification/NotificationCompanyStyle.scss";
import {ICONS} from "src/assets/sprite/iconsNames";
import {Breadcrumb, Button, ConfigProvider, List} from "antd";
import {useNavigate} from "react-router";
import {FormattedMessage} from "react-intl";
import {PRIVATE_COMPANY_ROUTE} from "src/route.constants";
import Icon from "src/component/Icon/Sprite";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/notification/actions";

const {getAllNotification} = actions

function NotificationUser() {

    const dispatch = useDispatch()
    const navigate = useNavigate()


    const notifications = useSelector(state => state.Notification.listNotification)

    const data = [];

    useEffect(()=>{
        dispatch(getAllNotification())
    },[])

    const customizedEmpty = () => (
        <div className="noNotification">
            <div className="noNotificationIcon flex-center">

                <Icon className={"infoIcon"} name={ICONS.notify} size={[20, 20]} color={"#C41A43"}/>
            </div>
            <div className="size-18 black weight-600">
                <FormattedMessage id="Notification_noNotification_1"/>
            </div>
            <div className="black opacity-70">
                <FormattedMessage id="Notification_noNotification_2"/>
            </div>
        </div>
    )

    return (
        <div>
            <div className="notification">
                <div className="max-width-1200 ">

                    <Breadcrumb className="customBreadcrumb" separator="›">
                        <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY)}><FormattedMessage id="Breadcrumb_home"/></Breadcrumb.Item>
                        <Breadcrumb.Item><FormattedMessage id="Breadcrumb_notification"/></Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="title flex-center-between margin-bottom-24">
                        <div>
                            <span onClick={() => navigate(-1)}>
                                <Icon className="opacity-50 margin-right-22 pointer" name={ICONS.arrow_2} size={[20, 20]} color={"#0E0E2C"}/>
                            </span>
                            <span className="size-28 weight-700 merriWeather">
                                <FormattedMessage id="topBar_notification"/>
                            </span>
                        </div>
                        <div className="flex">
                            <div>
                                <Button className="reloadButton flex-center">
                                    <Icon name={ICONS.refresh} size={[20, 20]} color={"#208AC6"}/>
                                    <span className="size-14 blue text"><FormattedMessage id="reload"/></span>
                                </Button>
                            </div>
                        </div>
                    </div>


                    <ConfigProvider renderEmpty={customizedEmpty}>
                        <List
                            itemLayout="horizontal"
                            dataSource={data}
                            bordered={data.length === 0}
                            header={data.length !== 0 &&
                            <div className={"flex-center-between"}>
                                <div className="size-14 weight-600">
                                    <FormattedMessage id="topBar_newNotification"/>
                                </div>

                                <div className="flex-align-center opacity-50">
                                    {/*<div className="flex-align-center margin-left-18">*/}
                                    {/*    <Icon className="opacity-50" name={ICONS.tik_all_read} size={[20, 20]} color={"#0E0E2C"}/>*/}
                                    {/*</div>*/}

                                    {/*<div className="size-14 weight-600 underlined">*/}
                                    {/*    <FormattedMessage id="topBar_MarkAsRead"/>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            }
                            renderItem={(item) => (
                                <List.Item>
                                    <div>
                                        <div className="notifyIcon flex-center">
                                            <Icon name={ICONS.user} size={[20, 20]} color={"#0E0E2C"}/>
                                        </div>
                                    </div>
                                    <div className={`size-14 ${!item.read ? "weight-700" : null}`}>
                                        {item.title}
                                    </div>
                                    <div className="size-14">
                                        {item.notificationBody}
                                    </div>
                                    <div className="opacity-70">
                                        {item.time}
                                    </div>
                                    <div className="justify-end">
                                        {item.read ? null : <div className="blueCircle"/>}
                                    </div>
                                </List.Item>
                            )}
                        />
                    </ConfigProvider>
                </div>
            </div>
        </div>
    )
}

export default NotificationUser
