const actions = {

    INIT_EMPLOYEE: 'INIT_EMPLOYEE',

    GET_ALL_EMPLOYEE: "GET_ALL_EMPLOYEE",
    GET_ALL_EMPLOYEE_SUCCESS: "GET_ALL_EMPLOYEE_SUCCESS",
    GET_ALL_EMPLOYEE_ERROR: "GET_ALL_EMPLOYEE_ERROR",

    GET_ALL_EMPLOYEE_BY_SYS_ADMIN: "GET_ALL_EMPLOYEE_BY_SYS_ADMIN",
    GET_ALL_EMPLOYEE_BY_SYS_ADMIN_SUCCESS: "GET_ALL_EMPLOYEE_BY_SYS_ADMIN_SUCCESS",
    GET_ALL_EMPLOYEE_BY_SYS_ADMIN_ERROR: "GET_ALL_EMPLOYEE_BY_SYS_ADMIN_ERROR",

    GET_EMPLOYEE_BY_ID: "GET_EMPLOYEE_BY_ID",
    GET_EMPLOYEE_BY_ID_SUCCESS: "GET_EMPLOYEE_BY_ID_SUCCESS",
    GET_EMPLOYEE_BY_ID_ERROR: "GET_EMPLOYEE_BY_ID_ERROR",

    SAVE_EMPLOYEE: "SAVE_EMPLOYEE",
    SAVE_EMPLOYEE_SUCCESS: "SAVE_EMPLOYEE_SUCCESS",
    SAVE_EMPLOYEE_ERROR: "SAVE_EMPLOYEE_ERROR",

    UPDATE_EMPLOYEE: "UPDATE_EMPLOYEE",
    UPDATE_EMPLOYEE_SUCCESS: "UPDATE_EMPLOYEE_SUCCESS",
    UPDATE_EMPLOYEE_ERROR: "UPDATE_EMPLOYEE_ERROR",

    DELETE_EMPLOYEE: "DELETE_EMPLOYEE",
    DELETE_EMPLOYEE_SUCCESS: "DELETE_EMPLOYEE_SUCCESS",
    DELETE_EMPLOYEE_ERROR: "DELETE_EMPLOYEE_ERROR",

    getAll: () => ({
        type: actions.GET_ALL_EMPLOYEE
    }),

    getAllBySysAdmin: (companyId) => ({
       type: actions.GET_ALL_EMPLOYEE_BY_SYS_ADMIN,
        id: companyId
    }),

    get: (employeeId) => ({
        type: actions.GET_EMPLOYEE_BY_ID,
        id: employeeId
    }),

    save: (data) => ({
        type: actions.SAVE_EMPLOYEE,
        payload: data
    }),

    update: (employeeId, data) => ({
        type: actions.UPDATE_EMPLOYEE,
        id: employeeId,
        payload: data
    }),

    deleteEmployee: (employeeId, companyId) => ({
        type: actions.DELETE_EMPLOYEE,
        employeeId: employeeId,
        companyId: companyId,
    })
};

export default actions;
