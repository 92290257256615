import {all, call, fork, put, takeEvery} from 'redux-saga/effects'

import {endpoints} from "src/config/site.config";

import SuperFetch from "../../helpers/superFetch";
import actions from "./actions";
import notificationActions from "src/redux/notification/actions"
import {getRole, getToken, isTokenExpired} from "src/helpers/utility";
import authActions from "src/redux/auth/actions";
import {deleteFcmToken, getFcmToken, messaging} from "src/firebase";
import {ROLES} from "../../utils/utility";

export function* loginRequest() {
    yield takeEvery(actions.LOGIN_REQUEST, function* (data) {
        const {response, error} = yield call(SuperFetch.post, endpoints.login, data.payload, false)
        if (response) {
            yield localStorage.setItem('id', response.id);
            yield localStorage.setItem('email', response.email);
            yield localStorage.setItem('id_token', response.access_token);
            yield localStorage.setItem('expires_at', response.expires_at);
            yield localStorage.setItem('role', response.role);
            yield localStorage.setItem('firstName', response.firstName);
            yield localStorage.setItem('lastName', response.lastName);
            yield localStorage.setItem('companyName', response.companyName);
            if (localStorage.getItem("role") !== ROLES.SYS_ADMIN)
                yield localStorage.setItem('companyId', response.companyId);

            yield put({
                type: actions.LOGIN_SUCCESS,
                token: response.access_token,
                role: response.role
            })

            if (response.role === "ROLE_USER") {
                localStorage.setItem("isEnabled", response.enabled)
                localStorage.setItem("isCompleted", response.isCompleted)
                yield put({
                    type: authActions.SET_IS_ENABLED,
                    isEnabled: response.enabled,
                    isCompleted: response.isCompleted,
                })
            }

            if (("Notification" in window)) {
                let notificationPermission = yield Notification.requestPermission()

                if(notificationPermission === "granted"){
                    let messagingInstance = yield messaging

                    if(messagingInstance){
                        let fcmToken = yield call(getFcmToken)

                        yield put({
                            type: notificationActions.SEND_FCM_TOKEN,
                            token: fcmToken
                        })
                    }
                }
            }


        } else {
            yield put({
                type: actions.LOGIN_ERROR,
                payload: error
            })
        }
    })
}

export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
        if (isTokenExpired()) {

        } else {
            const token = getToken();
            const role = getRole();

            if (token) {
                yield put({
                    type: authActions.SET_IS_ENABLED,
                    isEnabled: (localStorage.getItem("isEnabled") === "true"),
                    isCompleted: (localStorage.getItem("isCompleted") === "true")
                })
                yield put({
                    type: actions.LOGIN_SUCCESS,
                    token: token,
                    role: role
                })
            }
        }
    })

}

export function* checkActiveProfile() {
    yield takeEvery(actions.CHECK_ACTIVE_PROFILE, function* (data) {
        const {response, error} = yield call(SuperFetch.get, endpoints.activateProfile + "/" + data.otp, null, false)
        if (response) {
            yield put({
                type: actions.CHECK_ACTIVE_PROFILE_SUCCESS,
                payload: response
            })


        } else {
            yield put({
                type: actions.CHECK_ACTIVE_PROFILE_ERROR,
                payload: error
            })
        }
    })
}

export function* recoverPassword() {
    yield takeEvery(actions.RECOVER_PASSWORD, function* (data) {
        const {response, error} = yield call(SuperFetch.post, endpoints.recoveryPassword, data.payload, false)
        if (response) {
            yield put({
                type: actions.RECOVER_PASSWORD_SUCCESS,
                payload: response
            })
        } else {
            yield put({
                type: actions.RECOVER_PASSWORD_ERROR,
                payload: error
            })
        }
    })
}

export function* resetPassword() {
    yield takeEvery(actions.RESET_PASSWORD, function* (data) {
        const {response, error} = yield call(SuperFetch.post, endpoints.resetPassword, data.payload, false)
        if (response) {
            yield put({
                type: actions.RESET_PASSWORD_SUCCESS,
                payload: response
            })
        } else {
            yield put({
                type: actions.RESET_PASSWORD_ERROR,
                payload: error
            })
        }
    })
}

export function* checkOldPassword() {

    yield takeEvery(actions.CHECK_OLD_PASSWORD, function* (data) {
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.checkOldPassword, data.payload, true)
        if (response) {
            yield put({
                type: actions.CHECK_OLD_PASSWORD_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.CHECK_OLD_PASSWORD_ERROR,
                payload: error
            });
        }
    })

}


export function* resetNewPassword() {
    yield takeEvery(actions.RESET_NEW_PASSWORD, function* (data) {
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.resetNewPassword, data.payload, true)
        if (response) {
            yield put({
                type: actions.RESET_NEW_PASSWORD_SUCCESS,
                payload: response
            })
        } else {
            yield put({
                type: actions.RESET_NEW_PASSWORD_ERROR,
                payload: error
            })
        }
    })
}

export function* logout() {
    yield takeEvery(actions.LOGOUT, function* (data) {
        localStorage.clear()
        let test = yield call(deleteFcmToken)
       // data.navigate("/")
    })
}

export default function* rootSaga() {
    yield all([
        fork(loginRequest),
        fork(recoverPassword),
        fork(resetPassword),
        fork(checkAuthorization),
        fork(checkActiveProfile),
        fork(logout),
        fork(checkOldPassword),
        fork(resetNewPassword)
    ]);
}

