import {all, call, delay, fork, put, takeEvery, takeLatest} from 'redux-saga/effects'
import {endpoints} from "src/config/site.config";
import SuperFetch from "src/helpers/superFetch";
import actions from "./actions";
import authActions from "src/redux/auth/actions";
import React from "react";

export function* getAllCompany() {

    yield takeEvery(actions.GET_ALL_COMPANY, function* () {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllCompany, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ALL_COMPANY_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_COMPANY_ERROR,
                payload: error
            });
        }
    })

}

export function* getCompanyById() {

    yield takeEvery(actions.GET_COMPANY_BY_ID, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getCompanyById + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_COMPANY_BY_ID_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_COMPANY_BY_ID_ERROR,
                payload: error
            });
        }
    })

}

export function* saveCompany() {

    yield takeEvery(actions.SAVE_COMPANY, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.registrationCompany, data.payload, false)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.SAVE_COMPANY_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SAVE_COMPANY_ERROR,
                payload: error
            });
        }
    })

}

export function* getCompanyDashboard() {
    yield takeEvery(actions.GET_COMPANY_DASHBOARD, function* () {
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getCompanyDashboard, null, true)
        if (response) {
            localStorage.setItem("isEnabled", response.enabled)
            localStorage.setItem("isCompleted", response.isCompleted)
            yield put({
                type: authActions.SET_IS_ENABLED,
                isEnabled: response.enabled,
                isCompleted: response.isCompleted,
            })
            yield put({
                type: actions.GET_COMPANY_DASHBOARD_SUCCESS,
                payload: response
            })
        } else {
            yield put({
                type: actions.GET_COMPANY_DASHBOARD_ERROR,
                payload: error
            })
        }
    })
}

export function* updateCompany() {
    yield takeEvery(actions.UPDATE_COMPANY, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.put, endpoints.updateCompany + "/" + data.id, data.payload, true, true)
        if (response) {

            yield put({type: authActions.LOADING_FALSE});

            localStorage.setItem("isCompleted", "true")
            localStorage.setItem("companyName", data.payload.companyName)

            yield put({
                type: authActions.SET_IS_ENABLED,
                isEnabled: true,
                isCompleted: true
            });
            yield put({
                type: actions.UPDATE_COMPANY_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_COMPANY_ERROR,
                payload: error
            });
        }
    })

}

export function* getImageSource() {
    yield takeEvery(actions.GET_COMPANY_LOGO, function* (data) {
        const {
            response,
            fileName,
            error
        } = yield call(SuperFetch.post, endpoints.getImageSource, data.payload, true, false, null, true)
        if (response) {
            let blob = yield response.blob()
            let fileUrl = URL.createObjectURL(blob)
            yield put({
                type: actions.GET_COMPANY_LOGO_SUCCESS,
                payload: fileUrl,
                fileBlob: blob,
                fileName: fileName,
                fileType: response.headers.get("Content-Type")
            });

        } else {
            yield put({
                type: actions.GET_COMPANY_LOGO_ERROR,
                payload: error
            })
        }
    })
}

export function* closeActivationMessage() {
    yield takeLatest(actions.CLOSE_ACTIVATION_MESSAGE, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.closeActivationMessage, null, true)
        if (response) {
            yield put({
                type: actions.CLOSE_ACTIVATION_MESSAGE_SUCCESS,
            });

        } else {
            yield put({
                type: actions.CLOSE_ACTIVATION_MESSAGE_ERROR,
                payload: error
            })
        }
    })
}

export function* closeStep() {
    yield takeLatest(actions.CLOSE_STEP, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.closeStep, null, true)
        if (response) {
            yield put({
                type: actions.CLOSE_STEP_SUCCESS,
            });

        } else {
            yield put({
                type: actions.CLOSE_STEP_ERROR,
                payload: error
            })
        }
    })
}

export function* getCvSourceDetailUser(){
    yield takeEvery(actions.GET_CV_SOURCE_DETAIL_USER, function* (data) {
        const {
            response,
            fileName,
            error
        } = yield call(SuperFetch.post, endpoints.getImageSource, data.cv, true, false, null, true)
        if(response){
            let blob = yield response.blob()
            let fileUrl = URL.createObjectURL(blob)
            yield put({
                type: actions.GET_CV_SOURCE_DETAIL_USER_SUCCESS,
                payload: fileUrl,
                fileBlob: blob,
                fileName: fileName,
                fileType: response.headers.get("Content-Type")
            })
        }else{
            yield put({
                type: actions.GET_CV_SOURCE_DETAIL_USER_ERROR,
                payload: error
            })
        }
    })
}

export function* editEmailMessage() {
    yield takeEvery(actions.EDIT_MESSAGE_EMAIL, function* (data){
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.editEmailMessage, data.defaultMessage, true, false, null, true)
        if(response){
            yield put({
                type: actions.EDIT_MESSAGE_EMAIL_SUCCESS
            })
            yield put({
                type: actions.GET_COMPANY_BY_ID,
                id: data.companyId
            })
        }else{
            yield put({
                type: actions.EDIT_MESSAGE_EMAIL_ERROR
            })
        }
    })
}

export function* getWorkSectorSaga() {
    yield takeEvery(actions.GET_WORK_SECTOR, function* () {
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.workSector, null, true)

        if (response) {
            yield put({
                type: actions.GET_WORK_SECTOR_SUCCESS,
                payload: response
            })
        } else {
            yield put({
                type: actions.GET_WORK_SECTOR_ERROR,
                payload: error
            })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getAllCompany),
        fork(getCompanyById),
        fork(saveCompany),
        fork(updateCompany),
        fork(getCompanyDashboard),
        fork(getImageSource),
        fork(closeActivationMessage),
        fork(closeStep),
        fork(getCvSourceDetailUser),
        fork(editEmailMessage),
        fork(getWorkSectorSaga),
    ]);
}
