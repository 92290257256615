import React, {useEffect, useState} from "react";
import Tabs from "antd/es/tabs";
import Profile from "src/Pages/candidate/Profile";
import Cv from "src/Pages/candidate/Cv";
import Modal from "antd/es/modal";
import {ICONS} from "src/assets/sprite/iconsNames";
import Divider from "antd/es/divider";
import Icon from "src/component/Icon/Sprite";
import "src/component/Layout/Modal/ModalProfile/ModalProfile.scss"
import candidatureActions from "../../../../redux/candidature/actions";
import {useDispatch, useSelector} from "react-redux";
import CandidateCardStateTable from "../../Candidate/CandidateCardStateTable";

function ModalProfile(props) {

    const {getCandidatureById, getCvSource} = candidatureActions

    let dispatch = useDispatch()
    let {setProfile,cv} = props
    const [record, setRecord] = useState(null)
    const candidature = useSelector(state => state.Candidature.candidature)
    useEffect(()=>{
        if(setProfile){
            dispatch(getCandidatureById(setProfile))
        }
    },[props.setProfile])

    useEffect(()=>{
        if(candidature){
            setRecord(candidature)
        }
    },[candidature])

    return(
        <Modal
            className="modalProfile"
            centered={true}
            open={props.state}
            onCancel={props.onCancel}
            footer={null}
            destroyOnClose={true}
        >
            <div className="flex-center flex-between margin-top-30">
                <div className="flex-center weight-700 size-29 merriWeather">
                    {(candidature && candidature.userFirstName && candidature.userLastName) ? candidature.userFirstName + " " + candidature.userLastName : null}
                </div>
                <div className="flex-center flex-between">
                    <div className="flex-center pointer" onClick={()=>dispatch(getCvSource(candidature.userCvPath))}>
                        <Icon name={ICONS.download} size={[20, 20]} className="opacity-50"/>
                    </div>
                    <Divider type="vertical" className="divider"/>
                    <div className="flex">
                        <CandidateCardStateTable record={props.record} id={candidature && candidature.id} handleCandidateTo={props.handleCandidateTo} />
                    </div>
                </div>
            </div>
            <Tabs
                defaultActiveKey="1"
                items={[
                    {
                        label: <div className="weight-600 size-14 profilo">{'Profilo personale'}</div>,
                        key: '1',
                        children: <Profile/>,
                    },
                    {
                        label: `CV`,
                        key: '2',
                        children: <Cv />,
                    }
                ]}
            />
        </Modal>
    )
}

export default ModalProfile
