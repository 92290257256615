const actions = {

    INIT_LOCATION: 'INIT_LOCATION',

    GET_ALL_LOCATION: 'GET_ALL_LOCATION',
    GET_ALL_LOCATION_SUCCESS: 'GET_ALL_LOCATION_SUCCESS',
    GET_ALL_LOCATION_ERROR: 'GET_ALL_LOCATION_ERROR:',

    GET_ALL_PROVINCE: 'GET_ALL_PROVINCE',
    GET_ALL_PROVINCE_SUCCESS: 'GET_ALL_PROVINCE_SUCCESS',
    GET_ALL_PROVINCE_ERROR: 'GET_ALL_PROVINCE_ERROR:',

    GET_CITY_BY_PROVINCE: 'GET_CITY_BY_PROVINCE',
    GET_CITY_BY_PROVINCE_SUCCESS: 'GET_CITY_BY_PROVINCE_SUCCESS',
    GET_CITY_BY_PROVINCE_ERROR: 'GET_CITY_BY_PROVINCE_ERROR:',

    GET_LOCATION_BY_ID: "GET_LOCATION_BY_ID",
    GET_LOCATION_BY_ID_SUCCESS: "GET_LOCATION_BY_ID_SUCCESS",
    GET_LOCATION_BY_ID_ERROR: "GET_LOCATION_BY_ID_ERROR",

    RESET_LOCATION: "RESET_LOCATION",

    SAVE_LOCATION: "SAVE_LOCATION",
    SAVE_LOCATION_SUCCESS: "SAVE_LOCATION_SUCCESS",
    SAVE_LOCATION_ERROR: "SAVE_LOCATION_ERROR",

    UPDATE_LOCATION: "UPDATE_LOCATION",
    UPDATE_LOCATION_SUCCESS: "UPDATE_LOCATION_SUCCESS",
    UPDATE_LOCATION_ERROR: "UPDATE_LOCATION_ERROR",

    DELETE_LOCATION: "DELETE_LOCATION",
    DELETE_LOCATION_SUCCESS: "DELETE_LOCATION_SUCCESS",
    DELETE_LOCATION_ERROR: "DELETE_LOCATION_ERROR",

    GET_ALL_COMPANY_LOCATIONS: "GET_ALL_COMPANY_LOCATIONS",
    GET_ALL_COMPANY_LOCATIONS_SUCCESS: "GET_ALL_COMPANY_LOCATIONS_SUCCESS",
    GET_ALL_COMPANY_LOCATIONS_ERROR: "GET_ALL_COMPANY_LOCATIONS_ERROR",

    GET_ALL_COMPANY_LOCATIONS_BY_SYS_ADMIN: "GET_ALL_COMPANY_LOCATIONS_BY_SYS_ADMIN",
    GET_ALL_COMPANY_LOCATIONS_BY_SYS_ADMIN_SUCCESS: "GET_ALL_COMPANY_LOCATIONS_BY_SYS_ADMIN_SUCCESS",
    GET_ALL_COMPANY_LOCATIONS_BY_SYS_ADMIN_ERROR: "GET_ALL_COMPANY_LOCATIONS_BY_SYS_ADMIN_ERROR",

    GET_BY_ID_COMPANY_LOCATION: "GET_BY_ID_COMPANY_LOCATION",
    GET_BY_ID_COMPANY_LOCATION_SUCCESS: "GET_BY_ID_COMPANY_LOCATION_SUCCESS",
    GET_BY_ID_COMPANY_LOCATION_ERROR: "GET_BY_ID_COMPANY_LOCATION_ERROR",

    GET_BY_ID_COMPANY_LOCATION_MAIN: "GET_BY_ID_COMPANY_LOCATION_MAIN",
    GET_BY_ID_COMPANY_LOCATION_MAIN_SUCCESS: "GET_BY_ID_COMPANY_LOCATION_MAIN_SUCCESS",
    GET_BY_ID_COMPANY_LOCATION_MAIN_ERROR: "GET_BY_ID_COMPANY_LOCATION_MAIN_ERROR",

    SAVE_COMPANY_LOCATION: "SAVE_COMPANY_LOCATION",
    SAVE_COMPANY_LOCATION_SUCCESS: "SAVE_COMPANY_LOCATION_SUCCESS",
    SAVE_COMPANY_LOCATION_ERROR: "SAVE_COMPANY_LOCATION_ERROR",

    UPDATE_COMPANY_LOCATION: "UPDATE_COMPANY_LOCATION",
    UPDATE_COMPANY_LOCATION_SUCCESS: "UPDATE_COMPANY_LOCATION_SUCCESS",
    UPDATE_COMPANY_LOCATION_ERROR: "UPDATE_COMPANY_LOCATION_ERROR",

    getAll: (data) => ({
        type: actions.GET_ALL_LOCATION,
        page: data.page,
        size: data.size,
        city: data.city
    }),

    getAllProvince: (data) => ({
        type: actions.GET_ALL_PROVINCE,
        page: data.page,
        size: data.size,
        province: data.province
    }),

    getCityByProvince: (data) => ({
        type: actions.GET_CITY_BY_PROVINCE,
        page: data.page,
        size: data.size,
        province: data.province,
        city: data.city
    }),

    get: (locationId) => ({
        type: actions.GET_LOCATION_BY_ID,
        id: locationId
    }),

    resetLocation: () => ({
        type: actions.RESET_LOCATION
    }),

    save: (data) => ({
        type: actions.SAVE_LOCATION,
        payload: data
    }),

    update: (locationId,data) => ({
        type: actions.UPDATE_LOCATION,
        id: locationId,
        payload: data,
    }),

    deleteLocation: (id) => ({
        type: actions.DELETE_LOCATION,
        id: id
    }),

    getAllCompanyLocation: ()=>({
       type: actions.GET_ALL_COMPANY_LOCATIONS
    }),

    getAllCompanyLocationBySysAdmin: (companyId)=>({
       type: actions.GET_ALL_COMPANY_LOCATIONS_BY_SYS_ADMIN,
        id: companyId
    }),

    getCompanyLocationById: (id) => ({
       type: actions.GET_BY_ID_COMPANY_LOCATION,
       id: id
    }),

    getCompanyLocationMainById: (companyId) => ({
       type: actions.GET_BY_ID_COMPANY_LOCATION_MAIN,
        companyId: companyId
    }),

    saveCompanyLocation: (data) => ({
        type: actions.SAVE_COMPANY_LOCATION,
        payload: data
    }),

    updateCompanyLocation: (data) => ({
        type: actions.UPDATE_COMPANY_LOCATION,
        payload: data
    })
};

export default actions;
