import React from "react";
import {Outlet} from "react-router-dom"
import "../../variable.scss"
import Footerbar from "../../component/Layout/Footbar/footerbar";
import Topbar from "src/component/Layout/Topbar/landingTopbar";

export default function RedirectHome() {

    return (
        <div className={"content-container"}>
            <Topbar/>

            <div className={'content landing'}>
                <Outlet/>
            </div>

            <Footerbar/>
        </div>
    )

}
