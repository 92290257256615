import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {endpoints} from "../../config/site.config";
import SuperFetch from "../../helpers/superFetch";
import actions from "./actions";
import authActions from "../auth/actions";

export function* getSettings() {

    yield takeEvery(actions.GET_SETTINGS, function* () {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getSettings, null, true)
        yield put({type: authActions.LOADING_FALSE});

        if (response) {

            yield put({
                type: actions.GET_SETTINGS_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.GET_SETTINGS_ERROR,
                payload: error
            });
        }
    })

}

export function* updateSettings() {

    yield takeEvery(actions.UPDATE_SETTINGS, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.updateSettings, data.payload, true)
        yield put({type: authActions.LOADING_FALSE});

        if (response) {
            yield put({
                type: actions.UPDATE_SETTINGS_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.UPDATE_SETTINGS_ERROR,
                payload: error
            });
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getSettings),
        fork(updateSettings),
    ]);
}
