import actions from "./actions";
import offertActions from "../offer/actions";
import companyActions from "../company/actions";
import authActions from "../auth/actions";


const initState = {
    changeValue: false
}

export default function drawerReducer(state = initState, action) {
    switch (action.type) {
        case actions.INIT_CANDIDATURE:
            return {
                ...initState
            };
        case authActions.LOGOUT:
            return {...initState}
        case actions.SET_CHANGE_DRAWER:
            return {
                ...state,
                changeValue: true,
            }
        case actions.OPEN_DRAWER:
            return {
                ...state,
                isDrawerOpen: true
            }
        case actions.CLOSE_DRAWER :
        case offertActions.SAVE_OFFER_SUCCESS:
        case offertActions.UPDATE_OFFER_SUCCESS:
        case offertActions.UPDATE_OFFER_BY_SYS_ADMIN_SUCCESS:
        case companyActions.UPDATE_COMPANY_SUCCESS:
            return {
                ...state,
                isDrawerOpen: false,
                changeValue: false,
            }
        default:
            return state;
    }
}
