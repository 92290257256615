import React, {useEffect} from "react";
import Modal from "antd/es/modal";
import Icon from "../../../Icon/Sprite";
import {ICONS} from "../../../../assets/sprite/iconsNames";
import {Form, Input, Space} from "antd";
import {FormattedMessage} from "react-intl";
import {ButtonUser} from "../../../Useful/Button/ButtonUser";
import {InputLogin} from "../../../Useful/Input/InputLogin";
import ReactQuill from "react-quill";
import actions from "../../../../redux/company/actions";
import {useDispatch} from "react-redux";

const {editEmailMessage,closeModal} = actions

function ModalEditMessageEmail(props){

    const {isOpen, setIsOpen, defaultMessage,companyId} = props
    const [form] = Form.useForm()
    let dispatch = useDispatch()


    useEffect(()=>{
        if(props.defaultMessage){
            form.setFieldsValue({
                subject: props.defaultMessage.subject,
                message: props.defaultMessage.message
            })
        }
    },[props])


    return(
        <Modal
            closeIcon={<Icon name={ICONS.close} size={[20,20]}/>}
            className={"editAccount"}
            open={isOpen}
            onCancel={() => dispatch(closeModal())}
            centered
            destroyOnClose
            width={670}
            footer={
                <div>
                    <Space size={20} direction={"horizontal"}>
                        <div className={"pointer underlined opacity-50 flex-align-center"} onClick={() => dispatch(closeModal())}>
                            <FormattedMessage id="cancel"/>
                        </div>
                        <ButtonUser htmlType="submit" form={"editMessageEmail"}>
                            Salva e chiudi
                        </ButtonUser>
                    </Space>
                </div>
            }
        >
            <>
                <h1>Inserisci messaggio</h1>
                <p>Inserisci un messaggio preimpostato per la risposta agli stati delle candidature</p>
                <Form
                    id={"editMessageEmail"}
                    layout={"vertical"}
                    form={form}
                    onFinish={(value)=>{
                        dispatch(editEmailMessage(value,companyId))
                    }}
                >
                    <Form.Item
                        name={"subject"}
                        label={"Oggetto"}
                        className={"weight-600 size-14"}
                    >
                        <InputLogin placeholder={"Inserisci oggetto dell'email"}/>
                    </Form.Item>
                    <Form.Item
                        name={"message"}
                        label={"Messaggio della Mail"}
                        className={"weight-600 size-14"}
                    >
                        <ReactQuill style={{minHeight:"300px"}} placeholder="Inserisci qui il corpo dell'email" theme="snow" />
                    </Form.Item>
                </Form>
            </>
        </Modal>
    )
}

export default ModalEditMessageEmail