const actions = {
    INIT: "INIT",

    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',

    LOGOUT: "LOGOUT",

    GET_ID_TOKEN: "SET_ID_TOKEN",
    GET_ROLES: "SET_ROLES",
    CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",

    CHECK_ACTIVE_PROFILE: "CHECK_ACTIVE_PROFILE",
    CHECK_ACTIVE_PROFILE_SUCCESS: "CHECK_ACTIVE_PROFILE_SUCCESS",
    CHECK_ACTIVE_PROFILE_ERROR: "CHECK_ACTIVE_PROFILE_ERROR",

    LOADING_TRUE : "LOADING_TRUE",
    LOADING_FALSE : "LOADING_FALSE",

    SIGN_UP: 'SIGN_UP',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    SIGN_UP_ERROR: 'SIGN_UP_ERROR',

    RECOVER_PASSWORD: 'RECOVER_PASSWORD',
    RECOVER_PASSWORD_SUCCESS: 'RECOVER_PASSWORD_SUCCESS',
    RECOVER_PASSWORD_ERROR: 'RECOVER_PASSWORD_ERROR',

    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',

    RESET_SUCCESS_MESSAGE: "RESET_SUCCESS_MESSAGE",
    RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',

    SET_IS_ENABLED: 'SET_IS_ENABLED',

    CHECK_OLD_PASSWORD: "CHECK_OLD_PASSWORD",
    CHECK_OLD_PASSWORD_SUCCESS: "CHECK_OLD_PASSWORD_SUCCESS",
    CHECK_OLD_PASSWORD_ERROR: "CHECK_OLD_PASSWORD_ERROR",

    SAVE_URL: "SAVE_URL",

    RESET_NEW_PASSWORD: 'RESET_NEW_PASSWORD',
    RESET_NEW_PASSWORD_SUCCESS: 'RESET_NEW_PASSWORD_SUCCESS',
    RESET_NEW_PASSWORD_ERROR: 'RESET_NEW_PASSWORD_ERROR',

    RESET_STATE_NEW_PASSWORD: 'RESET_STATE_NEW_PASSWORD',

    RESET_OLD_PASSWORD: "RESET_OLD_PASSWORD",

    init: () => ({
        type: actions.INIT
    }),

    checkAuthorization: () => ({
        type: actions.CHECK_AUTHORIZATION
    }),

    checkActiveProfile: (otp) => ({
       type: actions.CHECK_ACTIVE_PROFILE,
       otp: otp
    }),

    login: (data) => ({
        type: actions.LOGIN_REQUEST,
        payload: data
    }),

    logout: (navigate) => ({
        type: actions.LOGOUT,
        navigate : navigate
    }),

    recoverPassword: (data) => ({
        type: actions.RECOVER_PASSWORD,
        payload: data,
    }),

    resetPassword: (data) => ({
       type: actions.RESET_PASSWORD,
        payload: data
    }),

    checkOldPassword: (data) => ({
        type: actions.CHECK_OLD_PASSWORD,
        payload: data,
    }),

    saveUrl: (url) => ({
        type: actions.SAVE_URL,
        url: url
    }),

    resetNewPassword: (data) => ({
        type: actions.RESET_NEW_PASSWORD,
        payload: data
    }),

    resetCheckPassword: () => ({
        type: actions.RESET_OLD_PASSWORD
    }),

    resetStateNewPassword: () => ({
        type: actions.RESET_STATE_NEW_PASSWORD
    })

}

export default actions
