import React, {useEffect} from "react";
import {PRIVATE_COMPANY_ROUTE, PRIVATE_USER_ROUTE, PUBLIC_ROUTE, PRIVATE_ADMIN_ROUTE} from "./route.constants";
import Home from "./Pages/home/Home"
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Landing from "./Pages/landing/Landing";
import DashboardCompany from "src/Pages/dashboard/DashboardCompany";
import ConfirmRegistration from "src/component/Layout/Registration/ConfirmRegistration/ConfirmRegistration.js";
import WorkOffers from "./Pages/workOffers/WorkOffers";
import UserHome from "./Pages/home/UserHome";
import LoginUser from "./component/Layout/Login/LoginUser/LoginUser";
import LoginCompany from "./component/Layout/Login/LoginCompany/LoginCompany";
import RegistrationCompany from "./component/Layout/Registration/RegistrationCompany/RegistrationCompany";
import RegistrationUser from "./component/Layout/Registration/RegistrationUser/RegistrationUser";
import Candidate from "./Pages/candidate/Candidate";
import NotificationCompany from "./Pages/notification/NotificationCompany";
import AccountCompany from "src/Pages/account/AccountCompany";
import OffersDetail from "src/Pages/offerDetail/OfferDetail";
import RecoveryEmailUser from "./component/Layout/Recovery/User/RecoveryEmailUser";
import RecoveryPasswordUser from "./component/Layout/Recovery/User/RecoveryPasswordUser";
import RecoveryEmailCompany from "./component/Layout/Recovery/Company/RecoveryEmailCompany";
import RecoveryPasswordCompany from "./component/Layout/Recovery/Company/RecoveryPasswordCompany";
import MyCompany from "./Pages/myCompany/MyCompany";
import {ROLES} from "src/utils/utility";
import CompanyHome from "src/Pages/home/CompanyHome";
import Site from "./Pages/myCompany/Site";
import DashboardUser from "./Pages/dashboard/DashboardUser";
import JobOffer from "./Pages/User/JobOffer/JobOffer";
import MyCandidature from "./Pages/User/MyCandidature/MyCandidature";
import NotificationUser from "./Pages/notification/NotificationUser";
import AccountUser from "./Pages/account/AccountUser";
import ActiveProfile from "./Pages/activeProfile/ActiveProfile";
import Preference from "./Pages/User/Preference/MyPreference";
import AdminDashboard from "src/Pages/superAdmin/AdminDashboard";
import AdminCompanies from "src/Pages/superAdmin/AdminCompanies";
import AdminUsers from "src/Pages/superAdmin/AdminUsers";
import SysAdminUsersList from "src/Pages/sysAdminUsersList/SysAdminUsersList";
import AdminHome from "src/Pages/home/AdminHome";
import DetailCompany from "./Pages/User/DetailCompany/DetailCompany";
import ConfirmOffer from "./Pages/ApprovesPages/confirmOffer";
import EditOffer from "./Pages/ApprovesPages/editOffer";
import CandidateDetail from "./component/Layout/Candidate/CadidateDetail/CandidateDetail";
import SaveUrlDatas from "./SaveUrlDatas";
import RedirectHome from "./Pages/home/RedirectHome";
import UserDetail from "./Pages/sysAdminUsersList/UserDetail";
import Settings from "./Pages/superAdmin/Settings";


const publicRoutes = [
    {
        path: PUBLIC_ROUTE.LANDING,
        exact: true,
        component: Landing
    },
    {
        path: PUBLIC_ROUTE.USER_LOG_IN,
        component: LoginUser
    },
    {
        path: PUBLIC_ROUTE.COMPANY_LOG_IN,
        component: LoginCompany
    },
    {
        path: PUBLIC_ROUTE.USER_REGISTRATION,
        component: RegistrationUser
    },
    {
        path: PUBLIC_ROUTE.COMPANY_REGISTRATION,
        component: RegistrationCompany
    },
    {
        path: PUBLIC_ROUTE.USER_RECOVERY_EMAIL,
        component: RecoveryEmailUser
    },
    {
        path: PUBLIC_ROUTE.COMPANY_RECOVERY_EMAIL,
        component: RecoveryEmailCompany
    },
    {
        path: PUBLIC_ROUTE.USER_RECOVERY_PASSWORD,
        component: RecoveryPasswordUser
    },
    {
        path: PUBLIC_ROUTE.COMPANY_RECOVERY_PASSWORD,
        component: RecoveryPasswordCompany
    },
    {
        path: PUBLIC_ROUTE.CONFIRM_REGISTRATION,
        component: ConfirmRegistration
    },
    {
        path: PUBLIC_ROUTE.COMPANY_ACTIVATE_PROFILE,
        component: ActiveProfile
    },
    {
        path: PUBLIC_ROUTE.USER_ACTIVATE_PROFILE,
        component: ActiveProfile
    },
    {
        path: PUBLIC_ROUTE.REDIRECT,
        component: Landing
    },
    {
        path: PUBLIC_ROUTE.EDIT_OFFER,
        component: EditOffer
    }

]

const redirectRoutes = [
    {
        path: PUBLIC_ROUTE.CONFIRM_OFFER,
        component: ConfirmOffer
    },
]

const privateUserRoutes = [
    {
        path: PRIVATE_USER_ROUTE.DASHBOARD_USER,
        component: DashboardUser
    },
    {
        path: PRIVATE_USER_ROUTE.JOB_OFFER,
        component: JobOffer
    },
    {
        path: PRIVATE_USER_ROUTE.MY_CANDIDATURE,
        component: MyCandidature
    },
    {
        path: PRIVATE_USER_ROUTE.NOTIFICATION,
        component: NotificationUser
    },
    {
        path: PRIVATE_USER_ROUTE.ACCOUNT,
        component: AccountUser
    },
    {
        path: PRIVATE_USER_ROUTE.FAVORITES,
        component: Preference
    },
    {
        path: PRIVATE_USER_ROUTE.DETAIL_COMPANY,
        component: DetailCompany
    }
]

const privateCompanyRoutes = [
    {
        path: PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY,
        component: DashboardCompany
    },
    {
        path: PRIVATE_COMPANY_ROUTE.WORK_OFFERS,
        component: WorkOffers
    },
    {
        path: PRIVATE_COMPANY_ROUTE.WORK_OFFERS_DETAIL,
        component: OffersDetail
    },
    {
        path: PRIVATE_COMPANY_ROUTE.MY_COMPANY,
        component: MyCompany
    },
    {
        path: PRIVATE_COMPANY_ROUTE.CANDIDATE,
        component: Candidate
    },
    {
        path: PRIVATE_COMPANY_ROUTE.CANDIDATE_DETAIL,
        component: CandidateDetail
    },
    {
        path: PRIVATE_COMPANY_ROUTE.NOTIFICATION,
        component: NotificationCompany
    },
    {
        path: PRIVATE_COMPANY_ROUTE.ACCOUNT,
        component: AccountCompany
    },
    {
        path: PRIVATE_COMPANY_ROUTE.SITE,
        component: Site
    }
]

const privateAdminRoute = [
    {
        path: PRIVATE_ADMIN_ROUTE.DASHBOARD,
        component: AdminDashboard
    },
    {
        path: PRIVATE_ADMIN_ROUTE.COMPANIES,
        component: AdminCompanies
    },
    {
        path: PRIVATE_ADMIN_ROUTE.USERS,
        component: SysAdminUsersList
    },
    {
        path: PRIVATE_ADMIN_ROUTE.USER_DETAIL,
        component: UserDetail
    },
    {
        path: PRIVATE_ADMIN_ROUTE.SETTINGS,
        component: Settings
    }
]


export default function Router() {

    const isLoggedIn = useSelector(state => state.AuthReducer.idToken)
    const role = useSelector(state => state.AuthReducer.role)

    const pathListOfUsersGradients = [
        PUBLIC_ROUTE.USER_LOG_IN,
        PUBLIC_ROUTE.USER_REGISTRATION,
        PUBLIC_ROUTE.USER_RECOVERY_EMAIL,
        PUBLIC_ROUTE.USER_RECOVERY_PASSWORD
    ]

    const pathListOfCompanyGradients = [
        PUBLIC_ROUTE.COMPANY_LOG_IN,
        PUBLIC_ROUTE.COMPANY_REGISTRATION,
        PUBLIC_ROUTE.COMPANY_RECOVERY_EMAIL,
        PUBLIC_ROUTE.COMPANY_RECOVERY_PASSWORD
    ]

    return (
        <BrowserRouter>
            <Routes>
                <Route path={""} element={<Home pathListOfUsersGradients={pathListOfUsersGradients}
                                                pathListOfCompanyGradients={pathListOfCompanyGradients}/>}>

                    {publicRoutes.map((route, index) => {
                            return <Route key={index} path={route.path} exact={route.exact}
                                          element={(<route.component path={route.path}/>)}/>
                        }
                    )}
                </Route>


                <Route path={""} element={<RedirectHome/>}>
                    {redirectRoutes.map((route, index) => {
                        return <Route key={index} element={<route.component/>} path={route.path}/>
                    })}
                </Route>

                {(isLoggedIn && role === ROLES.USER) && <Route path={"/"}
                                                               element={<UserHome
                                                                   pathListOfUsersGradients={pathListOfUsersGradients}
                                                                   pathListOfCompanyGradients={pathListOfCompanyGradients}/>
                                                               }
                >
                    {privateUserRoutes.map((route, index) => (
                        <Route key={index} path={route.path} exact={route.exact} element={<route.component/>}/>
                    ))}
                    <Route path="*" element={<Navigate to="/user/dashboard"/>}/>
                </Route>
                }

                {(isLoggedIn && (role === ROLES.ADMIN || role === ROLES.EMPLOYEE)) && <Route path={"/"}
                                                                                             element={<CompanyHome
                                                                                                 pathListOfUsersGradients={pathListOfUsersGradients}
                                                                                                 pathListOfCompanyGradients={pathListOfCompanyGradients}/>}
                >
                    {privateCompanyRoutes.map((route, index) => (
                        <Route key={index} path={route.path} exact={route.exact} element={<route.component/>}/>
                    ))}
                    <Route path="*" element={<Navigate to="/company/dashboard"/>}/>
                </Route>
                }

                {isLoggedIn && role === ROLES.SYS_ADMIN && <Route path={"/"}
                                                                  element={<AdminHome/>}
                >
                    {privateAdminRoute.map((route, index) => (
                        <Route key={index} path={route.path} exact={route.exact} element={<route.component/>}/>
                    ))}
                    <Route path="*" element={<Navigate to="/admin/dashboard"/>}/>
                </Route>
                }

                <Route path={"*"} exact={true} element={<SaveUrlDatas/>}/>
            </Routes>
        </BrowserRouter>
    )
}

