import React from "react";
import {useNavigate} from "react-router";
import Drawer from "antd/es/drawer";
import {Button, Card, Divider} from "antd";
import Icon from "../../Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import {ImgComponent, substituteZeroWithLine} from "src/utils/utility";
import preferenceActions from "src/redux/preference/actions";
import {useDispatch} from "react-redux";
import GreyLogo from "../../../assets/logo/logo-VT-grey.svg";

function DrawerListMyPreferenceMobile(props){

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {recordOffer, openState, setOpenState, width} = props

    const {deletePreference} = preferenceActions;

    function setField(record) {
        let path = "/user/company/"
        if (record.id) path = path + record.company.id
        navigate(path)
    }

    return(
        <Drawer placement="right" onClose={()=>setOpenState(false)} open={openState} width={width}>
            <div style={{width: "100%", height:"100%"}}>
                <Card style={{top: "50px", borderRadius: 0}}>
                    <div className="flex-row margin-bottom-30 flex-center-between">
                        <div className="flex-center">
                            <Icon color="grey" name={ICONS.user_ok} size={[20, 20]}/>
                            <span className="margin-left-5">
                                {substituteZeroWithLine(recordOffer && recordOffer.candidateCount)}
                            </span>
                        </div>
                        <div style={{width: "120px", height: "100px", margin: "-80px 0 0 -20px", border: "1px solid rgba(14, 14, 44, 0.15)"}}>
                            {recordOffer && !recordOffer.anonymous ?
                                <ImgComponent
                                    logoPath={recordOffer && recordOffer.company && recordOffer.company.logoPath}/>
                                    :
                                <div className="noLogo">
                                    <img src={GreyLogo}/>
                                </div>
                            }
                        </div>
                        <div/>
                    </div>
                    <div className="flex-center flex-column margin-bottom-30">
                        <div className="weight-700 size-22">Titolo: {recordOffer && recordOffer.title}</div>
                        {recordOffer && recordOffer.anonymous ?
                            <div className="opacity-50 underlined size-12">
                                Azienda Anonima
                            </div> :
                            <div className="weight-40 size-14 underlined pointer" onClick={() => setField(recordOffer)}>
                                {recordOffer && recordOffer.company && recordOffer.company.companyName}
                            </div>}
                    </div>
                    <Divider/>
                    <div className="margin-top-30 margin-bottom-30">
                        <div>
                            {recordOffer && recordOffer.location &&
                            <div>
                                <span className="weight-700 margin-right-5">Dove:</span>
                                <span>{recordOffer.location.city}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.impegnoRichiesto &&
                            <div>
                                <span className="weight-700 margin-right-5">Tempo:</span>
                                <span>{recordOffer.impegnoRichiesto}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.insertMode &&
                            <div>
                                <span className="weight-700 margin-right-5">Contratto:</span>
                                <span>{recordOffer.insertMode}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.workPlace &&
                            <div>
                                <span className="weight-700 margin-right-5">Modalità:</span>
                                <span>{recordOffer.workPlace}</span>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="flex-row flex-center-between">
                        <div className="margin-bottom-30">
                            {/*<Button className={"padding-5 border-radius-3 margin-right-10"}>*/}
                            {/*    <Icon name={ICONS.facebook} size={[20, 20]} color={"#1877F2"}/>*/}
                            {/*</Button>*/}
                            {/*<Button className={"padding-5 border-radius-3 margin-right-10"}>*/}
                            {/*    <Icon name={ICONS.twitter} size={[20, 20]} color={"#1DA1F2"}/>*/}
                            {/*</Button>*/}
                            {recordOffer && recordOffer.company && recordOffer.company.linkedin &&
                            <Button className={"padding-5 border-radius-3 margin-right-10"}>
                                <a href={recordOffer.company.linkedin} target="_blank">
                                    <Icon name={ICONS.linkedin} size={[20, 20]} color={"#1DA1F2"}/>
                                </a>
                            </Button>
                            }
                            {recordOffer && recordOffer.company && recordOffer.company.webPage &&
                            <Button className={"padding-5 border-radius-3"}>
                                <a href={recordOffer.company.webPage} target="_blank">
                                    <Icon name={ICONS.link} size={[20, 20]} className={"opacity-50"}/>
                                </a>
                            </Button>
                            }
                        </div>
                        <div className="flex-row margin-bottom-30 margin-left-55">

                            <div className={"flex-center margin-right-24 pointer"}>
                            <span onClick={() => {dispatch(deletePreference(recordOffer.id, "delete"))}}>
                                <Icon name={ICONS.union} size={[20, 20]} className={"icon-heart"} color="#208ac6"/>
                            </span>
                            </div>
                        </div>
                    </div>
                    {recordOffer && recordOffer.description ?
                        <div>
                            <Divider>
                                <div className="size-12 opacity-50">Descrizione</div>
                            </Divider>
                            <p dangerouslySetInnerHTML={{__html: recordOffer.description}}/>
                        </div> : null}
                </Card>
            </div>
        </Drawer>
    )
}

export default DrawerListMyPreferenceMobile