import React, {useState} from "react";
import "src/component/Layout/OffersList/OffersListStyle.scss";
import {Divider, Table} from "antd";
import {ICONS} from "src/assets/sprite/iconsNames";
import {useNavigate} from "react-router";
import Icon from "../../Icon/Sprite";
import {FormattedMessage} from "react-intl";
import moment from 'moment';
import 'moment/locale/it'
import {Select} from "../../Useful/Select/Select";
import {SORTING} from "src/utils/utility";
import {useDispatch} from "react-redux";

export function SiteTable(props) {

    let {siteList} = props

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)
    const [order, setOrder] = useState(SORTING.ASC.value)


    const columns = [
        {
            title: 'Offerte',
            dataIndex: 'offerts',
            render: (text, record) =>
                <div>
                    <div
                        className="pointer size-14 weight-600 underlined blue"
                        onClick={(e) => {
                            setField(e, record, record.key)
                        }}
                    >
                        {record.title}
                    </div>
                    <div className="size-12 weight-400 opacity-50">{record.location.city} · {record.impegnoRichiesto}</div>
                </div>
        },
        {
            title: 'Data applicazione',
            dataIndex: 'application_date',
            render: (text, record) =>
                <div className="size-14 weight-400">
                    {moment(record.dateCreated).locale('it').format("DD-MMM-YYYY")}
                </div>
        },
        {
            title: 'Candidati',
            dataIndex: 'candidate',
            render: (text, record) =>
                <div className="flex-center-evenly weight-500 size-15">
                    <Icon name={ICONS.user_multi} size={[20, 20]} color={"#868696"}/>
                    {CheckField(record.candidateCount)}
                </div>
        },
        {
            title: 'Visite',
            dataIndex: 'visits',
            render: (text, record) =>
                <div className="flex-center-evenly weight-500 size-15">
                    <Icon name={ICONS.eye} size={[20, 20]} color={"#868696"}/>
                    {CheckField(record.visualization)}
                </div>
        },
        {
            title: 'Stato',
            dataIndex: 'state',
            render: (text, record) =>
                <div>
                    <div className="flex-center weight-600 size-14">
                        {CheckState(record.state)}
                    </div>
                </div>
        },
        // {
        //     dataIndex: 'more',
        //     render: (text, record) => <div className="flex-center">
        //         <Icon className="icon" name={ICONS.more} color="#868696" size={[20, 20]}/>
        //     </div>
        // }
    ];

    const handleOk = () => {
        setOpen(false);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    function setField(e, record) {
        e.preventDefault()
        let path = '/company/offers'
        if (record.id) path = path + "/" + record.id
        navigate(path)
    }

    function CheckField(field) {
        if (field === 0 || field === null || field === undefined) {
            return "-"
        } else {
            return field
        }
    }

    function CheckHideIcon(field) {
        if (field === null || field === 0 || field === undefined) {
            return false
        } else {
            return field
        }
    }

    function CheckState(field) {
        if (field === "Attivo") {
            return <div className="green">{field}</div>
        } else return <div className="grey">{field}</div>
    }

    return (
        <>
            <div className="margin-bottom-24 flex-center-between">
                <div>
                    <FormattedMessage id="results"/>
                    ({siteList && siteList.offerts && siteList.offerts.length})
                </div>
                <div className="flex-align-center">
                    {/*<div className="margin-right-32">*/}
                    {/*    <span>*/}
                    {/*        <FormattedMessage id="state"/>:*/}
                    {/*    </span>*/}
                    {/*    <Select*/}
                    {/*        className="selectCustom"*/}
                    {/*        popupClassName="dropdown border-radius-0"*/}
                    {/*        bordered={false}*/}
                    {/*        dropdownMatchSelectWidth={false}*/}
                    {/*        style={{width: "fit-content"}}*/}
                    {/*        defaultValue={"all"}*/}
                    {/*        suffixIcon={*/}
                    {/*            <Icon name={ICONS.arrow_small} size={[20, 20]} color={"#0E0E2C"}/>*/}
                    {/*        }*/}
                    {/*        options={[*/}
                    {/*            {*/}
                    {/*                options: [*/}
                    {/*                    {label: 'Tutti', value: 'all'},*/}
                    {/*                ],*/}
                    {/*            },*/}
                    {/*            {*/}
                    {/*                label: <Divider className="divider"/>,*/}
                    {/*                options: [*/}
                    {/*                    {label: 'Assunti', value: 'hired'},*/}
                    {/*                    {label: 'Selezionati', value: 'selected'},*/}
                    {/*                    {label: 'Interessanti', value: 'interesting'}*/}
                    {/*                ],*/}
                    {/*            }*/}
                    {/*        ]}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div>
                        <span>
                            <FormattedMessage id="orderBy"/>:
                        </span>
                        <Select
                            popupClassName="dropdown"
                            bordered={false}
                            dropdownMatchSelectWidth={false}
                            style={{width: "fit-content"}}
                            value={order}
                            onChange={(value) => {
                                setOrder(value)
                            }}
                            suffixIcon={
                                <Icon name={ICONS.arrow_small} size={[20, 20]} color={"#0E0E2C"}/>
                            }
                            options={
                                [
                                    SORTING.ASC,
                                    SORTING.DESC,
                                ]
                            }
                        >
                        </Select>
                    </div>
                </div>
            </div>
            <Table
                className="siteTable"
                columns={columns}
                dataSource={siteList.offerts}
            />
        </>
    )
}
