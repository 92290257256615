import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import "./DashboardUserStyle.scss";
import {Col, List, Row} from "antd";
import {ICONS} from "src/assets/sprite/iconsNames"
import Icon from "../../component/Icon/Sprite";
import {Switch} from "src/component/Useful/Switch";
import {InputLogin} from "src/component/Useful/Input/InputLogin"
import SelectPrefix from "src/component/Useful/Select/Select"
import {ButtonUser} from "src/component/Useful/Button/ButtonUser";
import {ButtonLine} from "src/component/Useful/Button/ButtonLine";
import {useDispatch, useSelector} from "react-redux";
import actions from "src/redux/userProfile/actions";
import locationsActions from "src/redux/location/actions";
import {EFFORT} from "src/utils/utility.js";
import dayjs from "dayjs";
import {ImgComponent, substituteZeroWithLine} from "src/utils/utility";
import {PRIVATE_USER_ROUTE} from "src/route.constants";
import GreyLogo from "src/assets/logo/logo-VT-grey.svg"
import {FormattedMessage} from "react-intl";
import {FeedbackButton} from "../../component/Useful/Button/FeedbackButton";
import {getClickableLink} from "../../helpers/utility";
import EmptyOffers from "../../component/Layout/OffersList/EmptyOffers";

export default function DashboardUser() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const showItem = 7

    const {getUserDashboard} = actions
    const {getAll} = locationsActions

    const [titleFilter, setTitleFilter] = useState(null)
    const [locationFilter, setLocationFilter] = useState(null)
    const [impegnoFilter, setImpegnoFilter] = useState(null)
    const [onlyRemoteFilter, setOnlyRemoteFilter] = useState(false)
    const [showMoreLocation, setShowMoreLocation] = useState(false)

    const locations = useSelector(state => state.Location.locations)
    const lastOffers = useSelector(state => state.UserProfile.lastOffers)
    const offerByLocation = useSelector(state => state.UserProfile.offerByLocation)
    const otherLocationCount = useSelector(state => state.UserProfile.otherLocationCount)
    const feedbackLinkUsers = useSelector(state => state.UserProfile.feedbackLinkUsers)


    useEffect(() => {
        dispatch(getUserDashboard())
    }, [])


    function checkNumOfLocation() {
        if ((offerByLocation.length - showItem) === 1) {
            return "+ " + (offerByLocation.length - showItem) + " zona"
        } else {
            return "+ " + (offerByLocation.length - showItem) + " zone"
        }
    }

    return (
        <div className="userDashboard">
            <div className="bgImage">
                <div className="topPadding">
                    <div className="maxWidth">
                        <div className="title size-28 weight-700 merriWeather">
                            Buongiorno, {localStorage.getItem("firstName")}!
                        </div>
                        <div className="sub-title margin-bottom-24 size-16">
                            Cerca tra migliaia di offerte di lavoro di aziende globali che stanno assumendo in questo
                            momento.
                        </div>
                        {!!feedbackLinkUsers &&
                        <FeedbackButton
                            icon={<Icon name={ICONS.edit} size={[20, 20]}/>}
                            href={getClickableLink(feedbackLinkUsers)}
                            target={"_blank"}
                            color={"#208ac6"}
                            style={{
                                marginBottom: "20px"
                            }}
                        >
                            <FormattedMessage id={"feedbackPlatformUser"}/>
                        </FeedbackButton>
                        }

                        <div className="filterBar">
                            <Row gutter={[8, 8]}>
                                <Col xl={5} lg={24} md={24} xs={24}>
                                    <div className={"input-dashboard"}>
                                        <InputLogin
                                            className="input-login-dashboard"
                                            prefix={<Icon name={ICONS.search} className="opacity-50" size={[20, 20]}/>}
                                            placeholder={"Nome offerta"}
                                            style={{height: "100%"}}
                                            value={titleFilter}
                                            onChange={(element) => setTitleFilter(element.target.value)}
                                        />
                                    </div>
                                </Col>
                                <Col xl={5} lg={8} md={8} xs={24}>
                                    <div className={"select-dashboard-when"}>
                                        <SelectPrefix
                                            className="select-prefix-dashboard-when"
                                            placeholder="Dove?"
                                            key={"test"}
                                            showSearch
                                            allowClear
                                            value={locationFilter}
                                            filterOption={false}
                                            suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]}/>}
                                            prefixicon={<Icon name={ICONS.pin} className="opacity-50 margin-5"
                                                              size={[20, 20]}/>}
                                            options={locations && locations.map(element => ({
                                                id: element.id,
                                                value: element.city,
                                                label: element.city
                                            }))}
                                            onClear={() => {
                                                setLocationFilter(null)
                                            }}
                                            onFocus={() => {
                                                dispatch(getAll({
                                                    page: 0,
                                                    size: 20
                                                }))
                                            }}
                                            onSearch={(value) => {
                                                dispatch(getAll({
                                                    page: 0,
                                                    size: 20,
                                                    city: value
                                                }))
                                            }}
                                            onSelect={(value, record) => {
                                                setLocationFilter(record)
                                            }}

                                        />
                                    </div>
                                </Col>
                                <Col xl={5} lg={8} md={8} xs={24} className="select-dashboard">
                                    <div>
                                        <SelectPrefix
                                            className="select-prefix-dashboard"
                                            placeholder={"Impegno richiesto"}
                                            allowClear
                                            filterOption={false}
                                            suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]}/>}
                                            prefixicon={<Icon name={ICONS.calendar} className="opacity-50 margin-5"
                                                              size={[20, 20]}/>}
                                            options={EFFORT}
                                            value={impegnoFilter}
                                            onClear={() => {
                                                setImpegnoFilter(null)
                                            }}
                                            onSelect={(value) => {
                                                setImpegnoFilter(value)
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col xl={7} lg={8} md={7} xs={24}>
                                    <div className="switch-button">
                                        <Row>
                                            <Col xl={12} lg={11} md={0} sm={0} xs={0} className="flex-align-center">
                                                <div className={"switch-dashboard"}>
                                                    <Switch
                                                        checkedChildren={<Icon name={ICONS.ok} size={[20, 20]}/>}
                                                        unCheckedChildren={<Icon name={ICONS.close_switch} color="grey"
                                                                                 size={[20, 20]}/>}
                                                        checked={onlyRemoteFilter}
                                                        onChange={(value) => setOnlyRemoteFilter(value)}
                                                    />
                                                    <span
                                                        className="weight-500 size-14 margin-left-5">Solo remoto
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col xl={12} lg={13} md={24} sm={24} xs={24}>
                                                <div className="button-dashboard">
                                                    <ButtonUser className={"flex-center button-user-dashboard"}
                                                                icon={<Icon name={ICONS.search} size={[20, 20]}
                                                                            className={"margin-right-5"}/>}
                                                                onClick={() => {
                                                                    let queryString = "?onlyRemote=" + onlyRemoteFilter
                                                                    if (titleFilter) {
                                                                        queryString = queryString + "&title=" + titleFilter
                                                                    }
                                                                    if (locationFilter) {
                                                                        queryString = queryString + "&locationId=" + locationFilter.id
                                                                    }
                                                                    if (impegnoFilter) {
                                                                        queryString = queryString + "&impegnoRichiesto=" + impegnoFilter
                                                                    }
                                                                    navigate(PRIVATE_USER_ROUTE.JOB_OFFER + queryString)
                                                                }}>
                                                        Ricerca
                                                    </ButtonUser>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"maxWidth"}>
                <div className="list-margin">
                    <div className="margin-bottom-24 weight-700 size-20 merriWeather">
                        Ultime offerte
                    </div>
                    <div className="margin-bottom-24">
                        {lastOffers.length > 0 ?
                            <List
                                className="list-dashboard"
                                dataSource={lastOffers && lastOffers}
                                bordered={true}
                                renderItem={(element) => (
                                    <List.Item key={element.id}>
                                        {!element.anonymous ?
                                            <div className="imgBorder">
                                                <ImgComponent logoPath={element.company && element.company.logoPath}/>
                                            </div> :
                                            <div className="imgBorder bg-grey">
                                                <div className="noLogo">
                                                    <img src={GreyLogo}/>
                                                </div>
                                            </div>}
                                        <div className="title-companyname">
                                            <div className="companyName blue">
                                                {element.title && element.title}
                                            </div>
                                            {element.anonymous ?
                                                <div className="opacity-50 underlined size-12">
                                                    Azienda Anonima
                                                </div>
                                                :
                                                <div className="opacity-50 underlined size-12 pointer" onClick={() => {
                                                    navigate("/user/company/" + (element.company && element.company.id))
                                                }}>
                                                    {element.location && element.location.name}
                                                </div>
                                            }
                                        </div>
                                        <div className="mode-remote">
                                            <div>
                                                {element.insertMode && element.insertMode}
                                            </div>
                                            <div className="opacity-50 size-12">
                                                {element.remoto && element.remoto ? "Remoto" : "Presenziale"}
                                            </div>
                                        </div>
                                        <div className="location-commit">
                                            <div>
                                                {element.location && element.location.city && element.location.city}
                                            </div>
                                            <div className="opacity-50 size-12">
                                                {element.impegnoRichiesto && element.impegnoRichiesto}
                                            </div>
                                        </div>
                                        <div className="icon-user flex-align-center">
                                            <Icon className="userIcon opacity-50" name={ICONS.user_ok} size={[20, 20]}/>
                                            {substituteZeroWithLine(element && element.candidateCount)}
                                        </div>
                                        <div className="date opacity-50 size-12">
                                            {element.dateCreated && (dayjs(element.dateCreated).format("DD MMM YYYY"))}
                                        </div>
                                    </List.Item>
                                )}
                            />
                            :
                            <EmptyOffers/>
                        }
                    </div>
                    <div className="horizontal-center flex-center">
                        <ButtonLine className="blue" onClick={() => {
                            navigate(PRIVATE_USER_ROUTE.JOB_OFFER)
                        }}>Vedi tutte le offerte</ButtonLine>
                    </div>
                </div>
            </div>
            {offerByLocation && offerByLocation.length > 0 &&
            <div>
                <div className={"maxWidth"}>
                    <div className="margin-bottom-24 weight-700 size-20 merriWeather">Ricerca per zona</div>
                    <div>
                        {offerByLocation && offerByLocation.length <= showItem || showMoreLocation ?
                            <div className="grid-container">
                                {offerByLocation && offerByLocation.map(element => {
                                    return (
                                        <div className="grid-item">
                                            <div className="flex-center">
                                                <div className="flex-row">
                                                    <div className="flex-center">
                                                        <Icon className="userIcon opacity-50" name={ICONS.pin}
                                                              size={[20, 20]}/>
                                                    </div>
                                                    <div className="flex-center">
                                                        <span className="blue weight-600 size-16 underlined pointer"
                                                              onClick={() => {
                                                                  let queryString = "?locationId=" + element.locationId
                                                                  navigate(PRIVATE_USER_ROUTE.JOB_OFFER + queryString)
                                                              }}>
                                                            {element.locationName}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="opacity-50 weight-400 size-14 flex-center">
                                                {element.totalOfferCount} offerte
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            :
                            <div className="grid-container">
                                {offerByLocation && offerByLocation.length > showItem && offerByLocation.slice(0, showItem).map(element => {
                                    return (
                                        <div className="grid-item">
                                            <div style={{marginLeft: 75}}>
                                                <div className="flex-row">
                                                    <div className="flex-center">
                                                        <Icon className="userIcon opacity-50" name={ICONS.pin}
                                                              size={[20, 20]}/>
                                                    </div>
                                                    <div className="flex-center">
                                                        <span className="blue weight-600 size-16 underlined pointer"
                                                              onClick={() => {
                                                                  let queryString = "?locationId=" + element.locationId
                                                                  navigate(PRIVATE_USER_ROUTE.JOB_OFFER + queryString)
                                                              }
                                                              }>
                                                            {element.locationName}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="opacity-50 weight-400 size-14">
                                                {element.totalOfferCount} offerte
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="grid-item">
                                    <div className="flex-justify-center blue weight-600 size-16 underlined pointer"
                                         style={{lineHeight: "43px"}} onClick={() => setShowMoreLocation(true)}>
                                        {checkNumOfLocation()}
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>}
        </div>
    )
}
