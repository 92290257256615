import React from "react";
import {FormattedMessage} from "react-intl";


function EditOffer(){

    return(
        <div className={'activated-container-content-error'}>
            <h1><FormattedMessage id="approved_edit_offer"/></h1>
        </div>
    )
}

export default EditOffer
