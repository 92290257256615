import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, Col, Divider, List, Row} from "antd";
import Icon from "../../Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import {ButtonUser} from "../../Useful/Button/ButtonUser";
import drawerActions from "src/redux/drawer/actions";
import {DRAWER_TYPE, ImgComponent, OFFER_FILTERS_CANDIDATE, SORTING, substituteZeroWithLine} from "src/utils/utility";
import {ButtonLine} from "src/component/Useful/Button/ButtonLine";
import {FormattedMessage} from "react-intl";
import userActions from "../../../redux/userProfile/actions"
import actions from "src/redux/offer/actions";
import preferenceActions from "src/redux/preference/actions";
import candidatureActions from "src/redux/candidature/actions";
import * as dayjs from "dayjs";
import {useNavigate} from "react-router";
import GreyLogo from "../../../assets/logo/logo-VT-grey.svg";
import {PRIVATE_USER_ROUTE} from "src/route.constants";


let relativeTime = require('dayjs/plugin/relativeTime')

function ListMyOffert(props) {

    let dispatch = useDispatch()
    const navigate = useNavigate()

    dayjs.extend(relativeTime)

    const {addUserOfferToPrevious, updateOfferViews} = actions;
    const {openDrawer} = drawerActions;
    const {setRecordOffer} = userActions;
    const {savePreference, deletePreference} = preferenceActions;
    const {getAllMyCandidature} = candidatureActions;

    const {setType, page, setPage, titleFilter, locationFilter, impegnoFilter, onlyRemoteFilter, viewComponent, setViewComponent, changeStyle} = props;

    let isCompleted = localStorage.getItem("isCompleted") === "true"

    const [stateStyle, setStateStyle] = useState("width-100")

    const offers = useSelector(state => state.Offer.offers)
    const totalOffers = useSelector(state => state.Offer.totalOffers)
    const loading = useSelector(state => state.Offer.loading)
    const recordOffer = useSelector(state => state.UserProfile.recordOffer)
    const myCandidatures = useSelector(state => state.Candidature.myCandidatures)


    useEffect(() => {
        if (changeStyle !== null) {
            if (!changeStyle) {
                setViewComponent(false)
                setStateStyle("ease-list-out width-100")
            } else {
                setStateStyle("ease-list-in width-60")
                setTimeout(() => {
                    setViewComponent(true)
                }, 950)
            }
        }
    }, [changeStyle])

    function openDetailCard() {
        setStateStyle("ease-list-in width-60")
        setTimeout(() => {
            setViewComponent(true)
        }, 950)
    }

    function closeDetailCard() {
        setViewComponent(false)
        setStateStyle("ease-list-out width-100")
    }


    useEffect(() => {
        if (isCompleted) {
            dispatch(getAllMyCandidature({
                state: OFFER_FILTERS_CANDIDATE.ALL.value,
                sort: SORTING.ASC.value
            }))
        }
    }, [isCompleted])

    function setField(record) {
        let path = "/user/company/"
        if (record.id) path = path + record.company.id
        navigate(path)
    }


    return (
        <div className={"flex-row"}>
            <div className={stateStyle}>
                {offers &&
                <List bordered onClick={openDetailCard} dataSource={offers} className={"pointer"}>
                    {offers.map((element, index) => {
                        return (
                            <List.Item
                                key={element.id}
                                onClick={() => {
                                    if (element.id !== (recordOffer && recordOffer.id)) {
                                        dispatch(updateOfferViews(element.id))
                                    }
                                    dispatch(setRecordOffer(element))
                                }}
                                className={element.id === (recordOffer && recordOffer.id) ? "color-light-blue" : "white"}>
                                <div className="flex-row margin-right-20">

                                    <div style={!element.anonymous ? {width: "88px", height: "72px", marginRight: 20, border: "1px solid rgba(14, 14, 44, 0.15)"} : {width: "88px", height: "72px", marginRight: 20, border: "none"}}>
                                        {!element.anonymous ?
                                            <ImgComponent logoPath={element.company && element.company.logoPath}/>
                                            :
                                            <div className="noLogo">
                                                <img src={GreyLogo}/>
                                            </div>
                                        }
                                    </div>
                                    <div className="flex-column">
                                        <div className="blue underlined weight-700 size-16">{element.title}</div>
                                        <div className="opacity-50 underlined weight-400 size-12" style={{width: "fit-content"}} onClick={(e) => setField(element)}>
                                            {element.location && element.location.name}
                                        </div>
                                        <div className="flex-row dividerDot">
                                            {element.insertMode &&
                                            <div className="flex-center">
                                                {element.insertMode}
                                            </div>
                                            }
                                            {element.workPlace &&
                                            <div className="flex-center">
                                                {element.workPlace}
                                            </div>
                                            }
                                            {element.location && element.location.city &&
                                            <div className="flex-center">
                                                {element.location.city}
                                            </div>
                                            }
                                            {element.impegnoRichiesto &&
                                            <div className="flex-center">
                                                {element.impegnoRichiesto}
                                            </div>
                                            }
                                            <div className="flex-center">
                                                <Icon color="grey" name={ICONS.user_ok}
                                                      size={[20, 20]}/>
                                                <span className="margin-left-5">
                                                    {substituteZeroWithLine(element && element.candidateCount)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column weight-400 size-12 opacity-50"/>
                                    <div style={{transform: "rotate(-90deg)"}}>
                                        <Icon name={ICONS.arrow} size={[20, 20]} className="opacity-50"/>
                                    </div>
                                </div>
                            </List.Item>
                        )
                    })}
                </List>
                }
                <div className="loadMore size-14">
                    {totalOffers &&
                    <div className="flex-center">Offerte visualizzate <div
                        className="margin-left-5 weight-700">{totalOffers > page * 10 ? page * 10 : totalOffers} di {totalOffers}
                    </div>
                    </div>
                    }
                    <div>
                        {totalOffers > page * 10 &&
                        <ButtonLine
                            className="loadMoreButton"
                            loading={loading}
                            onClick={() => {
                                let currentPage = page
                                setPage(page + 1)
                                dispatch(addUserOfferToPrevious(
                                    {
                                        page: currentPage + 1,
                                        size: 10,
                                        title: titleFilter,
                                        locationId: locationFilter && locationFilter.id,
                                        impegnoRichiesto: impegnoFilter,
                                        onlyRemote: onlyRemoteFilter,
                                        sort: SORTING.DESC.value
                                    }
                                ))
                            }}>
                            <FormattedMessage id="seeAllOffers"/>
                        </ButtonLine>
                        }
                    </div>
                </div>
            </div>
            {viewComponent &&
            <div style={{width: "40%"}}>
                <Card style={{marginLeft: "20px", position: "sticky", top: "70px", borderRadius: 0}}>
                    <div className="flex-row margin-bottom-30 flex-center-between">
                        <div className="flex-align-center">
                            <Icon color="grey" name={ICONS.user_ok}
                                  size={[20, 20]}/>
                            <span className="margin-left-5">
                                {substituteZeroWithLine(recordOffer && recordOffer.candidateCount)}
                            </span>

                        </div>
                        <div style={{
                            width: "120px",
                            height: "100px",
                            margin: "-80px 0 0 0",
                            border: "1px solid rgba(14, 14, 44, 0.15)"
                        }}>
                            {recordOffer && !recordOffer.anonymous ?
                                <ImgComponent logoPath={recordOffer && recordOffer.company && recordOffer.company.logoPath && recordOffer.company.logoPath}/>
                                :
                                <div className="noLogo">
                                    <img src={GreyLogo}/>
                                </div>
                            }
                        </div>
                        <div className="flex-row">
                            <div onClick={closeDetailCard} className={"pointer"}>
                                <Icon name={ICONS.close} size={[20, 20]} className="icon" color={"#868696"}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex-center flex-column margin-bottom-30">
                        <div className="weight-700 size-22">{recordOffer && recordOffer.title}</div>
                        {recordOffer && recordOffer.anonymous ?
                            <div className="opacity-50 underlined size-12">
                                Azienda Anonima
                            </div> :
                            <div className="weight-40 size-14 underlined pointer" onClick={() => setField(recordOffer)}>
                                {recordOffer && recordOffer.location && recordOffer.location.name}
                            </div>}
                    </div>
                    <Divider/>
                    <div className="margin-top-30 margin-bottom-30">
                        <div>
                            {recordOffer && recordOffer.location &&
                            <div>
                                <span className="weight-700 margin-right-5">Dove:</span>
                                <span>{recordOffer.location.city}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.experience &&
                            <div>
                                <span className="weight-700 margin-right-5">Esperienza:</span>
                                <span>{recordOffer.experience}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.impegnoRichiesto &&
                            <div>
                                <span className="weight-700 margin-right-5">Tempo:</span>
                                <span>{recordOffer.impegnoRichiesto}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.insertMode &&
                            <div>
                                <span className="weight-700 margin-right-5">Contratto:</span>
                                <span>{recordOffer.insertMode}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.workPlace &&
                            <div>
                                <span className="weight-700 margin-right-5">Modalità:</span>
                                <span>{recordOffer.workPlace}</span>
                            </div>
                            }
                        </div>
                    </div>
                    <Row>
                        <Col xl={12}>
                            <div className="margin-bottom-30">
                                {/*<Button className={"padding-5 border-radius-3 margin-right-10"}>*/}
                                {/*    <Icon name={ICONS.facebook} size={[20, 20]} color={"#1877F2"}/>*/}
                                {/*</Button>*/}
                                {/*<Button className={"padding-5 border-radius-3 margin-right-10"}>*/}
                                {/*    <Icon name={ICONS.twitter} size={[20, 20]} color={"#1DA1F2"}/>*/}
                                {/*</Button>*/}

                                {recordOffer && recordOffer.company && recordOffer.company.linkedin &&
                                <Button className={"padding-5 border-radius-3 margin-right-10"}>
                                    <a href={recordOffer.company.linkedin} target="_blank">
                                        <Icon name={ICONS.linkedin} size={[20, 20]} color={"#1DA1F2"}/>
                                    </a>
                                </Button>
                                }
                                {recordOffer && recordOffer.company && recordOffer.company.webPage &&
                                <Button className={"padding-5 border-radius-3"}>
                                    <a href={recordOffer.company.webPage} target="_blank">
                                        <Icon name={ICONS.link} size={[20, 20]} className={"opacity-50"}/>
                                    </a>
                                </Button>
                                }
                            </div>
                        </Col>
                        <Col xl={12}>
                            <div className="flex-row margin-bottom-30 margin-left-55">
                                <div className={"flex-center flex-align-center margin-right-24 pointer"}>
                                    {recordOffer && recordOffer.preference ?
                                        <span onClick={() => {
                                            dispatch(deletePreference(recordOffer.id))
                                        }}>
                                            <Icon name={ICONS.union} size={[20, 20]} className={"icon-heart"}
                                                  color="#208ac6"/>
                                        </span>
                                        :
                                        <span onClick={() => {
                                            dispatch(savePreference(recordOffer.id))
                                        }}>
                                            <Icon name={ICONS.heart} size={[20, 20]} className={"icon-heart"} color="grey"/>
                                        </span>
                                    }
                                </div>

                                {(recordOffer && myCandidatures && myCandidatures.some(element => element.offertId === recordOffer.id))
                                    ? <div className={"flex-column"}>
                                        <div className={"flex-align-center margin-0"}>

                                            <Icon name={ICONS.tik} classname={"margin-right-5"} size={[20, 20]}
                                                  color="#69BE7B"/>
                                            <p className={"weight-700 margin-0 size-16 green"}>Candidato</p>
                                        </div>

                                        {/*<p className={"opacity-50 weight-400 margin-0 flex-center size-12"}>{dayjs().toNow()}</p>*/}
                                    </div>
                                    : <ButtonUser
                                        style={{minWidth: 100, height: 40}}
                                        onClick={() => {
                                            isCompleted
                                                ? setType(DRAWER_TYPE.secondStepCandidatureUser)
                                                : setType(DRAWER_TYPE.firstStepCandidatureUser)
                                            dispatch(openDrawer())
                                        }}>
                                        Candidati
                                    </ButtonUser>
                                }
                            </div>
                        </Col>
                        {(recordOffer && myCandidatures && myCandidatures.some(element => element.offertId === recordOffer.id)) &&
                        <div>
                            <p>Hai applicato a questa offerta di lavoro!</p>
                            <p>Vai alle <span className={"blue underlined weight-700 size-14 pointer"} onClick={() => navigate(PRIVATE_USER_ROUTE.MY_CANDIDATURE)}>"Mie Candidature"</span> per lo stato di avanzamento</p>
                        </div>
                        }
                    </Row>
                    <Divider>
                        <div className="size-12 opacity-50">Descrizione</div>
                    </Divider>
                    <p dangerouslySetInnerHTML={{__html: recordOffer && recordOffer.description}}/>
                </Card>
            </div>
            }
        </div>
    )
}

export default ListMyOffert
