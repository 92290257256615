import React, {useEffect, useState} from "react";
import {endpoints} from "src/config/site.config";


export function switchStateLabel(state) {
    switch (state) {
        case OFFER_FILTERS_CANDIDATE.WAITING.value:
            return OFFER_FILTERS_CANDIDATE.WAITING.label
        case OFFER_FILTERS_CANDIDATE.INTERVIEW.value:
            return OFFER_FILTERS_CANDIDATE.INTERVIEW.label
        case OFFER_FILTERS_CANDIDATE.PRESELECTED.value:
            return OFFER_FILTERS_CANDIDATE.PRESELECTED.label
        case OFFER_FILTERS_CANDIDATE.SELECTED.value:
            return OFFER_FILTERS_CANDIDATE.SELECTED.label
        case OFFER_FILTERS_CANDIDATE.DISLIKE.value:
            return OFFER_FILTERS_CANDIDATE.DISLIKE.label
        default:
            return "Non ancora visualizzata"
    }
}

export const OFFER_STATE = {
    all: {
        value: "Tutti",
        label: "Tutti"
    },
    toPublished: {
        value: "Da pubblicare",
        label: "Da pubblicare"
    },
    draft: {
        value: "Bozza",
        label: "Bozza"
    },
    published: {
        value: "Pubblicata",
        label: "Pubblicata"
    },
    expired: {
        value: "Scaduta",
        label: "Scaduta"
    }
}

export const ALERT_TYPE = {
    WARNING: "warning",
    ERROR: "error",
}

export const CONTEXT = {
    USER: "user",
    COMPANY: "company",
    LANDING: "landing"
}

export const ROLES = {
    SUPER_ADMIN: "ROLE_SUPERADMIN",
    ADMIN: "ROLE_ADMIN",
    SYS_ADMIN: "SYS_ADMIN",
    EMPLOYEE: "ROLE_EMPLOYEE",
    USER: "ROLE_USER"
}

export const OFFER_FILTERS = {
    READ: "none",
    WAITING: "In attesa",
    INTERVIEW: "Colloquio",
    PRESELECTED: "Preselezione",
    SELECTED: "Selezionato",
    DISLIKE: "Rifiutato"
}

export const OFFER_FILTERS_CANDIDATE = {
    ALL: {
        label: "Tutti",
        value: "none"
    },
    WAITING: {
        label: "CV Visualizzati",
        value: "In attesa"
    },
    INTERVIEW: {
        label: "Da Contattare",
        value: "Colloquio"
    },
    PRESELECTED: {
        label: "Da Riesaminare",
        value: "Preselezione"
    },
    SELECTED: {
        label: "Selezionato",
        value: "Selezionato"
    },
    DISLIKE: {
        label: "Escluso",
        value: "Rifiutato"
    }
}

export const COMPANY_MODAL_TYPE = {
    NEW: "new",
    NEW_FORM: "newForm",
    EDIT: "edit",
    EDIT_FORM: "editForm",
}

export const DRAWER_COMPANY_ITEM = [
    {
        id: "companyData",
        label: "Dati anagrafici"
    },
    {
        id: "companyDescription",
        label: "Descrizione"
    },
    {
        id: "location",
        label: "Sedi"
    },
    {
        id: "responsible",
        label: "Responsabile"
    },
    {
        id: "otherInformation",
        label: "Altre informazioni"
    },
]

export const DRAWER_OFFER_ITEM = [
    {
        id: "personalData",
        label: "Dati anagrafici"
    },
    {
        id: "offerDescription",
        label: "Descrizione dell'offerta"
    },
    {
        id: "freeQuestions",
        label: "Domande"
    },
    {
        id: "expiration",
        label: "Scadenza"
    },
    {
        id: "sendNotificationMail",
        label: "Notifiche candidati"
    }
]

export const DRAWER_OFFER_USER_ITEM = [
    {
        id: "cv",
        label: "CV"
    },
    {
        id: "personalData",
        label: "Dati anagrafici"
    },
    {
        id: "languages",
        label: "Lingue"
    },
    {
        id: "personalDescription",
        label: "Descrizione personale"
    },
    {
        id: "otherInformation",
        label: "Altro"
    }
]

export var QUALIFICATION = [
    {
        value: "Licenza Media",
        label: "Licenza Media"
    },
    {
        value: "Diploma",
        label: "Diploma"
    },
    {
        value: "Laurea",
        label: "Laurea"
    },
    {
        value: "Laurea Magistrale",
        label: "Laurea Magistrale"
    },
    {
        value: "Master",
        label: "Master"
    }
]

export var EXPERIENCE = [
    {
        value: "Anche Senza Esperienza",
        label: "Anche Senza Esperienza"
    },
    {
        value: "Con Esperienza Minima",
        label: "Con Esperienza Minima"
    },
    {
        value: "Esperto (da 1 a 3 anni)",
        label: "Esperto (da 1 a 3 anni)"
    },
    {
        value: "Esperto (da 3 a 5 anni)",
        label: "Esperto (da 3 a 5 anni)"
    },
    {
        value: "Esperto (oltre 5 anni)",
        label: "Esperto (oltre 5 anni)"
    }
]

export var HOW_DO_YOU_KNOW_US_USER = [
    {
        value: 'Internet',
        label: 'Internet',
    },
    {
        value: 'Pubblicità locale',
        label: 'Pubblicità locale',
    },
    {
        value: 'Amici',
        label: 'Amici',
    },
    {
        value: "Social",
        label: "Social"
    }
]

export var HOW_DO_YOU_KNOW_US_COMPANY = [
    {
        value: 'Internet',
        label: 'Internet',
    },
    {
        value: 'Pubblicità locale',
        label: 'Pubblicità locale',
    },
    {
        value: 'Amici',
        label: 'Amici',
    },
    {
        value: "Social",
        label: "Social"
    },
    {
        value: "Colleghi",
        label: "Colleghi"
    },
    {
        value: "Commercialista",
        label: "Commercialista"
    },
    {
        value: "Consulente del lavoro",
        label: "Consulente del lavoro"
    }
]

export var NUMBER_OF_EMPLOYEES = [
    {
        value: 'Da 0 a 10',
        label: 'Da 0 a 10 dipendenti',
        className: "weight-600 size-14",
    },
    {
        value: 'Da 11 a 50',
        label: 'Da 11 a 50 dipendenti',
        className: "weight-600 size-14",
    },
    {
        value: 'Da 51 a 100',
        label: 'Da 51 a 100 dipendenti',
        className: "weight-600 size-14",
    },
    {
        value: 'Oltre 100',
        label: 'Oltre 100 dipendenti',
        className: "weight-600 size-14",
    }
]

export var EFFORT = [
    {
        value: "Full-Time",
        label: "Full-Time"
    },
    {
        value: "Part-Time",
        label: "Part-Time"
    }
]

export var INSERT_MODE = [
    {
        value: "Tirocinio extracurriculare",
        label: "Tirocinio extracurriculare"
    },
    {
        value: "Tirocinio",
        label: "Tirocinio",
    },
    {
        value: "Contratto di Apprendistato",
        label: "Contratto di Apprendistato"
    },
    {
        value: "Contratto di collaborazione",
        label: "Contratto di collaborazione"
    },
    {
        value: "Tempo Indeterminato",
        label: "Tempo Indeterminato"
    },
    {
        value: "Tempo Determinato",
        label: "Tempo Determinato"
    },
    {
        value: "Altro",
        label: "Altro"
    }
]

export var WORK_ACTIVITY = [
    {
        value: "Da Remoto",
        label: "Da Remoto"
    },
    {
        value: "In Sede Aziendale",
        label: "In Sede Aziendale"
    }
]

export var LANGUAGE = [
    {
        value: "Elementare",
        label: "Elementare"
    },
    {
        value: "Intermedio ",
        label: "Intermedio "
    },
    {
        value: "Avanzato",
        label: "Avanzato"
    },
    {
        value: "Madre Lingua",
        label: "Madre Lingua"
    }
]

export const DRAWER_TYPE = {
    offer: "offer",
    company: "company",
    firstStepCandidatureUser: "firstStepCandidatureUser",
    secondStepCandidatureUser: "secondStepCandidatureUser",
    confirmOfferUser: "confirmOfferUser",
    companyEditAccount: "companyEditAccount"
}

export const SORTING = {
    ASC: {
        label: "Più recente",
        value: "ASC"
    },
    DESC: {
        label: "Meno recente",
        value: "DESC"
    },
}

export const NOTIFICATION_TYPE = {
    NEW_CANDIDATURE: "Nuova candidatura",
    NEW_OFFER: "Nuova offerta ",
    NEW_COMPANY: "Nuova azienda"
}


export function firstLetters(name, lastName) {
    if (name && lastName)
        return (name.slice(0, 1) + lastName.slice(0, 1)).toUpperCase()
}

export function substituteZeroWithLine(field) {
    if (!field) {
        return "-"
    } else {
        return field
    }
}

export const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    })


export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}

let lastId = 0;

export function newId(prefix = 'id') {
    lastId++;
    return `${prefix}${lastId}`;
}

export function ImgComponent(props) {

    const [image, setImage] = useState(null)

    useEffect(() => {
        if (props.logoPath) {
            fetch(endpoints.getImageSource, {
                headers: {
                    'ApiKey': "VKtYsk000kc8RXpduQpyEEmnoaGkrh",
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    ...(!props?.isPublic && {Authorization: 'Bearer ' + localStorage.getItem('id_token')}),
                },
                body: JSON.stringify({name: props.logoPath}),
                method: "POST"
            }).then(response => response.blob().then(blob => {
                setImage(URL.createObjectURL(blob))
            })).catch(e => console.log(e))
        }
    }, [props.logoPath])


    return (
        <>
            {image &&
            <img src={image} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
            }
        </>
    )
}
