import React, {useEffect} from "react";
import "src/component/Layout/OffersList/OffersListStyle.scss";
import {Checkbox, ConfigProvider, Divider, List, Popover, Space} from "antd";
import {ICONS} from "src/assets/sprite/iconsNames";
import {useLocation, useNavigate} from "react-router";
import {PRIVATE_COMPANY_ROUTE} from "src/route.constants";
import {FormattedMessage} from "react-intl";
import Icon from "src/component/Icon/Sprite";
import {OFFER_STATE, substituteZeroWithLine} from "src/utils/utility";


export function OffersList(props) {

    const location = useLocation()
    let navigate = useNavigate();

    let {lastOffers} = props


    const customizedEmpty = () => (
        <div className="noOffers">
            <div className="noOffersIcon flex-center">
                <Icon className="infoIcon" name={ICONS.doc_add_100} size={[100, 100]} color={"#C41A43"}/>
            </div>
            <div className="size-18 black-text weight-600">
                <FormattedMessage id="Offers_noOffers_1"/>
            </div>
            <div className="black-text opacity-70">
                <FormattedMessage id="Offers_noOffers_2"/>
            </div>
        </div>
    )

    return (
        <ConfigProvider renderEmpty={customizedEmpty}>
            <List
                className={`offersList ${location.pathname === PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY ? "dashboardPage" : (location.pathname === PRIVATE_COMPANY_ROUTE.WORK_OFFERS ? "offersPage" : null)}`}
                dataSource={lastOffers}
                bordered
                style={{backgroundColor: "#FFFFFF"}}
                renderItem={(item) => {
                    return (
                        <>
                            <List.Item key={item.id}>
                                {location.pathname === PRIVATE_COMPANY_ROUTE.WORK_OFFERS ?
                                    <div>
                                        <Checkbox/>
                                    </div>
                                    :
                                    null
                                }
                                <div>
                                    <div className="blue underlined pointer" onClick={() => navigate(PRIVATE_COMPANY_ROUTE.WORK_OFFERS_DETAIL.replace(":id", item.id))}>
                                        <u>{item && substituteZeroWithLine(item && item.title)}</u>
                                    </div>
                                    <div className="flex">
                                        <div className="flex grey size-12">
                                            <div style={{minWidth: 50, paddingInline: 5}}>
                                                {item && item.location ? item.location.city : "-"}
                                            </div>
                                            <div style={{minWidth: 50, paddingInline: 5}}>
                                                {item && substituteZeroWithLine(item.insertMode)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {item && substituteZeroWithLine(item.impegnoRichiesto)}
                                </div>
                                <div>
                                    <div
                                        className={`tag size-12 ${item && item.state === OFFER_STATE.published.value ? "green-background" : (item && item.state === OFFER_STATE.draft.value ? "grey-background" : "orange-background")}`}>
                                        {item && item.state}
                                    </div>
                                </div>
                                <div className="flex-align-center">
                                    <Icon className="opacity-50 margin-right-8" name={ICONS.user_ok} size={[20, 20]} color={"#0E0E2C"}/>
                                    {item && substituteZeroWithLine(item.candidateCount)}
                                </div>
                                <div className="flex-align-center">
                                    <Icon className="opacity-50 margin-right-8 pointer" name={ICONS.eye} size={[20, 20]} color={"#0E0E2C"}/>
                                    {item && substituteZeroWithLine(item.visualization)}
                                </div>
                                {/*<div>
                                    <Icon className="pointer icon" name={ICONS.edit} size={[20, 20]} color={"#868696"}/>
                                </div>*/}
                            </List.Item>
                        </>
                    )
                }}
            />
        </ConfigProvider>
    )
}
