import React, {useEffect} from "react";
import {Col, Row, Form, Space} from "antd";
import Icon from "../../../../Icon/Sprite";
import {ICONS} from "../../../../../assets/sprite/iconsNames";
import {Select} from "../../../../Useful/Select/Select";
import {LANGUAGE} from "../../../../../utils/utility";
import {FormattedMessage} from "react-intl";
import {InputLogin} from "../../../../Useful/Input/InputLogin";

export default function Languages(props) {

    const {languages, setLanguages} = props

    const addLanguages = () => {
        let tempLanguages = [...languages]
        tempLanguages.push({id: Math.random(),spokenLanguages: null, spokenLanguagesLevel: null })
        setLanguages(tempLanguages)
    }

    const onChangeValue = (value, key, type) => {
        let tempLanguages = [...languages]
        let index = tempLanguages.findIndex((item)=>item.id === key)
        if(index !== -1){
            if(type === "language"){
                tempLanguages[index].spokenLanguages = value
            }else if(type === "languageLevel"){
                tempLanguages[index].spokenLanguagesLevel= value
            }
        }
        setLanguages(tempLanguages)
    }

    const deleteLanguage = (id) => {
        setLanguages(languages.filter(item => item.id !== id))
    }

    return (
        <div className={"languageContent"}>
            {languages && languages.map((it, index)=>(
                <div>
                    <Row gutter={[24,24]} className={"flex-end margin-bottom-10"}>
                        <Col span={12}>
                            <div key={index} className={"languagesBox"}>
                                {index !== 0 &&
                                <header className={"languagesHeader"}>
                                    <div onClick={() => deleteLanguage(it.id)} className={"deleteDiv"}>
                                        <span className={"margin-bottom-0 margin-top-10 opacity-50 pointer flex-end underlined"}>Elimina</span>
                                    </div>
                                </header>}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[24,24]}>
                        <Col xl={12} md={12} sm={24} xs={24}>
                            <InputLogin placeholder="Inserisci la lingua conosciuta"
                                        value={it.spokenLanguages}
                                        onChange={(e)=>{
                                            onChangeValue(e.target.value, it.id, "language")
                                        }}
                            />
                        </Col>
                        <Col xl={12} md={12} sm={24} xs={24}>
                            <Select
                                allowClear
                                value={it.spokenLanguagesLevel}
                                suffixIcon={<Icon name={ICONS.arrow} size={[20, 20]} color={"#208AC6"}/>}
                                placeholder={"- Seleziona -"}
                                options={LANGUAGE}
                                onChange={(value)=>onChangeValue(value, it.id, "languageLevel")}
                            />
                        </Col>
                    </Row>
                </div>
            ))}
            <Row>
                <Col span={24} className="flex">
                    <div className="flex pointer addLanguages" onClick={addLanguages}>
                        <Icon name={ICONS.add} size={[20, 20]} className="padding-top-20" color={"#208AC6"}/>
                        <span className="weight-600 blue padding-top-20">
                            <FormattedMessage id="UserDetails_add_Language"/>
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
