import React, {useEffect} from "react";
import "src/component/Layout/AlertModal/AlertModal.scss"
import {Button, Modal, Space} from "antd";
import Icon from "src/component/Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import {FormattedMessage} from "react-intl";
import {ButtonUser} from "src/component/Useful/Button/ButtonUser";
import {ALERT_TYPE} from "src/utils/utility";
import {useDispatch, useSelector} from "react-redux";

export function AlertModal(props) {

    const {type, content, okText, onOk, onClose, isAlertOpen} = props

    const dispatch = useDispatch()

    return (
        <Modal
            className="alertModal"
            centered
            closable={false}
            open={isAlertOpen}
            width={440}
            destroyOnClose
            onCancel={() => onClose()}
            footer={
                <div className="footerPadding">
                    <Space size={20} direction={"horizontal"}>
                        <Button className="blue weight-700 size-14" type="text" onClick={() => onClose()}>
                            <FormattedMessage id="cancel"/>
                        </Button>
                        <ButtonUser
                            onClick={() => {
                                onOk()
                            }}
                        >
                            {okText}
                        </ButtonUser>
                    </Space>
                </div>
            }

        >
            <div className={`headerBar ${type ? type : ALERT_TYPE.WARNING}`}/>
            <div className="contentPadding">
                <div className="flex-align-center padding-bottom-16">
                    <Icon name={ICONS.info} size={[20, 20]} color={"#0E0E2C"}/>
                    <span className="padding-left-8 weight-700 size-24">
                        <FormattedMessage id="Alert_warning"/>
                    </span>
                </div>
                <div>
                    {content}
                </div>
            </div>
        </Modal>
    )
}