import React from "react";
import {ICONS} from "src/assets/sprite/iconsNames";
import {FormattedMessage} from "react-intl";
import Icon from "src/component/Icon/Sprite";

function DashboardNotActive() {
    return(
        <div className="accountInfo orange flex-center">
            <div className="flex-align-center">
                <Icon name={ICONS.info} size={[20, 20]} color={"#C41A43"}/>
                <span className="size-18 red-text weight-600 margin-left-10">
                    <FormattedMessage id="Dashboard_accountInfo3"/>
                </span>
            </div>
            <div>
                <span className="dark-grey-text">
                    <FormattedMessage id="Dashboard_accountInfo4"/>
                </span>
            </div>
    </div>
    )
}

export default DashboardNotActive
