import React, {useState} from "react";
import {ButtonGhost} from "../../Useful/Button/ButtonGhost";
import {FormattedMessage} from "react-intl";
import Icon from "../../Icon/Sprite";
import {ICONS} from "../../../assets/sprite/iconsNames";
import {Popover} from "antd";

function Content(){

    const [open, setOpen] = useState(false);

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const handleCloseChange = () => {
        setOpen(false);
    };

    const ContentText = (
        <div style={{width:670, right:8}}>
            <p className={"weight-700 size-29 dark merriWeather padding-left-40"}>Pubblicazione annuncio</p>
            <div>
                <p className={"weight-400 size-14 dark padding-left-right-40"}>Per una corretto svolgimento lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere, libero quis feugiat ornare, lectus purus tempus leo, et rutrum felis metus nec erat. In sit amet metus vestibulum, tristique felis ut, faucibus libero.</p>
                <p className={"weight-400 size-14 dark padding-left-right-40"}>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere, libero quis feugiat ornare, lectus purus tempus leo, et rutrum felis metus nec erat. In sit amet metus vestibulum, tristique felis ut, faucibus libero.</p>
                <p className={"weight-400 size-14 dark padding-left-right-40"}>2. Fusce sed tempor odio. Sed ut dapibus dui. Vestibulum eu euismod diam. Integer tellus augue, commodo a facilisis nec, pellentesque ut velit. Nullam sit amet orci a mauris lacinia suscipit. Cras vitae consequat ante. Vivamus suscipit pharetra fermentum.</p>
                <p className={"weight-400 size-14 dark padding-left-right-40"}>3. Maecenas nec pellentesque nibh. In laoreet nisl vehicula lectus commodo, quis dapibus diam volutpat. Donec neque purus, tristique vitae molestie sed, elementum consectetur nisi. Sed nibh neque, sodales vitae leo ac, facilisis fermentum lacus. Integer id ipsum risus. Fusce id lectus elementum, euismod metus ac, iaculis leo. In non mi egestas, sagittis erat accumsan, lobortis ligula. Sed ante sem, molestie non odio id, aliquam maximus neque. Fusce viverra tristique nulla pretium hendrerit. Pellentesque convallis ex mollis, gravida orci et, malesuada nisi. Sed non dictum felis.</p>
                <p className={"weight-400 size-14 dark padding-left-right-40"}>4. Maecenas posuere, libero quis feugiat ornare, lectus purus tempus leo, et rutrum felis metus nec erat. In sit amet metus vestibulum, tristique felis ut, faucibus libero.</p>
                <div className={"flex-end padding-top-bottom-right-40px"}>
                    <ButtonGhost onClick={handleCloseChange}>
                        <FormattedMessage id="button_close"/>
                    </ButtonGhost>
                </div>
            </div>
        </div>)

    return(
        <Popover content={ContentText} trigger="click" open={open} onOpenChange={handleOpenChange}>
            <span style={{paddingRight: 20}}>
                <Icon name={ICONS.help} size={[20, 20]} className="grey pointer"/>
            </span>
        </Popover>
    )
}

export default Content

