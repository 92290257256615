import React, {useEffect} from "react";
import "./superAdmin.scss"
import {ICONS} from "src/assets/sprite/iconsNames";
import Breadcrumb from "antd/es/breadcrumb";
import {PRIVATE_ADMIN_ROUTE} from "src/route.constants";
import {FormattedMessage} from "react-intl";
import {Button, message} from "antd";

import {useNavigate, useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import actions from "src/redux/admin/actions";

import {DashboardCard} from "../../component/Useful/Dashboard/DashboardCard/DashboardCard";
import "src/Pages/superAdmin/AdminDashboardStyle.scss"
import DownloadCompanyCsv from "../../component/Useful/Csv/DownloadCompanyCsv";
import DownloadUsersCsv from "../../component/Useful/Csv/DownloadUsersCsv";
import {ButtonGhost} from "../../component/Useful/Button/ButtonGhost";
import Icon from "../../component/Icon/Sprite";

export const CARD_TYPE = {
    companies: "companies",
    users: "users",
    settings: "settings"
}

function AdminDashboard() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [messageApi, contextHolder] = message.useMessage();

    const {getAdminDashboard} = actions;

    const companyCount = useSelector(state => state.Admin.companyCount)
    const newCompanyCount = useSelector(state => state.Admin.newCompanyCount)
    const baseUserCount = useSelector(state => state.Admin.baseUserCount)
    const newBaseUserCount = useSelector(state => state.Admin.newBaseUserCount)


    useEffect(() => {
        dispatch(getAdminDashboard())
    }, [])

    return (
        <div className="admin">
            {contextHolder}
            <div className="max-width-1200 padding-50">
                <div>
                    <Breadcrumb className="customBreadcrumb" separator="›">
                        <Breadcrumb.Item className="underlined pointer"
                                         onClick={() => navigate(PRIVATE_ADMIN_ROUTE.DASHBOARD)}>
                            <FormattedMessage id="Breadcrumb_home"/>
                        </Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="display-flex align-items-center">
                        <span className="size-28 weight-700 merriWeather">
                            Home
                        </span>
                    </div>
                </div>
                <div className="cardContainer">
                    <DashboardCard
                        type={CARD_TYPE.companies}
                        icon={ICONS.business}
                        header={`${companyCount} aziende`}
                        title="Aziende"
                        text={`${newCompanyCount} nuove`}
                        redirectTo={PRIVATE_ADMIN_ROUTE.COMPANIES}
                        csvButton={<DownloadCompanyCsv/>}
                    />
                    <DashboardCard
                        type={CARD_TYPE.users}
                        icon={ICONS.user_multi}
                        header={`${baseUserCount} utenti`}
                        title="Utenti"
                        text={`${newBaseUserCount} nuovi`}
                        redirectTo={PRIVATE_ADMIN_ROUTE.USERS}
                        csvButton={<DownloadUsersCsv/>}
                    />

                    <DashboardCard
                        type={CARD_TYPE.settings}
                        icon={ICONS.settings}
                        header={"Impostazioni"}
                        title={"Impostazioni"}
                        redirectTo={PRIVATE_ADMIN_ROUTE.SETTINGS}
                        />
                </div>
            </div>

        </div>
    )
}

export default AdminDashboard