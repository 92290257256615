import React from "react";
import {Button as AntInput} from "antd";
import styled from "styled-components"

export const ButtonNoLine = styled(AntInput)`

  &.ant-btn{
    height: 48px;
    min-width: 100px;
    background: #FFFFFF;
    border-radius: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    
    
    /* White */
    
    color: #FFFFFF;

    &::after {
      all: unset;
    }
  }
  
  &.ant-btn-default{
    height: 48px;
    min-width: 100px;
    background: #FFFFFF;
    border-radius: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    border-color: #FFFFFF;
    box-shadow: none;
    
    /* White */
    
    color: #208AC6;
  }
  
  &.ant-btn-default:hover{
    background: #FFFFFF;

    border-radius: 3px;
    border: 1px solid #FFFFFF;
    color: #208AC6;
  }
  &.ant-btn-default:active{
    background: #FFFFFF;
    
    border-radius: 3px;
    color: #208AC6;
    border: 3px solid #FFFFFF;
  }
  
  &.ant-btn-block{
    width: 100%;
  }
`
