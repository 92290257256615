const actions = {

    NEW_NOTIFICATION: 'NEW_NOTIFICATION',

    GET_ALL_NOTIFICATION: 'GET_ALL_NOTIFICATION',
    GET_ALL_NOTIFICATION_SUCCESS: 'GET_ALL_NOTIFICATION_SUCCESS',
    GET_ALL_NOTIFICATION_ERROR: 'GET_ALL_NOTIFICATION_ERROR',

    SET_NOTIFICATION_READ: "SET_NOTIFICATION_READ",
    SET_NOTIFICATION_READ_SUCCESS: "SET_NOTIFICATION_READ_SUCCESS",
    SET_NOTIFICATION_READ_ERROR: "SET_NOTIFICATION_READ_ERROR",

    SEND_FCM_TOKEN: "SEND_FCM_TOKEN",

    newNotification: () => ({
        type: actions.NEW_NOTIFICATION,
    }),

    getAllNotification: () => ({
        type: actions.GET_ALL_NOTIFICATION
    }),

    setNotificationRead: (data) => ({
        type: actions.SET_NOTIFICATION_READ,
        payload: data
    })

};

export default actions;
