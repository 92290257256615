import React, {useEffect} from "react";
import Icon from "src/component/Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import {useLocation, useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import "src/component/Layout/Registration/RegistrationCompany/RegistrationCompany.scss"
import {InputLogin} from "../../../Useful/Input/InputLogin";
import {Select} from "../../../Useful/Select/Select";
import {Checkbox} from "../../../Useful/Checkbox/Checkbox";
import {FormattedMessage, injectIntl} from "react-intl";
import {ButtonCompany} from "../../../Useful/Button/ButtonCompany";
import {ButtonLine} from "../../../Useful/Button/ButtonLine";
import actions from "../../../../redux/company/actions";
import {Col, Form, Row} from "antd";
import CompanyPicture from "src/assets/image/PictureLoginCompany.png";
import {PUBLIC_ROUTE} from "src/route.constants";
import {HOW_DO_YOU_KNOW_US_COMPANY, NUMBER_OF_EMPLOYEES} from "src/utils/utility";
import mobileLogo from "../../../../assets/logo/logo-VT-red-mobile.svg";
import AdvantagesCompany from "../../../../assets/svg/AdvantagesCompany.svg";

const {save} = actions

function RegistrationCompany() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [form] = Form.useForm()
    const confirm = useSelector(state => state.Company.confirm)
    const isButtonLoading = useSelector(state => state.Company.isRegistrationButtonLoading)

    const [toggleType, setToggleType] = React.useState(false)
    const [toggleIconPassword, setToggleIconPassword] = React.useState(false)

    const goTo = (path) => {
        const pathName = location.pathname
        let newPathName;

        switch (path) {
            case "user":
                newPathName = pathName.replace("company/registration", "user/login")
                navigate(newPathName, {replace: true})
                break;
            case "company":
                newPathName = pathName.replace("registration", "login")
                navigate(newPathName, {replace: true})
                break;
            default:
                newPathName = pathName.replace("/company/registration", "/confirm")
                navigate(newPathName, {replace: true})
                break;
        }

    }

    useEffect(() => {
        if (confirm === true) {
            navigate("/confirm")
        }
    }, [confirm])

    const ShowPassword = () => {
        setToggleType(!toggleType)
        setToggleIconPassword(!toggleIconPassword)
    }

    return (
        <div className={"registration-company-container"}>
            <div className="registration-content">
                <div className={"flex"} style={{flex: 1, gap: "50px"}}>
                    <div className={"advantagesSection flex-justify-center"} style={{flex: "1 1 50%"}}>
                        <div style={{width: "80%"}}>
                            <div className={"platformAdvantages"}>
                                <div className={"platformAdvantagesHeader size-18"}>
                                    <FormattedMessage id="CompanyAdvantages_Header" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                </div>
                                <div className={"platformAdvantagesDescription size-16"}>
                                    <FormattedMessage id="CompanyAdvantages_SubHeader" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                </div>
                            </div>
                            <div className={"flex-column gap-32"}>
                                <div className={"flex-start flex-align-center gap-16"}>
                                    <img src={AdvantagesCompany}/>

                                    <div className={"advantages size-16 merriWeather"}>
                                        <FormattedMessage id="CompanyAdvantages_1" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                    </div>
                                </div>

                                <div className={"flex-start flex-align-center gap-16"}>
                                    <img src={AdvantagesCompany}/>

                                    <div className={"advantages size-16 merriWeather"}>
                                        <FormattedMessage id="CompanyAdvantages_2" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                    </div>
                                </div>

                                <div className={"flex-start flex-align-center gap-16"}>
                                    <img src={AdvantagesCompany}/>

                                    <div className={"advantages size-16 merriWeather"}>
                                        <FormattedMessage id="CompanyAdvantages_3" values={{b: (chunks) => <strong>{chunks}</strong>}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="image margin-top-30">
                                <img src={CompanyPicture} className={"side-image"}/>
                            </div>
                            <div className={"technicalSupport"}>
                                <div className={"size-16"}><FormattedMessage id="TechnicalSupport_Company_1"/></div>
                                <br/>
                                <div><FormattedMessage id="TechnicalSupport_Company_2"/></div>
                                <div><FormattedMessage id="TechnicalSupport_1"/></div>
                                <div><FormattedMessage id="TechnicalSupport_2"/></div>
                                <div><FormattedMessage id="TechnicalSupport_3"/></div>
                            </div>
                        </div>
                    </div>

                    <Col md={12} xs={24}>
                        <div className={"containerRegistrationCompanyForm"}>
                            <header>
                                <div className="header_mobile">
                                    <div className="flex">
                                        <div className="back" onClick={() => navigate(PUBLIC_ROUTE.COMPANY_LOG_IN)}>
                                            <span>
                                                <Icon name={ICONS.arrow_2} className="pointer" size={[20, 20]}/>
                                            </span>
                                            <div className="p_back margin-5">
                                                Indietro
                                            </div>
                                        </div>
                                    </div>
                                    <h1>Registrazione</h1>
                                    <a href={"https://www.vt-job.it"}>
                                        <img className={"company-mobile-logo"} src={mobileLogo} />
                                    </a>
                                </div>
                                <div className="header_registration">
                                    <p className={"corp2_100"}>Benvenuto!</p>
                                    <p><FormattedMessage id="CompanyRegistration_header2"/></p>
                                </div>
                            </header>
                            <div className={"registration_recovery"}>
                                <p>Hai già un account?<a className={"corp2_100 weight-700 font-size-16"} onClick={() => navigate(PUBLIC_ROUTE.COMPANY_LOG_IN)}><FormattedMessage id="singIn"/></a></p>
                            </div>
                            <main>
                                <div className={"companyInfo"}>
                                    <h1 className="corp2_100">
                                        <FormattedMessage id="CompanyRegistration_main_title1"/>
                                    </h1>

                                    <Form
                                        form={form}
                                        layout={"vertical"}
                                        scrollToFirstError={{block: 'center'}}
                                        id="registrationCompanyForm"
                                        onFinish={(value) => {
                                            let data = {
                                                "companyName": value.companyName,
                                                "vatNumber": value.vatNumber,
                                                "phoneNumber": value.phoneNumber,
                                                "companySize": value.companySize,
                                                "email": value.email,

                                                "companyAdmin": {
                                                    "firstName": value.firstName,
                                                    "lastName": value.lastName,
                                                    "email": value.email,
                                                    "password": value.password,
                                                    "howDoYouKnowUs": value.howDoYouKnowUs
                                                }
                                            }
                                            dispatch(save(data))
                                        }}
                                    >
                                        <Form.Item
                                            name={"companyName"}
                                            label={<FormattedMessage id="CompanyRegistration_main_companyName"/>}
                                            rules={[{required: true, message: false}]}
                                            className="weight-600 size-14 inline-block width-100 margin-right-24"
                                        >
                                            <InputLogin className={"input"}
                                                        placeholder="Scrivi il nome o ragione sociale dell’azienda"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name={"vatNumber"}
                                            layout={"vertical"}
                                            label={<FormattedMessage id="CompanyRegistration_main_companyPIVA"/>}
                                            rules={[
                                                {
                                                    required: true,
                                                    email: false
                                                },
                                                {
                                                    pattern: /^[0-9]{11}$/,
                                                    message: false,
                                                }
                                            ]}
                                            className="weight-600 size-14 inline-block width-100 margin-right-24"
                                        >
                                            <InputLogin className={"input"}
                                                        placeholder="Inserisce la Partita IVA"
                                                        maxLength={11}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name={"phoneNumber"}
                                            layout={"vertical"}
                                            label={<FormattedMessage id="CompanyRegistration_main_companyPhone"/>}
                                            rules={[
                                                {min: 5, message: false},
                                                {pattern: "^[0-9]*$", message: false},
                                                {required: true, message: false}]}
                                            className="weight-600 size-14 inline-block width-100 margin-right-24"
                                        >
                                            <InputLogin className={"input"} placeholder="Inserisci un numero di telefono"/>
                                        </Form.Item>
                                        <p className={"weight-400 size-12 opacity-70 margin-0 margin-top-10"}>
                                            <FormattedMessage id="CompanyRegistration_main_companyPhoneInfo"/>
                                        </p>
                                        <Form.Item
                                            name={"companySize"}
                                            layout={"vertical"}
                                            label={<FormattedMessage id="CompanyRegistration_main_companyDimension"/>}
                                            className="weight-600 size-14 inline-block width-100 margin-right-24"
                                            rules={[
                                                {required: true, message: false}]}
                                        >
                                            <Select
                                                className={"companySize"}
                                                placeholder="- Seleziona dimensione -"
                                                style={{
                                                    width: '100%',
                                                }}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                suffixIcon={<Icon name={ICONS.arrow} size={[20, 20]}/>}
                                                options={NUMBER_OF_EMPLOYEES}
                                            />
                                        </Form.Item>
                                        <h1 className="corp2_100">
                                            <FormattedMessage id="CompanyRegistration_main_title2"/>
                                        </h1>
                                        <Form.Item
                                            name={"firstName"}
                                            label={<FormattedMessage id="UserRegistration_main_name"/>}
                                            rules={[{required: true, message: false}]}
                                            className="weight-600 size-14"
                                        >
                                            <InputLogin className={"input"}
                                                        placeholder="Nome"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name={"lastName"}
                                            label={<FormattedMessage id="UserRegistration_main_surname"/>}
                                            rules={[{required: true, message: false}]}
                                            className="weight-600 size-14"
                                        >
                                            <InputLogin
                                                className={"input"}
                                                placeholder="Cognome"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name={"email"}
                                            label={<FormattedMessage id="CompanyRegistration_main_email"/>}
                                            rules={[
                                                {
                                                    type: 'email',
                                                    message: false
                                                },
                                                {
                                                    required: true,
                                                    message: false
                                                }
                                            ]}
                                            className="weight-600 size-14 inline-block width-100 margin-right-24"
                                        >
                                            <InputLogin className={"input"}
                                                        placeholder="Inserisci un'email personale"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name={"password"}
                                            label={<FormattedMessage id="UserRegistration_main_password"/>}
                                            rules={[{required: true, message: false}]}
                                            className="weight-600 size-14 inline-block width-100 margin-right-24"
                                        >
                                            <InputLogin type={toggleType ? "text" : "password"} className={"input"} suffix={<div onClick={(e) => ShowPassword()}><Icon name={toggleIconPassword ? ICONS.eye : ICONS.eye_hide} className="flex-center pointer" color="#C41A43" size={[20, 20]}/></div>}
                                                        placeholder="Inserire la password"/>
                                        </Form.Item>

                                        <Form.Item
                                            name={"howDoYouKnowUs"}
                                            label={<FormattedMessage id="howMeetVT"/>}
                                            rules={[{required: true, message: false}]}
                                            className="weight-600 size-14 inline-block width-100 margin-right-24"
                                        >
                                            <Select className={"select"} placeholder="- Seleziona -"
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    suffixIcon={<Icon name={ICONS.arrow} size={[20, 20]}/>}
                                                    options={HOW_DO_YOU_KNOW_US_COMPANY}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            className={"generalConditions"}
                                            name={"policy"}
                                            valuePropName={"checked"}
                                            rules={[
                                                {required: true, message: false},
                                                ({getFieldValue}) => ({
                                                    validator(_, value) {
                                                        if (value) {
                                                            return Promise.resolve()
                                                        } else {
                                                            return Promise.reject(new Error())
                                                        }
                                                    }
                                                })
                                            ]}
                                        >
                                            <div className="flex-align-center">
                                                <Checkbox className="checkbox"/>
                                                <p className={"required"}>
                                                    <FormattedMessage id="generalConditions1"/>
                                                    <a href="https://www.iubenda.com/privacy-policy/82649189" target="_blank">
                                                        <FormattedMessage id="generalConditions2"/>
                                                    </a>
                                                    <FormattedMessage id="generalConditions3"/>
                                                </p>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                    <ButtonCompany form="registrationCompanyForm" type="primary" htmlType="submit" icon={<Icon name={ICONS.tik} className="margin-5 opacity-50" size={[20, 20]}/>} block className={"save flex-center"} loading={isButtonLoading}>
                                        Registrati
                                    </ButtonCompany>
                                </div>
                            </main>
                            <footer>
                                <div className={"loginAsUser"}>
                                    <h1><FormattedMessage id="loginAsUser_title"/></h1>
                                    <p><FormattedMessage id="loginAsUser_text"/></p>
                                    {/*<p onClick={() => goTo("user")}>button accedere come utente</p>*/}
                                    <ButtonLine block onClick={() => goTo("user")}><FormattedMessage id="loginAsUser_button"/></ButtonLine>
                                </div>
                            </footer>
                        </div>
                    </Col>
                </div>
            </div>
        </div>
    )
}

export default injectIntl(RegistrationCompany)
