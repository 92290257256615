import React from "react";
import {Card} from "antd";
import {ICONS} from "src/assets/sprite/iconsNames";
import Icon from "../../../Icon/Sprite";
import "src/component/Layout/MyCompanyElement/CardMyCompany.scss"
import { ButtonUser } from "src/component/Useful/Button/ButtonUser";
import actions from "src/redux/drawer/actions";
import {useDispatch} from "react-redux";
import {DRAWER_TYPE} from "../../../../utils/utility";


function CardNewCompany(props){

    const {setChangeType} = props

    let dispatch = useDispatch()
    const {openDrawer} = actions;

    return(
        <div>
            <Card style={{borderRadius: 0}}>
                <div  className="flex-center" style={{marginBottom:20}}>
                    <div className={"company-circle"}>
                        <Icon name={ICONS.business_100} size={[100,100]} color="#C41A43"/>
                    </div>
                </div>
                <div  className="flex-center">
                    <b>Profilo aziendale</b>
                </div>
                <div className="flex-center"><p>Qui visualizzerai le tue sedi e offerte collegate ad esse</p></div>
                <div className="flex-center">
                    <ButtonUser style={{width: 200}} onClick={() => {
                        setChangeType(DRAWER_TYPE.company)
                        dispatch(openDrawer())
                    }}>Aggiornare dati aziendali</ButtonUser>
                </div>
            </Card>
        </div>
    )
}

export default CardNewCompany
