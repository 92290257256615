const actions = {

    INIT_OFFER: 'INIT_OFFER',

    SET_CHANGE_DRAWER: "SET_CHANGE_DRAWER",
    SET_CHANGE_DRAWER_SUCCESS: "SET_CHANGE_DRAWER_SUCCESS",
    SET_CHANGE_DRAWER_ERROR: "SET_CHANGE_DRAWER_ERROR",

    OPEN_DRAWER: "OPEN_DRAWER",
    CLOSE_DRAWER: "CLOSE_DRAWER",

    setChangeDrawer: ()=>({
        type: actions.SET_CHANGE_DRAWER
    }),

    openDrawer: () => ({
        type: actions.OPEN_DRAWER
    }),

    closeDrawer: () => ({
        type: actions.CLOSE_DRAWER
    }),
};

export default actions;
