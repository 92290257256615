import actions from './actions'
import authActions from "../auth/actions";

const initState = {
    myPreferences: null,
    closeViewComponent: false,
    isPreferenceSetSuccessfully: null
}

export default function preferenceReducer(state = initState, action){
    switch (action.type) {
        case actions.INIT_PREFERENCE:
            return{
                ...initState
            };
        case authActions.LOGOUT:
            return {...initState}
        case actions.GET_ALL_PREFERENCE_SUCCESS:
            return{
                ...state,
                myPreferences: action.payload,
                closeViewComponent: false,
            };
        case actions.SAVE_PREFERENCE_SUCCESS:
            return {
                ...state,
                isPreferenceSetSuccessfully: "add"
            }
        case actions.DELETE_PREFERENCE_SUCCESS:
            return {
                ...state,
                isPreferenceSetSuccessfully: "remove"
            }
        case actions.CLOSE_VIEW_COMPONENT:
            return {
                ...state,
                closeViewComponent: true,
            }
        case actions.RESET_PREFERENCE:
            return {
                ...state,
                isPreferenceSetSuccessfully: null
            }
        default:
            return state;
    }
}
