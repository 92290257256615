import {all, call, fork, put, takeEvery, delay} from 'redux-saga/effects'
import {endpoints} from "src/config/site.config";
import SuperFetch from "../../helpers/superFetch";
import actions from "./actions";
import authActions from "../auth/actions";

export function* getAllLocation() {

    yield takeEvery(actions.GET_ALL_LOCATION, function* (data) {
        let queryString =  "?page=" + data.page + "&size=" + data.size
        if (data.city){
            queryString = queryString + "&city=" + data.city
        }
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllLocation + queryString, null, true)
        if (response) {
            yield put({
                type: actions.GET_ALL_LOCATION_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_LOCATION_ERROR,
                payload: error
            });
        }
    })
}

export function* getAllProvince() {

    yield takeEvery(actions.GET_ALL_PROVINCE, function* (data) {
        let queryString =  "?page=" + data.page + "&size=" + data.size
        if (data.province){
            queryString = queryString + "&province=" + data.province
        }
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllProvince + queryString, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ALL_PROVINCE_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_PROVINCE_ERROR,
                payload: error
            });
        }
    })

}

export function* getCityByProvince() {

    yield takeEvery(actions.GET_CITY_BY_PROVINCE, function* (data) {
        let queryString =  "?page=" + data.page + "&size=" + data.size + "&province=" + data.province
        if (data.city){
            queryString = queryString + "&city=" + data.city
        }
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllLocation + queryString, null, true)
        if (response) {

            yield put({
                type: actions.GET_CITY_BY_PROVINCE_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_CITY_BY_PROVINCE_ERROR,
                payload: error
            });
        }
    })
}

export function* getLocationById() {

    yield takeEvery(actions.GET_LOCATION_BY_ID, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getLocationById + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_LOCATION_BY_ID_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_LOCATION_BY_ID_ERROR,
                payload: error
            });
        }
    })

}

export function* saveLocation() {

    yield takeEvery(actions.SAVE_LOCATION, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.saveLocation, data.payload, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.SAVE_LOCATION_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SAVE_LOCATION_ERROR,
                payload: error
            });
        }
    })

}

export function* updateLocation() {

    yield takeEvery(actions.UPDATE_LOCATION, function* (data ) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.updateLocation + "/" + data.id, data.payload, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.UPDATE_LOCATION_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_LOCATION_ERROR,
                payload: error
            });
        }
    })

}

export function* deleteLocation() {

    yield takeEvery(actions.DELETE_LOCATION, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.delete, endpoints.deleteLocation + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.DELETE_LOCATION_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DELETE_LOCATION_ERROR,
                payload: error
            });
        }
    })

}

export function* getAllCompanyLocation() {
    yield takeEvery(actions.GET_ALL_COMPANY_LOCATIONS, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllCompanyLocation, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ALL_COMPANY_LOCATIONS_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_COMPANY_LOCATIONS_ERROR,
                payload: error
            });
        }
    })
}

export function* getAllCompanyLocationBySysAdmin() {
    yield takeEvery(actions.GET_ALL_COMPANY_LOCATIONS_BY_SYS_ADMIN, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllCompanyLocationBySysAdmin + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ALL_COMPANY_LOCATIONS_BY_SYS_ADMIN_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_COMPANY_LOCATIONS_BY_SYS_ADMIN_ERROR,
                payload: error
            });
        }
    })
}

export function* getCompanyLocationById() {

    yield takeEvery(actions.GET_BY_ID_COMPANY_LOCATION, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getCompanyLocationById + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_BY_ID_COMPANY_LOCATION_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_BY_ID_COMPANY_LOCATION_ERROR,
                payload: error
            });
        }
    })

}

export function* getCompanyLocationMainById() {

    yield takeEvery(actions.GET_BY_ID_COMPANY_LOCATION_MAIN, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getCompanyLocationMainById + "/" + data.companyId, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_BY_ID_COMPANY_LOCATION_MAIN_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_BY_ID_COMPANY_LOCATION_MAIN_ERROR,
                payload: error
            });
        }
    })

}

export function* saveCompanyLocation() {

    yield takeEvery(actions.SAVE_COMPANY_LOCATION, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.saveCompanyLocation, data.payload, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.SAVE_COMPANY_LOCATION_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SAVE_COMPANY_LOCATION_ERROR,
                payload: error
            });
        }
    })

}

export function* updateCompanyLocation() {

    yield takeEvery(actions.UPDATE_COMPANY_LOCATION, function* (data) {

        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.updateCompanyLocation + "/" + data.payload.id, data.payload, true)
        if (response) {
            yield put({
                type: actions.UPDATE_COMPANY_LOCATION_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.UPDATE_COMPANY_LOCATION_ERROR,
                payload: error
            });
        }
    })

}



export default function* rootSaga() {
    yield all([
        fork(getAllLocation),
        fork(getAllProvince),
        fork(getCityByProvince),
        fork(getLocationById),
        fork(saveLocation),
        fork(updateLocation),
        fork(deleteLocation),
        fork(getAllCompanyLocation),
        fork(getAllCompanyLocationBySysAdmin),
        fork(getCompanyLocationById),
        fork(getCompanyLocationMainById),
        fork(saveCompanyLocation),
        fork(updateCompanyLocation)
    ]);
}
