import React from "react";
import {Button, Card, Col, Divider, Row} from "antd";
import Icon from "../../Icon/Sprite";
import {ICONS} from "../../../assets/sprite/iconsNames";
import {DRAWER_TYPE, ImgComponent, substituteZeroWithLine} from "../../../utils/utility";
import {ButtonUser} from "../../Useful/Button/ButtonUser";
import Drawer from "antd/es/drawer";
import preferenceActions from "../../../redux/preference/actions";
import drawerActions from "../../../redux/drawer/actions";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import GreyLogo from "../../../assets/logo/logo-VT-grey.svg";

function DrawerListOffertUserMobile(props) {

    const {recordOffer, myCandidatures, openState, setOpenState, setType, idRecord, width} = props

    let dispatch = useDispatch()
    const navigate = useNavigate()

    const {openDrawer} = drawerActions;
    const {savePreference, deletePreference} = preferenceActions;

    let isCompleted = localStorage.getItem("isCompleted") === "true"

    function setField(record) {
        let path = "/user/company/"
        if (record.id) path = path + record.company.id
        navigate(path)
    }

    return (
        <Drawer placement="right" onClose={() => setOpenState(false)} open={openState} width={width}>
            <div style={{width: "100%", height: "100%"}}>
                <Card style={{top: "35px", borderRadius: 0}}>
                    <div className="flex-row margin-bottom-30 flex-center-between">
                        <div className="flex-align-center">
                            <Icon color="grey" name={ICONS.user_ok}
                                  size={[20, 20]}/>
                            <span className="margin-left-5">
                                {substituteZeroWithLine(recordOffer && recordOffer.candidateCount)}
                            </span>
                        </div>
                        <div style={{
                            width: "88px",
                            height: "72px",
                            margin: "-80px 0 0 -20px",
                            border: "1px solid rgba(14, 14, 44, 0.15)"
                        }}>
                            {recordOffer && !recordOffer.anonymous ?
                                <ImgComponent logoPath={recordOffer && recordOffer.company && recordOffer.company.logoPath && recordOffer.company.logoPath}/>
                                :
                                <div className="noLogo">
                                    <img src={GreyLogo}/>
                                </div>
                            }
                        </div>
                        <div/>
                    </div>
                    <div className="flex-center flex-column margin-bottom-30">
                        <div className="weight-700 size-22">{recordOffer && recordOffer.title}</div>
                        {recordOffer && recordOffer.anonymous ?
                            <div className="opacity-50 underlined size-12">
                                Azienda Anonima
                            </div> :
                            <div className="weight-40 size-14 underlined pointer" onClick={() => setField(recordOffer)}>
                                {recordOffer && recordOffer.location && recordOffer.location.name}
                            </div>}
                    </div>
                    <Divider/>
                    <div className="margin-top-30 margin-bottom-30">
                        <div>
                            {recordOffer && recordOffer.location &&
                            <div>
                                <span className="weight-700 margin-right-5">Dove:</span>
                                <span>{recordOffer.location.city}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.experience &&
                            <div>
                                <span className="weight-700 margin-right-5">Esperienza:</span>
                                <span>{recordOffer.experience}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.impegnoRichiesto &&
                            <div>
                                <span className="weight-700 margin-right-5">Tempo:</span>
                                <span>{recordOffer.impegnoRichiesto}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.insertMode &&
                            <div>
                                <span className="weight-700 margin-right-5">Contratto:</span>
                                <span>{recordOffer.insertMode}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.workPlace &&
                            <div>
                                <span className="weight-700 margin-right-5">Modalità:</span>
                                <span>{recordOffer.workPlace}</span>
                            </div>
                            }
                        </div>
                    </div>
                    <Row>
                        <Col xl={12}>
                            <div className="margin-bottom-30">
                                {/*<Button className={"padding-5 border-radius-3 margin-right-10"}>*/}
                                {/*    <Icon name={ICONS.facebook} size={[20, 20]} color={"#1877F2"}/>*/}
                                {/*</Button>*/}
                                {/*<Button className={"padding-5 border-radius-3 margin-right-10"}>*/}
                                {/*    <Icon name={ICONS.twitter} size={[20, 20]} color={"#1DA1F2"}/>*/}
                                {/*</Button>*/}
                                {recordOffer && recordOffer.company && recordOffer.company.linkedin &&
                                <Button className={"padding-5 border-radius-3 margin-right-10"}>
                                    <a href={recordOffer.company.linkedin} target="_blank">
                                        <Icon name={ICONS.linkedin} size={[20, 20]} color={"#1DA1F2"}/>
                                    </a>
                                </Button>
                                }
                                {recordOffer && recordOffer.company && recordOffer.company.webPage &&
                                <Button className={"padding-5 border-radius-3"}>
                                    <a href={recordOffer.company.webPage} target="_blank">
                                        <Icon name={ICONS.link} size={[20, 20]} className={"opacity-50"}/>
                                    </a>
                                </Button>
                                }
                            </div>
                        </Col>
                        <Col xl={12}>
                            <div className="flex-row margin-bottom-30 margin-left-55">
                                <div className={"flex-center flex-align-center margin-right-24 pointer"}>
                                    {recordOffer && recordOffer.preference ?
                                        <span onClick={() => {
                                            dispatch(deletePreference(recordOffer.id))
                                        }}>
                                            <Icon name={ICONS.union} size={[20, 20]} className={"icon-heart"}
                                                  color="#208ac6"/>
                                        </span>
                                        :
                                        <span onClick={() => {
                                            dispatch(savePreference(recordOffer.id))
                                        }}>
                                            <Icon name={ICONS.heart} size={[20, 20]} className={"icon-heart"} color="grey"/>
                                        </span>
                                    }
                                </div>

                                {(myCandidatures && myCandidatures.some(element => element.offertId === idRecord)) ?
                                    <div className={"flex-column"}>
                                        <div className={"flex-align-center margin-0"}>
                                            <Icon name={ICONS.tik} classname={"margin-right-5"} size={[20, 20]}
                                                  color="#69BE7B"/>
                                            <p className={"weight-700 margin-0 size-16 green"}>Candidato</p>
                                        </div>
                                    </div>
                                    :
                                    <ButtonUser
                                        style={{minWidth: 100, height: 40}}
                                        onClick={() => {
                                            isCompleted ? setType(DRAWER_TYPE.secondStepCandidatureUser) : setType(DRAWER_TYPE.firstStepCandidatureUser)
                                            dispatch(openDrawer())
                                        }}>
                                        Candidati
                                    </ButtonUser>
                                }
                            </div>
                        </Col>
                    </Row>
                    {recordOffer && recordOffer.description &&
                    <div>
                        <Divider>
                            <div className="size-12 opacity-50">Descrizione</div>
                        </Divider>
                        <p dangerouslySetInnerHTML={{__html: recordOffer && recordOffer.description}}/>
                    </div>}
                </Card>
            </div>
        </Drawer>
    )
}

export default DrawerListOffertUserMobile