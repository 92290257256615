import React from "react";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../redux/admin/actions";
import Icon from "../../Icon/Sprite";
import {ICONS} from "../../../assets/sprite/iconsNames";
import {CsvButton} from "../Button/CsvButton";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

export default function DownloadCompanyCsv() {

    const {createCompanyCsv} = actions
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.Admin.isDownloadingCompanyCsv)

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <Spin onClick={e => e.stopPropagation()} spinning={isLoading} indicator={antIcon}>
            <CsvButton
                className={"flex-center"}
                onClick={(e) => {
                    dispatch(createCompanyCsv())
                    e.stopPropagation()
                }}
                icon={<Icon name={ICONS.download} size={[20, 20]}/>}
            >

                CSV

            </CsvButton>
        </Spin>
    )
}