import actions from './actions'

const initState = {
    settings: null,
}

export default function settingsReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload,
            };
        case actions.UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload,
            };
        case actions.UPDATE_SETTINGS_ERROR:
            return {
                ...state,
                settings: action.payload,
            };
        default:
            return state;
    }
}
