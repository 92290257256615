const actions = {
    OPEN_MODAL_CHANGE_PASSWORD: "OPEN_MODAL_PASSWORD",

    CLOSE_MODAL_CHANGE_PASSWORD: "CLOSE_MODAL_PASSWORD",

    openModalChangePassword: () => ({
        type: actions.OPEN_MODAL_CHANGE_PASSWORD
    }),

    closeModalChangePassword: () => ({
        type: actions.CLOSE_MODAL_CHANGE_PASSWORD
    }),
}

export default actions