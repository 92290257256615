import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import "./topbar.scss"
import White from "src/assets/logo/logo-VT-white.svg"
import WhiteNoText from "src/assets/logo/logo-VT-noText.svg"
import {Badge, Col, Divider, Popover, Row} from "antd";
import {PRIVATE_USER_ROUTE} from "src/route.constants";
import {useLocation, useNavigate} from "react-router";
import {FormattedMessage, injectIntl} from "react-intl";
import {ICONS} from "src/assets/sprite/iconsNames";
import actions from "../../../redux/auth/actions";
import {useDispatch, useSelector} from "react-redux";
import Icon from "src/component/Icon/Sprite";
import {firstLetters, useWindowSize} from "src/utils/utility";

const {logout} = actions

function UserTopbar() {

    const dispatch = useDispatch();

    const location = useLocation()
    const navigate = useNavigate()
    const size = useWindowSize();


    const [isRead, setIsRead] = useState(false)
    const [isAccountPopoverOpen, setIsAccountPopoverOpen] = useState(false)
    const [isNotifyPopoverOpen, setIsNotifyPopoverOpen] = useState(false)
    const [isBurgerPopoverOpen, setIsBurgerPopoverOpen] = useState(false)

    const notificationCount = useSelector(state => state.Notification.notificationCount)
    const isCompleted = useSelector(state => state.AuthReducer.isCompleted)

    const logOut = () => {
        dispatch(logout(navigate))
    }

    return (
        <>
            <div className="userTopbar">

                <div className="sidePadding">
                    <div className="topbarContent flex-center-between">
                        {size.width > 546 ?
                            <>
                                <div className="pointer" onClick={() => navigate(PRIVATE_USER_ROUTE.DASHBOARD_USER)}>
                                    <img src={size.width > 620 ? White : WhiteNoText} className={"logo"}/>
                                </div>
                                <div className="menuContainer">
                                    <ul>
                                        <li>
                                            <NavLink to={PRIVATE_USER_ROUTE.JOB_OFFER} className={({isActive}) => isActive ? 'active' : null}>
                                                {size.width > 930 ?
                                                    <div className="text"><FormattedMessage id="topBar_JobOffer"/></div> :
                                                    <div className="text"><FormattedMessage id="topBar_JobOffer"/></div>}
                                            </NavLink>
                                        </li>
                                        
                                        {isCompleted &&
                                        <li>
                                            <NavLink to={PRIVATE_USER_ROUTE.MY_CANDIDATURE} className={({isActive}) => isActive ? 'active' : null}>
                                                <div className="text"><FormattedMessage id="topBar_MyCandidature_Mobile"/></div>
                                            </NavLink>
                                        </li>
                                        }

                                        <Divider type={"vertical"} style={{backgroundColor: "#FFFFFF", opacity: "0.2", height: "32px"}}/>

                                        <li>
                                            <NavLink to={PRIVATE_USER_ROUTE.FAVORITES} className={({isActive}) => isActive ? 'active' : null}>
                                                <div className="pointer text hover-opacity-70">
                                                    <Icon name={ICONS.heart} size={[20, 20]} color={"#FFFFFF"}/>
                                                </div>
                                            </NavLink>
                                        </li>

                                        {/*<li className="icon-notify">*/}
                                        {/*    <Popover*/}
                                        {/*        overlayClassName="notifyPopover"*/}
                                        {/*        placement={"bottomRight"}*/}
                                        {/*        getPopupContainer={triggerNode => triggerNode.parentElement}*/}
                                        {/*        trigger="click"*/}
                                        {/*        open={isNotifyPopoverOpen}*/}
                                        {/*        onOpenChange={(open) => setIsNotifyPopoverOpen(open)}*/}
                                        {/*        content={*/}
                                        {/*            <div style={{minHeight: "500px", maxHeight: "70vh"}}>*/}
                                        {/*                <div className={"header notifyPadding flex-center-between"}>*/}
                                        {/*                    <div className="size-16 weight-700">*/}
                                        {/*                        <FormattedMessage id="topBar_notification"/>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="flex-align-center">*/}
                                        {/*                        <div className="notifyPage size-14 weight-600 pointer" onClick={() => {*/}
                                        {/*                            navigate(PRIVATE_USER_ROUTE.NOTIFICATION)*/}
                                        {/*                            setIsNotifyPopoverOpen(false)*/}
                                        {/*                        }}>*/}
                                        {/*                            <FormattedMessage id="topBar_notificationPage"/>*/}
                                        {/*                        </div>*/}
                                        {/*                        <div className="flex-align-center margin-left-18">*/}
                                        {/*                            <Icon className="icon" name={ICONS.refresh} size={[20, 20]} color={"#0E0E2C"}/>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}

                                        {/*                <div>*/}
                                        {/*                    <div className={"notifyPadding border-bottom flex-center-between"}>*/}
                                        {/*                        <div className="size-14 weight-600">*/}
                                        {/*                            <FormattedMessage id="topBar_newNotification"/>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}

                                        {/*                    /!*<div>*!/*/}
                                        {/*                    /!*    <Row className="notifyPadding border-bottom">*!/*/}
                                        {/*                    /!*        <Col span={4}>*!/*/}
                                        {/*                    /!*            <div className="notifyIcon flex-center">*!/*/}
                                        {/*                    /!*                <Icon name={ICONS.user} size={[20, 20]} color={"#0E0E2C"}/>*!/*/}
                                        {/*                    /!*            </div>*!/*/}
                                        {/*                    /!*        </Col>*!/*/}
                                        {/*                    /!*        <Col span={20}>*!/*/}
                                        {/*                    /!*            <div className="flex-center-between">*!/*/}
                                        {/*                    /!*                <div className="flex-align-center">*!/*/}
                                        {/*                    /!*                    <div className={`size-14 ${!isRead ? "weight-700" : null}`}>*!/*/}
                                        {/*                    /!*                        <FormattedMessage id="notification_activeProfile"/>*!/*/}
                                        {/*                    /!*                    </div>*!/*/}
                                        {/*                    /!*                    <div className="size-12 opacity-70 margin-left-8">*!/*/}
                                        {/*                    /!*                        1h*!/*/}
                                        {/*                    /!*                    </div>*!/*/}
                                        {/*                    /!*                </div>*!/*/}

                                        {/*                    /!*                {!isRead &&*!/*/}
                                        {/*                    /!*                <div className="tik">*!/*/}
                                        {/*                    /!*                    <Icon name={ICONS.tik} size={[20, 20]} color={"#208AC6"}/>*!/*/}
                                        {/*                    /!*                </div>*!/*/}
                                        {/*                    /!*                }*!/*/}
                                        {/*                    /!*            </div>*!/*/}
                                        {/*                    /!*            <div className="size-14 opacity-70">*!/*/}
                                        {/*                    /!*                <FormattedMessage id="notification_profileCreated"/>*!/*/}
                                        {/*                    /!*            </div>*!/*/}
                                        {/*                    /!*        </Col>*!/*/}
                                        {/*                    /!*    </Row>*!/*/}

                                        {/*                    /!*</div>*!/*/}
                                        {/*                </div>*/}
                                        {/*                <div className="end-border-bottom"/>*/}
                                        {/*            </div>*/}
                                        {/*        }*/}
                                        {/*    >*/}
                                        {/*        <div className={location.pathname === PRIVATE_USER_ROUTE.NOTIFICATION ? 'active' : null}>*/}

                                        {/*            <div*/}
                                        {/*                className="hover-opacity-70 text pointer flex-align-center"*/}
                                        {/*                id="notifyMenu"*/}
                                        {/*                onClick={() => {*/}
                                        {/*                    setIsNotifyPopoverOpen(true)*/}
                                        {/*                }}*/}
                                        {/*            >*/}
                                        {/*                <Badge count={notificationCount} size={"small"}>*/}
                                        {/*                    <Icon className="icon-notify" name={ICONS.notify} size={[20, 20]} color={"#FFFFFF"}/>*/}
                                        {/*                </Badge>*/}
                                        {/*            </div>*/}

                                        {/*        </div>*/}
                                        {/*    </Popover>*/}
                                        {/*</li>*/}

                                        <Divider type={"vertical"} style={{backgroundColor: "#FFFFFF", opacity: "0.2", height: "32px"}}/>

                                        <li>
                                            <Popover
                                                overlayClassName="myAccount"
                                                placement={"bottomRight"}
                                                getPopupContainer={triggerNode => triggerNode.parentElement}
                                                trigger="click"
                                                open={isAccountPopoverOpen}
                                                onOpenChange={(open) => setIsAccountPopoverOpen(open)}
                                                content={
                                                    <div className="accountPadding">

                                                        <div className="flex-align-center padding-8 spacing pointer" onClick={() => {
                                                            setIsAccountPopoverOpen(false)
                                                            navigate(PRIVATE_USER_ROUTE.ACCOUNT)
                                                        }}>
                                                            <div className="flex-align-center padding-8">
                                                                <Icon className="opacity-70" name={ICONS.user} size={[20, 20]} color={"#0E0E2C"}/>
                                                            </div>
                                                            <div className="size-14 weight-500">
                                                                <FormattedMessage id="account"/>
                                                            </div>
                                                        </div>

                                                        <Divider/>

                                                        <div className="flex-align-center padding-8 spacing pointer" onClick={() => logOut()}>
                                                            <div className="flex-align-center padding-8">
                                                                <Icon className="opacity-70" name={ICONS.exit} size={[20, 20]} color={"#0E0E2C"}/>
                                                            </div>
                                                            <div className="size-14 weight-500">
                                                                <FormattedMessage id="exit"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <div className={location.pathname === PRIVATE_USER_ROUTE.ACCOUNT ? 'active' : null}>

                                                    <div
                                                        className="hover-opacity-70 pointer text flex-align-center"
                                                        id="accountMenu"
                                                        onClick={() => {
                                                            setIsAccountPopoverOpen(true)
                                                            setIsNotifyPopoverOpen(false)
                                                        }}
                                                    >
                                                        <div className="line-height-20" style={{maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis"}}>
                                                            {localStorage.getItem("firstName") + " " + localStorage.getItem("lastName")}
                                                        </div>
                                                        <div className="flex-center">
                                                            <Icon name={ICONS.arrow_small} size={[20, 20]} color={"#FFFFFF"}/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Popover>
                                        </li>
                                    </ul>
                                </div>
                            </>
                            :
                            <>
                                <div className="pointer" onClick={() => navigate(PRIVATE_USER_ROUTE.DASHBOARD_USER)}>
                                    <img src={WhiteNoText} className={"logo"}/>
                                </div>
                                <div className="menuContainer">
                                    <ul>
                                        <li>
                                            <NavLink to={PRIVATE_USER_ROUTE.FAVORITES} className={({isActive}) => isActive ? 'active' : null}>
                                                <div className="pointer text">
                                                    <Icon name={ICONS.heart} size={[20, 20]} color={"#FFFFFF"}/>
                                                </div>
                                            </NavLink>
                                        </li>

                                        <li>
                                            <Popover
                                                overlayClassName="notifyPopover"
                                                placement={"bottomRight"}
                                                getPopupContainer={triggerNode => triggerNode.parentElement}
                                                trigger="click"
                                                open={isNotifyPopoverOpen}
                                                onOpenChange={(open) => setIsNotifyPopoverOpen(open)}
                                                content={
                                                    <div style={{minHeight: "500px", maxHeight: "70vh"}}>
                                                        <div className={"header notifyPadding flex-center-between"}>
                                                            <div className="size-16 weight-700">
                                                                <FormattedMessage id="topBar_notification"/>
                                                            </div>
                                                            <div className="flex-align-center">
                                                                <div className="notifyPage size-14 weight-600 pointer" onClick={() => {
                                                                    navigate(PRIVATE_USER_ROUTE.NOTIFICATION)
                                                                    setIsNotifyPopoverOpen(false)
                                                                }}>
                                                                    <FormattedMessage id="topBar_notificationPage"/>
                                                                </div>
                                                                <div className="flex-align-center margin-left-18">
                                                                    <Icon className="icon" name={ICONS.refresh} size={[20, 20]} color={"#0E0E2C"}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            {!isRead ? <div className={"notifyPadding border-bottom flex-center-between"}>

                                                                    <div className="size-14 weight-600">
                                                                        <FormattedMessage id="topBar_newNotification"/>
                                                                    </div>

                                                                </div>
                                                                :
                                                                null
                                                            }

                                                            <div>
                                                                <Row className="notifyPadding border-bottom">
                                                                    <Col span={4}>
                                                                        <div className="notifyIcon flex-center">
                                                                            <Icon name={ICONS.user} size={[20, 20]} color={"#0E0E2C"}/>
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={20}>
                                                                        <div className="flex-center-between">
                                                                            <div className="flex-align-center">
                                                                                <div className={`size-14 ${!isRead ? "weight-700" : null}`}>
                                                                                    <FormattedMessage id="notification_activeProfile"/>
                                                                                </div>
                                                                                <div className="size-12 opacity-70 margin-left-8">
                                                                                    1h
                                                                                </div>
                                                                            </div>

                                                                            {!isRead &&
                                                                            <div className="tik">
                                                                                <Icon name={ICONS.tik} size={[20, 20]} color={"#208AC6"}/>
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                        <div className="size-14 opacity-70">
                                                                            <FormattedMessage id="notification_profileCreated"/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                            </div>
                                                        </div>
                                                        <div className="end-border-bottom"/>
                                                    </div>
                                                }
                                            >
                                                <div className={location.pathname === PRIVATE_USER_ROUTE.NOTIFICATION ? 'active' : null}>

                                                    <div
                                                        className="hover-opacity-70 text pointer flex-align-center"
                                                        id="notifyMenu"
                                                        onClick={() => {
                                                            setIsNotifyPopoverOpen(true)
                                                        }}
                                                    >
                                                        <Badge count={notificationCount} size={"small"}>
                                                            <Icon className="icon-notify" name={ICONS.notify} size={[20, 20]} color={"#FFFFFF"}/>
                                                        </Badge>
                                                    </div>

                                                </div>
                                            </Popover>
                                        </li>

                                        <Divider type={"vertical"} style={{backgroundColor: "#FFFFFF", opacity: "0.2", height: "32px"}}/>

                                        <li>
                                            <Popover
                                                overlayClassName="myAccount"
                                                placement={"bottomRight"}
                                                getPopupContainer={triggerNode => triggerNode.parentElement}
                                                trigger="click"
                                                open={isAccountPopoverOpen}
                                                onOpenChange={(open) => setIsAccountPopoverOpen(open)}
                                                content={
                                                    <div className="accountPadding">

                                                        <div className="flex-align-center spacing pointer" onClick={() => {
                                                            setIsAccountPopoverOpen(false)
                                                            navigate(PRIVATE_USER_ROUTE.ACCOUNT)
                                                        }}>
                                                            <div className="flex-align-center padding-10">
                                                                <Icon className="opacity-70" name={ICONS.user} size={[20, 20]} color={"#0E0E2C"}/>
                                                            </div>
                                                            <div className="size-14 weight-500">
                                                                <FormattedMessage id="account"/>
                                                            </div>
                                                        </div>

                                                        <Divider/>

                                                        <div className="flex-align-center spacing pointer" onClick={() => logOut()}>
                                                            <div className="flex-align-center padding-10">
                                                                <Icon className="opacity-70" name={ICONS.exit} size={[20, 20]} color={"#0E0E2C"}/>
                                                            </div>
                                                            <div className="size-14 weight-500">
                                                                <FormattedMessage id="exit"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            >

                                                <div className={location.pathname === PRIVATE_USER_ROUTE.ACCOUNT ? 'active' : null}>

                                                    <div
                                                        className="hover-opacity-70 pointer text flex-align-center"
                                                        id="accountMenu"
                                                        onClick={() => {
                                                            setIsAccountPopoverOpen(true)
                                                            setIsNotifyPopoverOpen(false)
                                                        }}
                                                    >
                                                        <div className="font-16 line-height-20">
                                                            {firstLetters(localStorage.getItem("firstName"), localStorage.getItem("lastName"))}
                                                        </div>
                                                        <div className="flex-center">
                                                            <Icon name={ICONS.arrow_small} size={[20, 20]} color={"#FFFFFF"}/>
                                                        </div>
                                                    </div>

                                                </div>

                                            </Popover>
                                        </li>

                                        <Divider type={"vertical"} style={{backgroundColor: "#FFFFFF", opacity: "0.2", height: "32px"}}/>

                                        <li>
                                            <Popover
                                                overlayClassName="myAccount"
                                                placement={"bottomRight"}
                                                getPopupContainer={triggerNode => triggerNode.parentElement}
                                                trigger="click"
                                                open={isBurgerPopoverOpen}
                                                onOpenChange={(open) => setIsBurgerPopoverOpen(open)}
                                                content={

                                                    <div className="accountPadding">
                                                        <div className="spacing pointer">
                                                            <NavLink to={PRIVATE_USER_ROUTE.JOB_OFFER} className={({isActive}) => isActive ? 'active-popup' : null}>
                                                                <div className="size-14 weight-500 padding-10 width-90 flex-center">
                                                                    <div style={{color: "black"}}>
                                                                        <FormattedMessage id="topBar_JobOffer"/>
                                                                    </div>
                                                                </div>
                                                            </NavLink>
                                                        </div>

                                                        <Divider/>

                                                        <div className="spacing pointer">
                                                            <NavLink to={PRIVATE_USER_ROUTE.MY_CANDIDATURE} className={({isActive}) => isActive ? 'active-popup' : null}>
                                                                <div className="size-14 weight-500 padding-10 width-90 flex-center">
                                                                    <div style={{color: "black"}}><FormattedMessage id="topBar_MyCandidature_Mobile"/></div>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>

                                                }
                                            >

                                                <div className={location.pathname === PRIVATE_USER_ROUTE.JOB_OFFER ? 'active' : null}>

                                                    <div
                                                        className="hover-opacity-70 text pointer flex-align-center"
                                                        id="accountMenu"
                                                        onClick={() => {
                                                            setIsAccountPopoverOpen(false)
                                                            setIsNotifyPopoverOpen(false)
                                                            setIsBurgerPopoverOpen(true)
                                                        }}
                                                    >
                                                        <Icon name={ICONS.burger} color="white" size={[20, 20]}/>
                                                    </div>

                                                </div>

                                            </Popover>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default injectIntl(UserTopbar)
