const actions = {

    INIT_OFFER: 'INIT_OFFER',

    GET_ALL_OFFERS: 'GET_ALL_OFFERS',
    GET_ALL_OFFERS_SUCCESS: 'GET_ALL_OFFERS_SUCCESS',
    GET_ALL_OFFERS_ERROR: 'GET_ALL_OFFERS_ERROR',

    GET_ALL_USER_OFFERS: 'GET_ALL_USER_OFFERS',
    GET_ALL_USER_OFFERS_SUCCESS: 'GET_ALL_USER_OFFERS_SUCCESS',
    GET_ALL_USER_OFFERS_ERROR: 'GET_ALL_USER_OFFERS_ERROR:',

    ADD_USER_OFFER_TO_PREVIOUS: "ADD_USER_OFFER_TO_PREVIOUS",
    ADD_USER_OFFER_TO_PREVIOUS_SUCCESS: "ADD_USER_OFFER_TO_PREVIOUS_SUCCESS",
    ADD_USER_OFFER_TO_PREVIOUS_ERROR: "ADD_USER_OFFER_TO_PREVIOUS_ERROR",

    GET_OFFER_BY_ID: "GET_OFFER_BY_ID",
    GET_OFFER_BY_ID_SUCCESS: "GET_OFFER_BY_ID_SUCCESS",
    GET_OFFER_BY_ID_ERROR: "GET_OFFER_BY_ID_ERROR",

    GET_OFFER_BY_ID_FOR_USER: "GET_OFFER_BY_ID_FOR_USER",
    GET_OFFER_BY_ID_FOR_USER_SUCCESS: "GET_OFFER_BY_ID_FOR_USER_SUCCESS",
    GET_OFFER_BY_ID_FOR_USER_ERROR: "GET_OFFER_BY_ID_FOR_USER_ERROR",

    SAVE_OFFER: "SAVE_OFFER",
    SAVE_OFFER_SUCCESS: "SAVE_OFFER_SUCCESS",
    SAVE_OFFER_ERROR: "SAVE_OFFER_ERROR",

    UPDATE_OFFER: "UPDATE_OFFER",
    UPDATE_OFFER_SUCCESS: "UPDATE_OFFER_SUCCESS",
    UPDATE_OFFER_ERROR: "UPDATE_OFFER_ERROR",

    UPDATE_OFFER_BY_SYS_ADMIN: "UPDATE_OFFER_BY_SYS_ADMIN",
    UPDATE_OFFER_BY_SYS_ADMIN_SUCCESS: "UPDATE_OFFER_BY_SYS_ADMIN_SUCCESS",
    UPDATE_OFFER_BY_SYS_ADMIN_ERROR: "UPDATE_OFFER_BY_SYS_ADMIN_ERROR",

    DELETE_OFFER: "DELETE_OFFER",
    DELETE_OFFER_SUCCESS: "DELETE_OFFER_SUCCESS",
    DELETE_OFFER_ERROR: "DELETE_OFFER_ERROR",

    GET_ALL_COMPANY_LOCATIONS: "GET_ALL_COMPANY_LOCATIONS",
    GET_ALL_COMPANY_LOCATIONS_SUCCESS: "GET_ALL_COMPANY_LOCATIONS_SUCCESS",
    GET_ALL_COMPANY_LOCATIONS_ERROR: "GET_ALL_COMPANY_LOCATIONS_ERROR",

    ENABLED_OFFERT: "ENABLED_OFFERT",
    ENABLED_OFFERT_SUCCESS: "ENABLED_OFFERT_SUCCESS",
    ENABLED_OFFERT_ERROR: "ENABLED_OFFERT_ERROR",

    UPDATE_OFFER_VIEWS: "UPDATE_OFFER_VIEWS",
    UPDATE_OFFER_VIEWS_SUCCESS: "UPDATE_OFFER_VIEWS_SUCCESS",
    UPDATE_OFFER_VIEWS_ERROR: "UPDATE_OFFER_VIEWS_ERROR",

    SET_OFFER_EXPIRED: "SET_OFFER_EXPIRED",
    SET_OFFER_EXPIRED_SUCCESS: "SET_OFFER_EXPIRED_SUCCESS",
    SET_OFFER_EXPIRED_ERROR: "SET_OFFER_EXPIRED_ERROR",


    getAll: (data) => ({
        type: actions.GET_ALL_OFFERS,
        payload: data
    }),

    addUserOfferToPrevious: (data) => ({
        type: actions.ADD_USER_OFFER_TO_PREVIOUS,
        payload: data
    }),

    getAllUserOffer: (data) => ({
        type: actions.GET_ALL_USER_OFFERS,
        payload: data
    }),

    get: (offerId) => ({
        type: actions.GET_OFFER_BY_ID,
        id: offerId
    }),

    getByIdForUser: (offerId) => ({
        type: actions.GET_OFFER_BY_ID_FOR_USER,
        id: offerId
    }),

    save: (data) => ({
        type: actions.SAVE_OFFER,
        payload: data
    }),

    update: (offerId,companyId,data,state) => ({
        type: actions.UPDATE_OFFER,
        id: offerId,
        companyId: companyId,
        payload: data,
        state: state
    }),

    updateBySysAdmin: (offertId, companyId, data, state) => ({
        type: actions.UPDATE_OFFER_BY_SYS_ADMIN,
        id: offertId,
        companyId: companyId,
        payload: data,
        state: state
    }),

    deleteData: (id, state, sort, pageSize) => ({
        type: actions.DELETE_OFFER,
        id: id,
        state: state,
        sort: sort,
        pageSize: pageSize
    }),

    setOfferExpired: (id, state, sort, pageSize) => ({
        type: actions.SET_OFFER_EXPIRED,
        id: id,
        state: state,
        sort: sort,
        pageSize: pageSize,
    }),

    enabledOffert: (otp) => ({
        type: actions.ENABLED_OFFERT,
        otp: otp
    }),

    updateOfferViews: (id) =>({
        type: actions.UPDATE_OFFER_VIEWS,
        offerId: id
    })
};

export default actions;
