import React from "react";
import "src/component/Useful/Dashboard/DashboardCard/DashboardCardStyle.scss";
import Icon from "src/component/Icon/Sprite";
import {substituteZeroWithLine} from "src/utils/utility";
import {useNavigate} from "react-router";


export function DashboardCard(props) {

    let {isCompleted, type, icon, header, text, title, total, childArray, redirectTo, csvButton} = props
    const navigate = useNavigate()
    return (
        <>
            {isCompleted && total !== 0 ?
                <div className="dashboardCard">
                    <div className="header">
                        <div className="card-title size-18 weight-600 ellipsis">
                            {title}
                        </div>
                        {type !== "OFFERS" ?
                            <div className="flex">
                                <div className="total pointer blue weight-600 size-25 underlined" onClick={() => navigate(redirectTo)}>
                                    {total}
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>

                    <div className="data-container">
                        {childArray.map((element) => (
                            <div className="data">
                                <div className={`size-20 weight-700 ${element.valueClassname}`}>
                                    {substituteZeroWithLine(element && element.value)}
                                </div>
                                <div className={`size-15 weight-400 noWrap ${element.textClassname}`} style={{marginTop: 5}}>
                                    {element.text}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                :
                <div className={`dashboardCard flex-center ${redirectTo && "pointer"}`} onClick={()=> redirectTo && navigate(redirectTo)}>
                    <div className="cardIcon flex-center">
                        <Icon name={icon} size={[20, 20]} color={"#C41A43"}/>
                    </div>
                    <div className="cardSection size-18 weight-600 text-center ellipsis">
                        {header}
                    </div>
                    <div className="size-14 text-center opacity-50 ellipsis">
                        {text}
                    </div>
                    <div className={'dashboardCsvButton'}>
                        {csvButton}
                    </div>
                </div>
            }
        </>
    )
}
