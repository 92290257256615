const actions = {

    GET_ALL_USERS: "GET_ALL_USERS",
    GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
    GET_ALL_USERS_ERROR: "GET_ALL_USERS_ERROR",

    GET_ALL_CANDIDATURE_BY_USER:"GET_ALL_CANDIDATURE_BY_USER",
    GET_ALL_CANDIDATURE_BY_USER_SUCCESS:"GET_ALL_CANDIDATURE_BY_USER_SUCCESS",
    GET_ALL_CANDIDATURE_BY_USER_ERROR:"GET_ALL_CANDIDATURE_BY_USER_ERROR",

    GET_USER: "GET_USER",
    GET_USER_SUCCESS: "GET_USER_SUCCESS",
    GET_USER_ERROR: "GET_USER_ERROR",

    GET_ALL_ADMIN_COMPANY: "GET_ALL_ADMIN_COMPANY",
    GET_ALL_ADMIN_COMPANY_SUCCESS: "GET_ALL_ADMIN_COMPANY_SUCCESS",
    GET_ALL_ADMIN_COMPANY_ERROR: "GET_ALL_ADMIN_COMPANY_ERROR",

    GET_ADMIN_DASHBOARD: "GET_ADMIN_DASHBOARD",
    GET_ADMIN_DASHBOARD_SUCCESS: "GET_ADMIN_DASHBOARD_SUCCESS",
    GET_ADMIN_DASHBOARD_ERROR: "GET_ADMIN_DASHBOARD_ERROR",

    ENABLE_COMPANY: "ENABLE_COMPANY",
    ENABLE_COMPANY_SUCCESS: "ENABLE_COMPANY_SUCCESS",
    ENABLE_COMPANY_ERROR: "ENABLE_COMPANY_ERROR",

    DISABLE_COMPANY: "DISABLE_COMPANY",
    DISABLE_COMPANY_SUCCESS: "DISABLE_COMPANY_SUCCESS",
    DISABLE_COMPANY_ERROR: "DISABLE_COMPANY_ERROR",

    DELETE_COMPANY: "DELETE_COMPANY",
    DELETE_COMPANY_SUCCESS: "DELETE_COMPANY_SUCCESS",
    DELETE_COMPANY_ERROR: "DELETE_COMPANY_ERROR",

    GET_ADMIN_CV: "GET_ADMIN_CV",
    GET_ADMIN_CV_SUCCESS: "GET_ADMIN_CV_SUCCESS",
    GET_ADMIN_CV_ERROR: "GET_ADMIN_CV_ERROR",

    OPEN_MODAL: "OPEN_MODAL",
    CLOSE_MODAL: "CLOSE_MODAL",

    HANDLE_LANDING_MODAL : "OPEN_LANDING_MODAL",

    CREATE_COMPANY_CSV: "CREATE_COMPANY_CSV",
    CREATE_COMPANY_CSV_ERROR: "CREATE_COMPANY_CSV_ERROR",
    CREATE_COMPANY_CSV_SUCCESS: "CREATE_COMPANY_CSV_SUCCESS",

    CREATE_USERS_CSV: "CREATE_USERS_CSV",
    CREATE_USERS_CSV_ERROR: "CREATE_USERS_CSV_ERROR",
    CREATE_USERS_CSV_SUCCESS: "CREATE_USERS_CSV_SUCCESS",


    getAllUsers: (data) => ({
        type: actions.GET_ALL_USERS,
        payload: data
    }),

    getAllCandidatureByUserId: (id) =>({
        type: actions.GET_ALL_CANDIDATURE_BY_USER,
        id: id
    }),

    getUserById: (id) =>({
        type: actions.GET_USER,
        id: id
    }),

    handleLandingModal: (data) => ({
        type: actions.HANDLE_LANDING_MODAL,
        isLandingModalOpen: data
    }),

    getAllAdminCompany: (data) => ({
        type: actions.GET_ALL_ADMIN_COMPANY,
        payload: data
    }),

    getAdminDashboard: (data) => ({
        type: actions.GET_ADMIN_DASHBOARD,
        payload: data
    }),

    enableCompany: (id) => ({
        type: actions.ENABLE_COMPANY,
        payload: id
    }),

    disableCompany: (id) => ({
        type: actions.DISABLE_COMPANY,
        payload: id
    }),

    deleteCompany: (id) => ({
        type: actions.DELETE_COMPANY,
        payload: id
    }),

    openModal: () => ({
        type: actions.OPEN_MODAL,
    }),

    getAdminCv: (data) => ({
        type: actions.GET_ADMIN_CV,
        payload: data
    }),

    closeModal: () => ({
        type: actions.CLOSE_MODAL
    }),

    createCompanyCsv: () => ({
        type: actions.CREATE_COMPANY_CSV
    }),

    createUsersCsv: () => ({
        type: actions.CREATE_USERS_CSV
    })


}

export default actions
