import React from "react";
import {injectIntl} from "react-intl";
import {useLocation, useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/auth/actions";
import "./activeProfile.scss"
import ActiveProfileSuccess from "./activeProfileSuccess/activeProfileSuccess";
import ActiveProfileError from "./activeProfileError/activeProfileError";

const {checkActiveProfile} = actions

function ActiveProfile(){

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation()

    const [typeRoute, setTypeRoute] = React.useState(null)

    const activateStatus = useSelector(state => state.AuthReducer.activateStatus)

    React.useEffect(()=>{
        setTypeRoute(location.pathname.split("/")[1])
        const otp = location.search.substring(5)
        dispatch(checkActiveProfile(otp))
    },[])

    React.useEffect(()=>{
        if(activateStatus){
            const path = '/' + typeRoute + '/login'
            setTimeout(() => {
                navigate(path)
            }, 2000)
        }
    },[activateStatus])

    return(
        <div className={'activated-container'}>
            {activateStatus ?  <ActiveProfileSuccess/> : <ActiveProfileError/>}
        </div>
    )
}

export default injectIntl(ActiveProfile)
