import React, {useState} from "react";
import {Popover} from "antd";
import Icon from "../../Icon/Sprite";
import {ICONS} from "../../../assets/sprite/iconsNames";

function Disclaimer(){

    const [open, setOpen] = useState(false);

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const text = (
        <p className={"weight-600 size-14 dark merriWeather"}>Il campo sarà visibile solo se selezionato</p>
    )

    return(
        <Popover content={text} open={open} onOpenChange={handleOpenChange}>
            <span style={{position:"absolute", marginLeft:"160px", marginTop:"2px", zIndex:1}}>
                <Icon name={ICONS.help} size={[20, 20]} className="grey pointer"/>
            </span>
        </Popover>
    )
}

export default Disclaimer