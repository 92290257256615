import React, {useEffect, useState} from "react";
import Modal from "antd/es/modal";
import {Col, Form, Row} from "antd"
import {InputLogin} from "src/component/Useful/Input/InputLogin";
import {Select} from "src/component/Useful/Select/Select"
import {Switch} from "src/component/Useful/Switch";
import "./ModalLocation.scss"
import Icon from "src/component/Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import List from "antd/es/list";
import {useDispatch, useSelector} from "react-redux";
import actions from "src/redux/location/actions";
import {Checkbox} from "src/component/Useful/Checkbox/Checkbox";
import Tag from "antd/es/tag";
import {ALERT_TYPE, COMPANY_MODAL_TYPE, firstLetters, newId} from "src/utils/utility";
import {FormattedMessage} from "react-intl";
import {ButtonUser} from "src/component/Useful/Button/ButtonUser";
import {ButtonLine} from "../../../Useful/Button/ButtonLine";
import {AlertModal} from "src/component/Layout/AlertModal/AlertModal";

function ModalLocation(props) {

    const {getAllProvince, getCityByProvince, saveCompanyLocation, updateCompanyLocation, deleteLocation} = actions;
    const {company, openModalLocation, setOpenModalLocation, setLocationArray, type, record, locationArray} = props;

    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const province = useSelector(state => state.Location.province)
    const city = useSelector(state => state.Location.city)
    const isButtonLoading = useSelector(state => state.Location.isButtonLoading)

    const [isProvinceSelected, setIsProvinceSelected] = useState(false)
    const [provinceSelected, setProvinceSelected] = useState(null)
    const [checkedEmployee, setCheckedEmployee] = useState([company && company.companyAdmin])
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [disabledMainLocation, setDisabledMainLocation] = useState(false)

    useEffect(() => {
        if (record && record.province) {
            setProvinceSelected(record.province)
        }
    }, [record])

    useEffect(() => {
        if (record) {
            form.setFieldsValue({
                name: record.name,
                province: record.province,
                city: {label: record.city, value: record.locationId},
                cap: record.cap,
                mainLocation: record.mainLocation,
            })
            company && company.employees && company.employees.forEach(it => {
                if (it.locations) {
                    let index = it.locations.findIndex(el => el.id === record.id)
                    if (index !== -1) {
                        setCheckedEmployee(checkedEmployee => [...checkedEmployee, it])
                    }
                }
            })
            setIsProvinceSelected(true)
        } else {
            form.resetFields()
            setIsProvinceSelected(false)
            setCheckedEmployee([company && company.companyAdmin])
        }
        if (!openModalLocation) {
            setIsProvinceSelected(false)
            setProvinceSelected(null)
            setCheckedEmployee([company && company.companyAdmin])
        }
    }, [openModalLocation])

    useEffect(() => {
        let locations = []

        switch (type) {
            case COMPANY_MODAL_TYPE.NEW:
            case COMPANY_MODAL_TYPE.EDIT:
                if (company && company.companyLocations.length !== 0) {
                    locations = company.companyLocations
                }
                break;
            case COMPANY_MODAL_TYPE.NEW_FORM:
            case COMPANY_MODAL_TYPE.EDIT_FORM:
                if (locationArray && locationArray.length !== 0) {
                    locations = company.companyLocations
                }
                break;
        }

        if (record ? (record && !record.mainLocation) : (locations && locations && locations.length !== 0 && locations.some((location) => location.mainLocation === true))) {
            setDisabledMainLocation(false)
            form.setFieldsValue({
                mainLocation: false
            })
        } else {
            setDisabledMainLocation(true)
            form.setFieldsValue({
                mainLocation: true
            })
        }
    }, [openModalLocation, locationArray, record])

    return (
        <>
            <Modal
                className={"modalNewSite"}
                open={openModalLocation}
                closeIcon={<Icon name={ICONS.close} size={[20, 20]}/>}
                centered={true}
                onCancel={() => {
                    setIsProvinceSelected(false)
                    setOpenModalLocation(false)
                    setCheckedEmployee([company.companyAdmin])
                }}
                destroyOnClose={true}
                footer={
                    <div className={(type === COMPANY_MODAL_TYPE.EDIT || type === COMPANY_MODAL_TYPE.EDIT_FORM) && record && !record.mainLocation ? "flex-center-between" : "flex-end"}>
                        {(type === COMPANY_MODAL_TYPE.EDIT || type === COMPANY_MODAL_TYPE.EDIT_FORM) && record && !record.mainLocation &&
                        <div onClick={() => setIsAlertOpen(true)}>
                            <Icon className="pointer icon margin-top-5" name={ICONS.delete} size={[20, 20]} color="#868696"/>
                        </div>
                        }
                        <div className={"modalFooter"}>
                            <div
                                onClick={() => {
                                    form.resetFields()
                                    setIsProvinceSelected(false)
                                    setOpenModalLocation(false)
                                }}
                                className={"underlined opacity-50 flex-align-center margin-right-24 pointer"}
                            >
                                <FormattedMessage id="cancel"/>
                            </div>
                            {type === COMPANY_MODAL_TYPE.NEW_FORM &&
                            <ButtonLine
                                width={169}
                                onClick={() => {
                                    form.validateFields().then(values => {
                                        if (values.mainLocation) {
                                            form.setFieldsValue({
                                                mainLocation: !disabledMainLocation
                                            })

                                            let updatedArray = [...locationArray];
                                            let mainLocationIndex = locationArray.findIndex(element => element.mainLocation === true)
                                            if (mainLocationIndex !== -1) {
                                                updatedArray[mainLocationIndex].mainLocation = false;
                                                setLocationArray(updatedArray);
                                            }
                                        }

                                        setLocationArray(locationArray => [...locationArray,
                                            {
                                                tempId: newId("location"),
                                                locationId: values.city.value,
                                                name: values.name,
                                                cap: values.cap,
                                                mainLocation: values.mainLocation,
                                                city: values.city.label,
                                                province: values.province,
                                                employees: [company.companyAdmin]
                                            }
                                        ])
                                        setIsProvinceSelected(false)
                                        form.resetFields()
                                    })
                                }}
                            >
                                <FormattedMessage id="ModalButton_SaveNew"/>
                            </ButtonLine>
                            }
                            <ButtonUser
                                htmlType="submit"
                                form={"createLocation"}
                                loading={isButtonLoading}
                                onClick={() => {
                                    setIsProvinceSelected(false)
                                }}
                                className="flex-center"
                            >
                                <Icon name={ICONS.tik} size={[20, 20]}/>
                                <FormattedMessage id="ModalButton_SaveClose"/>
                            </ButtonUser>
                        </div>
                    </div>
                }
            >

                <Form
                    id={"createLocation"}
                    form={form}
                    layout="vertical"
                    style={{width: "100%"}}
                    onFinish={(values) => {
                        let data
                        switch (type) {
                            case COMPANY_MODAL_TYPE.NEW:
                                data = {
                                    locationId: values.city.value,
                                    employees: checkedEmployee,
                                    name: values.name,
                                    cap: values.cap,
                                    mainLocation: values.mainLocation,
                                }
                                dispatch(saveCompanyLocation(data))
                                break;
                            case COMPANY_MODAL_TYPE.EDIT:
                                data = {
                                    id: record.id,
                                    locationId: values.city.value,
                                    employees: checkedEmployee,
                                    name: values.name,
                                    cap: values.cap,
                                    mainLocation: values.mainLocation,
                                }
                                dispatch(updateCompanyLocation(data))
                                break;
                            case COMPANY_MODAL_TYPE.NEW_FORM:

                                if (values.mainLocation) {
                                    let updatedArray = [...locationArray];
                                    let mainLocationIndex = locationArray.findIndex(element => element.mainLocation === true)
                                    if (mainLocationIndex !== -1) {
                                        updatedArray[mainLocationIndex].mainLocation = false;
                                        setLocationArray(updatedArray);
                                    }
                                }

                                setLocationArray(locationArray => [...locationArray,
                                    {
                                        tempId: newId("location"),
                                        locationId: values.city.value,
                                        name: values.name,
                                        cap: values.cap,
                                        mainLocation: values.mainLocation,
                                        city: values.city.label,
                                        province: values.province,
                                        employees: [company.companyAdmin]
                                    }
                                ])
                                break;
                            case COMPANY_MODAL_TYPE.EDIT_FORM:

                                if (record.id) {
                                    data = locationArray.find(location => location.id === record.id)
                                } else {
                                    data = locationArray.find(location => location.tempId === record.tempId)
                                }

                                data = {
                                    ...data,
                                    locationId: values.city.value,
                                    name: values.name,
                                    cap: values.cap,
                                    mainLocation: values.mainLocation,
                                    city: values.city.label,
                                    province: values.province,
                                }

                                let updatedArray = [...locationArray];

                                if (values.mainLocation) {
                                    let mainLocationIndex = locationArray.findIndex(element => element.mainLocation === true)
                                    if (mainLocationIndex !== -1) {
                                        updatedArray[mainLocationIndex].mainLocation = false
                                        setLocationArray(updatedArray)
                                    }
                                }
                                let index
                                if (record.id) {
                                    index = locationArray.findIndex(it => it.id === record.id)
                                } else {
                                    index = locationArray.findIndex(it => it.tempId === record.tempId)
                                }
                                updatedArray[index] = data
                                setLocationArray(updatedArray)
                                break;
                        }
                        form.resetFields()
                        setIsProvinceSelected(false)
                        setOpenModalLocation(false)
                    }}
                >
                    {(type === COMPANY_MODAL_TYPE.EDIT || type === COMPANY_MODAL_TYPE.EDIT_FORM) ?
                        <div style={{fontSize: "30px", fontWeight: 700}}>
                            <FormattedMessage id="CompanyDetails_editLocation"/>
                        </div>
                        :
                        <div style={{fontSize: "30px", fontWeight: 700}}>
                            <FormattedMessage id="CompanyDetails_newLocation"/>
                        </div>
                    }
                    <div>
                        <Form.Item
                            name="name"
                            label={"Denominazione attività/Ragione Sociale/Nome attività"}
                            className="weight-600 size-14 margin-top-10"
                            extra={<FormattedMessage id="Modal_nameLocationPublic"/>}
                            rules={[
                                {
                                    required: true,
                                    message: false,
                                },
                            ]}
                        >
                            <InputLogin autoComplete="nope" placeholder={"Nome/Ragione sociale"} maxLength={50}/>
                        </Form.Item>
                    </div>

                    <Row gutter={24} style={{display: "flex", flexDirection: "row"}}>
                        <Col span={9} style={{display: "flex", flexDirection: "column"}}>

                            <Form.Item
                                name="province"
                                label={"Provincia"}
                                className="weight-600 size-14"
                                rules={[
                                    {
                                        required: true,
                                        message: false,
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="-Seleziona-"
                                    autoComplete="nope"
                                    allowClear
                                    showSearch
                                    style={{height: 48}}
                                    suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]}/>}
                                    filterOption={false}
                                    onClear={() => {
                                        setIsProvinceSelected(false)
                                        form.setFieldsValue({...form.getFieldsValue, city: undefined})
                                    }}
                                    options={province && province.map(element => ({
                                        id: element.id,
                                        value: element.province,
                                        label: element.province
                                    }))}
                                    onFocus={() => {
                                        dispatch(getAllProvince({
                                            page: 0,
                                            size: 200
                                        }))
                                    }}
                                    onSearch={(value) => {
                                        dispatch(getAllProvince({
                                            page: 0,
                                            size: 200,
                                            province: value
                                        }))
                                    }}
                                    onSelect={(value) => {
                                        setIsProvinceSelected(true)
                                        setProvinceSelected(value)
                                        form.setFieldsValue({...form.getFieldsValue, city: undefined})
                                        dispatch(getCityByProvince({
                                            page: 0,
                                            size: 350,
                                            province: value
                                        }))
                                    }}
                                />

                            </Form.Item>
                        </Col>
                        <Col span={9} style={{display: "flex", flexDirection: "column"}}>

                            <Form.Item
                                name="city"
                                label={"Città"}
                                className="weight-600 size-14"
                                rules={[
                                    {
                                        required: true,
                                        message: false,
                                    },
                                ]}
                            >

                                <Select
                                    autoComplete="nope"
                                    allowClear
                                    showSearch
                                    suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]}/>}
                                    filterOption={false}
                                    labelInValue
                                    style={{height: 48}}
                                    placeholder="-Seleziona-"
                                    disabled={!isProvinceSelected}
                                    options={city && city.map(element => ({
                                        id: element.id,
                                        value: element.id,
                                        label: element.city
                                    }))}
                                    onSearch={(value) => {
                                        dispatch(getCityByProvince({
                                            page: 0,
                                            size: 350,
                                            province: provinceSelected,
                                            city: value,
                                        }))
                                    }}
                                    onFocus={() => {
                                        dispatch(getCityByProvince({
                                            page: 0,
                                            size: 350,
                                            province: provinceSelected,
                                        }))
                                    }}
                                />

                            </Form.Item>
                        </Col>
                        <Col span={6}>


                            <Form.Item
                                name="cap"
                                label={"CAP"}
                                className="weight-600 size-14"
                                rules={[
                                    {
                                        required: true,
                                        pattern: /^[0-9]{5}$/,
                                        message: false,
                                    },
                                ]}
                            >
                                <InputLogin placeholder="CAP" autoComplete="nope" maxLength={5}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div>

                        <Form.Item
                            name="mainLocation"
                            label={"Sede principale"}
                            className="weight-600 size-14"
                            valuePropName="checked"
                            // initialValue={checkInitialValueLocation(record)}
                        >
                            <Switch
                                disabled={disabledMainLocation}
                                checkedChildren={<Icon name={ICONS.ok} size={[20, 20]}/>}
                                unCheckedChildren={<Icon name={ICONS.close_switch} size={[20, 20]}/>}
                            />

                        </Form.Item>
                    </div>

                    {type === COMPANY_MODAL_TYPE.NEW || type === COMPANY_MODAL_TYPE.EDIT ?
                        <Row>
                            <Col style={{width: "100%"}}>
                                <div style={{marginBottom: "16px"}}>
                                    <div className="size-14 weight-600">
                                        <FormattedMessage id="Modal_associateResponsible1"/>
                                    </div>
                                    <div>
                                        <FormattedMessage id="Modal_associateResponsible2"/>
                                    </div>
                                </div>

                                <List className="employeeList" bordered={false}>

                                    {company &&
                                    <List.Item className="newLocationList flex-center-between width-100">
                                        <div className="flex-center">
                                            <div>
                                                <Checkbox defaultChecked={true} disabled={true}/>
                                            </div>
                                            <div style={{margin: "0 12px 0 16px"}}>
                                                <div className="isEmployeeNotActive weight-700 padding-4 size-14">{firstLetters(company.companyAdmin.firstName, company.companyAdmin.lastName)}</div>
                                            </div>
                                            <div>
                                                <div>
                                                    {company.companyAdmin.firstName} {company.companyAdmin.lastName}
                                                </div>
                                                {/*<div>*/}
                                                {/*    {!company.companyAdmin.locations ?*/}
                                                {/*        "Nessuna sede associata"*/}
                                                {/*        :*/}
                                                {/*        <>*/}
                                                {/*            {company && company.companyAdmin.locations && company.companyAdmin.locations.map((location) => {*/}
                                                {/*                return (*/}
                                                {/*                    <Tag className="locationTag">{location.name}</Tag>*/}
                                                {/*                )*/}
                                                {/*            })}*/}
                                                {/*        </>*/}
                                                {/*    }*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                        <div>
                                            <FormattedMessage id="CompanyDetails_owner"/>
                                        </div>
                                    </List.Item>
                                    }

                                    {company && company.employees && company.employees.map((element) => {
                                            if (element.isActive) {
                                                return (
                                                    <List.Item className="newLocationList flex-center-between">
                                                        <div className="flex-center">
                                                            <div>
                                                                <Checkbox
                                                                    checked={checkedEmployee.some(it => it.id === element.id)}
                                                                    onChange={(value) => {
                                                                        if (value.target.checked) {
                                                                            setCheckedEmployee(checkedEmployee => [...checkedEmployee, element])
                                                                        } else {
                                                                            let tempArray = checkedEmployee.filter(it => it.id !== element.id)
                                                                            setCheckedEmployee(tempArray)
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div style={{margin: "0 12px 0 16px"}}>
                                                                <div className="weight-700 padding-4 size-14 isEmployeeNotActive">{firstLetters(element.firstName, element.lastName)}</div>
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    {element.firstName} {element.lastName}
                                                                </div>
                                                                <div>
                                                                    {element.locations.length === 0 ?
                                                                        "Nessuna sede associata"
                                                                        :
                                                                        <>
                                                                            {element.locations.map((location) => {
                                                                                return (
                                                                                    <Tag className="locationTag">{location.name}</Tag>
                                                                                )
                                                                            })}
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )
                                            }
                                        }
                                    )}
                                </List>
                            </Col>
                        </Row>
                        :
                        null
                    }
                </Form>

            </Modal>
            <AlertModal
                type={ALERT_TYPE.ERROR}
                content={<FormattedMessage id="Alert_deleteLocation"/>}
                okText={<FormattedMessage id="Alert_proceed"/>}
                onOk={() => {
                    if (type === COMPANY_MODAL_TYPE.EDIT_FORM) {
                        let index
                        if (record.id) {
                            index = locationArray.findIndex(location => location.id === record.id)
                        } else {
                            index = locationArray.findIndex(location => location.tempId === record.tempId)
                        }
                        let tempArray = [...locationArray]
                        tempArray.splice(index, 1);
                        setLocationArray(tempArray)
                    } else {
                        dispatch(deleteLocation(record.id))
                    }
                    setOpenModalLocation(false)
                    setIsAlertOpen(false)
                }}
                onClose={() => {
                    setIsAlertOpen(false)
                }}
                isAlertOpen={isAlertOpen}
            />
        </>
    )
}

export default ModalLocation

