import React, {useEffect} from "react";
import Modal from "antd/es/modal";
import {ICONS} from "src/assets/sprite/iconsNames";
import Icon from "src/component/Icon/Sprite";
import {InputLogin} from "../../../Useful/Input/InputLogin";
import {FormattedMessage} from "react-intl";
import {ButtonUser} from "../../../Useful/Button/ButtonUser";
import {useDispatch, useSelector} from "react-redux";
import actions from "src/redux/auth/actions";
import accountActions from "src/redux/account/actions"
import "src/component/Layout/Modal/ModalChangePassword/ModalChangePassword.scss"

function ModalChangePassword() {

    const {openModalChangePassword, closeModalChangePassword} = accountActions;
    const {resetNewPassword, checkOldPassword, resetCheckPassword} = actions;

    const dispatch = useDispatch()

    const isCurrentPasswordEqual = useSelector(state => state.AuthReducer.isCurrentPasswordEqual)
    const isOpenModalForChangePassword = useSelector(state => state.Account.isOpenModalForChangePassword)

    const [oldPassword, setOldPassword] = React.useState(null)
    const [newPassword, setNewPassword] = React.useState(null)
    const [toggleActualPassword, setToggleActualPassword] = React.useState(false)
    const [toggleNewPassword, setToggleNewPassword] = React.useState(false)

    return (
        <Modal
            open={isOpenModalForChangePassword}
            onCancel={() => {
                dispatch(resetCheckPassword())
                dispatch(closeModalChangePassword())
            }}
            closeIcon={<Icon name={ICONS.close} size={[20, 20]}/>}
            centered
            footer={
                <div className="padding-20-no-top flex-end">
                    <div className={"underlined opacity-50 flex-align-center margin-right-24 pointer"} onClick={()=> dispatch(closeModalChangePassword())}>
                        <FormattedMessage id="cancel"/>
                    </div>
                    <ButtonUser
                        className="flex-center"
                        style={{width: "30%", minWidth: 0}}
                        block
                        icon={<Icon name={ICONS.tik} size={[20, 20]} className="margin-5 opacity-50"/>}
                        onPressEnter={() => {
                            if (!isCurrentPasswordEqual) {
                                dispatch(resetNewPassword({
                                    oldPassword : oldPassword,
                                    newPassword : newPassword
                                }))
                            }
                            dispatch(closeModalChangePassword())
                        }}
                        onClick={() => {
                            if (!isCurrentPasswordEqual) {
                                dispatch(resetNewPassword({
                                    oldPassword : oldPassword,
                                    newPassword : newPassword
                                }))
                            }
                            dispatch(closeModalChangePassword())
                        }}>
                        Salva
                    </ButtonUser>
                </div>
            }
        >
            <div className="flex-column padding-20-no-bottom">
                <div className="flex-row flex-align-center padding-bottom-20">
                    <div className="weight-700 size-29 merriWeather">
                        <FormattedMessage id="Modal_Title_Change_Password"/>
                    </div>
                </div>

                <div className={"inputField"}>
                    <div>
                        <label>
                            <FormattedMessage id="Account_currentPassword"/>
                        </label>
                        <InputLogin
                            type={toggleActualPassword ? "text" : "password"}
                            className={"input pointer"}
                            suffix={
                                <div onClick={(e) => setToggleActualPassword(!toggleActualPassword)}>
                                    <Icon
                                        name={toggleActualPassword ? ICONS.eye : ICONS.eye_hide}
                                        className="flex-center" color="#C41A43" size={[20, 20]}/>
                                </div>}
                            onBlur={(e) => {
                                setOldPassword(e.target.value)
                                dispatch(checkOldPassword(e.target.value))
                            }}
                        />
                    </div>

                    <div className="recovery margin-top-12 margin-bottom-30">
                        <FormattedMessage id="Account_redirect_recovery"/>
                        <span>
                            <FormattedMessage id="Account_link_recovery"/>
                        </span>
                    </div>

                    <div>
                        <label>
                            <FormattedMessage id="Account_newPassword"/>
                        </label>
                        <InputLogin
                            type={toggleNewPassword ? "text" : "password"}
                            className={"input pointer"}
                            suffix={
                                <div onClick={(e) => setToggleNewPassword(!toggleNewPassword)}>
                                    <Icon
                                        name={toggleNewPassword ? ICONS.eye : ICONS.eye_hide}
                                        className="flex-center" color="#C41A43" size={[20, 20]}/>
                                </div>
                            }
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalChangePassword