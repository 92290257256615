import React, {useEffect, useState} from "react";
import SelectPrefix from "src/component/Useful/Select/Select";
import {ButtonUser} from "src/component/Useful/Button/ButtonUser";
import {Switch} from "src/component/Useful/Switch";
import "./JobOfferStyle.scss"
import {Collapse, Divider, message} from "antd";
import {InputLogin} from "src/component/Useful/Input/InputLogin";
import Icon from "src/component/Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import ListMyOffert from "src/component/Layout/MyOffertUser/ListMyOffert";
import {CustomDrawer} from "src/component/Layout/Drawer/CustomDrawer";
import {useDispatch, useSelector} from "react-redux";
import actions from "src/redux/offer/actions";
import {useSearchParams} from "react-router-dom";
import {OFFER_FILTERS_CANDIDATE, EFFORT, SORTING, DRAWER_TYPE} from "src/utils/utility";
import locationsActions from "src/redux/location/actions";
import EmptyOffers from "../../../component/Layout/OffersList/EmptyOffers";
import candidatureActions from "../../../redux/candidature/actions";
import preferenceActions from "src/redux/preference/actions"
import useMediaQuery from "src/component/Useful/Responsive/UseMedia";
import ListMyOffertMobile from "../../../component/Layout/MyOffertUser/ListMyOfferMobile";
import userActions from "../../../redux/userProfile/actions";
import drawerActions from "src/redux/drawer/actions";


function JobOffer() {

    let dispatch = useDispatch()
    const [messageApi, contextHolder] = message.useMessage();

    const {getAllUserOffer, getByIdForUser} = actions;
    const {getAll, get, resetLocation} = locationsActions
    const {getAllMyCandidature, resetStateCandidature} = candidatureActions;
    const {resetStatePreference} = preferenceActions;
    const {setRecordOffer} = userActions;

    const isDrawerOpen = useSelector(state => state.UserProfile.isDrawerOpen)
    const locations = useSelector(state => state.Location.locations)
    const location = useSelector(state => state.Location.location)
    const offers = useSelector(state => state.Offer.offers)
    const isCandidatureCreatedSuccess = useSelector(state => state.Candidature.isCandidatureCreatedSuccess)
    const confirmCandidature = useSelector(state => state.Candidature.confirmCandidature)
    const isPreferenceSetSuccessfully = useSelector(state => state.Preference.isPreferenceSetSuccessfully)

    let [searchParams, setSearchParams] = useSearchParams();

    const [titleFilter, setTitleFilter] = useState(searchParams.get("title") && searchParams.get("title"))
    const [locationFilter, setLocationFilter] = useState(searchParams.get("locationId") && searchParams.get("locationId"))
    const [impegnoFilter, setImpegnoFilter] = useState(searchParams.get("impegnoRichiesto") && searchParams.get("impegnoRichiesto"))
    const [onlyRemoteFilter, setOnlyRemoteFilter] = useState(searchParams.get("onlyRemote") && searchParams.get("onlyRemote") === "true")
    const [viewComponent, setViewComponent] = useState(false)
    const [changeStyle, setChangeStyle] = useState(null)

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = React.useState(10)

    const [type, setType] = useState(null)
    const { openDrawer } = drawerActions;


    const isDesktop = useMediaQuery('(min-width: 1024px)')

    useEffect(() => {
        setLocationFilter(location)
    }, [location])

    useEffect(() => {
        return () => {
            dispatch(resetLocation())
        }
    }, [])

    useEffect(() => {
        if (searchParams.get("id")){
            dispatch(setRecordOffer(offers[0]))
        }
    }, [offers])

    useEffect(() => {
        let params = []

        for (let entry of searchParams.entries()) {
            params.push(entry)
        }

        if (searchParams.get("locationId")) {
            dispatch(get(searchParams.get("locationId")))
        }
        if (params.length !== 0) {
            if (searchParams.get("id")){
                setViewComponent(true)
                dispatch(getByIdForUser(searchParams.get("id")))
            } else {
                dispatch(getAllUserOffer(
                    {
                        page: page,
                        size: pageSize,
                        title: searchParams.get("title"),
                        locationId: searchParams.get("locationId"),
                        impegnoRichiesto: searchParams.get("impegnoRichiesto"),
                        onlyRemote: searchParams.get("onlyRemote"),
                    }
                ))
            }
        } else {
            dispatch(getAllUserOffer(
                {
                    page: page,
                    size: pageSize,
                }
            ))
        }
    }, [searchParams])

    useEffect(()=>{
        if (isCandidatureCreatedSuccess) { 
            dispatch(getAllMyCandidature({
                state: OFFER_FILTERS_CANDIDATE.ALL.value,
                sort: SORTING.ASC.value
            }))

            messageApi.open({
                type: 'success',
                content: 'Candidatura aggiornata con successo!'
            })
            dispatch(resetStateCandidature())
        }
    },[isCandidatureCreatedSuccess])

    useEffect(() => {
        if (isPreferenceSetSuccessfully === "add") {
            messageApi.open({
                type: 'success',
                content: 'Preference aggiunta con successo!'
            })

            dispatch(resetStatePreference())
        }else if(isPreferenceSetSuccessfully === "remove"){
            messageApi.open({
                type: 'success',
                content: 'Preferenza rimossa con successo!'
            })

            dispatch(resetStatePreference())
        }
    }, [isPreferenceSetSuccessfully])

    return (
        <div className="userOffert">
            {contextHolder}
            <div className="maxWidth">
                <div className="filterBar">
                    <div className={"input-dashboard"}>
                        <InputLogin
                            prefix={<Icon name={ICONS.search} className="opacity-50" size={[20, 20]}/>}
                            placeholder={"Nome offerta"}
                            className={"input-offert"}
                            style={{height: "100%", width:"100%"}}
                            value={titleFilter}
                            onChange={(element) => setTitleFilter(element.target.value)}
                        />
                    </div>
                    <div className={"select-dashboard"}>
                        <SelectPrefix
                            className="width-100"
                            placeholder="Dove?"
                            key={"test"}
                            showSearch
                            allowClear
                            value={locationFilter}
                            filterOption={false}
                            suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]}/>}
                            prefixicon={<Icon name={ICONS.pin} className="opacity-50 margin-5" size={[20, 20]}/>}
                            options={locations && locations.map(element => ({
                                id: element.id,
                                value: element.city,
                                label: element.city
                            }))}
                            onClear={() => {
                                setLocationFilter(null)
                            }}
                            onFocus={() => {
                                dispatch(getAll({
                                    page: 0,
                                    size: 20
                                }))
                            }}
                            onSearch={(value) => {
                                dispatch(getAll({
                                    page: 0,
                                    size: 20,
                                    city: value
                                }))
                            }}
                            onSelect={(value, record) => {
                                setLocationFilter(record)
                            }}

                        />
                    </div>
                    <div className={"select-dashboard"}>
                        <SelectPrefix
                            className="width-100"
                            placeholder={"Impegno richiesto"}
                            allowClear
                            filterOption={false}
                            suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]}/>}
                            prefixicon={<Icon name={ICONS.clock} className="opacity-50 margin-5" size={[20, 20]}/>}
                            options={EFFORT}
                            value={impegnoFilter}
                            onClear={() => {
                                setImpegnoFilter(null)
                            }}
                            onSelect={(value) => {
                                setImpegnoFilter(value)
                            }}
                        />
                    </div>
                    <div className={"switch-dashboard flex-center"}>
                        <Switch
                            checkedChildren={<Icon name={ICONS.ok} size={[20, 20]}/>}
                            unCheckedChildren={<Icon name={ICONS.close_switch} color="grey" size={[20, 20]}/>}
                            checked={onlyRemoteFilter}
                            onChange={(value) => setOnlyRemoteFilter(value)}
                        />
                        <span className="weight-500 margin-left-8">Solo remoto</span>
                    </div>
                    <div className={"container-button"}>
                        <ButtonUser
                            className={"flex-center"}
                            style={{width:"100%"}}
                            icon={<Icon name={ICONS.search} size={[20, 20]} className={"margin-5"}/>} onClick={() => {
                            setViewComponent(false)
                            setChangeStyle(false)
                            searchParams.delete('id');
                            setSearchParams(searchParams)
                            setPage(1)
                            dispatch(getAllUserOffer(
                                {
                                    size: 10,
                                    title: titleFilter,
                                    locationId: locationFilter && locationFilter.id,
                                    impegnoRichiesto: impegnoFilter,
                                    onlyRemote: onlyRemoteFilter,
                                }
                            ))
                        }}>
                            <span className="text-search-button">Ricerca</span>
                        </ButtonUser>
                    </div>
                </div>
            </div>
            <Divider/>
            <div className={"maxWidth"}>
                <div className="list-margin">
                    <div className="margin-bottom-24 weight-500 size-18 margin-bottom-20">
                        {
                            offers ? offers.length != 1 ? offers.length + " risultati" : offers.length + " risultato" : null
                        }
                    </div>
                    {offers && offers.length > 0 ? isDesktop ?
                        <ListMyOffert setType={setType} page={page} setPage={setPage} titleFilter={titleFilter} locationFilter={locationFilter} impegnoFilter={impegnoFilter} onlyRemoteFilter={onlyRemoteFilter}
                                      viewComponent={viewComponent} setViewComponent={setViewComponent} changeStyle={changeStyle} setChangeStyle={setChangeStyle}/> :
                        <ListMyOffertMobile setType={setType} page={page} setPage={setPage} titleFilter={titleFilter} locationFilter={locationFilter} impegnoFilter={impegnoFilter} onlyRemoteFilter={onlyRemoteFilter}
                                            viewComponent={viewComponent} setViewComponent={setViewComponent} changeStyle={changeStyle} setChangeStyle={setChangeStyle}/> :
                        <EmptyOffers/>
                    }
                </div>
            </div>
            <CustomDrawer
                record={""}
                modify={"modify"}
                state={""}
                isDrawerOpen={isDrawerOpen}
                type={type}
                setType={setType}
            />
        </div>
    )
}

export default JobOffer
