import actions from './actions'
import preferenceActions from 'src/redux/preference/actions'
import authActions from "../auth/actions";

const initState = {
    offers: [],
    totalOffers: null,
    offer: null,
    updateList: false,
    updated: false,
    isOffersCreatedSuccess: false,
    isUpdateOfferBySysAdminSuccess: false,
    loading: false
}

export default function offerReducer(state = initState, action) {
    switch (action.type) {
        case actions.INIT_CANDIDATURE:
            return {
                ...initState
            };
        case authActions.LOGOUT:
            return {...initState}
        case actions.GET_ALL_OFFERS:
            return {
                ...state,
                offers: [],
                totalOffers: 0,
                isOffersCreatedSuccess: false,
            };
            case actions.GET_ALL_OFFERS_SUCCESS:
            return {
                ...state,
                offers: action.payload.elements,
                totalOffers: action.payload.total,
                isOffersCreatedSuccess: false,
            };
        case actions.GET_ALL_USER_OFFERS:
            return {
                ...state,
                offers: [],
                totalOffers: 0,
            };
            case actions.GET_ALL_USER_OFFERS_SUCCESS:
            return {
                ...state,
                offers: action.payload.elements,
                totalOffers: action.payload.total,
            };
        case actions.ADD_USER_OFFER_TO_PREVIOUS:
            return {
                ...state,
                loading: true
            };
        case actions.ADD_USER_OFFER_TO_PREVIOUS_SUCCESS:
            let newOffers = [...state.offers]
            action.payload.elements.map((offer) => {
                newOffers.push(offer)
            })
            return {
                ...state,
                offers: newOffers,
                totalOffers: action.payload.total,
                loading: false
            };
        case actions.GET_OFFER_BY_ID_SUCCESS:
            return {
                ...state,
                offer: action.payload,
            };
        case actions.GET_OFFER_BY_ID_FOR_USER_SUCCESS:
            return {
                ...state,
                offers: [action.payload],
            };
        case actions.SAVE_OFFER:
        case actions.UPDATE_OFFER:
            return {
                ...state,
                isOffersCreatedSuccess: false,
            }
        case actions.SAVE_OFFER_SUCCESS:
        case actions.UPDATE_OFFER_SUCCESS:
            return {
                ...state,
                isOffersCreatedSuccess: true,
            }
        case actions.UPDATE_OFFER_BY_SYS_ADMIN:
            return {
                ...state,
                isOffersCreatedSuccess: false,
            }

        case actions.UPDATE_OFFER_BY_SYS_ADMIN_SUCCESS:
            return {
                ...state,
                isOffersCreatedSuccess: true,
            }
        case actions.ENABLED_OFFERT:
            return {
                ...state,
                isUpdateOfferBySysAdminSuccess: false,
            }
        case actions.ENABLED_OFFERT_SUCCESS:
            return {
                ...state,
                isUpdateOfferBySysAdminSuccess: true,
            }
        case actions.DELETE_OFFER_SUCCESS:
            return {
                ...state,
                updateList: true,
                offers: action.payload.elements,
                totalOffers: action.payload.total
            }
        case preferenceActions.SAVE_PREFERENCE_SUCCESS:
            let savePreference = [...state.offers]
            let saveIndex = state.offers.findIndex(it => it.id === action.id)
            if (saveIndex !== -1) {
                savePreference[saveIndex].preference = true
            }
            return {
                ...state,
                offers: savePreference,
            }
        case preferenceActions.DELETE_PREFERENCE_SUCCESS:
            let deletePreference = [...state.offers]
            let deleteIndex = state.offers.findIndex(it => it.id === action.id)
            if (deleteIndex !== -1) {
                deletePreference[deleteIndex].preference = false
            }
            return {
                ...state,
                offers: deletePreference,
            }
        default:
            return state;
    }
}
