import Icon from "../../Icon/Sprite";
import {ICONS} from "../../../assets/sprite/iconsNames";
import {Input} from "./Input";
import React from "react";

function InputSearch({text}) {
    return(<div>
        <Input placeholder={text} prefix={<Icon name={ICONS.search} size={[20,20]} color={"#868696"}/>}/>
    </div>)
}

export default InputSearch
