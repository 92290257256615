const actions = {

    SEND_EMAIL: 'SEND_EMAIL',
    SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
    SEND_EMAIL_ERROR: 'SEND_EMAIL_ERROR',

    GET_EMAIL: 'GET_EMAIL',
    GET_EMAIL_SUCCESS: 'GET_EMAIL_SUCCESS',
    GET_EMAIL_ERROR: 'GET_EMAIL_ERROR',


    sendEmailAction: (recipient, object, body) => ({
        type: actions.SEND_EMAIL,
        recipient: recipient,
        object: object,
        body: body
    }),

    getEmailAction: () => ({
        type: actions.GET_EMAIL
    }),

};

export default actions;
