import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "antd";
import Icon from "../../../../Icon/Sprite";
import {ICONS} from "../../../../../assets/sprite/iconsNames";
import {Select} from "../../../../Useful/Select/Select";
import {EXPERIENCE, QUALIFICATION} from "../../../../../utils/utility";
import {FormattedMessage} from "react-intl";
import {InputLogin} from "../../../../Useful/Input/InputLogin";
import "./GraduateLevels.scss";

export default function GraduateLevels(props) {

    const {graduateLevels, setGraduateLevels} = props

    const addGraduateLevels = () => {
        let tempGraduateLevels = [...graduateLevels]
        tempGraduateLevels.push({id: Math.random(), qualification: null, qualificationLevel: null})
        setGraduateLevels(tempGraduateLevels)
    }

    const onChangeValue = (value, key, type) => {
        let tempGraduateLevels = [...graduateLevels]
        let index = tempGraduateLevels.findIndex((item)=>item.id === key)
            if(type === "qualification"){
                tempGraduateLevels[index].qualification = value
            }else if(type === "qualificationLevel"){
                tempGraduateLevels[index].qualificationLevel = value
            }
        setGraduateLevels(tempGraduateLevels)
    }

    const deleteGraduateLevels = (key) => {
        setGraduateLevels(graduateLevels.filter(item => item.id !== key))
    }

    return (
        <div className={"questionContent"}>
            {graduateLevels && graduateLevels.map((it, index)=>(
                <div key={index}>
                    <Row gutter={[24,24]}>
                        <Col span={12}>
                            <Form>
                                <Form.Item
                                    label={<FormattedMessage id="UserDetails_graduate_level"/>}
                                    name="InstructureLevel"
                                    className="weight-600 size-14 margin-top-10 margin-bottom-0"
                                    rules={[
                                        {
                                            required: true,
                                            message: false,
                                        }
                                    ]}
                                />
                            </Form>
                        </Col>
                        <Col span={12}>
                            <div key={index} className={"questionBox"}>
                                {index !== 0 &&
                                <header className={"questionHeader"}>
                                    <div onClick={() => deleteGraduateLevels(it.id)} className={"deleteDiv"}>
                                        <span className={"margin-bottom-0 margin-top-10 opacity-50 pointer flex-end underlined"}>Elimina</span>
                                    </div>
                                </header>}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[24,24]}>
                        <Col xl={it.qualificationLevel !== "Licenza Media" ? 12 : 24} md={it.qualificationLevel !== "Licenza Media" ? 12 : 24} sm={24} xs={24}>
                            <Select
                                value={it.qualificationLevel}
                                allowClear
                                suffixIcon={<Icon name={ICONS.arrow} size={[20, 20]} color={"#208AC6"}/>}
                                placeholder={"- Seleziona -"}
                                options={QUALIFICATION}
                                onChange={(value)=> {
                                    onChangeValue(value, it.id, "qualificationLevel")
                                }}
                            />
                        </Col>
                        {it.qualificationLevel !== "Licenza Media" &&
                        <Col xl={12} md={12} sm={24} xs={24}>
                            <InputLogin
                                value={it.qualification}
                                onChange={(e)=>{
                                onChangeValue(e.target.value, it.id, "qualification")
                            }}/>
                        </Col>}
                    </Row>
                </div>
            ))}
            <Row>
                <Col span={24} className="flex ">
                    <div className="flex pointer" onClick={()=>addGraduateLevels()}>
                        <Icon name={ICONS.add} size={[20, 20]} className="padding-top-20" color={"#208AC6"}/>
                        <span className="weight-600 blue padding-top-20">
                            <FormattedMessage id="UserDetails_add_graduate_level"/>
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
