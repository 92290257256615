import actions from './actions'
import authActions from "../auth/actions";

const initState = {
    listNotification: [],
    notificationCount: 0
}

export default function notificationReducer(state = initState, action){
    switch (action.type) {
        case authActions.LOGOUT:
            return {...initState}
        case actions.NEW_NOTIFICATION:
            return{
                ...state,
                notificationCount: state.notificationCount + 1
            };
        case actions.GET_ALL_NOTIFICATION_SUCCESS:
            return{
                ...state,
                listNotification: action.payload
            }
        default:
            return state;
    }
}
