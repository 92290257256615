import React from "react";
import {ImgComponent} from "src/utils/utility";
import Icon from "../../../Icon/Sprite";
import {ICONS} from "src/assets/sprite/iconsNames";
import GreyLogo from "../../../../assets/logo/logo-VT-grey.svg";

function ListInputMobilePreference(props) {

    const {element} = props

    return(
        <div className="width-100">
            <div className="flex-between">
                <div className="flex-column">
                    <div className="blue underlined weight-700 size-16">{element.title}</div>
                    <div className="opacity-50 underlined weight-400 size-12">
                        {element.company && element.company.companyName}
                    </div>
                    <div className="flex-row dividerDot">
                        {element.insertMode &&
                        <div className="flex-center">
                            {element.insertMode}
                        </div>
                        }
                        {element.workPlace &&
                        <div className="flex-center">
                            {element.workPlace}
                        </div>
                        }
                    </div>
                    <div className="flex-row dividerDot">
                        {element.location && element.location &&
                        <div className="flex-center">
                            {element.location.city}
                        </div>
                        }
                        {element.impegnoRichiesto &&
                        <div className="flex-center">
                            {element.impegnoRichiesto}
                        </div>
                        }
                    </div>
                    <div>
                        <Icon color="grey" className="margin-left-5" name={ICONS.user_ok} size={[20, 20]}/>
                        <span className="margin-left-5">
                            {element.candidateCount}
                        </span>
                    </div>
                </div>
                <div style={{height: "55px"}}>
                    {!element.anonymous ?
                        <ImgComponent logoPath={element.company && element.company.logoPath}/>
                        :
                        <div className="noLogo">
                            <img src={GreyLogo}/>
                        </div>
                    }
                </div>
            </div>
        </div>


    )
}

export default ListInputMobilePreference