import React from "react";
import sprite from "src/assets/sprite/sprite.svg"

export default function Icon(props) {

    return(
        <svg className={props.className} width={props.size[0]} height={props.size[1]} color={props.color}>
            <use href={sprite + "#" + props.name}/>
        </svg>
    )
}