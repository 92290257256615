import "./OfferElementMobile.scss"
import Icon from "../../Icon/Sprite";
import {ICONS} from "../../../assets/sprite/iconsNames";
import {substituteZeroWithLine} from "../../../utils/utility";
import {getOfferDate} from "../../../helpers/utility";
import {List} from 'antd';


function OfferElementMobile({offer, selected, onClick, candidate}) {

    return (
        <List.Item key={offer.id} onClick={onClick} className={`om-list-item ${selected && "selected"}`}>
            <div className={`om`}>
                {offer && <>
                    <div className={"om-left-section"}>
                        <p className={"om-title"}>{offer.title}</p>
                        <p className={"om-location-name"}>{offer.location?.name}</p>
                        <div className={"om-info-section"}>
                            {(offer.insertMode || offer.workPlace) && <p className={"om-info-line"}>
                                {offer.insertMode && <span className={"om-info"}>{offer.insertMode}</span>}
                                {offer.workPlace && <span className={"om-info"}>{offer.workPlace}</span>}
                            </p>}
                            {(offer.location?.city || offer.impegnoRichiesto) && <p className={"om-info-line"}>
                                {offer.location.city && <span className={"om-info"}>{offer.location.city}</span>}
                                {offer.impegnoRichiesto && <span className={"om-info"}>{offer.impegnoRichiesto}</span>}
                            </p>}
                        </div>
                        <div className="flex-start">
                            <Icon color="grey" name={ICONS.user_ok}
                                  size={[20, 20]}/>
                            {substituteZeroWithLine(offer.candidateCount)}
                        </div>

                    </div>
                    <div className={"om-right-section"}>
                        <p className={"om-date"}>{getOfferDate(offer.dateCreated)}</p>
                        {candidate && <p className={"om-candidate"}>Candidato</p>}
                    </div>
                    <Icon name={ICONS.arrow} size={[20, 20]} className="om-arrow"/>
                </>}
            </div>
        </List.Item>
    )
}

export default OfferElementMobile;