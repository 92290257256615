import actions from './actions'
import authActions from "../auth/actions";

const initState = {
    locations: null,
    province: null,
    city: null,
    location: null,
    companyLocation: null,
    companyLocationMain: null,
    updateList: false,
    updated: false,
    isButtonLoading: false,
    companyLocations: null,
    companyLocationsBySysAdmin: null
}

export default function locationReducer(state = initState, action){
    switch (action.type) {
        case actions.INIT_LOCATION:
            return{
                ...initState
            };
        case authActions.LOGOUT:
            return {...initState}
        case actions.GET_ALL_LOCATION_SUCCESS:
            return{
                ...state,
                locations: action.payload.elements,
            };
        case actions.GET_ALL_PROVINCE_SUCCESS:
            return{
                ...state,
                province: action.payload.elements,
                updateList: false
            };
        case actions.GET_CITY_BY_PROVINCE_SUCCESS:
            return{
                ...state,
                city: action.payload.elements,
                updateList: false
            };
        case actions.GET_LOCATION_BY_ID_SUCCESS:
            return{
                ...state,
                location: {
                    id: action.payload.id,
                    value: action.payload.city,
                    label: action.payload.city
                },
            };
        case actions.RESET_LOCATION:
            return {
                ...state,
                location: initState.location
            }
        case actions.GET_ALL_COMPANY_LOCATIONS_SUCCESS:
            return {
                ...state,
                companyLocations: action.payload
            };
        case actions.GET_ALL_COMPANY_LOCATIONS_BY_SYS_ADMIN_SUCCESS:
            return {
                ...state,
                companyLocationsBySysAdmin: action.payload
            };
        case actions.GET_BY_ID_COMPANY_LOCATION_SUCCESS:
            return{
                ...state,
                companyLocation: action.payload
            }
        case actions.SAVE_LOCATION_SUCCESS:
            return {
                ...state,
                updateList: true,
                updated: true,
            }
        case actions.UPDATE_LOCATION_SUCCESS:
            return {
                ...state,
                location: action.payload.value,
                updateList: true,
                updated: true,
            }
        case actions.DELETE_LOCATION_SUCCESS:
            return {
                ...state,
                updateList: true
            }
        case actions.SAVE_COMPANY_LOCATION:
            return {
                ...state,
                isButtonLoading: true,
            }
        case actions.SAVE_COMPANY_LOCATION_SUCCESS:
            return {
                ...state,
                isButtonLoading: false,
            }
        case actions.UPDATE_COMPANY_LOCATION:
            return {
                ...state,
                isButtonLoading: true,
            }
        case actions.UPDATE_COMPANY_LOCATION_SUCCESS:
            return {
                ...state,
                isButtonLoading: false,
            }
        case action.UPDATE_COMPANY_LOCATION_ERROR:
            return {
                ...state,
                isButtonLoading: false,
            }
        case actions.CLOSE_DRAWER :
        default:
            return state;
    }
}
