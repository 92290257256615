import React from "react";
import styled from "styled-components"


const CsvWrapper = styled.div`
  
  button{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    padding: 14px 16px;
    border: none;
    color: gray;
    background: none;
    font-weight: bold;
    cursor: pointer;
    
    :hover{
      color: #0E0E2C;
    }
  }
`

export function CsvButton(props){
    const {icon, children, onClick} = props

    return(
        <CsvWrapper>
            <button onClick={onClick}>
                {icon}
                {children}
            </button>
        </CsvWrapper>
    )
}