import actions from './actions'
import locationActions from '../location/actions'
import employeeActions from '../employee/actions'
import authActions from "../auth/actions";

const initState = {
    companies: null,
    company: null,
    updateList: false,
    updated: false,
    registered: false,
    confirm: false,
    companyDashboard: {},
    logo: null,
    cv: null,
    offertToModify: null,  //stato se l'offerta è da creare o modificare
    fileBlob: null,
    fileType: null,
    fileName: null,
    record: null,
    isCompanyUpdatedSuccess: false,
    isEmployeeCreatedSuccess: false,
    isLocationCreatedSuccess: false,
    isButtonLoading: false,
    isAccountEditSuccess: false,
    modalEditEmail: null,
    isRegistrationButtonLoading: false,
    workSector: null
}

export default function companyReducer(state = initState, action) {
    switch (action.type) {
        case actions.INIT_COMPANY:
            return {
                ...initState
            };
        case authActions.LOGOUT:
            return {...initState}
        case actions.GET_ALL_COMPANY_SUCCESS:
            return {
                ...state,
                companies: action.payload.value
            };
        case actions.GET_COMPANY_BY_ID_SUCCESS:
            return {
                ...state,
                company: action.payload,
                updated: false,
                isCompanyUpdatedSuccess: false,
                isLocationCreatedSuccess: false,
                isEmployeeCreatedSuccess: false,
            };
        case actions.SAVE_COMPANY:
            return {
                ...state,
                isRegistrationButtonLoading: true,
            }
        case actions.SAVE_COMPANY_SUCCESS:
            return {
                ...state,
                updateList: true,
                updated: true,
                confirm: true,
                isCreatedSuccess: true,
                isRegistrationButtonLoading: false
            }
        case actions.SAVE_COMPANY_ERROR:
            return {
                ...state,
                isCreatedSuccess: true,
                isRegistrationButtonLoading: false
            }
        case actions.GET_COMPANY_DASHBOARD_SUCCESS:
            return {
                ...state,
                companyDashboard: action.payload,
                isEnabled: action.payload.enabled,
                isCompleted: action.payload.isCompleted
            }
        case actions.UPDATE_COMPANY:
            return {
                ...state,
                isButtonLoading: true
            }
        case actions.UPDATE_COMPANY_SUCCESS:
            return {
                ...state,
                company: action.payload,
                updateList: true,
                updated: true,
                isCompanyUpdatedSuccess: true,
                isButtonLoading: false,
            }
        case actions.RESET_REGISTRATION_CONFIRM:
            return {
                ...state,
                confirm: false
            };
        case locationActions.SAVE_COMPANY_LOCATION_SUCCESS:
            return {
                ...state,
                company: action.payload,
                isLocationCreatedSuccess: true,
            };
        case employeeActions.SAVE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                company: action.payload,
                isEmployeeCreatedSuccess: true,
            };
        case actions.GET_COMPANY_LOGO_SUCCESS:
            return {
                ...state,
                logo: action.payload,
                fileBlob: action.fileBlob,
                fileName: action.fileName,
                fileType: action.fileType
            }
        case locationActions.UPDATE_COMPANY_LOCATION_SUCCESS:
            return {
                ...state,
                company: action.payload
            }
        case actions.CLOSE_ACTIVATION_MESSAGE_SUCCESS:
            return {
                ...state,
                companyDashboard: {...state.companyDashboard, showActivationMessage: false}
            }
        case actions.OFFERT_MODIFY: 
            return {
                ...state,
                offertToModify: true
            }
        case actions.OFFERT_CREATE:
            return {
                ...state,
                offertToModify: false
            }
        case actions.SET_OFFERT_RECORD:
            return {
                ...state,
                record: action.payload
            }
        case actions.GET_CV_SOURCE_DETAIL_USER_SUCCESS:
            return {
                ...state,
                cv: action.payload,
                fileBlob: action.fileBlob,
                fileName: action.fileName,
                fileType: action.fileType
            }
        case locationActions.DELETE_LOCATION_SUCCESS:
            return {
                ...state,
                company: action.payload,
            }
        case employeeActions.UPDATE_EMPLOYEE:
            return {
                ...state,
                isAccountEditSuccess: false
            }
        case employeeActions.UPDATE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                company: action.payload,
                isAccountEditSuccess: true
            }
        case employeeActions.DELETE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                company: action.payload
            }
        case actions.GET_WORK_SECTOR_SUCCESS:
            return {
                ...state,
                workSector: action.payload
            }
        case actions.EDIT_MESSAGE_EMAIL_SUCCESS:
        case actions.MODAL_EDIT_MESSAGE_EMAIL_CLOSE:
            return {
                ...state,
                modalEditEmail: false
            }
        case actions.MODAL_EDIT_MESSAGE_EMAIL_OPEN:
            return {
                ...state,
                modalEditEmail: true
            }

        default:
            return state;
    }
}
