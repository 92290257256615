import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "src/redux/drawer/actions"
import candidatureActions from "src/redux/candidature/actions"
import adminActions from "src/redux/admin/actions"
import "src/component/Layout/Drawer/CustomDrawerStyle.scss";
import { Col, Drawer, Row, Space, Button } from "antd";
import { ICONS } from "src/assets/sprite/iconsNames";
import { OfferContent } from "src/component/Layout/Drawer/Offer/OfferContent"
import { CompanyContent } from "src/component/Layout/Drawer/Company/CompanyContent"
import { OffertUserContent } from "src/component/Layout/Drawer/OfferUser/OffertUserContent"
import Icon from "src/component/Icon/Sprite";
import {
    DRAWER_COMPANY_ITEM,
    DRAWER_OFFER_ITEM,
    ALERT_TYPE,
    OFFER_STATE,
    DRAWER_OFFER_USER_ITEM,
    DRAWER_TYPE,
    ROLES,
    useWindowSize
} from "src/utils/utility";
import { ButtonUser } from "../../Useful/Button/ButtonUser";
import { Select } from "../../Useful/Select/Select"
import { FormattedMessage } from "react-intl";
import { AlertModal } from "src/component/Layout/AlertModal/AlertModal";
import { QuestionsUser } from "src/component/Layout/Drawer/OfferUser/QuestionUser/QuestionsUser";
import { ConfirmOfferUser } from "./OfferUser/ConfirmOfferUser";
import { ButtonGhost } from "../../Useful/Button/ButtonGhost";
import Content from "src/component/Layout/PopoverContent/content"
import { PRIVATE_ADMIN_ROUTE } from "src/route.constants";
import { useNavigate } from "react-router";

export function CustomDrawer(props) {

    const { type, setType, company, state, companyEditAccount, userEditAccount } = props

    let dispatch = useDispatch()
    const navigate = useNavigate()

    const { closeDrawer } = actions;
    const { confirmCandidature } = candidatureActions;
    const { closeModal } = adminActions;

    const isDrawerOpen = useSelector(state => state.DrawerReducer.isDrawerOpen)
    const offertToModify = useSelector(state => state.Company.offertToModify)
    const isButtonLoading = useSelector(state => state.Company.isButtonLoading)
    const changeCustomDrawerProfile = useSelector(state => state.UserProfile.changeCustomDrawer)
    const recordOffer = useSelector(state => state.UserProfile.recordOffer)
    const changeValue = useSelector(state => state.DrawerReducer.changeValue)
    const open = useSelector(state => state.Admin.open)
    const role = useSelector(state => state.AuthReducer.role)

    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [offertState, setOffertState] = useState(OFFER_STATE.toPublished.value)

    const size = useWindowSize();

    function dataToLoad(type) {
        switch (type) {
            case DRAWER_TYPE.offer:
                return DRAWER_OFFER_ITEM
            case DRAWER_TYPE.company:
                return DRAWER_COMPANY_ITEM
            case DRAWER_TYPE.firstStepCandidatureUser:
                return DRAWER_OFFER_USER_ITEM
            case DRAWER_TYPE.companyEditAccount:
                return DRAWER_OFFER_USER_ITEM
            default:
                return []
        }
    }

    function checkTypeDrawer(type) {
        switch (type) {
            case DRAWER_TYPE.offer:
                let openStatus = localStorage.getItem("openStatus")
                let openStatusCompany = localStorage.getItem("openStatusCompany")
                if (openStatus || openStatusCompany) {
                    return "Offerta da rivedere - CompanyTest, SRL"
                } else {
                    return "Nuova offerta"
                }
            case DRAWER_TYPE.company:
                return "Dati aziendali"
            case DRAWER_TYPE.firstStepCandidatureUser:
                if (userEditAccount) {
                    return "Profilo professionale"
                }
            case DRAWER_TYPE.secondStepCandidatureUser:
                if (recordOffer && recordOffer.title)
                    return recordOffer.title
            case DRAWER_TYPE.confirmOfferUser:
                return "Ti sei candidato con successo!"
        }
    }

    function checkTypeButton(type) {
        switch (type) {
            case DRAWER_TYPE.offer:
                return "offerForm"
            case DRAWER_TYPE.company:
                return "companyForm"
            case DRAWER_TYPE.firstStepCandidatureUser:
                return "firstStepCandidatureUserForm"
            case DRAWER_TYPE.secondStepCandidatureUser:
                return "secondStepCandidatureUserForm"
            case DRAWER_TYPE.confirmOfferUser:
                return "confirmOfferUser"
        }
    }

    function checkTypeShowComponent(type) {
        switch (type) { 
            case DRAWER_TYPE.offer:
                return <OfferContent offertState={offertState} resetOffertState={resetOffertState} record={props.record} offertToModify={offertToModify} state={state} companyLocation={props.sede} disabledLocation={props.disabledLocation} />
            case DRAWER_TYPE.company:
                return <CompanyContent company={company} companyEditAccount={companyEditAccount} />
            case DRAWER_TYPE.firstStepCandidatureUser:
                return <OffertUserContent userProfile={props.userProfile} userEditAccount={props.userEditAccount} />
            case DRAWER_TYPE.secondStepCandidatureUser:
                return <QuestionsUser />
            case DRAWER_TYPE.confirmOfferUser:
                return <ConfirmOfferUser />
        }
    }

    function resetOffertState() {
        setOffertState(OFFER_STATE.draft.value)
    }

    function checkTypeForTest(type, offertState, confirm = null) {
        switch (type) {
            case DRAWER_TYPE.firstStepCandidatureUser:
                if (userEditAccount) {
                    return "Salva e Chiudi"
                } else {
                    return "Salva e continua"
                }
            //qui c'è il pulsante candidati
            case DRAWER_TYPE.secondStepCandidatureUser:
                return "Candidati"
            case DRAWER_TYPE.confirmOfferUser:
                return "Chiudi"
            case DRAWER_TYPE.offer:
                if (OFFER_STATE.toPublished.label === offertState) {
                    return "Inviare per l'approvazione"
                }
            case DRAWER_TYPE.company:
                return "Salva"
            default:
                return "Approva"
        }
    }

    function checkOpenState() {
        if ((localStorage.getItem("openStatus") === 'true') || (localStorage.getItem("openStatusCompany") === 'true')) {
            return false
        } else if ((localStorage.getItem("openStatus") === null) || (localStorage.getItem("openStatusCompany") === null)) {
            return true
        } else {
            return true
        }
    }

    useEffect(() => {
        if (props.record && offertToModify) {
            setOffertState(props.record.state)
        }
    }, [props.record])

    useEffect(() => {
        return () => {
            dispatch(closeDrawer())
        }
    }, [])

    useEffect(() => {
        setOffertState(offertToModify && props.record && props.record.state ? props.record.state : OFFER_STATE.toPublished.value)
    }, [isDrawerOpen])

    useEffect(() => {
        if (changeCustomDrawerProfile) {
            setType(changeCustomDrawerProfile)
        }
    }, [changeCustomDrawerProfile])

    return (
        <Drawer
            className="customDrawer"
            title={(size.width > 800) ? checkTypeDrawer(type) : null}
            placement="bottom"
            height={"100%"}
            closeIcon={
                <div onClick={() => {
                    if (changeValue) {
                        setIsAlertOpen(true)
                    } else {
                        if (open) {
                            dispatch(closeModal())
                            dispatch(closeDrawer())
                            navigate(PRIVATE_ADMIN_ROUTE.DASHBOARD)
                        } else {
                            dispatch(closeModal())
                            dispatch(closeDrawer())
                        }
                    }
                }}>
                    <Icon className="margin-right-22 icon" name={ICONS.close} size={[20, 20]} color={"grey"} />
                </div>
            }
            open={isDrawerOpen}
            destroyOnClose={true}
            extra={
                <Space>
                    {(type === DRAWER_TYPE.offer && checkOpenState()) ?
                        <div className={"flex-align-center"}>
                            <span style={{ paddingRight: 20 }}>
                                <Icon name={ICONS.eye} size={[20, 20]} className="grey pointer" />
                            </span>
                            <Content />
                            {role !== ROLES.SYS_ADMIN &&
                                <span style={{ paddingRight: 20 }}>
                                    <Select
                                        height={"40px"}
                                        suffixIcon={<Icon name={ICONS.arrow} color="#208AC6" size={[20, 20]} />}
                                        className="drawer-select"
                                        value={offertState}
                                        onChange={(value) => setOffertState(value)}
                                        options={[
                                            { label: OFFER_STATE.draft.label, value: OFFER_STATE.draft.value },
                                            { label: OFFER_STATE.toPublished.label, value: OFFER_STATE.toPublished.value }
                                        ]}
                                    />
                                </span>
                            }
                        </div> : null}
                    {type !== DRAWER_TYPE.confirmOfferUser ?
                        (<div>
                            <ButtonUser
                                style={{ width: "93", height: "40px", minWidth: "0", display: "flex", alignItems: "center" }}
                                className="flex" type="primary" htmlType="submit" form={checkTypeButton(type)}
                                loading={isButtonLoading}>
                                <Icon name={ICONS.tik} size={[20, 20]} color={"#FFFFFF"} />
                                <span>{checkTypeForTest(type, offertState)}</span>
                            </ButtonUser>
                        </div>)
                        :
                        <ButtonGhost
                            style={{ width: "93", height: "40px", minWidth: "0", display: "flex", alignItems: "center" }}
                            className="flex"
                            htmlType="submit"
                            loading={isButtonLoading}
                            onClick={() => {
                                dispatch(confirmCandidature())
                                dispatch(closeDrawer())
                            }}
                        >
                            <Icon name={ICONS.close} size={[20, 20]} color={"#208AC6"} />
                            <span>{checkTypeForTest(type, offertState)}</span>
                        </ButtonGhost>
                    }
                </Space>
            }
        >
            <div className="max-width-1200 bodyPadding">
                <Row className="flex-justify-center">
                    {!companyEditAccount && (size.width > 800) &&
                        <Col span={8}>
                            <Row gutter={[0, 16]} className="fixed">
                                {dataToLoad(type).map((element) => (
                                    <Col span={24}>
                                        <a className="pointer menuItem" href={"#" + element.id}>
                                            {element.label}
                                        </a>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    }
                    {type === DRAWER_TYPE.secondStepCandidatureUser || type === DRAWER_TYPE.confirmOfferUser || (size.width < 800) ?
                        <Col span={24}>
                            {checkTypeShowComponent(type)}
                        </Col> :
                        <Col span={16}>
                            {checkTypeShowComponent(type)}
                        </Col>}
                </Row>
            </div>
            <AlertModal
                type={ALERT_TYPE.WARNING}
                content={"Perderai tutti i dati non salvati. Sei sicuro di procedere?"}
                okText={<FormattedMessage id="Alert_proceed" />}
                onOk={() => {
                    setIsAlertOpen(false)
                    dispatch(closeDrawer())
                }}
                onClose={() => {
                    setIsAlertOpen(false)
                }}
                isAlertOpen={isAlertOpen}
            />
        </Drawer>
    )
}
