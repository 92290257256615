import {combineReducers} from "redux";
import AuthReducer from "./auth/reducer";
import Account from "./account/reducer"
import Candidature from "./candidature/reducer";
import Company from "./company/reducer"
import Employee from "./employee/reducer"
import Location from "./location/reducer"
import UserProfile from "./userProfile/reducer"
import Offer from "./offer/reducer"
import Preference from "./preference/reducer"
import Notification from "./notification/reducer"
import Email from "./email/reducer";
import DrawerReducer from "./drawer/reducer";
import Admin from "./admin/reducer";
import Settings from "./settings/reducer";
import Landing from "./landing/reducer";


export default combineReducers({
    AuthReducer,
    Account,
    Candidature,
    Company,
    Employee,
    Location,
    UserProfile,
    Offer,
    Preference,
    Email,
    Notification,
    DrawerReducer,
    Admin,
    Settings,
    Landing
})
