import React, {useEffect, useState} from "react";
import {Breadcrumb, Col, Divider, message, Row} from "antd";
import "src/Pages/myCompany/MyCompany.scss";
import {FormattedMessage} from "react-intl";
import Card from "antd/es/card";
import {ICONS} from "src/assets/sprite/iconsNames";
import Icon from "../../component/Icon/Sprite";
import {PRIVATE_COMPANY_ROUTE} from "src/route.constants";
import {useNavigate} from "react-router";
import CardCreatedCompany from "src/component/Layout/MyCompanyElement/CardCreatedCompany/CardCreatedCompany";
import CardNewCompany from "src/component/Layout/MyCompanyElement/CardNewCompany/CardNewCompany";
import {CustomDrawer} from "src/component/Layout/Drawer/CustomDrawer";
import {DRAWER_TYPE} from "src/utils/utility";
import {useDispatch, useSelector} from "react-redux";
import actions from "src/redux/company/actions";
import drawerActions from "src/redux/drawer/actions"
import {COMPANY_MODAL_TYPE, firstLetters} from "src/utils/utility";
import ModalEmployee from "src/component/Layout/Modal/ModalEmployee/ModalEmployee";
import { ButtonLine } from "src/component/Useful/Button/ButtonLine";

function MyCompany() {

    const {getCompanyById, getImageSource, getAll} = actions;
    const {openDrawer} = drawerActions;

    const navigate = useNavigate()
    let dispatch = useDispatch()
    const [messageApi, contextHolder] = message.useMessage();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [openModalEmployee, setOpenModalEmployee] = useState(false)
    const [modalType, setModalType] = useState(null)
    const [editEmployee, setEditEmployee] = useState(null)
    const [changeType, setChangeType] = useState(null)

    const loading = useSelector(state => state.AuthReducer.loading)
    const company = useSelector(state => state.Company.company)
    const updated = useSelector(state => state.Company.updated)
    const isCompanyUpdatedSuccess = useSelector(state => state.Company.isCompanyUpdatedSuccess)
    const isEmployeeCreatedSuccess = useSelector(state => state.Company.isEmployeeCreatedSuccess)
    const isLocationCreatedSuccess = useSelector(state => state.Company.isLocationCreatedSuccess)
    const logo = useSelector(state => state.Company.logo)

    const [location, setLocation] = useState(null)

    useEffect(() => {
        if (updated) {
            dispatch(getCompanyById(window.localStorage.getItem('companyId')))
        }
    }, [updated])

    useEffect(() => {
        if (isEmployeeCreatedSuccess) {
            messageApi.open({
                type: 'success',
                content: 'Responsabile aggiornato con successo!'
            })
        }
    }, [isEmployeeCreatedSuccess])

    useEffect(() => {
        if (isLocationCreatedSuccess) {
            messageApi.open({
                type: 'success',
                content: 'Sede aggiornata con successo!'
            })
        }
    }, [isLocationCreatedSuccess])

    useEffect(() => {
        if (isCompanyUpdatedSuccess) {
            messageApi.open({
                type: 'success',
                content: 'Azienda aggiornata con successo!'
            })
        }
    }, [isCompanyUpdatedSuccess])

    useEffect(() => {
        if (company) {
            company.companyLocations.forEach((it) => {
                if (it.mainLocation) {
                    setLocation(it)
                }
            })

            if (company.logoPath) {
                dispatch(getImageSource({name: company.logoPath}))
            }
        }
    }, [company])

    useEffect(() => {
        dispatch(getCompanyById(window.localStorage.getItem('companyId')))
    }, [])

    return (
        <>
            <div>
                <div className="myCompany">
                    {contextHolder}

                    <div className="max-width-1200">
                        <Breadcrumb className="customBreadcrumb" separator="›">
                            <Breadcrumb.Item className="underlined pointer" onClick={() => navigate(PRIVATE_COMPANY_ROUTE.DASHBOARD_COMPANY)}>
                                <FormattedMessage id="Breadcrumb_home"/>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <FormattedMessage id="Breadcrumb_myCompany"/>
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="flex-center-between margin-bottom-24">
                            <div>
                                <span className="size-28 weight-700 merriWeather" style={{marginRight: 20}}>
                                    {company && company.companyName}
                                </span>
                                <span className="pointer" onClick={() => {
                                    setChangeType(DRAWER_TYPE.company)
                                    dispatch(openDrawer())
                                }}>
                                    <Icon name={ICONS.edit} className="icon" size={[20, 20]} color="#9595a2"/>
                                </span>
                            </div>
                            <div className="flex-center">
                                {/*{company && company.isCompleted ?*/}
                                {/*    <div className="flex-center">*/}
                                {/*        <ButtonLine style={{height: "40px"}} className="flex-center">*/}
                                {/*            <Icon name={ICONS.eye} size={[20, 20]} color={"#208AC6"} className={"pointer"}/>*/}
                                {/*            <span className="margin-5 weight-700 size-14 blue">*/}
                                {/*                <FormattedMessage id="OffersDetail_seeOnlineOffer"/>*/}
                                {/*            </span>*/}
                                {/*        </ButtonLine>*/}
                                {/*    </div> */}
                                {/*    : */}
                                {/*    null*/}
                                {/*}*/}
                            </div>
                        </div>
                        <Row gutter={40}>
                            <Col span={8}>
                                <Card className="border-grey border-radius-0">
                                    <div className="flex-column flex-center">
                                        <div className="border-grey border-radius-25" style={{width: 56, height: 9}}/>
                                        {company && company.isCompleted ? <div/> :
                                            <div className="margin-top-20 bg-grey" style={{width: 140, height: 60}}>
                                                <div className="flex-center weight-700 size-14 opacity-50" style={{lineHeight: "60px"}}>No foto</div>
                                            </div>}
                                    </div>
                                    {company && company.isCompleted ?
                                        <div>
                                            <div className="flex-center">
                                                <div className="flex-column">
                                                    <div className={"flex-center"}>
                                                        {logo &&
                                                        <img src={logo} className={"image-cover"}/>
                                                        }
                                                    </div>
                                                    <p className="flex-justify-center weight-700 size-22 margin-0 margin-top-10">{company.companyName}</p>
                                                    <p className="flex-justify-center weight-400 size-14 italic opacity-50 margin-0">{company.workSector ? company.workSector : "-"}</p>
                                                    <p className="flex-justify-center weight-700 size-12 green margin-0">SEDE PRINCIPALE</p>
                                                    <p className="flex-justify-center weight-400 size-14 margin-0">{location && location.city ? location.city : "-"}</p>
                                                </div>
                                            </div>
                                            <div className="flex-center margin-top-10">
                                                <Icon name={ICONS.business} size={[20, 20]} className="opacity-50"/>
                                                <span className="padding-left-5">{company && company.companyLocations && company.companyLocations.length}</span>
                                                <span className="padding-left-5">Sedi</span>
                                            </div>
                                        </div> : null}
                                    <Divider/>
                                    <div>
                                        <p className="weight-600 size-12 opacity-50">GENERALE</p>
                                        {company && company.isCompleted ?
                                            <div className="size-14">
                                                <span className="weight-600 colon">Sito web</span>
                                                <span>
                                                    {company.webPage}
                                                </span>
                                            </div>
                                            :
                                            null
                                        }
                                        <div className="flex-align-center weight-600 size-14">
                                            <span className="colon">
                                                <FormattedMessage className="colon" id="state"/>
                                            </span>
                                            <p className={company && company.isCompleted ? "green" : "orange"} style={{margin: 3}}>
                                                {company && company.isCompleted ? "Attivo" : "Disattivo"}
                                            </p>
                                        </div>
                                        <div className="flex-align-center ">
                                            <span className="colon weight-600 size-14">
                                                <FormattedMessage className="colon" id="MyCompany_responsible"/>
                                            </span>
                                            <p style={{margin: 3}}>
                                                {(company && company.employees && company.employees.length) ? company.employees.length + 1 : "1"}
                                            </p>
                                        </div>
                                        <br/>
                                    </div>
                                    <Divider className="margin-5"/>
                                    <div className="margin-top-20">
                                        <p className="weight-600 size-12 opacity-50">RESPONSABILI</p>

                                        {company &&
                                        <div className="flex-row flex-between margin-5">
                                            <div className="flex-center flex-between">
                                                <div className={`margin-right-5 weight-700 padding-4 size-14 ${company.companyAdmin.isActive ? "isEmployeeActive" : null}`}>
                                                    {firstLetters(company.companyAdmin.firstName, company.companyAdmin.lastName)}
                                                </div>
                                                <div className="weight-400 size-14">
                                                    {company.companyAdmin.firstName + " " + company.companyAdmin.lastName}
                                                </div>
                                                {!company.companyAdmin.isActive &&
                                                <span style={{whiteSpace: "nowrap", paddingLeft: 10}}>
                                                    (<FormattedMessage id="CompanyDetails_employeeNotActive"/>)
                                                </span>
                                                }
                                            </div>
                                            <div className="flex-center weight-600 size-12 opacity-50">
                                                <FormattedMessage id="CompanyDetails_owner"/>
                                            </div>
                                        </div>
                                        }

                                        {company && company.employees && company.employees.map((element) => (
                                            <div className="flex-row flex-between margin-5">
                                                <div className="flex-center flex-between">
                                                    <div className={`margin-right-5 weight-700 padding-4 size-14 ${element.isActive ? "isEmployeeActive" : "isEmployeeNotActive"}`}>
                                                        {firstLetters(element.firstName, element.lastName)}
                                                    </div>
                                                    <div className="weight-400 size-14">
                                                        {element.firstName + " " + element.lastName}
                                                    </div>
                                                    {!element.isActive &&
                                                    <span className="grey" style={{whiteSpace: "nowrap", paddingLeft: 10}}>
                                                        (<FormattedMessage id="CompanyDetails_employeeNotActive"/>)
                                                    </span>
                                                    }
                                                </div>
                                                <div className="flex-center">
                                                    <span className={"pointer"} onClick={() => {
                                                        setEditEmployee(element)
                                                        setOpenModalEmployee(true)
                                                        setModalType(COMPANY_MODAL_TYPE.EDIT)
                                                    }}>
                                                        <Icon name={ICONS.edit} className="icon" size={[20, 20]} color="#9595a2"/>
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Card>
                            </Col>
                            <Col span={16}>
                                {company && company.isCompleted ?
                                    <CardCreatedCompany stateView={company.isCompleted} company={company} changeType={changeType} setChangeType={setChangeType}/>
                                    :
                                    <CardNewCompany stateDrawer={setIsDrawerOpen} setChangeType={setChangeType}/>
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
                <ModalEmployee setOpenModalEmployee={setOpenModalEmployee} openModalEmployee={openModalEmployee} type={modalType} company={company} record={editEmployee}/>
                <CustomDrawer
                    type={changeType}
                    setChangeType={setChangeType }
                    company={changeType === DRAWER_TYPE.company ? company : ""}
                    record={company}
                />
            </div>
        </>
    )
}

export default MyCompany
