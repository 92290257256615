import React, {useEffect, useState} from "react";
import "src/component/Layout/Ellipsis/Ellipsis.scss"

export function Ellipsis(props) {

    const {text, maxLength, readMoreText, customClassName} = props
    const [isTruncated, setIsTruncated] = useState(false);
    const [truncatedText, setTruncatedText] = useState(false);

    function showMoreText() {
        setTruncatedText(text)
        setIsTruncated(false)
    }

    useEffect(() => {
        if (text.length > maxLength) {
            setTruncatedText(text.substring(0, maxLength))
            setIsTruncated(true)
        }
        else{
            setTruncatedText(text)
        }
    }, [])

    return (
        <span className={customClassName ? customClassName + " customEllipsis" : "customEllipsis"}>
            {truncatedText}
            {isTruncated && <span onClick={() => showMoreText()}>{readMoreText}</span>}
        </span>
    )
}