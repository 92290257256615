import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {endpoints} from "src/config/site.config";
import SuperFetch from "../../helpers/superFetch";
import actions from "./actions";
import authActions from "../auth/actions";

export function* getAllNotification() {

    yield takeEvery(actions.GET_ALL_NOTIFICATION, function* () {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllNotification, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ALL_NOTIFICATION_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_NOTIFICATION_ERROR,
                payload: error
            });
        }
    })

}
export function* setNotificationRead() {

    yield takeEvery(actions.SET_NOTIFICATION_READ, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.setNotificationRead, {ids: data.payload}, true)

        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ALL_NOTIFICATION
            });

            yield put({
                type: actions.SET_NOTIFICATION_READ_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SET_NOTIFICATION_READ_ERROR,
                payload: error
            });
        }
    })

}

export function* sendFcmToken() {
    yield takeEvery(actions.SEND_FCM_TOKEN, function* (data) {
        const {response, error} = yield call(SuperFetch.post, endpoints.sendFcmToken, {fcmToken: data.token}, true)
    })
}

export default function* rootSaga() {
    yield all([
        fork(getAllNotification),
        fork(setNotificationRead),
        fork(sendFcmToken),
    ]);
}
