export const PUBLIC_ROUTE ={
    LANDING: "/",
    USER_REGISTRATION: '/user/registration',
    COMPANY_REGISTRATION: "/company/registration",
    USER_LOG_IN: '/user/login',
    COMPANY_LOG_IN: '/company/login',
    USER_RECOVERY_EMAIL: '/user/recovery',
    USER_RECOVERY_PASSWORD: '/user/recovery/password',
    COMPANY_RECOVERY_EMAIL: '/company/recovery',
    COMPANY_RECOVERY_PASSWORD: '/company/recovery/password',
    CONFIRM_REGISTRATION: '/confirm',
    COMPANY_ACTIVATE_PROFILE: 'company/auth/activateProfile',
    USER_ACTIVATE_PROFILE: 'user/auth/activateProfile',
    CONFIRM_OFFER: "/offer/confirm",
    EDIT_OFFER: "/offer/edit"
}

export const PRIVATE_COMPANY_ROUTE = {
    DASHBOARD_COMPANY: "/company/dashboard",
    WORK_OFFERS: "/company/offers",
    WORK_OFFERS_DETAIL: "/company/offers/:id",
    MY_COMPANY: "/company/myCompany",
    MY_COMPANY_LOCATION: "/company/myCompany/location",
    SITE: "/company/myCompany/site",
    CANDIDATE: "/company/candidate",
    CANDIDATE_DETAIL: "/company/candidate/:id",
    NOTIFICATION: "/company/notification",
    ACCOUNT: "/company/account",
}

export const PRIVATE_USER_ROUTE = {
    DASHBOARD_USER: "/user/dashboard",
    JOB_OFFER: "/user/offer",
    MY_CANDIDATURE: "/user/myCandidature",
    NOTIFICATION: "/user/notification",
    ACCOUNT: "/user/account",
    FAVORITES: "/user/favorites",
    DETAIL_COMPANY: "/user/company/:idCompany"
}

export const PRIVATE_ADMIN_ROUTE = {
    DASHBOARD: "/admin/dashboard",
    COMPANIES: "/admin/companies",
    USERS: "/admin/users",
    USER_DETAIL: "/admin/users/:id",
    SETTINGS: "/admin/settings"
}
