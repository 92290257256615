import React from 'react';
import {FormattedMessage} from "react-intl";
import "./LandingOffer.scss"
import {Divider} from "antd";
import {ImgComponent} from "../../../utils/utility";
import GreyLogo from "../../../assets/logo/logo-VT-grey.svg";
import {ButtonUser} from "../../Useful/Button/ButtonUser";
import {useNavigate} from "react-router";
import {PUBLIC_ROUTE} from "../../../route.constants";
import EmptyOffers from "../OffersList/EmptyOffers";

function LandingOffer(props) {

    const {offer, totalOffer} = props

    const navigate = useNavigate()

    return (
        <div className={"landingOffer"}>
            <div className="title size-30 weight-700 merriWeather">
                <FormattedMessage id="topBar_workOffers"/>
            </div>

            {offer?.length > 0 ?
                <div className={"offers"}>
                    {offer.map((element, index) => {

                        const isLastOffer = index === offer.length - 1

                        return (
                            <div key={element.id}>
                                <div className={"flex-between offerCard"}>
                                    <div className={"card"}>
                                        <div style={!element.anonymous ? {width: "88px", height: "72px", marginRight: 20, border: "1px solid rgba(14, 14, 44, 0.15)"} : {width: "88px", height: "72px", marginRight: 20, border: "none"}}>
                                            {!element.anonymous ?
                                                <ImgComponent isPublic={true} logoPath={element.company && element.company.logoPath}/>
                                                :
                                                <div className="noLogo">
                                                    <img src={GreyLogo}/>
                                                </div>
                                            }
                                        </div>
                                        <div className="flex-column flex-between" style={{gap: "8px"}}>
                                            <div className="blue weight-700 size-16">{element.title}</div>
                                            <div className="opacity-50 weight-400 size-12" style={{width: "fit-content"}}>
                                                {element.anonymous ? "Azienda anonima" : element?.company?.companyName}
                                            </div>
                                            <div className="offerDescription dividerDot">
                                                <div className={"flex dividerDot"}>
                                                    {element.insertMode &&
                                                    <div className="flex-center">
                                                        {element.insertMode}
                                                    </div>
                                                    }
                                                    {element.workPlace &&
                                                    <div className="flex-center">
                                                        {element.workPlace}
                                                    </div>
                                                    }
                                                </div>

                                                <div className={"flex dividerDot"}>
                                                    {element.location && element.location.city &&
                                                    <div className="flex-center">
                                                        {element.location.city}
                                                    </div>
                                                    }
                                                    {element.impegnoRichiesto &&
                                                    <div className="flex-center">
                                                        {element.impegnoRichiesto}
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-center">
                                        <ButtonUser className={"candidate"} style={{minWidth: 100, height: 40}} onClick={() => navigate(PUBLIC_ROUTE.USER_LOG_IN)}>
                                            Candidati
                                        </ButtonUser>
                                    </div>
                                </div>

                                {!isLastOffer &&
                                <Divider className="divider" type={"horizontal"}/>
                                }
                            </div>
                        )
                    })}
                </div>
                :
                <EmptyOffers/>
            }
        </div>
    );
}

export default LandingOffer;