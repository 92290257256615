import React from "react";
import {Outlet} from "react-router-dom"
import {useSelector} from "react-redux";
import "../home/home.scss"
import Footerbar from "../../component/Layout/Footbar/footerbar";
import Topbar from "src/component/Layout/Topbar/adminTopbar";

export default function AdminHome() {

    const loading = useSelector(state => state.AuthReducer.loading)

    return (
        <div className={"content-container"}>
                <Topbar/>

                <div className={'content admin'}>
                    <Outlet/>
                </div>

                <Footerbar/>
        </div>
    )

}
