import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import UserPicture from "../../assets/image/PictureLoginUser.png"
import CompanyPicture from "../../assets/image/PictureLoginCompany.png"
import "./landing.scss"
import {Card} from "antd"
import {ButtonUser} from "src/component/Useful/Button/ButtonUser";
import {FormattedMessage, injectIntl} from "react-intl";
import {ButtonCompany} from "src/component/Useful/Button/ButtonCompany";
import LandingOffer from "../../component/Layout/LandingOffer/LandingOffer";
import {useDispatch, useSelector} from "react-redux";
import landingActions from "../../redux/landing/actions";
import {SORTING} from "../../utils/utility";
import {ButtonLine} from "../../component/Useful/Button/ButtonLine";

const {getAllLandingOffer, initLanding} = landingActions;


function Landing() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const offer = useSelector(state => state.Landing.offer)
    const totalOffer = useSelector(state => state.Landing.totalOffer)
    const loading = useSelector(state => state.Landing.loading)

    const [currentPage, setCurrentPage] = useState(1)

    const goTo = (param) => {
        if (param === "company") {
            navigate("/company/login")
        } else {
            navigate("/user/login")
        }
    }

    useEffect(() => {
        dispatch(getAllLandingOffer({page: currentPage, size: 10, sort: SORTING.DESC.value}))

        return () => {
            dispatch(initLanding())
        }
    }, [])


    return (
        <div className={'landing-container'}>
            <div className={"landing-content"}>
                <div className={"flex cards"} style={{gap: "40px"}}>
                    <div className={"flex"} style={{flex: "1 1 50%"}}>
                        {/*Card user*/}
                        <Card className={"card-content flex-center bgUser border-radius-0"}>
                            <div className={"flex-justify-center image"}>
                                <img src={UserPicture} className={"content-image"} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                            </div>
                            <div className={"flex-center cardText"}>
                                <p className={"header-text color-company"}><FormattedMessage id="Landing_cardUser_1"/></p>
                                <p className={"sub-text"}><FormattedMessage id="Landing_cardUser_2"/></p>
                                <div className={"button"}>
                                    <ButtonUser className="button-user" onClick={() => goTo("user")}><FormattedMessage id="button_select"/></ButtonUser>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className={"flex"} style={{flex: "1 1 50%"}}>
                        {/*Card company*/}
                        <Card className={"card-content bgCompany border-radius-0"}>
                            <div className={"flex-justify-center image"}>
                                <img src={CompanyPicture} className={"content-image"} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                            </div>
                            <div className={"flex-center cardText"}>
                                <p className={"header-text color-user"}><FormattedMessage className="size-32" id="Landing_cardCompany_1"/></p>
                                <p className={"sub-text"}><FormattedMessage id="Landing_cardCompany_2"/></p>
                                <div className={"button"}>
                                    <ButtonCompany className="button-company" onClick={() => goTo("company")}><FormattedMessage id="button_select"/></ButtonCompany>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>

                <LandingOffer offer={offer} totalOffer={totalOffer}/>

                {totalOffer > offer.length &&
                <div className={"flex-center"}>
                    <ButtonLine
                        loading={loading}
                        onClick={() => {
                            dispatch(getAllLandingOffer({page: currentPage + 1, size: 10, sort: SORTING.DESC.value}))
                            setCurrentPage(currentPage + 1)
                        }}>
                        <FormattedMessage id="seeAllOffers"/>
                    </ButtonLine>
                </div>
                }
            </div>
        </div>
    )

}

export default injectIntl(Landing)
