import React from "react";
import "./footbar.scss"
import {FormattedMessage, injectIntl} from "react-intl";
import logo from "src/assets/logo/VT-FooterLogo.svg"

function footerbar(props) {

    return (
        <div className={"footer"}>
            <div className={"content-footer weight-400 size-12"}>
                <a href={"https://www.vocationaltraining.it"} target={"_blank"}>
                    <img src={logo} className={"pointer"}/>
                </a>
                <div className="h1 margin-left-20"><FormattedMessage id="vocational"/></div>
                <div className="opacity-50 margin-left-5 p">
                    <FormattedMessage id="footer_PIVA"/>
                    <FormattedMessage id="footer_version"/> 1.2.0
                </div>

            </div>
        </div>
    )
}

export default injectIntl(footerbar)
