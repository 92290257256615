import React, {useEffect, useState} from "react";
import {Button, Card, Divider, List} from "antd";
import {ImgComponent, substituteZeroWithLine} from "../../../utils/utility";
import Icon from "../../Icon/Sprite";
import {ICONS} from "../../../assets/sprite/iconsNames";
import {useDispatch, useSelector} from "react-redux";
import preferenceActions from "src/redux/preference/actions"
import {useNavigate} from "react-router";
import GreyLogo from "../../../assets/logo/logo-VT-grey.svg";

function ListMyPreference(props) {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {myPreferences, recordOffer, setIdRecord, idRecord, viewComponent, setViewComponent} = props

    const {deletePreference} = preferenceActions;

    const [stateStyle, setStateStyle] = useState("width-100")

    const closeViewComponent = useSelector(state => state.Preference.closeViewComponent)

    function openDetailCard() {
        setStateStyle("ease-list-in width-60")
        setTimeout(() => {
            setViewComponent(true)
        }, 950)
    }

    function closeDetailCard() {
        setViewComponent(false)
        setStateStyle("ease-list-out width-100")
    }

    useEffect(() => {
        if (closeViewComponent) {
            setViewComponent(false)
            setStateStyle("width-100")
        }
    }, [closeViewComponent])

    function setField(record) {
        let path = "/user/company/"
        if (record.id) path = path + record.company.id
        navigate(path)
    }

    return (
        <div className={"flex-row"}>
            <div className={stateStyle}>
                <List bordered onClick={openDetailCard} dataSource={myPreferences} className={"pointer"}>
                    {myPreferences && myPreferences.map((element) => {
                        return (
                            <List.Item key={element.id} onClick={() => {
                                setIdRecord(element.id)
                            }}
                                       className={element.id === idRecord && viewComponent === true ? "color-light-blue" : "white"}>
                                <div className="flex-row margin-right-20">
                                    <div style={!element.anonymous ? {width: "88px", height: "72px", marginRight: 20, border: "1px solid rgba(14, 14, 44, 0.15)"} : {width: "88px", height: "72px", marginRight: 20, border: "none"}}>
                                        {!element.anonymous ?
                                            <ImgComponent logoPath={element.company && element.company.logoPath}/>
                                            :
                                            <div className="noLogo">
                                                <img src={GreyLogo}/>
                                            </div>
                                        }
                                    </div>
                                    <div className="flex-column">
                                        <div className="blue underlined weight-700 size-16">{element.title}</div>
                                        <div className="opacity-50 underlined weight-400 size-12">
                                            {element.company && element.company.companyName}
                                        </div>
                                        <div className="flex-row dividerDot">
                                            {element.insertMode &&
                                            <div className="flex-center">
                                                {element.insertMode}
                                            </div>
                                            }
                                            {element.workPlace &&
                                            <div className="flex-center">
                                                {element.workPlace}
                                            </div>
                                            }
                                            {element.location &&
                                            <div className="flex-center">
                                                {element.location.city}
                                            </div>
                                            }
                                            {element.impegnoRichiesto &&
                                            <div className="flex-center">
                                                {element.impegnoRichiesto}
                                            </div>
                                            }
                                            {
                                                <div className="flex-center">
                                                    <Icon color="grey" name={ICONS.user_ok} size={[20, 20]}/>
                                                    <span className="margin-left-5">
                                                        {element.candidateCount}
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div style={{transform: "rotate(-90deg)"}}>
                                        <Icon name={ICONS.arrow} size={[20, 20]} className="opacity-50"/>
                                    </div>
                                </div>
                            </List.Item>
                        )
                    })}
                </List>
            </div>

            {viewComponent &&
            <div style={{width: "40%"}}>
                <Card style={{marginLeft: "20px", position: "sticky", top: "70px", borderRadius: 0}}>
                    <div className="flex-row margin-bottom-30 flex-center-between">
                        <div className="flex-center">
                            <Icon color="grey" name={ICONS.user_ok}
                                  size={[20, 20]}/>
                            <span className="margin-left-5">
                                {substituteZeroWithLine(recordOffer && recordOffer.candidateCount)}
                            </span>
                        </div>
                        <div style={{width: "120px", height: "100px", margin: "-80px 0 0 -20px", border: "1px solid rgba(14, 14, 44, 0.15)"}}>
                            {recordOffer && !recordOffer.anonymous ?
                                <ImgComponent logoPath={recordOffer && recordOffer.company && recordOffer.company.logoPath}/>
                                :
                                <div className="noLogo">
                                    <img src={GreyLogo}/>
                                </div>
                            }
                        </div>
                        <div className="flex-row">
                            <div onClick={closeDetailCard} className={"pointer"}>
                                <Icon name={ICONS.close} size={[20, 20]} className="icon" color={"#868696"}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex-center flex-column margin-bottom-30">
                        <div className="weight-700 size-22">Titolo: {recordOffer && recordOffer.title}</div>
                        <div className="weight-40 size-14 underlined" onClick={() => setField(recordOffer)}>{recordOffer && recordOffer.company && recordOffer.company.companyName}</div>
                    </div>
                    <Divider/>
                    <div className="margin-top-30 margin-bottom-30">
                        <div>
                            {recordOffer && recordOffer.location &&
                            <div>
                                <span className="weight-700 margin-right-5">Dove:</span>
                                <span>{recordOffer.location.city}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.impegnoRichiesto &&
                            <div>
                                <span className="weight-700 margin-right-5">Tempo:</span>
                                <span>{recordOffer.impegnoRichiesto}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.insertMode &&
                            <div>
                                <span className="weight-700 margin-right-5">Contratto:</span>
                                <span>{recordOffer.insertMode}</span>
                            </div>
                            }
                        </div>
                        <div>
                            {recordOffer && recordOffer.workPlace &&
                            <div>
                                <span className="weight-700 margin-right-5">Modalità:</span>
                                <span>{recordOffer.workPlace}</span>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="flex-row flex-center-between">
                        <div className="margin-bottom-30">
                            {/*<Button className={"padding-5 border-radius-3 margin-right-10"}>*/}
                            {/*    <Icon name={ICONS.facebook} size={[20, 20]} color={"#1877F2"}/>*/}
                            {/*</Button>*/}
                            {/*<Button className={"padding-5 border-radius-3 margin-right-10"}>*/}
                            {/*    <Icon name={ICONS.twitter} size={[20, 20]} color={"#1DA1F2"}/>*/}
                            {/*</Button>*/}
                            {recordOffer && recordOffer.company && recordOffer.company.linkedin &&
                            <Button className={"padding-5 border-radius-3 margin-right-10"}>
                                <a href={recordOffer.company.linkedin} target="_blank">
                                    <Icon name={ICONS.linkedin} size={[20, 20]} color={"#1DA1F2"}/>
                                </a>
                            </Button>
                            }
                            {recordOffer && recordOffer.company && recordOffer.company.webPage &&
                            <Button className={"padding-5 border-radius-3"}>
                                <a href={recordOffer.company.webPage} target="_blank">
                                    <Icon name={ICONS.link} size={[20, 20]} className={"opacity-50"}/>
                                </a>
                            </Button>
                            }
                        </div>
                        <div className="flex-row margin-bottom-30 margin-left-55">

                            <div className={"flex-center margin-right-24 pointer"}>
                                <span onClick={() => {
                                    dispatch(deletePreference(recordOffer.id, "delete"))
                                }}>
                                    <Icon name={ICONS.union} size={[20, 20]} className={"icon-heart"} color="#208ac6"/>
                                </span>
                            </div>
                        </div>
                    </div>
                    {recordOffer && recordOffer.description ?
                        <div>
                            <Divider>
                                <div className="size-12 opacity-50">Descrizione</div>
                            </Divider>
                            <p dangerouslySetInnerHTML={{__html: recordOffer.description}}/>
                        </div> : null}
                </Card>
            </div>
            }
        </div>
    )
}

export default ListMyPreference