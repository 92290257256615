import actions from './actions'
import authActions from "../auth/actions";

const initState = {
    employeeList: null,
    employeeListBySysAdmin: null,
    employee: null,
    updateList: false,
    updated: false,
}

export default function employeeReducer(state = initState, action){
    switch (action.type) {
        case actions.INIT_EMPLOYEE:
            return{
                ...initState
            };
        case authActions.LOGOUT:
            return {...initState}
        case actions.GET_ALL_EMPLOYEE_SUCCESS:
            return{
                ...state,
                employeeList: action.payload,
            };
        case actions.GET_ALL_EMPLOYEE_BY_SYS_ADMIN_SUCCESS:
            return{
                ...state,
                employeeListBySysAdmin: action.payload
            }
        case actions.GET_EMPLOYEE_BY_ID_SUCCESS:
            return{
                ...state,
                employee: action.payload.value,
            };
        case actions.SAVE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                updateList: true,
                updated: true,
            }
        case actions.UPDATE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                employee: action.payload.value,
                updateList: true,
                updated: true,
            }
        case actions.DELETE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                updateList: true
            }
        default:
            return state;
    }
}
