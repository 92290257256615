import React, {useState} from "react";
import {List} from "antd";
import DrawerListMyPreferenceMobile from "./DrawerListMyPreferenceMobile";
import useMediaQuery from "../../Useful/Responsive/UseMedia";
import ListInputMyPreference from "../../Useful/ListInput/MyPreference/ListInputMyPreference";
import ListInputMobilePreference from "../../Useful/ListInput/MyPreference/ListInputMobilePreference";

function ListMyPreferenceMobile(props) {

    const {myPreferences, recordOffer, setIdRecord, idRecord, viewComponent, setViewComponent} = props

    const isTablet = useMediaQuery('(min-width: 720px)')
    const isMobile = useMediaQuery('(min-width: 640px)')

    return(
        <div className={"flex-row"}>
            <div className="width-100">
                <List bordered dataSource={myPreferences} className={"pointer"}>
                    {myPreferences && myPreferences.map((element) => {
                        return (
                            <List.Item key={element.id} onClick={() => {
                                setIdRecord(element.id)
                                setViewComponent(true)
                            }}
                                className={element.id === idRecord ? "color-light-blue" : "white"}>
                                {isMobile ?
                                    <ListInputMyPreference element={element}/> :
                                    <ListInputMobilePreference element={element}/>}
                            </List.Item>
                        )
                    })}
                </List>
            </div>
            <DrawerListMyPreferenceMobile
                width={isTablet ? "70%" : "100%"}
                myPreferences={myPreferences}
                recordOffer={recordOffer}
                openState={viewComponent}
                setOpenState={setViewComponent}
            />
        </div>
    )
}

export default ListMyPreferenceMobile