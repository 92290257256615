import React, {useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import "src/Pages/dashboard/DashboardCompanyStyle.scss";
import {List, Typography} from "antd";
import {ICONS} from "src/assets/sprite/iconsNames";
import {DashboardCard} from "src/component/Useful/Dashboard/DashboardCard/DashboardCard"
import {OffersList} from "src/component/Layout/OffersList/OffersList"
import {FormattedMessage, injectIntl} from "react-intl";
import DashboardNotActive from "../../component/Useful/Dashboard/DashboardNotActive";
import Icon from "src/component/Icon/Sprite";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/company/actions";
import {PRIVATE_COMPANY_ROUTE} from "src/route.constants";
import {OFFER_FILTERS_CANDIDATE, OFFER_STATE} from "../../utils/utility";
import {FeedbackButton} from "../../component/Useful/Button/FeedbackButton";
import {getClickableLink} from "../../helpers/utility";

const {getCompanyDashboard, closeActivationMessage, closeStep} = actions

const {Text} = Typography;

export const CARD_TYPE = {
    workOffers: "workOffers",
    candidates: "candidates",
    publishedOffers: "publishedOffers"
}

function DashboardCompany(props) {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const dashboard = useSelector(state => state.Company.companyDashboard)
    const activateStatus = useSelector(state => state.AuthReducer.activateStatus)

    const [accountInfo, setAccountInfo] = React.useState("")

    const [completeStep, setCompleteStep] = React.useState("Non completata")
    const [disabled, setDisabled] = React.useState(true)
    const [showHelpMessage, setShowHelpMessage] = React.useState(true)


    useEffect(() => {
        let name = localStorage.getItem("firstName")
        let surname = localStorage.getItem("lastName")
        let openStatusCompany = localStorage.getItem("openStatusCompany")
        setAccountInfo(name + " " + surname)
        dispatch(getCompanyDashboard())
        if (openStatusCompany) {
            navigate(PRIVATE_COMPANY_ROUTE.WORK_OFFERS)
        }
    }, [])


    const customizedEmpty = () => (
        <div className="noOffers">
            <div className="noOffersIcon flex-center">
                <Icon className={"infoIcon"} name={ICONS.info} size={[20, 20]} color={"#C41A43"}/>
            </div>
            <div className="size-18 black weight-600">
                <FormattedMessage id="Dashboard_noOffers1"/>
            </div>
            <div>
                <FormattedMessage id="Dashboard_noOffers2"/>
            </div>
        </div>
    )

    const ChangeFirstStepStatus = () => {
        setDisabled(!disabled)
        setCompleteStep(
            <div className="green"><Icon name={ICONS.tik} size={[20, 20]}/>Completata</div>
        )
    }

    return (
        <>
            <div className="companyDashboard">
                <div className={dashboard.showActivationMessage && dashboard.enabled ? "bgImage" : "bgImageWithBox"}>
                    <div className="sidePadding">
                        <div className="maxWidth">

                            {/*Title*/}
                            <div className="topPadding">
                                <div className="title size-28 weight-700 merriWeather">
                                    Buongiorno, {accountInfo}
                                </div>
                                <div className={!!dashboard?.feedbackLinkCompanies ? "margin-bottom-15" : "margin-bottom-42"}>
                                    {new Date().toLocaleDateString("it-IT", {weekday: "long", month: "short", day: "numeric", year: "numeric"})}
                                </div>


                                {!!dashboard?.feedbackLinkCompanies &&
                                <FeedbackButton
                                    icon={<Icon name={ICONS.edit} size={[20, 20]}/>}
                                    href={getClickableLink(dashboard?.feedbackLinkCompanies)}
                                    target={"_blank"}
                                    color={"#c41a43"}
                                    style={{marginBottom: "12px"}}
                                >
                                    <FormattedMessage id={"feedbackPlatformCompany"}/>
                                </FeedbackButton>
                                }

                                {dashboard && dashboard.enabled &&
                                <>
                                    {dashboard.showActivationMessage &&
                                    <div>
                                        <div className="accountInfo green flex-center">
                                            <div className="closeIcon" onClick={() => dispatch(closeActivationMessage())}>
                                                <Icon className={"pointer opacity-50"} name={ICONS.close} size={[20, 20]} color={"#0E0E2C"}/>
                                            </div>
                                            <div className="flex-align-center">
                                                <Icon name={ICONS.tik} size={[20, 20]} color={"#69BE7B"}/>
                                                <span className="size-18 green-text weight-600">
                                                    <FormattedMessage id="Dashboard_accountInfo1"/>
                                                </span>
                                            </div>
                                            <div>
                                                <FormattedMessage id="Dashboard_accountInfo2"/>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </>
                                }
                                {dashboard && dashboard.enabled === false && <DashboardNotActive/>}

                            </div>


                            {/*Card Grid*/}
                            {dashboard.enabled &&
                            <div className="cardContainer">
                                <DashboardCard
                                    isCompleted={dashboard.isCompleted}
                                    type={CARD_TYPE.publishedOffers}
                                    icon={ICONS.doc}
                                    header="Offerte pubblicate"
                                    text="Non ci sono offerte pubblicate"
                                    title={localStorage.getItem("companyName")}
                                    childArray={[
                                        {
                                            value: dashboard.totalEmployeeCount,
                                            text: "team",
                                        },
                                        {
                                            value: dashboard.totalOffertCount,
                                            text: "offerte di lavoro",
                                        },
                                        {
                                            value: dashboard.totalCandidateCount,
                                            text: "candidati",
                                        }
                                    ]}
                                />

                                <DashboardCard
                                    isCompleted={dashboard.isCompleted}
                                    type={CARD_TYPE.workOffers}
                                    icon={ICONS.bag}
                                    header="Offerte di lavoro"
                                    text="Non ci sono offerte di lavoro"
                                    title="Offerte di lavoro"
                                    total={dashboard.totalOffertCount}
                                    redirectTo={PRIVATE_COMPANY_ROUTE.WORK_OFFERS}
                                    childArray={[
                                        {
                                            value: dashboard.totalPublishedOffertCount,
                                            text: OFFER_STATE.published.label,
                                            valueClassname: "#69BE7B",
                                            textClassname: "underlined",
                                        },
                                        {
                                            value: dashboard.totalNotPublishedOffertCount,
                                            text: OFFER_STATE.toPublished.label,
                                            valueClassname: "#FFA34E",
                                            textClassname: "underlined",
                                        },
                                        {
                                            value: dashboard.totalDraftOffertCount,
                                            text: OFFER_STATE.draft.label,
                                            valueClassname: "grey",
                                            textClassname: "underlined",
                                        }
                                    ]}
                                />

                                <DashboardCard
                                    isCompleted={dashboard.isCompleted}
                                    type={CARD_TYPE.candidates}
                                    icon={ICONS.user}
                                    header="Candidati"
                                    text="Non ci sono ancora candidati"
                                    title="Candidati"
                                    total={dashboard.totalCandidateCount}
                                    redirectTo={PRIVATE_COMPANY_ROUTE.CANDIDATE}
                                    childArray={[
                                        {
                                            value: dashboard.totalInterviewCandidateCount,
                                            text: OFFER_FILTERS_CANDIDATE.INTERVIEW.label,
                                            valueClassname: "green",
                                            textClassname: "underlined",
                                        },
                                        {
                                            value: dashboard.totalPreselectedCandidateCount,
                                            text: OFFER_FILTERS_CANDIDATE.PRESELECTED.label,
                                            valueClassname: "lilla",
                                            textClassname: "underlined",
                                        },
                                        {
                                            value: dashboard.totalRejectedCandidateCount,
                                            text: OFFER_FILTERS_CANDIDATE.DISLIKE.label,
                                            valueClassname: "red",
                                            textClassname: "underlined",
                                        }
                                    ]}
                                />

                            </div>
                            }
                        </div>
                    </div>
                </div>


                <div className="negativeMargin">
                    {/*First steps*/}

                    {dashboard.enabled && showHelpMessage && (!dashboard.totalOffertCount || dashboard.totalOffertCount.length === 0) &&
                    <div className="sidePadding">
                        <div className="maxWidth margin-bottom-48">
                            <div className="margin-bottom-24 flex-center-between">
                                <div>
                                    <Icon name={ICONS.lock} size={[20, 20]} color={"#C41A43"}/>
                                    <span className="margin-left-10 weight-700 size-20">
                                        <FormattedMessage id="Dashboard_firstSteps"/>
                                    </span>
                                </div>
                                {dashboard.isCompleted &&
                                <div className="pointer underlined" style={{opacity: 0.6}} onClick={() => setShowHelpMessage(false)}>
                                    <FormattedMessage id="Dashboard_skipHelp"/>
                                </div>
                                }
                            </div>
                            <List bordered>
                                <List.Item
                                    extra={dashboard.isCompleted ?
                                        <span className="flex-center green">
                                            <Icon name={ICONS.tik} size={[20, 20]} color={"#69BE7B"}/>
                                            <span>
                                                Completata
                                            </span>
                                        </span>
                                        :
                                        "Non completata"
                                    }
                                >
                                    <div>
                                        <span>
                                            1
                                        </span>
                                        <span className="pointer size-16 side-margin-16 underlined blue" onClick={() => navigate(PRIVATE_COMPANY_ROUTE.MY_COMPANY)}>
                                            <FormattedMessage id="Dashboard_updateData"/>
                                        </span>
                                    </div>
                                </List.Item>
                                <List.Item
                                    className={!dashboard.isCompleted && "opacity-10"}
                                    style={{cursor: !dashboard.isCompleted && "not-allowed"}}
                                    extra={dashboard.totalOffertCount && dashboard.totalOffertCount !== 0 ?
                                        <span className="flex-center green">
                                            <Icon name={ICONS.tik} size={[20, 20]} color={"#69BE7B"}/>
                                            <span>
                                                Completata
                                            </span>
                                        </span>
                                        :
                                        "Non completata"
                                    }
                                >
                                    <div>
                                        <span>
                                            2
                                        </span>
                                        <span className={`size-16 side-margin-16 underlined blue ${dashboard.isCompleted && "pointer"}`} onClick={() => {
                                            if (dashboard.isCompleted) {
                                                navigate(PRIVATE_COMPANY_ROUTE.WORK_OFFERS)
                                            }
                                        }}>
                                            <FormattedMessage id="Dashboard_createWorkOffers"/>
                                        </span>
                                    </div>
                                </List.Item>
                            </List>
                        </div>
                    </div>
                    }

                    {/*Last offers*/}

                    <div className="sidePadding">
                        <div className="maxWidth lastOffers">
                            <div className="flex-center-between">
                                <div className="margin-bottom-24 weight-700 size-20">
                                    <FormattedMessage id="Dashboard_lastOffers"/>
                                </div>
                                {dashboard.totalOfferCount === 0 &&
                                <div
                                    className="blue-text pointer underlined"
                                    onClick={() => navigate("/company/offers")}>
                                    <FormattedMessage id="Dashboard_goToOffersPage"/>
                                </div>
                                }
                            </div>
                            <div className="margin-bottom-24">
                                <OffersList lastOffers={dashboard && dashboard.lastOfferts}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default injectIntl(DashboardCompany)

