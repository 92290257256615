import React from "react";
import Modal from "antd/es/modal";
import {ICONS} from "src/assets/sprite/iconsNames";
import Icon from "src/component/Icon/Sprite";
import {Form} from "antd";
import {InputLogin} from "src/component/Useful/Input/InputLogin";
import {FormattedMessage} from "react-intl";
import {ButtonUser} from "src/component/Useful/Button/ButtonUser";
import {useDispatch, useSelector} from "react-redux";
import actions from "src/redux/auth/actions";
import "src/component/Layout/Modal/ModalSuperAdmin/ModalSuperAdmin.scss"


function ModalSuperAdmin(props) {

    const {login} = actions;

    const dispatch = useDispatch()

    const isButtonLoading = useSelector(state => state.AuthReducer.isButtonLoading)

    const [email, setEmail] = React.useState(null)
    const [password, setPassword] = React.useState(null)
    const [toggleType, setToggleType] = React.useState(false)
    const [toggleIconPassword, setToggleIconPassword] = React.useState(false)

    const handleClick = () => {
        if (email && password) {
            let data = {
                email: email,
                password: password,
                context: "sys_admin"
            }
            dispatch(login(data))
        }
    }

    const ShowPassword = () => {
        setToggleType(!toggleType)
        setToggleIconPassword(!toggleIconPassword)
    }


    return (
        <Modal open={props.state} onOk={props.onOk} onCancel={props.onCancel} closeIcon={<Icon name={ICONS.close} size={[20, 20]}/>} footer={[
            <div className="padding-20-no-top flex-center">
                <ButtonUser className="flex-center" icon={<Icon name={ICONS.tik} size={[20, 20]} className="margin-5 opacity-50"/>} block onClick={() => handleClick()} loading={isButtonLoading}>
                    Accedere
                </ButtonUser>
            </div>]}>
            <div className="flex-column padding-20-no-bottom">
                <div className="flex-row flex-align-center padding-bottom-20">
                    <Icon name={ICONS.lock} size={[20, 20]} className="margin-5"/>
                    <div className="weight-700 size-29 merriWeather">Admin</div>
                </div>
                <div className="weight-400 size4-16 padding-bottom-20">Inserisci le tue credenziali per entrare</div>
                <div className={"inputField"}>
                    <Form layout={"vertical"}>
                        <Form.Item
                            name={"email"}
                            type={"email"}
                            label={"Email"}
                            rules={[{type: 'email', message: "Inserire un'email valida"}]}
                            className="weight-600 size4-14 inline-block width-100 margin-right-24"
                        >
                            <InputLogin className={"input"} prefix={<Icon name={ICONS.mail} className="opacity-50" size={[20, 20]}/>} placeholder="Inserire l'email" onChange={(e) => setEmail(e.target.value)}/>
                        </Form.Item>
                    </Form>
                    <Form layout={"vertical"}>
                        <Form.Item
                            name={"password"}
                            label={<FormattedMessage id="UserRegistration_main_password"/>}
                            className="weight-600 size4-14 inline-block width-100 margin-right-24"
                        >
                            <InputLogin type={toggleType ? "text" : "password"} className={"input"} prefix={<Icon name={ICONS.lock} className="opacity-50" size={[20, 20]}/>}
                                        suffix={<div onClick={(e) => ShowPassword()}><Icon name={toggleIconPassword ? ICONS.eye : ICONS.eye_hide} className="flex-center pointer" color="#C41A43" size={[20, 20]}/></div>} placeholder="Inserire la password" onChange={(e) => setPassword(e.target.value)}/>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    )
}

export default ModalSuperAdmin
