import actions from './actions'
import authActions from "../auth/actions";

const initState = {
    offer: [],
    totalOffer: null,
    loading: false
}

export default function landingReducer(state = initState, action){
    switch (action.type) {
        case actions.INIT_LANDING:
            return{
                ...initState
            };
        case authActions.LOGOUT:
            return {...initState}

        case actions.GET_ALL_LANDING_OFFER:
            return{
                ...state,
                loading: true
            }
        case actions.GET_ALL_LANDING_OFFER_SUCCESS:
            let newOffers = [...state?.offer]
            action.payload.elements.map((offer) => {
                newOffers.push(offer)
            })
            return {
                ...state,
                offer: newOffers,
                totalOffer: action.payload.total,
                loading: false
            }
        default:
            return state;
    }
}
