import {ButtonLine} from "../../../Useful/Button/ButtonLine";
import {ButtonNoLine} from "../../../Useful/Button/ButtonNoLine";
import {ButtonUser} from "../../../Useful/Button/ButtonUser";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";
import {InputLogin} from "../../../Useful/Input/InputLogin";
import {Col, Form, Row} from "antd";
import {Select} from "../../../Useful/Select/Select";
import Icon from "../../../Icon/Sprite";
import {ICONS} from "../../../../assets/sprite/iconsNames";
import Modal from "antd/es/modal";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import actions from "src/redux/location/actions";
import candidatureActions from "../../../../redux/candidature/actions";
import {QUALIFICATION} from "../../../../utils/utility";

function ModalFilter(props) {
    const [isProvinceSelected, setIsProvinceSelected] = useState(false)
    const [provinceSelected, setProvinceSelected] = useState(null)
    const [search, setSearch] = useState(props.searchToModal)

    const {open, onOk, onCancel, resetFilters, handleSearch, handleCount ,changeReset, handleObjectFilters, objectFilters} = props

    const province = useSelector(state => state.Location.province)
    const city = useSelector(state => state.Location.city)

    const [form] = Form.useForm();
    const dispatch = useDispatch()

    useEffect(()=>{
        form.setFieldsValue({
            search: props.searchToModal
        })
        let arr = []
        if(form.getFieldValue("search"))
            arr.push(form.getFieldValue("search"))
        if(form.getFieldValue("province"))
            arr.push(form.getFieldsValue("province"))
        if(form.getFieldValue("city"))
            arr.push(form.getFieldValue(city))
        handleCount(arr.length)
    },[props.searchToModal])

    useEffect(()=>{
        if(resetFilters){
            form.resetFields()


            handleCount(0)
            changeReset(false)
        }
    },[resetFilters])

    const filterReset = () =>{
        handleObjectFilters({
            ...objectFilters,
            name: null,
            province: null,
            city: null,
            qualificationLevel: null
        })
    }
    const {getAll} = candidatureActions

    const {getAllProvince, getCityByProvince} = actions
    return(
        <Modal title={<div className="weight-700 size-29 merriWeather">Filtri</div>} closeIcon={<Icon name={ICONS.close} size={[20,20]}/>} open={open} handleSearch={form.getFieldValue("search")} onCancel={onCancel} footer={[
            <div className="flex-between">
                <div className="flex">
                    <ButtonLine key="reset" onClick={()=>{
                        form.resetFields()
                        filterReset()
                        handleCount(null)
                        handleSearch(null)
                    }}>
                        Resetta i filtri
                    </ButtonLine>
                </div>
                <div className="flex">
                    <ButtonNoLine key="cancel" onClick={()=> {
                        form.resetFields()
                        handleCount(null)
                        onCancel()
                    }}>
                        Annulla
                    </ButtonNoLine>
                    <ButtonUser key="confirm"
                                htmlType="submit"
                                form={"searchCandidates"}
                                onClick={()=>{
                                    handleObjectFilters({
                                        ...objectFilters,
                                        name: form.getFieldValue("search"),
                                        province: form.getFieldValue("province"),
                                        city: form.getFieldValue("city"),
                                        qualificationLevel: form.getFieldValue("qualificationLevel")
                                    })
                                    handleSearch && handleSearch(form.getFieldValue("search"))
                                    onOk()
                                }}>
                        <div className="flex-center"><Icon name={ICONS.tik} size={[20,20]} color="#FFFFFF"/>Applica</div>
                    </ButtonUser>
                </div>
            </div>
        ]}>

            <div className="flex-col">
                <Form
                    id={"searchCandidates"}
                    form={form}
                    layout={"vertical"}
                    onFinish={(value)=>{
                        let arr = []
                        if(value.search)
                            arr.push(value.search)
                        if(value.province)
                            arr.push(value.province)
                        if(value.city)
                            arr.push(value.city)
                        if(value.qualificationLevel)
                            arr.push(value.qualificationLevel)
                        handleCount(arr.length)
                    }}
                >
                    <Form.Item
                        label={<label className="weight-600 size-14">Ricerca per nome o cognome candidato</label>}
                        name={"search"}
                    >

                        <InputLogin className="margin-top-5 margin-bottom-10" placeholder="Ricerca..." />
                    </Form.Item>
                    <Row gutter={[24, 24]}>
                        <Col span={12}>

                            <Form.Item
                                label={"Provincia"}
                                name={"province"}
                                className="weight-600 size-14"
                            >
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    suffixIcon={<Icon name={ICONS.arrow} size={[20, 20]}/>}
                                    placeholder={"- Provincia -"}
                                    allowClear
                                    onSelect={(value)=>{
                                        setIsProvinceSelected(true)
                                        setProvinceSelected(value)
                                        form.setFieldsValue({...form.getFieldsValue, city: undefined})
                                        dispatch(getCityByProvince({
                                            page: 0,
                                            pageSize: 350,
                                            province: value
                                        }))
                                    }}
                                    showSearch
                                    filterOption={false}
                                    onClear={() => {
                                        setIsProvinceSelected(false)
                                        form.setFieldsValue({...form.getFieldsValue, city: undefined})
                                    }}
                                    options={province && province.map(element => ({
                                        id: element.id,
                                        value: element.province,
                                        label: element.province
                                    }))}
                                    onFocus={() => {
                                        dispatch(getAllProvince({
                                            page: 0,
                                            pageSize: 200
                                        }))
                                    }}
                                    onSearch={(value) => {
                                        dispatch(getAllProvince({
                                            page: 0,
                                            pageSize: 200,
                                            province: value
                                        }))
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={"city"}
                                label={"Città"}
                                className="weight-600 size-14"
                            >
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    placeholder={"- Città -"}
                                    suffixIcon={<Icon name={ICONS.arrow} size={[20, 20]}/>}
                                    autoComplete="nope"
                                    allowClear
                                    showSearch
                                    filterOption={false}
                                    labelInValue
                                    disabled={!isProvinceSelected}
                                    options={city && city.map(element => ({
                                        id: element.id,
                                        value: element.id,
                                        label: element.city
                                    }))}
                                    onSearch={(value) => {
                                        dispatch(getCityByProvince({
                                            page: 0,
                                            pageSize: 350,
                                            province: provinceSelected,
                                            city: value,
                                        }))
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <Form.Item
                                name={"qualificationLevel"}
                                label={"Titolo di studio"}
                                className="weight-600 size-14"
                            >
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    placeholder={"- Titolo di studio -"}
                                    suffixIcon={<Icon name={ICONS.arrow} size={[20, 20]}/>}
                                    allowClear
                                    options={QUALIFICATION}
                                />
                            </Form.Item>
                        </Col>
                        {/*<Col span={12}>*/}
                        {/*    <Form.Item*/}
                        {/*        label={"Età"}*/}
                        {/*        className="weight-600 size-14"*/}
                        {/*    >*/}
                        {/*        <Select*/}
                        {/*            getPopupContainer={trigger => trigger.parentNode}*/}
                        {/*            placeholder={"- Età -"}*/}
                        {/*            suffixIcon={<Icon name={ICONS.arrow} size={[20, 20]}/>}*/}
                        {/*            allowClear*/}
                        {/*            options={[*/}
                        {/*                {*/}
                        {/*                    value: '18',*/}
                        {/*                    label: '18',*/}
                        {/*                },*/}
                        {/*            ]}*/}
                        {/*        />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                    </Row>
                    {/*<Row gutter={[24, 24]}>*/}
                    {/*    <Col span={12}>*/}
                    {/*        <Form.Item*/}
                    {/*            label={"Genere"}*/}
                    {/*            className="weight-600 size-14"*/}
                    {/*        >*/}
                    {/*            <Select*/}
                    {/*                getPopupContainer={trigger => trigger.parentNode}*/}
                    {/*                disabled={true}*/}
                    {/*                placeholder={"- Genere -"}*/}
                    {/*                suffixIcon={<Icon name={ICONS.arrow} size={[20, 20]}/>}*/}
                    {/*                allowClear*/}
                    {/*                options={[*/}
                    {/*                    {*/}
                    {/*                        value: 'M',*/}
                    {/*                        label: 'M',*/}
                    {/*                    },*/}

                    {/*                ]}*/}
                    {/*            />*/}
                    {/*        </Form.Item>*/}

                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Form>
            </div>
        </Modal>
    )
}

export default ModalFilter
