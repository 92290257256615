import React, {useEffect, useState} from 'react';
import Router from './router'
import Boot from './redux/boot'
import dayjs from "dayjs";
import {message} from "antd";

require('dayjs/locale/it')

function App() {

    dayjs.locale('it')

    const [isTokenFound, setTokenFound] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();

    return (
        <>
            {contextHolder}
            <Router/>
        </>
    )
}

Boot().then(() => App())

export default App;
