import React from 'react';
import ReactDOM from "react-dom/client"
import { Provider } from 'react-redux';
import App from './App';
import store from './redux/store/configureStore';
import "./_index.scss"
import {IntlProvider} from "react-intl";
import {ConfigProvider} from "antd";
import Italian from "./config/languages/it-IT.json";
import English from "./config/languages/en-EN.json";

const root = ReactDOM.createRoot(document.getElementById("root"))

const local = navigator.language

let lang;
if (local === "it-IT" || local === "it"){
    lang = Italian;
} else {
    lang = English;
}

const theme = {
    token:{
        fontFamily: "Inter, sans-serif"
    }
}

root.render(
    <Provider store={store}>
        <ConfigProvider theme={theme}>
            <IntlProvider locale={"it-IT"} messages={Italian}>
                <App />
            </IntlProvider>
        </ConfigProvider>
    </Provider>
);
