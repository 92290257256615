import React from "react";
import "src/component/Layout/Drawer/OfferUser/ConfirmOfferUser.scss";
import {ButtonGhost} from "../../../Useful/Button/ButtonGhost";
import {ICONS} from "../../../../assets/sprite/iconsNames";
import Icon from "../../../Icon/Sprite";
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";


export function ConfirmOfferUser() {

    const navigate = useNavigate()

    const record = useSelector(state => state.UserProfile.recordOffer)

    return (
        <div className="max-width-1200 bodyPadding">
            <div className="flex-center flex-column">
                <div className="padding-24">
                    <div className="confirm-avatar flex-center">
                        <Icon name={ICONS.tik} size={[20, 20]} color={"#FFF"}/>
                    </div>
                </div>
                <div className="padding-24 title">Grazie, ti sei candidato con successo!</div>
                <div className="padding-24 subtitle">Ti sei candidato con successo all'offerta <span style={{color:"#208AC6"}}>"{record.title}"</span></div>
                <div className="padding-24 description">Riceverai una notifica quando l'azienda avrà visionato il tuo profilo.</div>
                <ButtonGhost onClick={()=> navigate("/user/myCandidature")}>
                    <FormattedMessage id="UserDetails_myApplication"/>
                </ButtonGhost>
            </div>
        </div>
    )
}
