import React, {useEffect} from "react";
import {Outlet} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import "../home/home.scss"
import Footerbar from "../../component/Layout/Footbar/footerbar";
import Topbar from "src/component/Layout/Topbar/companyTopbar";
import {client} from "src/firebase";
import notificationActions from "src/redux/notification/actions"

export default function CompanyHome(props) {
    const dispatch = useDispatch()

    const loading = useSelector(state => state.AuthReducer.loading)

    const newNotification = (payload) => {
        dispatch(notificationActions.newNotification())
        console.log(payload)
    }

    useEffect(() => {
        let test = client.on("newNotification", newNotification)
        console.log(test)

        return () => client.off("newNotification", newNotification)
    },[])

    return (
        <div className={"content-container"}>
            <Topbar/>

            <div className={'content company'}>
                <Outlet/>
            </div>

            <Footerbar/>
        </div>
    )

}
