import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {endpoints} from "src/config/site.config";
import SuperFetch from "../../helpers/superFetch";
import actions from "./actions";
import authActions from "../auth/actions";

export function* getAllOffer() {

    yield takeEvery(actions.GET_ALL_OFFERS, function* (data) {
        let queryString = "?page=" + (data.payload.page - 1) + "&size=" + data.payload.size
        if (data.payload.title) {
            queryString = queryString + "&title=" + data.payload.title
        }
        if (data.payload.state && data.payload.state !== "all" && data.payload.state !== "Tutti") {
            queryString = queryString + "&state=" + data.payload.state
        }
        if (data.payload.sort) {
            queryString = queryString + "&sort=dateCreated," + data.payload.sort
        }
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllOffer + queryString, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            localStorage.setItem("isEnabled", response.isEnabled)
            localStorage.setItem("isCompleted", response.isCompleted)

            yield put({
                type: authActions.SET_IS_ENABLED,
                isEnabled: response.isEnabled,
                isCompleted: response.isCompleted,
            })

            yield put({
                type: actions.GET_ALL_OFFERS_SUCCESS,
                payload: response
            });

        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_OFFERS_ERROR,
                payload: error
            });
        }
    })

}


export function* getAllUserOffer() {

    yield takeEvery(actions.GET_ALL_USER_OFFERS, function* (data) {
        let queryString = "?page=" + (data.payload.page - 1) + "&size=" + data.payload.size

        if (data.payload.title) {
            queryString = queryString + "&title=" + data.payload.title
        }
        if (data.payload.sort) {
            queryString = queryString + "&sort=dateCreated," + data.payload.sort
        } else {
            queryString = queryString + "&sort=dateCreated,DESC"
        }
        if (data.payload.locationId) {
            queryString = queryString + "&locationId=" + data.payload.locationId
        }
        if (data.payload.impegnoRichiesto) {
            queryString = queryString + "&impegnoRichiesto=" + data.payload.impegnoRichiesto
        }
        if (data.payload.onlyRemote) {
            queryString = queryString + "&onlyRemote=" + data.payload.onlyRemote
        }
        if (data.payload.companyId) {
            queryString = queryString + "&companyId=" + data.payload.companyId
        }

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllOfferUser + queryString, null, true)

        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_USER_OFFERS_SUCCESS,
                payload: response
            });

        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_USER_OFFERS_ERROR,
                payload: error
            });
        }
    })

}


export function* addUserOfferToPrevious() {

    yield takeEvery(actions.ADD_USER_OFFER_TO_PREVIOUS, function* (data) {
        let queryString = "?page=" + (data.payload.page - 1) + "&size=" + data.payload.size

        if (data.payload.title) {
            queryString = queryString + "&title=" + data.payload.title
        }
        if (data.payload.sort) {
            queryString = queryString + "&sort=dateCreated," + data.payload.sort
        }

        if (data.payload.locationId) {
            queryString = queryString + "&locationId=" + data.payload.locationId
        }
        if (data.payload.impegnoRichiesto) {
            queryString = queryString + "&impegnoRichiesto=" + data.payload.impegnoRichiesto
        }
        if (data.payload.onlyRemote) {
            queryString = queryString + "&onlyRemote=" + data.payload.onlyRemote
        }

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllOfferUser + queryString, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.ADD_USER_OFFER_TO_PREVIOUS_SUCCESS,
                payload: response
            });

        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.ADD_USER_OFFER_TO_PREVIOUS_ERROR,
                payload: error
            });
        }
    })

}


export function* getOfferById() {

    yield takeEvery(actions.GET_OFFER_BY_ID, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getOfferDetails + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_OFFER_BY_ID_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_OFFER_BY_ID_ERROR,
                payload: error
            });
        }
    })

}

export function* getOfferByIdForUser() {

    yield takeEvery(actions.GET_OFFER_BY_ID_FOR_USER, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getOfferDetailsForUser + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_OFFER_BY_ID_FOR_USER_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_OFFER_BY_ID_FOR_USER_ERROR,
                payload: error
            });
        }
    })

}

export function* saveOffer() {

    yield takeEvery(actions.SAVE_OFFER, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.saveOffer, data.payload, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.SAVE_OFFER_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SAVE_OFFER_ERROR,
                payload: error
            });
        }
    })

}

export function* updateOffer() {

    yield takeEvery(actions.UPDATE_OFFER, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.put, endpoints.updateOffer + "/" + data.id + "/" + data.companyId, data.payload, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_OFFER_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_OFFER_ERROR,
                payload: error
            });
        }
    })

}

export function* updateOfferBySysAdmin() {

    yield takeEvery(actions.UPDATE_OFFER_BY_SYS_ADMIN, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.put, endpoints.updateOfferBySysAdmin + "/" + data.id + "/" + data.companyId, data.payload, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_OFFER_BY_SYS_ADMIN_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_OFFER_BY_SYS_ADMIN_ERROR,
                payload: error
            });
        }
    })

}

export function* deleteOffer() {

    yield takeEvery(actions.DELETE_OFFER, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.delete, endpoints.deleteOffer + "/" + data.id, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.DELETE_OFFER_SUCCESS,
                payload: response
            });
            let page = {
                page: 1,
                size: 10,
                state: data.state,
                sort: data.sort,
                pageSize: data.pageSize,
            }
            yield put({
                type: actions.GET_ALL_OFFERS,
                payload: page
            })
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DELETE_OFFER_ERROR,
                payload: error
            });
        }
    })

}

export function* enableOffert() {

    yield takeEvery(actions.ENABLED_OFFERT, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.enabledOffert + "?otp=" + data.otp, null, false)
        yield put({type: authActions.LOADING_FALSE});
        if (response) {
            yield put({
                type: actions.ENABLED_OFFERT_SUCCESS,
                payload: response
            });

        } else {
            yield put({
                type: actions.ENABLED_OFFERT_ERROR,
                payload: error
            });
        }
    })
}


export function* updateOfferViews() {

    yield takeEvery(actions.UPDATE_OFFER_VIEWS, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.updateOfferCount + "/" + data.offerId, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_OFFER_VIEWS_SUCCESS,
                payload: response
            });

        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_OFFER_VIEWS_ERROR,
                payload: error
            });
        }
    })
}

export function* setOfferExpired() {

    yield takeEvery(actions.SET_OFFER_EXPIRED, function* (data) {
        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.setOfferExpired + "/" + data.id, null, true)

        debugger
        if (response) {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SET_OFFER_EXPIRED_SUCCESS,
                payload: response
            });

            debugger

            let page = {
                page: 1,
                size: 10,
                state: data.state,
                sort: data.sort,
                pageSize: data.pageSize,
            }
            yield put({
                type: actions.GET_ALL_OFFERS,
                payload: page
            })

        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.SET_OFFER_EXPIRED_ERROR,
                payload: error
            });
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getAllOffer),
        fork(getAllUserOffer),
        fork(addUserOfferToPrevious),
        fork(getOfferById),
        fork(getOfferByIdForUser),
        fork(saveOffer),
        fork(updateOffer),
        fork(updateOfferBySysAdmin),
        fork(deleteOffer),
        fork(enableOffert),
        fork(updateOfferViews),
        fork(setOfferExpired)
    ]);
}
