import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Breadcrumb from "antd/es/breadcrumb";
import {PRIVATE_USER_ROUTE} from "src/route.constants";
import {FormattedMessage} from "react-intl";
import {useNavigate, useParams} from "react-router";
import "./detailCompanyStyle.scss"
import {ICONS} from "src/assets/sprite/iconsNames";
import {DRAWER_TYPE, ImgComponent, OFFER_FILTERS_CANDIDATE, SORTING, substituteZeroWithLine} from "src/utils/utility";
import {Button, Card, Divider, List, message, Space} from "antd";
import Icon from "src/component/Icon/Sprite";
import userActions from "../../../redux/userProfile/actions";
import actions from "../../../redux/offer/actions";
import preferenceActions from "../../../redux/preference/actions";
import {ButtonLine} from "../../../component/Useful/Button/ButtonLine";
import {ButtonUser} from "../../../component/Useful/Button/ButtonUser";
import drawerActions from "../../../redux/drawer/actions";
import candidatureActions from "../../../redux/candidature/actions";
import {CustomDrawer} from "../../../component/Layout/Drawer/CustomDrawer";
import UserProfile from "../../../redux/userProfile/reducer";
import useMediaQuery from "../../../component/Useful/Responsive/UseMedia";
import OfferElementMobile from "../../../component/Layout/Offerts/OfferElementMobile";
import DrawerListOffertUserMobile from "../../../component/Layout/MyOffertUser/DrawerListOffertUserMobile";
import {getOfferDate} from "../../../helpers/utility";

function DetailCompany() {

    const navigate = useNavigate()
    let dispatch = useDispatch()
    const [messageApi, contextHolder] = message.useMessage();
    let {idCompany} = useParams()

    const {getAllUserOffer} = actions;
    const {setRecordOffer, getCompanyByIdForUser} = userActions;
    const {openDrawer} = drawerActions;
    const {savePreference, deletePreference, resetStatePreference} = preferenceActions;
    const {resetStateCandidature, getAllMyCandidature} = candidatureActions;

    let isCompleted = localStorage.getItem("isCompleted") === "true"
    const isMobile = useMediaQuery('(max-width: 640px)')

    const [viewComponent, setViewComponent] = useState(false)
    const [stateStyle, setStateStyle] = useState("width-100")
    const [idRecord, setIdRecord] = useState(null)
    const [mainLocation, setMainLocation] = useState(null)

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = React.useState(100)

    const [type, setType] = useState(null)

    const isDrawerOpen = useSelector(state => state.UserProfile.isDrawerOpen)
    const offers = useSelector(state => state.Offer.offers)
    const recordOffer = useSelector(state => state.UserProfile.recordOffer)
    const company = useSelector(state => state.UserProfile.companyByUser)
    const myCandidatures = useSelector(state => state.Candidature.myCandidatures)
    const isCandidatureCreatedSuccess = useSelector(state => state.Candidature.isCandidatureCreatedSuccess)
    const confirmCandidature = useSelector(state => state.Candidature.confirmCandidature)
    const isPreferenceSetSuccessfully = useSelector(state => state.Preference.isPreferenceSetSuccessfully)


    function openDetailCard() {
        if (isMobile) {
            setViewComponent(true)
        } else {
            setStateStyle("ease-list-in width-60")
            setTimeout(() => {
                setViewComponent(true)
            }, 950)
        }
    }

    function closeDetailCard() {
        setViewComponent(false)
        setStateStyle("ease-list-out width-100")
    }

    useEffect(() => {
        dispatch(getAllUserOffer(
            {
                companyId: idCompany,
                page: page,
                size: pageSize,
            }
        ))
        dispatch(getCompanyByIdForUser(idCompany))
        dispatch(getAllMyCandidature({
            state: OFFER_FILTERS_CANDIDATE.ALL.value,
            sort: SORTING.ASC.value
        }))
    }, [])

    useEffect(() => {
        if (idRecord) {
            const record = offers.find(element => element.id === idRecord)
            dispatch(setRecordOffer(record))
        }
    }, [idRecord])

    useEffect(() => {
        if (company && company.companyLocations) {
            const mainLocation = company.companyLocations.find(element => element.mainLocation === true)
            setMainLocation(mainLocation)
        }
    }, [company])

    useEffect(() => {
        if (isCandidatureCreatedSuccess) {
            messageApi.open({
                type: 'success',
                content: 'Candidatura aggiornata con successo!'
            })
            dispatch(resetStateCandidature())
        }
    }, [confirmCandidature])

    useEffect(() => {

        if (isPreferenceSetSuccessfully === "add") {
            messageApi.open({
                type: 'success',
                content: 'Preference aggiunta con successo!'
            })

            dispatch(resetStatePreference())
        } else if (isPreferenceSetSuccessfully === "remove") {
            messageApi.open({
                type: 'success',
                content: 'Preferenza rimossa con successo!'
            })

            dispatch(resetStatePreference())
        }
    }, [isPreferenceSetSuccessfully])

    return (
        <div className="detailCompany">
            {!isMobile ? <div>
                    {contextHolder}
                    <div className="maxWidth">
                        <Breadcrumb className="customBreadcrumb" separator="›">
                            <Breadcrumb.Item className="underlined pointer"
                                             onClick={() => navigate(PRIVATE_USER_ROUTE.DASHBOARD_USER)}>
                                <FormattedMessage id="Breadcrumb_home"/>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item className="underlined pointer"
                                             onClick={() => navigate(PRIVATE_USER_ROUTE.JOB_OFFER)}>
                                <FormattedMessage id="Breadcrumb_workOffer"/>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <FormattedMessage id="Breadcrumb_company_profile"/>
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        <div>
                            <div className={"flex-row"}>
                                <div className={stateStyle}>
                                    <div>
                                        <div className={"margin-right-20"}>
                                            <div style={{
                                                width: "88px",
                                                height: "72px",
                                                marginRight: 20,
                                                border: "1px solid rgba(14, 14, 44, 0.15)",
                                                float: "left"
                                            }}>
                                                <ImgComponent logoPath={company && company.logoPath}/>
                                            </div>
                                            <div
                                                className="weight-700 size-22 dark merriWeather padding-left-40">{company && company.mainLocationName}</div>
                                            <div className={"flex-row margin-top-10"}>
                                                <Icon color="grey" name={ICONS.pin} size={[20, 20]}/>
                                                <div className="dividerDot flex-center">
                                                    <div>{company && company.mainLocationCityName && company.mainLocationCityName}</div>
                                                    <div>{company && company.mainLocationProvinceName && company.mainLocationProvinceName}</div>
                                                    <div>Italia</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="margin-top-54">
                                            {company && company.description}
                                        </div>
                                        <div>
                                            <div className="margin-top-24 flex-row">
                                                <Space direction={"horizontal"} size={20}>
                                                    {company && company.webPage &&
                                                        <a href={company.webPage} target="_blank">
                                                            <ButtonLine style={{height: "32px"}} className="flex-center">
                                                        <span>
                                                            Vedi sito web
                                                        </span>
                                                                <span style={{transform: "rotate(-90deg)", marginTop: 5}}>
                                                            <Icon color="#208AC6" className="margin-left-5"
                                                                  name={ICONS.arrow_small} size={[20, 20]}/>
                                                        </span>
                                                            </ButtonLine>
                                                        </a>
                                                    }
                                                    {company && company.linkedin &&
                                                        <a href={company.linkedin} target="_blank">
                                                            <div className="margin-top-5">
                                                                <Icon color="#208AC6" className="margin-left-5"
                                                                      name={ICONS.linkedin} size={[20, 20]}/>
                                                            </div>
                                                        </a>
                                                    }
                                                </Space>
                                            </div>
                                        </div>
                                        <Divider/>
                                    </div>
                                    <div className={"margin-bottom-20 weight-700 size-14 dark"}>Offerte di
                                        lavoro({offers.length})
                                    </div>
                                    <List bordered onClick={openDetailCard} dataSource={offers} className={"pointer"}>
                                        {offers && offers.map((element) => {
                                            return (
                                                <List.Item key={element.id} onClick={() => {
                                                    setIdRecord(element.id)
                                                }}>
                                                    <div className="flex-row ">
                                                        <div style={{
                                                            width: "88px",
                                                            height: "72px",
                                                            marginRight: 20,
                                                            border: "1px solid rgba(14, 14, 44, 0.15)"
                                                        }}>
                                                            <ImgComponent
                                                                logoPath={element.company && element.company.logoPath}/>
                                                        </div>
                                                        <div className="flex-column">
                                                            <div
                                                                className="blue underlined weight-700 size-16">{element.title && element.title}</div>
                                                            <div
                                                                className="opacity-50 weight-400 size-12">{element.location?.name}</div>
                                                            <div className="flex-row dividerDot">
                                                                {element.offertInsertMode &&
                                                                    <div className="flex-center">
                                                                        {element.offertInsertMode}
                                                                    </div>
                                                                }
                                                                {element.workPlace &&
                                                                    <div className="dot flex-center">
                                                                        <div>{element.workPlace}</div>
                                                                    </div>
                                                                }
                                                                {element.location && element.location &&
                                                                    <div className="dot flex-center">
                                                                        <div>{element.location.city}</div>
                                                                    </div>
                                                                }
                                                                {element.impegnoRichiesto &&
                                                                    <div className="dot flex-center">
                                                                        <div>{element.impegnoRichiesto}</div>
                                                                    </div>
                                                                }
                                                                <div className="flex-center">
                                                                    <Icon color="grey" name={ICONS.user_ok}
                                                                          size={[20, 20]}/>
                                                                    {substituteZeroWithLine(element && element.candidateCount)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-row">
                                                        <div
                                                            className="flex-column-end weight-400 size-12 padding-right-20">
                                                            <div className={"black-opacity-50"}>{getOfferDate(element.dateCreated)}</div>
                                                            {myCandidatures?.some(it => it.offertId === element.id) && <p className={"weight-700 margin-0 size-12 green"}>Candidato</p>}
                                                        </div>
                                                        <div style={{transform: "rotate(-90deg)"}}>
                                                            <Icon name={ICONS.arrow} size={[20, 20]}
                                                                  className="opacity-50"/>
                                                        </div>
                                                    </div>
                                                </List.Item>
                                            )
                                        })}
                                    </List>
                                </div>

                                {viewComponent &&
                                    <div style={{width: "40%"}}>
                                        <Card
                                            style={{marginLeft: "20px", position: "sticky", top: "20px", borderRadius: 0}}>
                                            <div className="flex-row margin-bottom-30 flex-center-between">
                                                <div className="flex-center">
                                                    <Icon name={ICONS.user_ok} size={[20, 20]} color={"#868696"}/>
                                                    <span className="flex-center margin-left-8">
                                                {substituteZeroWithLine(recordOffer && recordOffer.candidateCount)}
                                            </span>
                                                </div>
                                                <div className="flex-row">
                                                    <div onClick={closeDetailCard} className={"pointer"}>
                                                        <Icon name={ICONS.close} size={[20, 20]} className="icon"
                                                              color={"#868696"}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-center flex-column margin-bottom-30">
                                                <div className="weight-700 size-22">{recordOffer && recordOffer.title}</div>
                                                <div
                                                    className="weight-40 size-14 underlined">{recordOffer && recordOffer.company && recordOffer.company.companyName}</div>
                                            </div>
                                            <Divider/>
                                            <div className="margin-top-30 margin-bottom-30">
                                                <div>
                                                    {recordOffer && recordOffer.location &&
                                                        <div>
                                                            <span className="weight-700 margin-right-5">Dove:</span>
                                                            <span>{recordOffer.location.city}</span>
                                                        </div>
                                                    }
                                                </div>
                                                <div>
                                                    {recordOffer && recordOffer.impegnoRichiesto &&
                                                        <div>
                                                            <span className="weight-700 margin-right-5">Tempo:</span>
                                                            <span>{recordOffer.impegnoRichiesto}</span>
                                                        </div>
                                                    }
                                                </div>
                                                <div>
                                                    {recordOffer && recordOffer.insertMode &&
                                                        <div>
                                                            <span className="weight-700 margin-right-5">Contratto:</span>
                                                            <span>{recordOffer.insertMode}</span>
                                                        </div>
                                                    }
                                                </div>
                                                <div>
                                                    {recordOffer && recordOffer.workPlace &&
                                                        <div>
                                                            <span className="weight-700 margin-right-5">Modalità:</span>
                                                            <span>{recordOffer.workPlace}</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex-row flex-center-between">
                                                <div className="margin-bottom-30">
                                                    {/*<Button className={"padding-5 border-radius-3 margin-right-10"}>*/}
                                                    {/*    <Icon name={ICONS.facebook} size={[20, 20]} color={"#1877F2"}/>*/}
                                                    {/*</Button>*/}
                                                    {/*<Button className={"padding-5 border-radius-3 margin-right-10"}>*/}
                                                    {/*    <Icon name={ICONS.twitter} size={[20, 20]} color={"#1DA1F2"}/>*/}
                                                    {/*</Button>*/}
                                                    {recordOffer && recordOffer.company && recordOffer.company.linkedin &&
                                                        <Button className={"padding-5 border-radius-3 margin-right-10"}>
                                                            <a href={recordOffer.company.linkedin} target="_blank">
                                                                <Icon name={ICONS.linkedin} size={[20, 20]}
                                                                      color={"#1DA1F2"}/>
                                                            </a>
                                                        </Button>
                                                    }
                                                    {recordOffer && recordOffer.company && recordOffer.company.webPage &&
                                                        <Button className={"padding-5 border-radius-3"}>
                                                            <a href={recordOffer.company.webPage} target="_blank">
                                                                <Icon name={ICONS.link} size={[20, 20]}
                                                                      className={"opacity-50"}/>
                                                            </a>
                                                        </Button>
                                                    }
                                                </div>
                                                <div className="flex-row margin-bottom-30 margin-left-55">
                                                    <div className={"flex-center margin-right-24 pointer"}>
                                                        <div
                                                            className={"flex-center flex-align-center margin-right-24 pointer"}>
                                                            {recordOffer.preference ?
                                                                <span onClick={() => {
                                                                    dispatch(deletePreference(recordOffer.id))
                                                                }}>
                                                            <Icon name={ICONS.union} size={[20, 20]}
                                                                  className={"icon-heart"} color="#208ac6"/>
                                                        </span>
                                                                :
                                                                <span onClick={() => {
                                                                    dispatch(savePreference(recordOffer.id))
                                                                }}>
                                                            <Icon name={ICONS.heart} size={[20, 20]}
                                                                  className={"icon-heart"} color="grey"/>
                                                        </span>
                                                            }
                                                        </div>

                                                        {(myCandidatures && myCandidatures.some(element => element.offertId === idRecord)) ?
                                                            <div className={"flex-column"}>
                                                                <div className={"flex-align-center margin-0"}>
                                                                    <Icon name={ICONS.tik} classname={"margin-right-5"}
                                                                          size={[20, 20]}
                                                                          color="#69BE7B"/>
                                                                    <p className={"weight-700 margin-0 size-16 green"}>Candidato</p>
                                                                </div>
                                                            </div>
                                                            :
                                                            <ButtonUser
                                                                style={{minWidth: 100, height: 40}}
                                                                onClick={() => {
                                                                    isCompleted ? setType(DRAWER_TYPE.secondStepCandidatureUser) : setType(DRAWER_TYPE.firstStepCandidatureUser)
                                                                    dispatch(openDrawer())
                                                                }}>
                                                                Candidati
                                                            </ButtonUser>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {(myCandidatures && myCandidatures.some(element => element.offertId === idRecord)) ?
                                                <div>
                                                    <p>Hai applicato a questa offerta di lavoro!</p>
                                                    <p>Vai alle <span
                                                        className={"blue underlined weight-700 size-14 pointer"}
                                                        onClick={() => navigate(PRIVATE_USER_ROUTE.MY_CANDIDATURE)}>"Mie Candidature"</span> per
                                                        lo stato di avanzamento</p>
                                                </div>
                                                : null
                                            }
                                            <Divider>
                                                <div className="size-12 opacity-50">Descrizione</div>
                                            </Divider>
                                            <p dangerouslySetInnerHTML={{__html: recordOffer.description}}/>
                                        </Card>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div> :
                <div>
                    <div className={"margin-16"}>
                        <div className={"flex-column flex-align-center gap-10"}>
                            <div style={{
                                width: "150px",
                                height: "110px",
                                marginTop: 20,
                                border: "1px solid rgba(14, 14, 44, 0.15)",
                                float: "left"
                            }}>
                                <ImgComponent logoPath={company && company.logoPath}/>
                            </div>
                            <div
                                className="weight-700 size-22 dark merriWeather">{company && company.mainLocationName}</div>
                            <div className={"flex-row"}>
                                <Icon color="grey" name={ICONS.pin} size={[20, 20]}/>
                                <div className="dividerDot flex-center">
                                    <div>{company && company.mainLocationCityName && company.mainLocationCityName}</div>
                                    <div>{company && company.mainLocationProvinceName && company.mainLocationProvinceName}</div>
                                    <div>Italia</div>
                                </div>
                            </div>
                        </div>
                        <div className={"margin-top-16"}>
                            {company && company.description}
                        </div>
                        <div>
                            <div className="margin-top-24 flex-row">
                                <Space direction={"horizontal"} size={20}>
                                    {company && company.webPage &&
                                        <a href={company.webPage} target="_blank">
                                            <ButtonLine style={{height: "32px"}} className="flex-center">
                                                        <span>
                                                            Vedi sito web
                                                        </span>
                                                <span style={{transform: "rotate(-90deg)", marginTop: 5}}>
                                                            <Icon color="#208AC6" className="margin-left-5"
                                                                  name={ICONS.arrow_small} size={[20, 20]}/>
                                                        </span>
                                            </ButtonLine>
                                        </a>
                                    }
                                    {company && company.linkedin &&
                                        <a href={company.linkedin} target="_blank">
                                            <div className="margin-top-5">
                                                <Icon color="#208AC6" className="margin-left-5" name={ICONS.linkedin}
                                                      size={[20, 20]}/>
                                            </div>
                                        </a>
                                    }
                                </Space>
                            </div>
                        </div>
                        <Divider/>

                        <p className={"size-14"}><span
                            className={"weight-700"}>Offerte di lavoro </span>{"(" + offers.length + ")"}</p>

                        {offers &&
                            <List onClick={openDetailCard} dataSource={offers} className={"pointer"}>
                                {offers.map(offer =>
                                    <OfferElementMobile
                                        key={offer.id}
                                        offer={offer}
                                        selected={idRecord === offer.id}
                                        onClick={() => setIdRecord(offer.id)}
                                        candidate={myCandidatures?.some(element => element.offertId === offer.id)}
                                    />
                                )}
                            </List>
                        }
                    </div>
                    <DrawerListOffertUserMobile
                        width={"100%"}
                        recordOffer={recordOffer}
                        myCandidatures={myCandidatures}
                        openState={viewComponent}
                        setOpenState={setViewComponent}
                        idRecord={recordOffer && recordOffer.id}
                        setType={setType}
                    />
                </div>
            }
            <CustomDrawer
                record={""}
                modify={"modify"}
                state={""}
                isDrawerOpen={isDrawerOpen}
                type={type}
                setType={setType}
            />
        </div>
    )
}

export default DetailCompany
